import React, { useEffect, useState, useCallback } from "react";
import Container from "@material-ui/core/Container";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ReportingLogs } from "../../services/reportingLogs";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import GetAppIcon from "@material-ui/icons/GetApp";
import { toastr } from "react-redux-toastr";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});
function ReportsDetails({ match }) {
  const classes = useStyles();
  const [fieldsDetails, setFieldsDetails] = useState([]);
  const [csvfilename, setCsvfilename] = useState("");
  const [csvuploaddate, setCsvuploaddate] = useState("");
  const [advertiserid, setAdvertiserid] = useState("");
  const [tableHeader, setTableHeader] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id, action } = match.params;
  const [title, setTitle] = useState("");

  const fetchLogsDetails = useCallback(async () => {
    try {
      setLoading(true);
      const res = await ReportingLogs.fetchReportsById(id);
      if (res) {
        setLoading(false);
        if (action === "uploaded") {
          setTitle("Uploaded CSV Details");
          setCsvfilename(res?.data?.skippedRows?.csvfile);
          setFieldsDetails(res?.data?.skippedRows?.uploaded_fields);
          setCsvuploaddate(res?.data?.skippedRows?.createdAt);
          setAdvertiserid(res?.data?.skippedRows?.advertiser_id);
          setTableHeader(Object.keys(res.data?.skippedRows?.fields[0]));
        } else {
          setTitle("Skipped CSV Details");
          setCsvfilename(res?.data?.skippedRows?.csvfile);
          setFieldsDetails(res?.data?.skippedRows?.fields);
          setCsvuploaddate(res?.data?.skippedRows?.createdAt);
          setAdvertiserid(res?.data?.skippedRows?.advertiser_id);
          setTableHeader(Object.keys(res.data?.skippedRows?.fields[0]));
        }
      } else {
        throw new Error("Failed to fetch logs");
      }
    } catch (error) {
      toastr.error("Error", "Failed to fetch logs");
    } finally {
      setLoading(false);
    }
  }, [action, id]);

  useEffect(() => {
    fetchLogsDetails();
  }, [id, fetchLogsDetails]);

  const icon = <GetAppIcon fontSize="inherit" color="primary" />;

  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <div className="reporting-title">
          {/* <h3 style={{"fontSize": "36px","color": "blue"}}>{title}</h3>  */}
          <div className="publishers-container__header">
            <ContentHeader icon={icon} title={title} />
          </div>
          <List className={classes.root}>
            <ListItem>
              <ListItemText primary="CSV Name :" secondary={csvfilename} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Advertiser ID :"
                secondary={advertiserid}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="CSV Uploaded date :"
                secondary={moment(csvuploaddate).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Validation Steps :"
                secondary="Every ROWS 1.Check row data fields is valid 2: If valid then check publisherAccount to take PID"
              />
            </ListItem>
          </List>
        </div>
        <div className="reporting-content">
          <TableContainer component={Paper}>
            {!loading ? (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableHeader.map((header, index) => (
                      <TableCell align="center">{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fieldsDetails.map((row) => (
                    <TableRow key={row.index}>
                      {tableHeader.map((header, index) => (
                        <TableCell align="center">{row[header]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <h1 style={{ textAlign: "center" }}>Please Wait ....</h1>
            )}
          </TableContainer>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default ReportsDetails;
