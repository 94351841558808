import Api from '../api';

class LinkedAccount {
  fetchLinkedAccount = async (id) => {
    const { body } = await Api.get(`pubaccount/${id}`);
    return body;
  }

  updateLinkedAccount = async (id, data) => {
    const { body } = await Api.put(`pubaccount/${id}`, data);
    return body;
  }
}

export default new LinkedAccount();