import { useEffect } from "react";
import { useSelect } from "../../../hooks/useSelect";
import { useTextInput } from "../../../hooks/useTextInput";
import { useState } from "react";
import { SHOW_EDIT, SHOW_NEW_CREATION_FORM } from "./formUI.logic";
import { validURL } from "../../../helpers/validation";
import { toastr } from "react-redux-toastr";
import { changeNullToString, changeEmptyStringToNull } from "../../../helpers";
import { Provider } from "../../../services/Provider";
import { useModal } from "../../../hooks/useModal";
import { TAG_TYPES } from "../../../helpers/constant/types";

const inital_form_errors = {
  linkID: false,
  link: false,
  description: false,
  searchEngineId: false,
  tagTypeId: false,
  platformId: false,
  providerId: false,
  type: false,
  templatePath: false
};

export const useProviderLinkBox = ({
  pageData,
  currentPage,
  getProviderLinks,
  showAddButton
}) => {
  const [submittingForm, setSubmittingForm] = useState(false);

  const [id, handleIdChange, setId] = useTextInput({ initialValue: "" });
  const [link, handleLinkChange, setLink] = useTextInput({ initialValue: "" });
  const [description, handleDescriptionChange, setDescription] = useTextInput({
    initialValue: ""
  });
  const [source_tag, handleSourceTagChange, setSourceTag] = useTextInput({ initialValue: "" });
  const [clarity_id, handleClarityIdChange, setClarityId] = useTextInput({ initialValue: "" });

  const [backEndDuplicates, setBackEndDuplicates] = useState({
    hasDuplicatesInBackend: false,
    duplicateLinkIdsInBackend: []
  });

  const [formErrors, setFormErrors] = useState({ ...inital_form_errors });

  const [duplicateModalOpen, openDuplicateModal, closeDuplicateModal] =
    useModal();

  const {
    selectedData: selectedSearchEngine,
    changeSelectedData: changeSelectedSearchEngine
  } = useSelect();

  const {
    selectedData: selectedTagType,
    changeSelectedData: changeSelectedTagType
  } = useSelect();

  const {
    selectedData: selectedPlatform,
    changeSelectedData: changeSelectedPlatform
  } = useSelect();

  const {
    selectedData: selectedProvider,
    changeSelectedData: changeSelectedProvider
  } = useSelect();

  const { selectedData: selectedType, changeSelectedData: changeSelectedType } =
    useSelect();

  const {
    selectedData: selectedTemplate,
    changeSelectedData: changeSelectedTemplate
  } = useSelect();

  useEffect(() => {
    if (pageData) {
      setFormErrors({ ...inital_form_errors });
      setId(currentPage === SHOW_NEW_CREATION_FORM ? "" : pageData.id);
      setLink(changeEmptyStringToNull(pageData.link));
      setDescription(changeNullToString(pageData.description));
      changeSelectedPlatform(pageData?.platform?.id);
      changeSelectedSearchEngine(pageData?.search_engine?.id);
      changeSelectedTagType(pageData?.tag_type?.id);
      changeSelectedProvider(pageData?.provider?.id);
      changeSelectedType(pageData.type);
      changeSelectedTemplate(changeNullToString(pageData.template_path));
      setSourceTag(changeEmptyStringToNull(pageData.source_tag));
      setClarityId(changeEmptyStringToNull(pageData.clarity_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData, currentPage]);

  useEffect(() => {
    /** This useffect set value to the link, whenever a different tag type(redirect , xml or template) is selected */
    if (selectedType && selectedType !== TAG_TYPES.REDIRECT) {
      setLink(
        "https://xml-eu-ss.ysm.yahoo.com/d/search/p/logicleap/xmlb/multi/?mkt=de&adultFilter=clean&type=[CHANNEL]&on=4&os=1&in=2&is=1&Partner=logicleap_d2s_xmlb_2389&adEnable4thLine=true&adEnableCalloutExtension=1&adEnableReview=1&adEnableTopAd=true&adEnableActionExt=true&adEnhancedSiteLink=1&adSmartAnnotations=1&bolding=1&enableFavicon=1&enableHttpsFavicon=1&longAdsTitle=1&adEnableMerchRating=true&enableMerchRating=1&siteLink=1&serveUrl=[SERVE_URL]&ua=[USER_AGENT]&ip=[USER_IP]&Keywords=[Q]"
      );
    }
  }, [selectedType, setLink]);

  let formValues = {
    linkID: id,
    link: link,
    searchEngineId: selectedSearchEngine,
    tagTypeId: selectedTagType,
    platformId: selectedPlatform,
    description: description,
    providerId: selectedProvider,
    type: selectedType,
    templatePath: selectedTemplate,
    sourceTag: source_tag,
    clarityId: clarity_id
  };

  let handleChangeFunctions = {
    changeSelectedSearchEngine,
    changeSelectedTagType,
    changeSelectedPlatform,
    changeSelectedProvider,
    handleIdChange,
    handleLinkChange,
    handleDescriptionChange,
    changeSelectedType,
    changeSelectedTemplate,
    handleSourceTagChange,
    handleClarityIdChange
  };

  const getFormData = () => {
    let formData = {
      provider_id: selectedProvider,
      link: changeEmptyStringToNull(link),
      description: changeEmptyStringToNull(description)
        ? description.trim()
        : null,
      platform_id: selectedPlatform,
      search_engine_id: selectedSearchEngine,
      tag_type_id: selectedTagType,
      type: selectedType,
      template_path: changeEmptyStringToNull(selectedTemplate)
        ? selectedTemplate.trim()
        : null,
      source_tag: changeEmptyStringToNull(source_tag),
      clarity_id: changeEmptyStringToNull(clarity_id)
    };
    if (currentPage === SHOW_EDIT) {
      formData["id"] = pageData.id;
    }
    return formData;
  };

  const formValidate = (formData) => {
    let isValid = true;
    let currentFormErrors = { ...inital_form_errors };

    if (!validURL(formData.link)) {
      currentFormErrors.link = true;
      isValid = false;
    }

    if (validURL(formData.link)) {
      if (
        formData.link.includes("{") ||
        formData.link.includes("}") ||
        formData.link.includes("(") ||
        formData.link.includes(")")
      ) {
        isValid = false;
        currentFormErrors.link = true;
      }

      if (!formData.link.includes("[Q]")) {
        isValid = false;
        currentFormErrors.link = true;
      }
    }

    if (!formData["search_engine_id"]) {
      isValid = false;
      currentFormErrors.searchEngineId = true;
    }
    if (!formData["platform_id"]) {
      isValid = false;
      currentFormErrors.platformId = true;
    }
    if (!formData["tag_type_id"]) {
      isValid = false;
      currentFormErrors.tagTypeId = true;
    }

    if (!formData["provider_id"]) {
      isValid = false;
      currentFormErrors.providerId = true;
    }

    if (!formData["type"]) {
      isValid = false;
      currentFormErrors.type = true;
    }

    if (!isValid) {
      setFormErrors(currentFormErrors);
    }

    return [isValid, currentFormErrors];
  };

  async function fetchBackEndIdenticalLinks(providerId, formData) {
    let data = [formData.link];
    let link_id = formData.id ? formData.id : null;

    let hasDuplicatesInBackend = false;
    const duplicateLinkIdsInBackend = [];
    try {
      const identicalLinks = await Provider.getIdenticalLinks(
        providerId,
        {
          data
        },
        true,
        link_id
      );
      if (identicalLinks.success) {
        if (identicalLinks.data.is_Found_Identical) {
          hasDuplicatesInBackend = true;
          const duplicateLinks = identicalLinks.data.links;
          duplicateLinks.forEach((linkArray) => {
            let link = linkArray[0];
            duplicateLinkIdsInBackend.push({
              linkId: link.id,
              providerId: link.provider.id,
              providerName: link.provider.name
            });
          });
        }
      } else {
        throw new Error(JSON.stringify(identicalLinks.error));
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", error.message);
    }
    return { hasDuplicatesInBackend, duplicateLinkIdsInBackend };
  }

  async function checkBackEndIdenticalLinks(providerId, formData) {
    const { hasDuplicatesInBackend, duplicateLinkIdsInBackend } =
      await fetchBackEndIdenticalLinks(providerId, formData);
    setBackEndDuplicates({
      hasDuplicatesInBackend,
      duplicateLinkIdsInBackend
    });
    return { hasDuplicatesInBackend };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmittingForm(true);
    let formData = getFormData();
    let [isValid, newFormErrors] = formValidate(formData);
    if (!isValid) {
      setFormErrors(newFormErrors);
      setSubmittingForm(false);
      toastr.warning("Oops!", "Please fix the errors before saving!");
      return;
    }
    //check back end duplicate links
    const { hasDuplicatesInBackend } = await checkBackEndIdenticalLinks(
      formData.provider_id,
      formData
    );

    // If no duplicate links, submit Links
    if (!hasDuplicatesInBackend) {
      submitLinks(formData);
    } else {
      // If duplicate Links, open confirmation model box
      openDuplicateModal();
    }
  };

  const submitLinks = (formData) => {
    setFormErrors({ ...inital_form_errors });
    Provider.saveProviderLinks(formData.provider_id, formData)
      .then((response) => {
        if (response.success) {
          getProviderLinks();
          closeDuplicateModalHandler();
          showAddButton();
          toastr.success("Success", "Advertiser links saved!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        closeDuplicateModalHandler();
        toastr.error("Oops!", error.message);
      });

    closeDuplicateModalHandler();
  };

  const closeDuplicateModalHandler = () => {
    setSubmittingForm(false);
    closeDuplicateModal();
  };

  return {
    formValues,
    formErrors,
    handleChangeFunctions,
    submittingForm,
    handleSubmit,
    backEndDuplicates,
    closeDuplicateModal,
    duplicateModalOpen,
    getFormData,
    submitLinks,
    closeDuplicateModalHandler
  };
};
