import React, { useEffect, useState, useCallback } from "react";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";

import NotExistInfo from "./Action";
import Details from "./Details";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";
import { PublisherApiService } from "../../../services/publisherApi";
import { getCurrentUser } from "../../../helpers/AuthHelper";

const PublisherApiInfo = (props) => {
  const [isExistInfo, setIsExistInfo] = useState(false);
  const [apiDetails, setApiDetails] = useState({});
  const [confirm_box_open, setConfirmBoxOpen] = useState(false);
  const { id } = useParams() || null;
  let currentUser = useSelector(getCurrentUser);

  const fetchApiDetails = useCallback(async () => {
    try {
      // id will exist for admin page
      // and will fetch token of the given id
      if (id) {
        const response = await PublisherApiService.fetchPublisherApiInfo(id);
        if (response) {
          if (response?.success && response?.data) {
            setApiDetails(response.data);
            setIsExistInfo(true);
          }
        } else {
          throw new Error("Something went wrong");
        }
      } else {
        // for publishers account
        // token will be fetched from the from the id of the publiser state
        const response =
          await PublisherApiService.fetchPublisherApiInfoByAccountId(
            currentUser.id
          );
        if (response) {
          if (response?.success && response?.data) {
            setApiDetails(response.data);
            setIsExistInfo(true);
          }
        } else {
          throw new Error("Something went wrong");
        }
      }
    } catch (error) {
      toastr.error("Error", error.message);
    }
    // eslint-disable-next-line
  }, [id, isExistInfo, currentUser.id]);

  useEffect(() => {
    fetchApiDetails();
  }, [fetchApiDetails]);

  const icon = <InfoIcon fontSize="inherit" color="primary" />;

  const isExistInfoDetails = () => {
    // if any token has not been set and the admin is logged in
    if (!isExistInfo && id) {
      return (
        <NotExistInfo
          isExistInfo={isExistInfo}
          publisher={id}
          setIsExistInfo={setIsExistInfo}
        />
      );
    }
    // if the token does not exist and the publisher is logged in
    if (!isExistInfo && !id) {
      return (
        <div>
          <h3
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "300" }}
          >
            <HelpIcon fontSize="inherit" color="primary" /> Please contact admin
            to access revenue API
          </h3>
        </div>
      );
    }
  };

  const regenerateToken = async () => {
    if (isExistInfo && id) {
      const generated = await PublisherApiService.postPublisherApiInfo({}, id);
      console.log(generated, "generated");
      if (generated) {
        setIsExistInfo(true);
        fetchApiDetails();
      }
    }
  };
  const handleRegenerate = () => {
    setConfirmBoxOpen(true);
  };

  return (
    <>
      {confirm_box_open && (
        <ConfirmBox
          message="Are you sure You Want to regenerate token"
          confirmAction={async () => {
            await regenerateToken();
            setConfirmBoxOpen(false);
          }}
          denyAction={() => setConfirmBoxOpen(false)}
        />
      )}
      <div className="publishers-container">
        <div className="publishers-container__header">
          <ContentHeader icon={icon} title="API Informations" />
        </div>
        {isExistInfo && id && (
          <div className="publishers-container__addBtn">
            <Button
              onClick={handleRegenerate}
              variant="contained"
              size="medium"
              color="primary"
            >
              Regenerate Token
            </Button>
          </div>
        )}
        <div className="publisher-apis">
          {isExistInfo && (
            <Details data={apiDetails} isExistInfo={isExistInfo} />
          )}
          {isExistInfoDetails()}
          {/* {!isExistInfo && (
          <NotExistInfo
            isExistInfo={isExistInfo}
            publisher={id}
            setIsExistInfo={setIsExistInfo}
          />
        )} */}
        </div>
      </div>
    </>
  );
};

export default PublisherApiInfo;
