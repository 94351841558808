import { useState, useCallback, useEffect } from "react";
import { useIsMounted } from "../../../../hooks/useIsMounted";

import { Log } from "../../../../services/Logs";
import { Report } from "../../../../services/Report";

export const useGetRuleStatsLogic = (ruleId) => {
  const [loadingSearchStats, setLoadingSearchStats] = useState({
    loading: false,
    error: false
  });
  const [searchStats, setSearchStats] = useState({});

  //   Life Stat -> Last Hour Activity
  const [loadingLifeStats, setLoadingLifeStats] = useState({
    loading: false,
    error: false
  });
  const [lastHourActivity, setLastHourActivity] = useState({});

  const [loadingGrossRevenueStats, setLoadingGrossRevenueStats] = useState({
    loading: false,
    error: false
  });
  const [grossRevenueStats, setGrossRevenueStats] = useState({});

  const isMounted = useIsMounted();

  const fetchSearchStats = useCallback(
    async (ruleId, fromDate, setLoading, setStats) => {
      try {
        setLoading({ loading: true, error: false });
        const fetchedLinkedAccounts = await Log.fetchLogs({
          from: fromDate,
          rule: ruleId
        });
        if (fetchedLinkedAccounts.success && isMounted()) {
          setStats(fetchedLinkedAccounts.data);
          setLoading({ loading: false, error: false });
        } else {
          throw new Error(JSON.stringify(fetchedLinkedAccounts.error));
        }
      } catch (error) {
        console.trace(
          error?.message || "Oops! there was an error loading search stats"
        );
        if (isMounted()) {
          setStats({});
          setLoading({ loading: false, error: true });
        }
      }
    },
    [isMounted]
  );

  const fetchGrossRevenueStats = useCallback(
    async (rule_id, order_by, order_direction, interval, start_date) => {
      try {
        setLoadingGrossRevenueStats({ loading: true, error: false });
        const fetchedGrossRevenueStats = await Report.fetchDashboardData({
          rule_id,
          order_by,
          order_direction,
          interval,
          start_date
        });
        if (fetchedGrossRevenueStats.success && isMounted()) {
          setGrossRevenueStats(fetchedGrossRevenueStats.data);
          setLoadingGrossRevenueStats({ loading: false, error: false });
        } else {
          throw new Error(JSON.stringify(fetchedGrossRevenueStats.error));
        }
      } catch (error) {
        console.trace(
          error?.message || "Oops! there was an error net revenue stats"
        );
        if (isMounted()) {
          setLoadingGrossRevenueStats({ loading: false, error: true });
          setGrossRevenueStats({});
        }
      }
    },
    [isMounted]
  );

  const fetchLast24hoursStats = useCallback(
    (ruleId) => {
      const last24hours = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
      fetchSearchStats(
        ruleId,
        last24hours,
        setLoadingSearchStats,
        setSearchStats
      );
    },
    [fetchSearchStats]
  );

  const fetchLastHourStats = useCallback(
    (ruleId) => {
      const lastHour = new Date(new Date().getTime() - 1 * 60 * 60 * 1000);
      fetchSearchStats(
        ruleId,
        lastHour,
        setLoadingLifeStats,
        setLastHourActivity
      );
    },
    [fetchSearchStats]
  );

  useEffect(() => {
    if (ruleId && isMounted()) {
      fetchLast24hoursStats(ruleId);
      fetchLastHourStats(ruleId);
      fetchGrossRevenueStats(
        ruleId,
        "date",
        "DESC",
        "day",
        new Date("1990-01-01")
      );
    }
  }, [
    fetchLast24hoursStats,
    fetchLastHourStats,
    fetchGrossRevenueStats,
    ruleId,
    isMounted
  ]);

  const searchCount = searchStats?.logs?.[0]?.total_searches || 0;

  // hasLife = record in last hour in the search logs
  const hasLife = lastHourActivity?.logs?.[0]?.total_searches ? true : false;

  const grossRevenue = grossRevenueStats?.list?.[0]?.gross_revenue || 0;

  const grossRevenueDate = grossRevenueStats?.list?.[0]?.date || "";

  return {
    hasLife,
    loadingLifeStats,
    searchCount,
    loadingSearchStats,
    grossRevenue,
    grossRevenueDate,
    loadingGrossRevenueStats
  };
};
