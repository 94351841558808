import React from "react";
import moment from "moment";
const CsvRowDetails = ({ uploadStatus }) => {
  const listRender = () => {
    if (
      uploadStatus.uploaded_status === "Uploaded Successfully" ||
      uploadStatus.uploaded_status === "Partially Uploaded" ||
      uploadStatus.uploaded_status === "Zero Records Uploaded"
    ) {
      return (
        <>
          <li>
            <span>Total records :</span> {uploadStatus?.total_rows}
          </li>
          <li>
            <span>Total skipped records :</span> {uploadStatus?.total_skipped}
          </li>
          <li>
            <span>Total uploaded records :</span>
            {uploadStatus?.total_uploaded}
          </li>
          <li>
            <span>Total checksum in CSV:</span>
            {uploadStatus?.check_sum_totalSearches}
          </li>

          <li>
            <span>Total checksum in DB :</span>
            {uploadStatus?.uploaded_sum_totalSearches}
          </li>

          <li className="subid">
            <div>
              <span>Uploaded subIds/channel</span>
            </div>
            {uploadStatus.uploaded_subid?.length > 0 && (
              <div className="skipped-uploaded-subId">
                {uploadStatus.uploaded_subid.join(",  ")}
              </div>
            )}
          </li>
          <li className="subid">
            <div>
              <span>Validation failed for Channels :</span>
            </div>
            {uploadStatus.skippedSubIdWithValidation?.length > 0 && (
              <div className="skipped-uploaded-subId">
                {uploadStatus.skippedSubIdWithValidation.join(",  ")}
              </div>
            )}
          </li>
          <li className="subid">
            <div>
              <span>Missing Ad-accounts for Channels :</span>
            </div>
            {uploadStatus.skippedSubIdWithNotFoundPubAccount?.length > 0 && (
              <div className="skipped-uploaded-subId">
                {uploadStatus.skippedSubIdWithNotFoundPubAccount.join(",  ")}
              </div>
            )}
          </li>
          <li className="subid">
            <div>
              <span>Missing Advertiser tags for Channels :</span>
            </div>
            {uploadStatus.missing_tags_with_subIds?.length > 0 && (
              <div className="skipped-uploaded-subId">
                {uploadStatus.missing_tags_with_subIds.join(",  ")}
              </div>
            )}
          </li>

          <li className="subid">
            <div>
              <span>Missing Ad-accounts for Channels (Tags exist) :</span>
            </div>
            {uploadStatus.exist_tags_with_subIds?.length > 0 && (
              <div className="skipped-uploaded-subId">
                {uploadStatus.exist_tags_with_subIds.join(",  ")}
              </div>
            )}
          </li>
        </>
      );
    }
  };
  return (
    <div className="api-details" style={{ margin: "20px 0px" }}>
      <div className="header">
        <h2>
          CSV upload details for the advertiser{" "}
          {uploadStatus?.provider.id + "-" + uploadStatus?.provider.name}
        </h2>
        <h2>
          Applied date (UTC) :
          {uploadStatus?.startDate
            ? moment(uploadStatus?.startDate).format("dddd, MMMM Do YYYY, hA")
            : "Not Applied"}
        </h2>
      </div>
      <ul style={{ padding: "10px" }}>
        <li>
          <span>Applied date :</span>
          {moment(uploadStatus?.startDate).format("dddd, MMMM Do YYYY, hA")}
        </li>

        <li>
          <span>Message/Info :</span> {uploadStatus?.message}
        </li>
        <li>
          <span>Status :</span>{" "}
          {uploadStatus?.uploaded_status
            ? uploadStatus?.uploaded_status
            : uploadStatus?.status}
        </li>
        <li>
          <span>File name :</span>{" "}
          {uploadStatus?.csv_name
            ? uploadStatus?.csv_name
            : uploadStatus?.csv_name}
        </li>
        {listRender()}
      </ul>
    </div>
  );
};

export default CsvRowDetails;
