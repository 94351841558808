import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  FormLabel,
  IconButton,
  Checkbox,
  FormControlLabel,
  TextField,
  Divider,
  Switch,
  Radio,
  RadioGroup,
  Tooltip,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import {
  FileCopyTwoTone,
  HistoryOutlined,
  InfoSharp
} from "@material-ui/icons";
// import History from '@material-ui/icons/InfoTwoTone';
import TargetRule from "./TargetRule";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DoneIcon from "@material-ui/icons/Done";
import TargetIcon from "@material-ui/icons/OfflineBoltSharp";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import PublishIcon from "@material-ui/icons/PublishSharp";
import CloseIcon from "@material-ui/icons/CloseSharp";
import { Publisher } from "../../services/Publisher";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getTargetingRules,
  getTargetingRulesError,
  getTargeting,
  getDeletedTargetingRules
} from "../../reducers/target/target.selector";
import {
  updateTargetingRules,
  updateTargetingFormErrors,
  updateTargetingForm,
  clearTargetingForm
} from "../../reducers/target/target.action";
import { textFieldValid, validURL } from "../../helpers/validation";
import { toastr } from "react-redux-toastr";
import { copyToClipboard } from "../../helpers/index";
import moment from "moment";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import { Prompt } from "react-router-dom";
import History from "./layouts/History";
import { getSettingsObject } from "../../reducers/setting/setting.selector";
import {
  updateDefaultSettings,
  updateSettingsObject
} from "../../reducers/setting/setting.action";
import { Setting } from "../../services/Settings";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { TAG_TYPES, TARGETING_TYPES } from "../../helpers/constant/types";
import HelpIcon from "@material-ui/icons/Help";
import InfoToolTip from "../../components/InfoToolTip/InfoToolTip";
import SavingMessage from "./layouts/SavingMessage";
import {
  formatTargetDataForSave,
  sumOfInputedWeights,
  areTheWeightsValid,
  isThereLimitlessDailyCap,
  getNumberOfActiveRules,
  ROUND_ROBIN_HELP_TEXT,
  WATERFALL_HELP_TEXT,
  REDIRECT_HELP_TEXT,
  XML_HELP_TEXT,
  TEMPLATE_HELP_TEXT
} from "./helper/helper-functions";
import { useFormTouched } from "../../hooks/useFormTouched";
import AutoCompleteSelect from "../../components/AutoCompleteSelect/AutoCompleteSelect";
import FormAddtionalHeaders from "./layouts/FormAddtionalHeaders";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../helpers/constant/misc";
import DuplicateLinksMsg from "./layouts/DuplicateLinksMsg";
import { PublisherAccount } from "../../services/publisherAccounts";
import { useModal } from "../../hooks/useModal";
import DuplicateAdvertiserLinksMsg from "./layouts/DuplicateAdvertiserLinksMsg";
import TargetTypeRule from "./TargetTypeRule";

export default function Index() {
  const history = useHistory();
  const settingsObject = useSelector(getSettingsObject);
  const [DEFAULT_DOMAIN, setDEFAULT_DOMAIN] = useState(
    () => settingsObject?.default_domain || ""
  );

  const [S2S_DOMAIN, setS2S_DOMAIN] = useState(
    () => settingsObject?.redirection_domain || ""
  );
  const [DEFAULT_FALLBACK, setDEFAULT_FALLBACK] = useState(
    () => settingsObject?.system_fallback || ""
  );

  const targetingRules = useSelector(getTargetingRules);
  const targetingRulesError = useSelector(getTargetingRulesError);
  const deletedTargetingRules = useSelector(getDeletedTargetingRules);
  const [oldRules, setOldRules] = useState(null);
  const initialTargeting = useSelector(getTargeting);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [rulesBlockNumber, setRulesBlockNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [publisher, setPublisher] = useState(initialTargeting.publisher);
  const [state, setState] = useState({
    cusDomain: initialTargeting.cusDomain,
    cusFallback: initialTargeting.cusFallback,
    useN: initialTargeting.useN,
    useClickId: initialTargeting.useClickId,
    useSubId: initialTargeting.useSubId,
    domain: initialTargeting.domain,
    subID: initialTargeting.subID,
    clientID: initialTargeting.clientID,
    // oID: initialTargeting.oID,
    fallback: initialTargeting.fallback,
    targeting: initialTargeting.targeting,
    notes: initialTargeting.notes,
    tagDescription: initialTargeting.tagDescription,
    status: initialTargeting.status,
    updatedAt: initialTargeting.updatedAt,
    publishedAt: initialTargeting.publishedAt,
    tag_type: initialTargeting.tag_type
  });
  const [activate, setActivate] = useState(initialTargeting.is_active);
  const [publishers, setPublishers] = useState(null);
  const [disabledPublishers, setDisabledPublishers] = useState([]); //publishers whose status is inactive
  const [targetLink, setTargetLink] = useState(initialTargeting.link);
  const [editing, setEditing] = useState(false);
  const [logicError, setLogicError] = useState(false);
  const [sendingFormData, setSendingFormData] = useState(false);
  //checking the url param to see if edit page is to be used
  const { pathname } = useLocation();
  const { id } = useParams() || null;
  const [targetingID, setTargetingID] = useState(id);
  const [showPrompt, setShowPrompt] = useState(true);

  //state for modal
  const [open, setOpen] = useState(false);
  //state for show History Model
  const [openHistory, setOpenHistory] = useState(false);
  //state for popup message when weight is less than 100  when round robin is selected
  const [openWeightMessage, setOpenWeightMessage] = useState({
    modal: false,
    type: "draft"
  });
  const [targetingHistory, setTargetingHistory] = useState({});
  const handleModalClose = () => setOpen(false);
  const handleHistoryModalClose = () => setOpenHistory(false);
  const handleWeightMessageModalClose = () =>
    setOpenWeightMessage({ modal: false, type: "draft" });
  const handleDuplicateAction = () => {
    setOpen(true);
  };
  const [formOneErrors, setFormOneErrors] = useState({
    publisher: false,
    subID: false,
    clientID: false,
    // oID: false,
    targetLink: false,
    fallback: false,
    domain: false
  });

  // duplicated provider links in the backend
  const [duplicateProviderLinksDB, setDuplicatedProviderLinksDB] = useState([]);

  // duplicated provider links in the current form page
  const [duplicatedProviderLinks, setDuplicatedProviderLinks] = useState([]);

  const [
    duplicateAdLinksModal,
    openDuplicateLinksModal,
    closeDuplicateLinksModal
  ] = useModal(false);

  const cleanupFunction = useCallback(() => {
    stableDispatch(clearTargetingForm());
  }, [stableDispatch]);
  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction
  });

  //clearning up form touched when the page opens a new targeting ID
  useEffect(() => {
    clearFormTouched();
  }, [clearFormTouched, targetingID]);

  const fetchTargeting = useCallback(
    (id) => {
      setLoading(true);
      Publisher.fetchTargeting(id)
        .then((response) => {
          if (response.success) {
            let targeting = response.data.targeting;
            if (!targeting) {
              history.push("/target");
              toastr.warning("Oops", "No such targeting found!");
            }
            setPublisher(targeting?.publisher_id);
            setOldRules(
              JSON.stringify(response.data.targeting?.targeting_rules) || ""
            );
            setState((state) => ({
              ...state,
              domain: targeting?.custom_domain,
              cusDomain:
                targeting?.use_custom_domain === "true" ||
                targeting?.use_custom_domain
                  ? true
                  : false,
              cusFallback:
                targeting?.use_custom_fallback === "true" ||
                targeting?.use_custom_fallback
                  ? true
                  : false,
              subID: targeting?.sub_id,
              clientID: targeting?.client_id,
              // oID: targeting.o_id,
              useN: targeting?.n === "true" || targeting?.n ? true : false,
              useClickId:
                targeting?.click_id === "true" || targeting?.click_id
                  ? true
                  : false,
              useSubId:
                targeting?.use_sid === "true" || targeting?.use_sid
                  ? true
                  : false,
              notes: targeting?.notes ? targeting?.notes : "",
              tagDescription: targeting?.tag_description
                ? targeting?.tag_description
                : "",
              status: targeting?.status,
              targeting: targeting?.targeting_type,
              fallback: targeting?.default_fallback
                ? targeting?.default_fallback
                : "",
              updatedAt: targeting?.updatedAt,
              publishedAt: targeting?.publishedAt,
              tag_type: targeting?.tag_type
            }));
            setTargetLink(targeting?.link);
            setActivate(targeting?.is_active);
            setRulesBlockNumber(targeting?.targeting_rules.length);
            setTargetingHistory(targeting?.history);
            stableDispatch(
              updateTargetingRules(
                targeting?.targeting_rules ? targeting?.targeting_rules : []
              )
            );
            setLoading(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          setLoading(false);
        });
    },
    [stableDispatch, history]
  );
  //create and dispatch error fields equivalent to targeting rules length for ruleset Form
  useEffect(() => {
    const formErrors = [];
    for (let j = 0; j < targetingRules.length; j++) {
      formErrors.push({
        dailyCap: false,
        trafficOne: false,
        trafficTwo: false,
        providerOne: false,
        providerTwo: false,
        providerOneLink: false,
        providerTwoLink: false,
        dailyFrequency: false
      });
    }
    stableDispatch(updateTargetingFormErrors(formErrors));
  }, [targetingRules.length, targetingRules, stableDispatch]);

  //changing rules blockset when targeting rules length changes
  useEffect(() => {
    setRulesBlockNumber(targetingRules.length);
  }, [targetingRules.length]);

  //confirming if user wants to leave
  useEffect(() => {
    if (id && pathname.includes("edit")) {
      setEditing(true);
      setTargetingID(id);
      fetchTargeting(id);
    } else {
      setEditing(false);
      setLoading(false);
    }
  }, [stableDispatch, fetchTargeting, id, pathname]);

  /**
   * CAUTION!! Replaced cid with tid in
   * the link, also cid and tid are used as the
   * same field in the targeting engine. Do not
   * add another cid field in the link unless
   * it's replacing tid's role.
   */
  const handleGenerateLink = useCallback(() => {
    let link = "";
    if (state.cusDomain) {
      if (state.domain !== "") {
        link = state.domain + "?";
        if (publisher !== "none" && publisher !== "")
          link = `${link}pid=${publisher}&`;
        link = `${link}tid=${state.clientID ? state.clientID : "[TID]"}&`;
        if (state.useSubId || state.subID) {
          link = `${link}sid=${state.subID ? state.subID : "[SID]"}&`;
        }
        // link = `${link}sid=${state.subID ? state.subID : "[SID]"}&`;
        // link = `${link}oid=${state.oID ? state.oID : "[OID]"}&`;
        if (state.useClickId) link = `${link}click_id=[CLICK_ID]&`;
        if (state.useN) link = `${link}n=[N]&`;
        link = `${link}q=[QUERY]`;
      }
    } else {
      link = (state.tag_type && state.tag_type === TAG_TYPES.XML) ? S2S_DOMAIN + "?" : DEFAULT_DOMAIN + "?";
      if (publisher !== "none" && publisher !== "")
        link = `${link}pid=${publisher}&`;
      link = `${link}tid=${state.clientID ? state.clientID : "[TID]"}&`;
      if (state.useSubId || state.subID) {
        link = `${link}sid=${state.subID ? state.subID : "[SID]"}&`;
      }
      // link = `${link}sid=${state.subID ? state.subID : "[SID]"}&`;
      // link = `${link}oid=${state.oID ? state.oID : "[OID]"}&`;
      if (state.useClickId) link = `${link}click_id=[CLICK_ID]&`;
      if (state.useN) link = `${link}n=[N]&`;
      link = `${link}q=[QUERY]`;
    }

    if(state.tag_type && state.tag_type !== TAG_TYPES.REDIRECT){
      link = `${link}&channel=[CHANNEL]&serve_url=[SERVE_URL]&user_agent=[USER_AGENT]&user_ip=[USER_IP]`;
    }

    setTargetLink(link);
    return link;
  }, [
    publisher,
    state.clientID,
    state.cusDomain,
    state.domain,
    // state.oID,
    state.subID,
    state.useClickId,
    state.useSubId,
    state.useN,
    DEFAULT_DOMAIN,
    S2S_DOMAIN,
    state.tag_type
  ]);
  useEffect(() => {
    handleGenerateLink();
  }, [
    state.subID,
    state.clientID,
    // state.oID,
    state.domain,
    state.useN,
    state.useClickId,
    state.cusDomain,
    state.useSubId,
    handleGenerateLink
  ]);

  const validateForm1 = () => {
    let errorFields = [];
    if (publisher === "none" || publisher === "") {
      errorFields.push("publisher");
    }

    if (state.cusDomain) {
      if (!textFieldValid(state.domain) || !validURL(state.domain)) {
        errorFields.push("domain");
      }
    }

    if (!textFieldValid(targetLink)) {
      errorFields.push("targetLink");
    }

    if (state.clientID) {
      if (state.clientID < 0) {
        errorFields.push("clientID");
      }
    }

    if (textFieldValid(state.fallback)) {
      if (!validURL(state.fallback)) {
        errorFields.push("fallback");
      }
    }
    if (!errorFields.length > 0) {
      return true;
    } else {
      let errors = {};
      errorFields.forEach((field) => {
        errors[field] = true;
      });

      setFormOneErrors((formOneErrors) => {
        return {
          ...formOneErrors,
          ...errors
        };
      });
      return false;
    }
  };

  //validating the values of the ruleset Form
  const validateForm2 = () => {
    let isValid = true;
    let weightsValid = true;
    let limitlessDailyCapExists = true;
    const formErrors = [...targetingRulesError];
    let providerLinksSelected = {};

    //validate if targeting Rules was create
    if (targetingRules.length === 0) {
      isValid = false;
      toastr.error("Oops", "Please add targeting rules!!!");
    }

    //validating that the sum of all the weights does not exceed 100
    if (state.targeting === TARGETING_TYPES.ROUND_ROBIN) {
      if (!areTheWeightsValid(targetingRules)) {
        isValid = false;
        weightsValid = false;
        setLogicError(true);
        toastr.warning(
          "Oops!",
          "Total Weights(%) exceed 100% / Enabled rule with 0 value for weight!"
        );
      }
    }

    if (state.targeting === TARGETING_TYPES.WATERFALL) {
      if (!isThereLimitlessDailyCap(targetingRules)) {
        isValid = false;
        limitlessDailyCapExists = false;
        setLogicError(true);
        toastr.warning(
          "Oops!",
          "Daily Cap of atleast one rule must be default value(0)!"
        );
      }
    }

    for (let i = 0; i < targetingRules.length; i++) {
      let rule = targetingRules[i];
      let ruleDisabled = rule?.disabled || false;
      let formErrorObject = formErrors[i];
      if (
        rule.daily_cap === "" ||
        isNaN(rule.daily_cap) ||
        parseInt(rule.daily_cap) < 0
      ) {
        isValid = false;
        formErrorObject.dailyCap = true;
      }

      if (state.targeting === TARGETING_TYPES.WATERFALL) {
        if (!limitlessDailyCapExists) {
          formErrorObject.dailyCap = true;
        }
      }

      //validating that weights are valid
      if (state.targeting === TARGETING_TYPES.ROUND_ROBIN) {
        if (
          !ruleDisabled &&
          (rule.daily_frequency <= 0 || rule.daily_frequency > 100)
        ) {
          isValid = false;
          formErrorObject.dailyFrequency = true;
        }
        if (
          rule.daily_frequency === undefined ||
          rule.daily_frequency === null ||
          isNaN(parseFloat(rule.daily_frequency))
        ) {
          isValid = false;
          formErrorObject.dailyFrequency = true;
        }
        if (!weightsValid) {
          formErrorObject.dailyFrequency = true;
        }
      }

      if (!rule.provider_details || rule.provider_details.length === 0) {
        isValid = false;
        formErrorObject.trafficOne = true;
      } else {
        if (rule.provider_details.length === 2) {
          //case when two providers are selected
          if (
            !rule.provider_details[0].traffic ||
            isNaN(rule.provider_details[0].traffic) ||
            parseInt(rule.provider_details[0].traffic) <= 0
          ) {
            isValid = false;
            formErrorObject.trafficOne = true;
          }
          if (
            !rule.provider_details[0].provider_id ||
            rule.provider_details[0].provider_id === ""
          ) {
            isValid = false;
            formErrorObject.providerOne = true;
          }
          let providerOneLink = rule.provider_details[0].provider_link;
          if (providerOneLink === "") {
            isValid = false;
            formErrorObject.providerOneLink = true;
          } else if (!ruleDisabled) {
            providerLinksSelected[providerOneLink] =
              providerLinksSelected.hasOwnProperty(providerOneLink)
                ? providerLinksSelected[providerOneLink] + 1
                : 1;
          }
          if (
            !rule.provider_details[1].traffic ||
            isNaN(rule.provider_details[1].traffic) ||
            parseInt(rule.provider_details[1].traffic) <= 0
          ) {
            isValid = false;
            formErrorObject.trafficTwo = true;
          }
          if (
            !rule.provider_details[1].provider_id ||
            rule.provider_details[1].provider_id === ""
          ) {
            isValid = false;
            formErrorObject.providerTwo = true;
          }
          let providerTwoLink = rule.provider_details[1].provider_link;
          if (providerTwoLink === "") {
            isValid = false;
            formErrorObject.providerTwoLink = true;
          } else if (!ruleDisabled) {
            providerLinksSelected[providerTwoLink] =
              providerLinksSelected.hasOwnProperty(providerTwoLink)
                ? providerLinksSelected[providerTwoLink] + 1
                : 1;
          }
          if (
            parseInt(rule.provider_details[1].traffic) +
              parseInt(rule.provider_details[0].traffic) !==
            100
          ) {
            isValid = false;
            formErrorObject.trafficOne = true;
          }
        } else {
          //case when only one provider is selected
          if (
            !rule.provider_details[0].traffic ||
            isNaN(rule.provider_details[0].traffic) ||
            parseInt(rule.provider_details[0].traffic) !== 100
          ) {
            isValid = false;
            formErrorObject.trafficOne = true;
          }
          if (
            !rule.provider_details[0].provider_id ||
            rule.provider_details[0].provider_id === ""
          ) {
            isValid = false;
            formErrorObject.providerOne = true;
          }
          let providerOneLink = rule.provider_details[0].provider_link;
          if (providerOneLink === "") {
            isValid = false;
            formErrorObject.providerOneLink = true;
          } else if (!ruleDisabled) {
            providerLinksSelected[providerOneLink] =
              providerLinksSelected.hasOwnProperty(providerOneLink)
                ? providerLinksSelected[providerOneLink] + 1
                : 1;
          }
        }
      }
    }

    // validating for duplicates in provider links for rules that are not disabled
    let duplicatedProviderLinks = [];
    let providerLinksSelectedKeys = Object.keys(providerLinksSelected);
    for (let i = 0; i < providerLinksSelectedKeys.length; i++) {
      let linkToCheck = providerLinksSelectedKeys[i];
      if (providerLinksSelected[linkToCheck] > 1) {
        duplicatedProviderLinks.push(linkToCheck);
        isValid = false;
        setLogicError(true);
      }
    }
    setDuplicatedProviderLinks(duplicatedProviderLinks);
    dispatch(updateTargetingFormErrors(formErrors));
    return isValid;
  };

  /**
   *  @returns isValid;
   *  @description validates if the advertiser links selected here is duplicate with any publisher tag in the backend
   */
  const validateAdvertiserLinks = async () => {
    let isValid = true;
    let publisherLinks = [];
    let activeTargetingRules = targetingRules.filter((rule) => !rule.disabled);

    // send the link ids to the backend end point
    activeTargetingRules.forEach((rule) => {
      if (rule.provider_details.length === 2) {
        publisherLinks.push(rule.provider_details[0].provider_link);
        publisherLinks.push(rule.provider_details[1].provider_link);
      } else {
        publisherLinks.push(rule.provider_details[0].provider_link);
      }
    });

    const dataToSend = {
      linkIds: publisherLinks
    };

    try {
      const duplicatesCall = await PublisherAccount.verifyAccounts(dataToSend);
      if (!duplicatesCall.success)
        throw new Error(JSON.stringify(duplicatesCall.error));
      let duplicates = duplicatesCall.data;
      if (id) {
        duplicates = duplicates.filter((duplicate) => +duplicate.tid !== +id);
      }
      if (duplicates.length > 0) {
        isValid = false;
        setDuplicatedProviderLinksDB(duplicates);
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error(
        "Error",
        "Error verifying advertiser links and publisher ad accounts"
      );
      isValid = false;
    }
    return isValid;
  };

  /**
   * @description opens the modal showing the duplicated advertiser links in other tags using the value saved in state
   */
  const openDuplicatedAdvertiserLinksModal = () => {
    openDuplicateLinksModal();
  };

  const closeDuplicatedAdvertiserLinksModal = () => {
    setDuplicatedProviderLinksDB([]);
    closeDuplicateLinksModal();
  };

  const fetchPublishers = () => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          let inactivePublishers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            isInactive && inactivePublishers.push(p.id);
            publishers.push({
              label: p.name,
              value: p.id,
              disabled: isInactive
            });
          }
          setDisabledPublishers(inactivePublishers);
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  };
  useEffect(() => {
    if (!settingsObject?.default_domain || !settingsObject?.system_fallback) {
      Setting.fetchSettings()
        .then((response) => {
          if (response.success) {
            const settings = response.data?.settings || [];
            let settingsObject = {};
            settings.forEach((setting) => {
              settingsObject[setting.key] = setting.value;
            });
            stableDispatch(updateSettingsObject(settingsObject));
            stableDispatch(updateDefaultSettings(settings));
            setDEFAULT_DOMAIN(settingsObject?.default_domain);
            setS2S_DOMAIN(settingsObject?.redirection_domain);
            setDEFAULT_FALLBACK(settingsObject?.system_fallback);
            if (
              !settingsObject?.default_domain ||
              !settingsObject?.system_fallback
            ) {
              toastr.warning("Oops!", "Default Domain not set");
              history.push("/settings");
            }
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          toastr.error("Opps", "Cannot load default settings!!!");
          console.trace(error.message);
        });
    }
    fetchPublishers();
  }, [history, settingsObject, stableDispatch, DEFAULT_DOMAIN]);

  const addMoreRulesBlock = () => {
    let currentTargetingRules = [...targetingRules];
    currentTargetingRules.push({
      daily_cap: 0,
      daily_frequency: 100,
      provider_details: [{ traffic: 100 }]
    });
    dispatch(updateTargetingRules(currentTargetingRules));
  };

  const handlePublisherChange = (value) => {
    if (disabledPublishers.includes(value)) {
      return;
    }
    setPublisher(value);
    handleFormTouched();
  };
  const handleChange = (event) => {
    handleFormTouched();
    setFormOneErrors({
      publisher: false,
      subID: false,
      clientID: false,
      // oID: false,
      targetLink: false,
      fallback: false,
      domain: false
    });
    //making useSubId false if static data entered for sub id
    if (event.target.name === "subID") {
      setState({
        ...state,
        [event.target.name]: event.target.value,
        useSubId: false
      });
      return;
    }
    //clearing subID if useSubID is true
    if (event.target.name === "useSubId") {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
        subID: null
      });
      return;
    }

    //not chaning values if negative values are entered
    //for OID and Client ID
    //if (["oID", "clientID"].includes(event.target.name))
    if (["clientID"].includes(event.target.name)) {
      if (parseInt(event.target.value) < 0) {
        setFormOneErrors({
          ...formOneErrors,
          [event.target.name]: true
        });
        return;
      }
    }
    if (
      ["cusDomain", "useN", "useClickId", "cusFallback"].includes(
        event.target.name
      )
    ) {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      // console.log(event.target.name, event.target.value);
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleActivate = (event) => {
    handleFormTouched();
    setActivate(event.target.checked);
  };

  //type = "draft" or "publish"
  const handleSave = async (statusType) => {
    setLogicError(false);
    //validating forms
    let isFormOneValid = validateForm1();
    let isFormTwoValid = validateForm2();
    if (!isFormOneValid || !isFormTwoValid) {
      toastr.info("Please check all the required field values!");
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth"
      });
      return;
    }
    let isAdvertiserLinksValid = await validateAdvertiserLinks();
    if (!isAdvertiserLinksValid) {
      openDuplicatedAdvertiserLinksModal();
      return;
    }
    if (targetLink !== "") {
      setShowPrompt(false);
      clearFormTouched();
      const data = getFormData(statusType);
      if (typeof data["sub_id"] === "string") {
        if (data["sub_id"].trim() === "") {
          data["sub_id"] = null;
        }
      }
      if (typeof data["client_id"] === "string") {
        if (data["client_id"].trim() === "") {
          data["client_id"] = null;
        }
      }

      data["targeting_rules"] = formatTargetDataForSave(
        targetingRules,
        null,
        state.targeting
      );
      setSendingFormData(true);
      Publisher.saveTargeting(data)
        .then((response) => {
          if (response.success) {
            if (statusType === "draft") {
              // set edit to true, add targeting id and then update
              setEditing(true);
              let savedTargeting = response.data.targeting;
              setTargetingID(savedTargeting.id);
              fetchTargeting(savedTargeting.id);
              toastr.success(
                "Success",
                "This publisher tag is ready to be published!"
              );
            } else {
              dispatch(clearTargetingForm());
              history.push("/target");
              toastr.success("Success", "Publisher Tag published!");
            }
            setSendingFormData(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          toastr.error("Oops!", JSON.parse(error.message).message);
          setSendingFormData(false);
        });
    }
  };

  //type = "draft" or "publish"
  const handleUpdate = async (statusType) => {
    setLogicError(false);
    //validating forms
    let isFormOneValid = validateForm1();
    let isFormTwoValid = validateForm2();
    if (!isFormOneValid || !isFormTwoValid) {
      toastr.info("Please check all the required field values!");
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth"
      });
      return;
    }
    let isAdvertiserLinksValid = await validateAdvertiserLinks();
    if (!isAdvertiserLinksValid) {
      openDuplicatedAdvertiserLinksModal();
      return;
    }
    if (targetLink !== "") {
      setShowPrompt(false);
      clearFormTouched();
      const data = getFormData(statusType);
      if (typeof data["sub_id"] === "string") {
        if (data["sub_id"].trim() === "") {
          data["sub_id"] = null;
        }
      }
      if (typeof data["client_id"] === "string") {
        if (data["client_id"].trim() === "") {
          data["client_id"] = null;
        }
      }

      let targeting_id = targetingID;
      data["targeting_rules"] = formatTargetDataForSave(
        targetingRules,
        targeting_id,
        state.targeting
      );
      data["targeting_rules"] = data["targeting_rules"].concat(
        deletedTargetingRules
      );
      setSendingFormData(true);
      //make api call and update here
      Publisher.updateTargeting(targeting_id, data)
        .then((response) => {
          if (response.success) {
            if (statusType === "draft") {
              fetchTargeting(targeting_id);
              toastr.success(
                "Success",
                "This publisher tag is ready to be published!"
              );
            } else {
              dispatch(clearTargetingForm());
              history.push("/target");
              toastr.success("Success", "Publisher tag updated!");
            }
            setSendingFormData(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          toastr.error("Oops!", JSON.parse(error.message).message);
          setSendingFormData(false);
        });
    }
  };

  const getFormData = (statusType) => {
    let fallback;
    if (!state.cusFallback) {
      fallback = DEFAULT_FALLBACK;
    } else {
      fallback = state.fallback.trim() === "" ? null : state.fallback;
    }
    const data = {
      publisher_id: publisher,
      custom_domain: state.cusDomain ? state.domain : DEFAULT_DOMAIN,
      use_custom_domain: state.cusDomain,
      use_custom_fallback: state.cusFallback,
      is_active: activate,
      sub_id: state.subID,
      client_id: state.clientID,
      use_sid: state.useSubId,
      status: statusType,
      n: state.useN,
      click_id: state.useClickId,
      default_fallback: fallback,
      targeting_type: state.targeting,
      tag_type: state.tag_type,
      link: handleGenerateLink(),
      notes: state.notes.trim() === "" ? null : state.notes,
      tag_description:
        state.tagDescription.trim() === "" ? null : state.tagDescription
    };

    return data;
  };

  const handleCopyToClipboard = () => {
    if (textFieldValid(targetLink)) {
      copyToClipboard(targetLink);
      toastr.success("Success", "Tag copied!");
    }
  };

  const duplicateForm = () => {
    setShowPrompt(false);
    clearFormTouched();
    Publisher.fetchTargeting(targetingID).then((response) => {
      if (response.success) {
        let targeting = response.data.targeting;
        const data = {
          publisher: targeting.publisher_id,
          domain: targeting.custom_domain,
          cusDomain: targeting.use_custom_domain,
          cusFallback: targeting.use_custom_fallback,
          is_active: targeting.is_active,
          subID: targeting.sub_id,
          clientID: null,
          status: targeting.status,
          useN: targeting.n,
          useSubId:
            targeting.use_sid === "true" || targeting.use_sid ? true : false,
          useClickId: targeting.click_id,
          fallback: targeting.default_fallback
            ? targeting.default_fallback
            : "",
          targeting: targeting.targeting_type,
          link: targeting.link,
          notes: targeting.notes ? targeting.notes : "",
          tagDescription: targeting.tag_description
            ? targeting.tag_description
            : "",
          deletedTargetingRules: [],
          tag_type: targeting.tag_type
        };
        let newTargetingRules = [...targeting.targeting_rules];
        for (let i = 0; i < newTargetingRules.length; i++) {
          let rule = newTargetingRules[i];
          if (rule.id) {
            delete rule.id;
          }
          if (rule.targeting_id) {
            delete rule.targeting_id;
          }
        }
        setState(data);
        data.targetingRules = [...newTargetingRules];
        setOpen(false);
        setTargetingID(null);
        setEditing(false);
        history.push("/target/add");
        toastr.success("Success", "Data duplicated!");
        dispatch(updateTargetingForm(data));
      }
    });
  };

  const handleShowHistory = () => {
    setOpenHistory(true);
  };

  const handleCheckTag = (link) => {
    let checkedlink = link.match(/^http[s]?:\/\//) ? link : "http://" + link;
    window.open(checkedlink, "_blank");
  };

  /**
   * Returns a Boolean Value on whether to open or not the modal for weight<100 msg!
   *
   * @returns [True] if the follwing conditions are met
   *  a) The Targeting is of Round Robin Type
   *  b) If atleast one of the rule is not inactive (one rule exists such that rule.disabled === false)
   *  c) Sum is less than 100
   */
  function shouldOpenWeightMessage() {
    //state to track if we should open the popup message for weight
    let openMessage = false;
    let openMessageConditions = {
      IS_ROUND_ROBIN: state.targeting === TARGETING_TYPES.ROUND_ROBIN,
      ACTIVE_RULES_EXIST: getNumberOfActiveRules(targetingRules) > 0
    };
    if (
      openMessageConditions.IS_ROUND_ROBIN &&
      openMessageConditions.ACTIVE_RULES_EXIST
    ) {
      let { sum } = sumOfInputedWeights(targetingRules);
      if (sum < 100) {
        openMessage = true;
      }
    }
    return openMessage;
  }

  const handleFormButtonClick = (statusType) => {
    let openMessage = shouldOpenWeightMessage();
    if (openMessage) {
      setOpenWeightMessage({
        modal: true,
        statusType
      });
    } else {
      !editing ? handleSave(statusType) : handleUpdate(statusType);
    }
  };

  const handleFormSubmit = (statusType) => {
    !editing ? handleSave(statusType) : handleUpdate(statusType);
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div className="targeting-form">
      <div className="targeting-form__header">
        <ContentHeader
          icon={<TargetIcon fontSize="inherit" color="primary" />}
          title="Publisher Tag"
          additionalComponent={
            <FormAddtionalHeaders targetingID={targetingID} />
          }
        />
      </div>

      <div className="target__section">
        <FormLabel component="legend">Type</FormLabel>
        <RadioGroup
          aria-label="Type"
          name="tag_type"
          value={state.tag_type}
          onChange={handleChange}
          className="radio-section"
        >
          <div className="targeting-option">
            <FormControlLabel
              value={TAG_TYPES.REDIRECT}
              control={<Radio />}
              label="Redirect"
            />
            <InfoToolTip
              title={REDIRECT_HELP_TEXT}
              className="targeting-option__info"
            >
              <div className="gray-icon ">
                <HelpIcon fontSize="small" />
              </div>
            </InfoToolTip>
          </div>
          <div className="targeting-option">
            <FormControlLabel
              value={TAG_TYPES.XML}
              control={<Radio />}
              label="XML"
            />
            <InfoToolTip
              title={XML_HELP_TEXT}
              className="targeting-option__info"
            >
              <div className="gray-icon ">
                <HelpIcon fontSize="small" />
              </div>
            </InfoToolTip>
          </div>
          <div className="targeting-option">
            <FormControlLabel
              value={TAG_TYPES.TEMPLATE}
              control={<Radio />}
              label="Template"
            />
            <InfoToolTip
              title={TEMPLATE_HELP_TEXT}
              className="targeting-option__info"
            >
              <div className="gray-icon ">
                <HelpIcon fontSize="small" />
              </div>
            </InfoToolTip>
          </div>
        </RadioGroup>
      </div>
      <Divider />

      {!sendingFormData ? (
        <>
          <div className="targeting-form__publisher-section">
            <div className="targeting-form__publisher-section__top">
              <div className="targeting-form__client-id">
                <FormControl>
                  <TextField
                    label="Tag ID"
                    variant="outlined"
                    disabled
                    name="clientID"
                    value={state.clientID == null ? "" : state.clientID}
                    onChange={handleChange}
                    error={formOneErrors.clientID}
                    autoFocus={formOneErrors.clientID}
                    type="number"
                  />
                  {formOneErrors.clientID && (
                    <FormHelperText className="red-text">
                      Positive Number Required
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="targeting-form__publisher">
                <AutoCompleteSelect
                  selected={publisher ? publisher : ""}
                  data={publishers || []}
                  handleChange={(value) => handlePublisherChange(value)}
                  label={"Publisher"}
                  showValueInLabel={true}
                  enableMultiple={false}
                  withDotsForStatus={true}
                  cannotSelectDisabled={false}
                />
              </div>
              <div className="targeting-form__cus-domain">
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.cusDomain}
                        onChange={handleChange}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="cusDomain"
                      />
                    }
                    label="Use Custom Domain"
                  />
                </div>
                <div>
                  <FormControl fullWidth>
                    <TextField
                      name="domain"
                      value={!state.cusDomain ? DEFAULT_DOMAIN : state.domain}
                      onChange={handleChange}
                      error={formOneErrors.domain}
                      autoFocus={formOneErrors.domain}
                      disabled={!state.cusDomain}
                    />
                    {formOneErrors.domain && (
                      <FormHelperText>
                        <span className="red-text">Domain Required</span>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
              <div className="targeting-form__active">
                <FormControlLabel
                  control={
                    <Switch
                      checked={activate}
                      onChange={handleActivate}
                      name="checkedA"
                    />
                  }
                  label="Active"
                />
              </div>
            </div>
            <div className="targeting-form__publisher-section__mid">
              <div className="targeting-form__tag-options">
                <div className="ids">
                  <FormControl>
                    <TextField
                      label="Sub ID"
                      name="subID"
                      value={state.subID == null ? "" : state.subID}
                      onChange={handleChange}
                      error={formOneErrors.subID}
                      autoFocus={formOneErrors.subID}
                    />
                    {formOneErrors.subID && (
                      <FormHelperText className="red-text">
                        Value Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="targeting-form__clickIds">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.useSubId}
                        onChange={handleChange}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="useSubId"
                      />
                    }
                    label="Sub ID"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.useN}
                        onChange={handleChange}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="useN"
                      />
                    }
                    label="Use N"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.useClickId}
                        onChange={handleChange}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="useClickId"
                      />
                    }
                    label="Use Click ID"
                  />
                </div>
              </div>
              <div className="targeting-form__notes">
                <TextField
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  name="tagDescription"
                  value={state.tagDescription}
                  onChange={handleChange}
                  fullWidth
                />
                <TextField
                  label="Notes"
                  multiline
                  rows={4}
                  variant="outlined"
                  name="notes"
                  value={state.notes}
                  onChange={handleChange}
                  fullWidth
                />
              </div>
              <div className="targeting-form__status__section flow-content--small">
                <div>
                  <TextField
                    disabled
                    label="Status"
                    value={state?.status ? state?.status.toUpperCase() : ""}
                  />
                  <Tooltip title="Show history">
                    <IconButton
                      aria-label="history"
                      className="copy-icon"
                      onClick={() => {
                        editing && handleShowHistory();
                      }}
                    >
                      <HistoryOutlined fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="duplicate-btn">
                  {editing && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDuplicateAction}
                      startIcon={<FileCopyTwoTone />}
                    >
                      Duplicate Targeting Ruleset
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="targeting-form__publisher-section__bottom flow-content--small">
              <div className="targeting-form__tag">
                <div className="targeting-form__tag__link-row">
                  <FormControl fullWidth>
                    <TextField
                      required
                      disabled
                      label="Tag"
                      value={targetLink}
                      error={formOneErrors.targetLink}
                      autoFocus={formOneErrors.targetLink}
                    />
                    {formOneErrors.targetLink && (
                      <FormHelperText className="red-text">
                        Tag not generated
                      </FormHelperText>
                    )}
                  </FormControl>
                  {editing && (
                    <div className="button-group">
                      <Tooltip
                        title="Copy Tag"
                        onClick={() => handleCopyToClipboard(targetLink)}
                      >
                        <IconButton aria-label="history" className="green-icon">
                          <AssignmentTurnedInIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Check Tag"
                        onClick={() => handleCheckTag(targetLink)}
                      >
                        <IconButton aria-label="history" className="copy-icon">
                          <OpenInBrowserIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <FormControlLabel
                  control={
                    <InfoSharp
                      className="info-icon"
                      style={{ color: "blue" }}
                      fontSize="large"
                    />
                  }
                  label="Tag is auto generated based on input and is read-only."
                />
              </div>
              <div className="targeting-form__update-info">
                <FormLabel>
                  Last Updated (UTC) -{" "}
                  {state.updatedAt &&
                    moment(state.updatedAt).utc().format("lll")}
                </FormLabel>
                <FormLabel>
                  {" "}
                  Last Published (UTC) -{" "}
                  {state.publishedAt &&
                    moment(state.publishedAt).utc().format("lll")}
                </FormLabel>
              </div>
            </div>
          </div>
          <div>
            <Divider />
            {duplicatedProviderLinks.length > 0 && (
              <DuplicateLinksMsg
                duplicatedIds={duplicatedProviderLinks || []}
              />
            )}

            {state.tag_type === 'redirect' && (
            <div className="target__section">
              <FormLabel component="legend">Targeting</FormLabel>
              <RadioGroup
                aria-label="targeting"
                name="targeting"
                value={state.targeting}
                onChange={handleChange}
                className="radio-section"
              >
                <div className="targeting-option">
                  <FormControlLabel
                    value={TARGETING_TYPES.ROUND_ROBIN}
                    control={<Radio />}
                    label="Round Robin"
                  />
                  <InfoToolTip
                    title={ROUND_ROBIN_HELP_TEXT}
                    className="targeting-option__info"
                  >
                    <div className="gray-icon ">
                      <HelpIcon fontSize="small" />
                    </div>
                  </InfoToolTip>
                </div>
                <div className="targeting-option">
                  <FormControlLabel
                    value={TARGETING_TYPES.WATERFALL}
                    control={<Radio />}
                    label="Waterfall"
                  />
                  <InfoToolTip
                    title={WATERFALL_HELP_TEXT}
                    className="targeting-option__info"
                  >
                    <div className="gray-icon ">
                      <HelpIcon fontSize="small" />
                    </div>
                  </InfoToolTip>
                </div>
              </RadioGroup>
            </div>
            )}
            {state.tag_type === 'redirect' && targetingRules && (
              <TargetRule
                editing={editing}
                targeting_type={state.targeting}
                addMoreRulesBlock={addMoreRulesBlock}
                rulesBlockNumber={rulesBlockNumber}
                logicError={logicError}
                oldRules={oldRules}
                targetingID={targetingID}
                publisher={publisher}
                targetingRules={targetingRules}
                targetingRulesError={targetingRulesError}
                handleFormTouched={handleFormTouched}
              />
            )}

          {state.tag_type !== 'redirect' && targetingRules && (
              <TargetTypeRule
                editing={editing}
                targeting_type={state.targeting}
                addMoreRulesBlock={addMoreRulesBlock}
                rulesBlockNumber={rulesBlockNumber}
                logicError={logicError}
                oldRules={oldRules}
                targetingID={targetingID}
                publisher={publisher}
                targetingRules={targetingRules}
                targetingRulesError={targetingRulesError}
                handleFormTouched={handleFormTouched}
                tag_type={state.tag_type}
              />
            )}

            <div className="default-url-block gray-text">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.cusFallback}
                    onChange={handleChange}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusFallback"
                  />
                }
                label="Use Custom Fallback URL"
              />

              <div className="default-url-block__fallback">
                <FormControl>
                  <TextField
                    name="fallback"
                    value={
                      !state.cusFallback ? DEFAULT_FALLBACK : state.fallback
                    }
                    onChange={handleChange}
                    error={formOneErrors.fallback}
                    autoFocus={formOneErrors.fallback}
                    disabled={!state.cusFallback}
                  />
                  {formOneErrors.fallback && (
                    <FormHelperText className="red-text">
                      Valid link required
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="footer-section">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleFormButtonClick("draft")}
                startIcon={<DoneIcon />}
              >
                Save
              </Button>
              <Button
                variant="contained"
                className="save-publish"
                color="primary"
                onClick={() => handleFormButtonClick("published")}
                startIcon={<PublishIcon />}
              >
                Save & Publish
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push("/target");
                }}
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
            </div>
          </div>
        </>
      ) : (
        <SavingMessage />
      )}

      {openWeightMessage.modal && (
        <ConfirmBox
          message="Please note that Total weight is less than 100%. Are you sure you want to save and exit?"
          confirmAction={() => {
            handleFormSubmit(openWeightMessage.type);
            handleWeightMessageModalClose();
          }}
          denyAction={handleWeightMessageModalClose}
        />
      )}
      {open && (
        <ConfirmBox
          message="Your current changes will not be saved. Do you wish to proceed?"
          confirmAction={duplicateForm}
          denyAction={handleModalClose}
        />
      )}
      {openHistory && (
        <SimpleModal handleClose={handleHistoryModalClose}>
          <History targetingHistory={targetingHistory} />
        </SimpleModal>
      )}
      {duplicateAdLinksModal && (
        <SimpleModal handleClose={closeDuplicatedAdvertiserLinksModal}>
          <DuplicateAdvertiserLinksMsg
            duplicates={duplicateProviderLinksDB || []}
          />
        </SimpleModal>
      )}
      <Prompt
        when={
          showPrompt && formTouched.current
          // settingsObject?.default_domain !== undefined &&
          // settingsObject?.default_fallback !== undefined
        }
        message="Are you sure you want to leave?"
      />
    </div>
  );
}
