import Api from "../api";

class SystemLog {
  fetchLogs = async (params = {}) => {
    const { body } = await Api.get(`systemLogs`, null, params);
    return body;
  };
}

export default new SystemLog();
