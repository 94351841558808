import {
  ADVERTISER_REPORT,
  AD_COVERAGE,
  GROSS_REVENUE,
  PUBLISHER_REVENUE,
  DATE,
  TAG_ID,
  PUBLISHER_REPORT,
  CHANNEL,
  RULE_ID,
  GEO,
  TOTAL_SEARCHES,
  MONETIZED_SEARCHES,
  CLICKS,
  CTR,
  NET_REVENUE,
  RPM,
  RPMM,
  RPC,
  FOLLOWON_SEARCHES,
  INITIAL_SEARCHES,
  SEARCH_ENGINE,
  LINK_PLATFORM,
  TAG_TYPE,
  PUBLISHER_AD_ACCOUNT
} from "./filters";

export const MAPPING_COLUMN_NAME = {
  date: DATE,
  subId: "subId",
  publisher: PUBLISHER_REPORT,
  rule_id: RULE_ID,
  tag_description: "tag_description",
  advertiser_id: ADVERTISER_REPORT,
  tag_number: "tag_number",
  geo: GEO,
  total_searches: TOTAL_SEARCHES,
  clicks: CLICKS,
  monetized_searches: MONETIZED_SEARCHES,
  gross_revenue: GROSS_REVENUE,
  net_revenue: NET_REVENUE,
  ctr: CTR,
  rpm: RPM,
  rpmm: RPMM,
  rpc: RPC,
  followon_searches: FOLLOWON_SEARCHES,
  initial_searches: "initial_searches"
};

export const mapping_order = [
  "date",
  "tag_number",
  "publisher",
  "rule_id",
  "advertiser_id",
  "subId",
  "geo",
  "total_searches",
  "monetized_searches",
  "gross_revenue",
  "clicks",
  "ctr",
  "net_revenue",
  "rpm",
  "rpmm",
  "rpc",
  "tag_description",
  "followon_searches",
  "initial_searches"
];

export const csv_mapping_order = [
  "date",
  "publisher",
  "tag_number",
  "subId",
  "geo",
  "total_searches",
  "monetized_searches",
  "clicks",
  "gross_revenue",
  "tag_description",
  "followon_searches",
  "initial_searches"
];

export const table_mapping_order = [
  DATE,
  TAG_ID,
  PUBLISHER_REPORT,
  PUBLISHER_AD_ACCOUNT,
  CHANNEL,
  SEARCH_ENGINE,
  TAG_TYPE,
  LINK_PLATFORM,
  RULE_ID,
  ADVERTISER_REPORT,
  GEO,
  "server_search_counts",
  TOTAL_SEARCHES,
  MONETIZED_SEARCHES,
  CLICKS,
  CTR,
  AD_COVERAGE,
  GROSS_REVENUE,
  PUBLISHER_REVENUE,
  NET_REVENUE,
  RPM,
  RPMM,
  RPC,
  FOLLOWON_SEARCHES,
  INITIAL_SEARCHES
];

export const table_mapping_order_publisher = [
  DATE,
  TAG_ID,
  CHANNEL,
  SEARCH_ENGINE,
  TAG_TYPE,
  LINK_PLATFORM,
  GEO,
  "search_counts",
  TOTAL_SEARCHES,
  MONETIZED_SEARCHES,
  CLICKS,
  CTR,
  AD_COVERAGE,
  NET_REVENUE,
  RPM,
  RPMM,
  RPC,
  FOLLOWON_SEARCHES,
  INITIAL_SEARCHES
];
