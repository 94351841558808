export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const TARGETING_TYPES = {
  ROUND_ROBIN: "round-robin",
  WATERFALL: "waterfall"
};
Object.freeze(TARGETING_TYPES);

export const PUBLISHER_TAG_ADVERTISER_LINKS = "PUBLISHER_TAG_ADVERTISER_LINK";

export const TAG_TYPES = {
  REDIRECT: "redirect",
  XML: "xml",
  TEMPLATE: "template",
};

Object.freeze(TAG_TYPES);
