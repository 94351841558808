import React from "react";
import PropTypes from "prop-types";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import SimpleDateRangePicker from "../../../components/SimpleDateRangePicker/SimpleDateRangePicker";
import {
  CUSTOM,
  COMPAIR_SERVER_SEARCHES_TOTAL_SEARCHES
} from "../../../helpers/constant/filters";
const FilterSelections = ({ dataLists, selectionAddActions, filterValues }) => {
  const {
    handleProviderChange,
    handleCountryChange,
    handlePublisherChange,
    changeSelectedInterval,
    handleGroupByChange,
    handleRuleIdChange,
    handleTagIdChange,
    handleDateChange,
    handleTagTypeChange,
    handlePlatformChange,
    handleSearchEngineChange,
    changeSelectedPeriod,
    handlePublisherAccountChange,
    handleChannelsChange,
    changeSelectedCompairServerSearches
  } = selectionAddActions;

  const {
    publishers,
    providers,
    countries,
    intervals,
    groupByList,
    tagIds,
    ruleIds,
    tagTypes,
    platforms,
    searchEngines,
    publisherAccounts,
    channels,
    periods
  } = dataLists;

  const {
    selectedProviders,
    selectedCountries,
    selectedPublishers,
    fromDate,
    toDate,
    selectedInterval,
    selectedGroupBy,
    selectedRuleIds,
    selectedTagIds,
    selectedTagTypes,
    selectedPlatforms,
    selectedSearchEngines,
    selectedPublisherAccounts,
    selectedChannels,
    selectedPeriod,
    selectedCompairServerSearches
  } = filterValues;

  return (
    <div className="dashboard-report__filters__filter-selects">
      <AutoCompleteSelect
        selected={selectedPeriod}
        data={periods}
        handleChange={changeSelectedPeriod}
        label={"Period"}
        showValueInLabel={false}
        enableMultiple={false}
      />
      {selectedPeriod === CUSTOM && (
        <SimpleDateRangePicker
          startDate={fromDate ? fromDate : null}
          endDate={toDate ? toDate : null}
          handleDateChange={handleDateChange}
        />
      )}
      <AutoCompleteSelect
        selected={selectedInterval}
        data={intervals}
        handleChange={changeSelectedInterval}
        label={"Interval"}
        showValueInLabel={false}
        enableMultiple={false}
      />

      <AutoCompleteSelect
        selected={selectedProviders}
        data={providers}
        handleChange={handleProviderChange}
        label={"Advertisers"}
        showValueInLabel={true}
        enableMultiple={true}
        withDotsForStatus={true}
      />
      <AutoCompleteSelect
        selected={selectedChannels}
        data={channels}
        handleChange={handleChannelsChange}
        label={"Sub ID(Channel)"}
        showValueInLabel={false}
        enableMultiple={true}
        withDotsForStatus={false}
      />
      <AutoCompleteSelect
        selected={selectedPublishers}
        data={publishers}
        handleChange={handlePublisherChange}
        label={"Publisher"}
        showValueInLabel={true}
        enableMultiple={true}
        withDotsForStatus={true}
      />
      <AutoCompleteSelect
        selected={selectedPublisherAccounts}
        data={publisherAccounts}
        handleChange={handlePublisherAccountChange}
        label={"Publisher Ad Account"}
        showValueInLabel={false}
        enableMultiple={true}
        withDotsForStatus={true}
      />

      <AutoCompleteSelect
        selected={selectedPlatforms}
        data={platforms}
        handleChange={handlePlatformChange}
        label={"Platform"}
        showValueInLabel={true}
        enableMultiple={true}
        withDotsForStatus={false}
      />
      <AutoCompleteSelect
        selected={selectedSearchEngines}
        data={searchEngines}
        handleChange={handleSearchEngineChange}
        label={"Search Engine"}
        showValueInLabel={true}
        enableMultiple={true}
        withDotsForStatus={false}
      />
      <AutoCompleteSelect
        selected={selectedTagTypes}
        data={tagTypes}
        handleChange={handleTagTypeChange}
        label={"Tag Type"}
        showValueInLabel={true}
        enableMultiple={true}
        withDotsForStatus={false}
      />
      <AutoCompleteSelect
        selected={selectedRuleIds}
        data={ruleIds}
        handleChange={handleRuleIdChange}
        label={"Rule ID"}
        showValueInLabel={false}
        enableMultiple={true}
        withDotsForStatus={true}
      />
      <AutoCompleteSelect
        selected={selectedTagIds}
        data={tagIds}
        handleChange={handleTagIdChange}
        label={"Tag ID"}
        showValueInLabel={false}
        enableMultiple={true}
        withDotsForStatus={true}
      />

      <AutoCompleteSelect
        selected={selectedCountries}
        data={countries}
        handleChange={handleCountryChange}
        label={"Geo"}
        showValueInLabel={true}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedGroupBy}
        data={groupByList}
        handleChange={handleGroupByChange}
        label={"Group By"}
        showValueInLabel={false}
        enableMultiple={true}
      />
      <div style={{ width: "470px" }}>
        <AutoCompleteSelect
          selected={selectedCompairServerSearches}
          data={COMPAIR_SERVER_SEARCHES_TOTAL_SEARCHES}
          handleChange={changeSelectedCompairServerSearches}
          label={
            "Server searches greater than 0 and Advertiser total searches equals to 0"
          }
          showValueInLabel={false}
          enableMultiple={false}
        />
      </div>
    </div>
  );
};

FilterSelections.propTypes = {
  dataLists: PropTypes.object.isRequired,
  selectionAddActions: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired
};

export default FilterSelections;
