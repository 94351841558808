export const ADMIN_REPORT_RULE_ID = "admin_report_rule_id";
export const ADMIN_REPORT_ADVERTISER_ID = "admin_report_advertiser_id";
export const ADMIN_LOG_RULE_ID = "admin_log_rule_id";
export const ADMIN_LOG_FROM_DATE = "admin_log_from_date";
export const ADMIN_REPORT_FROM_DATE = "admin_report_from_date";
export const ADMIN_REPORT_INTERVAL = "admin_report_interval";
export const ADMIN_LOG_INTERVAL = "admin_log_interval";
export const ADMIN_ADVERTISER_ID = "admin_advertiser_id";
export const ADMIN_ADVERTISER_FROM_DATE = "admin_advertiser_from_date";
export const ADMIN_ADVERTISER_INTERVAL = "admin_advertiser_interval";

export const ADMIN_PROVIDER_ID = "admin_provider_id";
export const ADMIN_CSVNAME = "admin_csvname";
export const ADMIN_PROVIDER_FROM_DATE = "admin_provider_from_date";
export const ADMIN_PROVIDER_INTERVAL = "admin_provider_interval";
export const REVENUE_TABLE_HEADERS = "table_headers";
export const REVENUE_TABLE_HEADERS_PUBLISHER = "table_headers_publisher";
