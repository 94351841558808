import Api from "../api";

class Publisher {
  addPublisher = async (data) => {
    const { body } = await Api.post("publisher", data);
    return body;
  };

  fetchPublisher = async (id) => {
    const { body } = await Api.get(`publisher/${id}`);
    return body;
  };

  updatePublisher = async (id, data, params = {}) => {
    const { body } = await Api.put(`publisher/${id}`, data, params);
    return body;
  };

  fetchPublishers = async (
    params = { order_by: "id", order_direction: "ASC" }
  ) => {
    const { body } = await Api.get(`publishers`, null, params);
    return body;
  };

  deletePublisher = async (id, params = {}) => {
    const { body } = await Api.destroy(`publisher/${id}`, {}, params);
    return body;
  };

  bulkDeletePublishers = async (data) => {
    const { body } = await Api.destroy(`publishers`, data);
    return body;
  };

  bulkPermanentDeletePublishers = async (data) => {
    const { body } = await Api.destroy(`publishers?permanent=true`, data);
    return body;
  };

  bulkUpdatePublishers = async (type, data) => {
    const { body } = await Api.put(`publishers?status=${type}`, data);
    return body;
  };

  restorePublishers = async (data) => {
    const { body } = await Api.put(`publishers?deleted=false`, data);
    return body;
  };

  saveTargeting = async (data) => {
    const { body } = await Api.post(`targeting`, data);
    return body;
  };

  deleteTargeting = async (id, params = {}) => {
    const { body } = await Api.destroy(`targeting/${id}`, {}, params);
    return body;
  };

  bulkDeleteTargetings = async (data) => {
    const { body } = await Api.destroy(`targetings`, data);
    return body;
  };

  bulkPermanentDeleteTargetings = async (data) => {
    const { body } = await Api.destroy(`targetings?permanent=true`, data);
    return body;
  };

  bulkUpdateTargetings = async (type, data) => {
    const { body } = await Api.put(`targetings?is_active=${type}`, data);
    return body;
  };

  bulkUpdatePublishStatusTargetings = async (type, data) => {
    const { body } = await Api.put(`targetings?status=${type}`, data);
    return body;
  };

  restoreTargetings = async (data) => {
    const { body } = await Api.put(`targetings?deleted=false`, data);
    return body;
  };

  getTargeting = async (params = {}) => {
    const { body } = await Api.get(`targetings`, null, params);
    return body;
  };

  saveTargetingRule = async (data) => {
    const { body } = await Api.post(`targetingRules`, data);
    return body;
  };

  fetchTargeting = async (id) => {
    const { body } = await Api.get(`targeting/${id}`);
    return body;
  };

  fetchTargetingRules = async (id) => {
    const { body } = await Api.get(`targetingRules`);
    return body;
  };

  deleteTargetingRule = async (id) => {
    const { body } = await Api.destroy(`targetingRule/${id}`);
    return body;
  };

  updateTargeting = async (id, data, params = {}) => {
    const { body } = await Api.put(`targeting/${id}`, data, params);
    return body;
  };
  updateTargetingRules = async (data) => {
    const { body } = await Api.put(`targetingRules`, data);
    return body;
  };

  fetchLinkedAccounts = async ({ pid, tid, ruleID }) => {
    const { body } = await Api.get(`pubaccount/${pid}/${tid}/${ruleID}`);
    return body;
  };
}

export default new Publisher();
