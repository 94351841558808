import React from "react";
import moment from "moment";
import InfoToolTip from "../../../../components/InfoToolTip/InfoToolTip";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const GrossRevenueStatsBtn = ({
  loadingGrossRevenueStats,
  grossRevenue,
  grossRevenueDate,
  targeting_id,
  redirectToReportPage
}) => {
  if (!targeting_id || loadingGrossRevenueStats.error) {
    return <></>;
  }

  if (loadingGrossRevenueStats.loading) {
    return "...";
  }

  let formatedGrossRevenueDate = grossRevenueDate
    ? moment(grossRevenueDate).utc().format("MMM DD YYYY")
    : "N/A";

  const InfoBox = (
    <div className="default-padding">
      <pre>Gross Revenue: {grossRevenue} </pre>
      <pre>UTC Date: {formatedGrossRevenueDate}</pre>
    </div>
  );

  return (
    <InfoToolTip
      title={InfoBox}
      className="targeting-option__info"
      style={{ cursor: "pointer" }}
      onClick={() => redirectToReportPage(targeting_id)}
    >
      <div className={grossRevenue !== 0 ? "blue-icon" : "gray-icon"}>
        <AttachMoneyIcon />
      </div>
    </InfoToolTip>
  );
};

export default GrossRevenueStatsBtn;
