import React from "react";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API_URL } from "../../../../helpers";
import { copyToClipboard } from "../../../../helpers";
import { toastr } from "react-redux-toastr";

const Details = (props) => {
  const { data } = props;
  function createData(params, format, example) {
    return { params, format, example };
  }

  const rows = [
    createData("from_date", "YYYY-MM-DD", "2022-04-01"),
    createData("to_date", "YYYY-MM-DD", "2022-05-23"),
    createData("format", "JSON/CSV", "JSON"),
    createData("api_key", "String", data?.api_key)
  ];
  const success_response = {
    success: true,
    msg: "Revenue reports",
    data: [
      {
        Date: "2022-04-01",
        "Total Searches": 10,
        Clicks: 1,
        Subid: 71,
        "Publisher ID": 7,
        "Monetized Searches": 9,
        Country: "RU",
        "Net Revenue": 0
      },
      {
        Date: "2022-05-06",
        "Total Searches": 1151,
        Clicks: 59,
        Subid: 71,
        "Publisher ID": 7,
        "Monetized Searches": 1114,
        Country: "US",
        "Net Revenue": 57.89
      }
    ]
  };

  const failed_response = {
    error: {
      code: 401,
      message: "Unauthorized"
    },
    success: false
  };

  const handleCopyTagToClipboard = (value) => {
    copyToClipboard(value);
    toastr.success("Success", "Tag copied!");
  };

  return (
    <div className="publisherapi-body">
      <div className="body-content">
        <div className="content">
          <div className="left">Api Token(Never Expire) :</div>
          <div className="right hover-cell">
            {data?.api_key}{" "}
            <button
              onClick={() => {
                handleCopyTagToClipboard(data?.api_key);
              }}
              className="hover-cell__button btn"
            >
              Copy Token
            </button>
          </div>
        </div>
        <div className="content">
          <div className="left">Base URl :</div>
          <div className="right hover-cell">
            {API_URL}publisher/api/revenue
            <button
              onClick={() => {
                handleCopyTagToClipboard(API_URL + "publisher/api/revenue");
              }}
              className="hover-cell__button btn"
            >
              Copy Base URL
            </button>
          </div>
        </div>
        <div className="content">
          <div className="left">Required Params :</div>
          <div className="right">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Params</TableCell>
                    <TableCell align="right">Format</TableCell>
                    <TableCell align="right">Example</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.params}>
                      <TableCell component="th" scope="row">
                        {row.params}
                      </TableCell>
                      <TableCell align="right">{row.format}</TableCell>
                      <TableCell align="right">{row.example}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="content">
          <div className="left">Example Request Url :</div>
          <div className="right hover-cell">
            {API_URL}
            publisher/api/revenue?from_date=2022-01-1&to_date=2022-05-20&api_key=
            {data?.api_key}
            <button
              onClick={() => {
                handleCopyTagToClipboard(
                  API_URL +
                    "publisher/api/revenue?from_date=2022-01-01&to_date=2022-05-20&api_key=" +
                    data?.api_key
                );
              }}
              className="hover-cell__button btn"
            >
              Copy URL
            </button>
          </div>
        </div>
        <div className="content">
          <div className="left">Success Response :</div>
          <div className="right">
            <pre>{JSON.stringify(success_response, null, 2)}</pre>
          </div>
        </div>
        <div className="content">
          <div className="left">Failed Response :</div>
          <div className="right">
            <pre>{JSON.stringify(failed_response, null, 2)}</pre>
          </div>
        </div>
        <Divider />
        <div className="footer">
          <div className="content">
            <div className="left">Note :</div>
            <div className="right">
              Please, read the message and try to fix the problem. In case you
              can’t fix it, please, contact the company representative
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
