import { useState, useEffect } from "react";
import { Report } from "../../../../../services/Report";
import { toastr } from "react-redux-toastr";

export const useFetchStatsLogic = () => {
  const [statsData, setStatsData] = useState(null);
  const [loadingStatsData, setLoadingStatsData] = useState(false);

  const fetchStats = () => {
    Report.fetchMonthlyStats()
      .then((response) => {
        if (response.success) {
          let statsData = response.data?.stats;
          setStatsData(statsData);
          // changing state inside timeout to prevent slow UI re-rendering
          // of reporting table
          setTimeout(() => {
            setLoadingStatsData(false);
          }, 800);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        toastr.error("Oops", "Unable to load dashboard report data!");
        setLoadingStatsData(false);
        setStatsData(null);
        console.trace(error.message);
      });
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return {
    statsData,
    loadingStatsData
  };
};
