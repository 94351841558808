import React from "react";
import TagTypeForm from "./layouts/TagTypeForm";
import TagTypeTable from "./layouts/TagTypeTable";
import { useFormAndTableSwitch } from "../../hooks/formAndTableSwitch.logic";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
const TagType = () => {
  const { currentPage, openAddForm, openEditForm, openTable } =
    useFormAndTableSwitch();

  return (
    <div>
      {currentPage.page === "form" && (
        <TagTypeForm
          tagTypeId={currentPage.pageData.id ? currentPage.pageData.id : ""}
          openTable={openTable}
        />
      )}
      {currentPage.page === "table" && (
        <div
          className="flow-content"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px"
          }}
        >
          <Button
            onClick={openAddForm}
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddBoxIcon />}
          >
            Add New Tag Type
          </Button>
          <TagTypeTable openEditForm={openEditForm} />
        </div>
      )}
    </div>
  );
};

export default TagType;
