import React from "react";
import { SHOW_ADD_BUTTON } from "./logic/formUI.logic";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Button from "@material-ui/core/Button";
import ProviderLinkBox from "./layouts/ProviderLinkBox";

const ProviderLinksFormContainer = ({
  tagTypes,
  platforms,
  searchEngines,
  templates,
  providers,
  formUILogic,
  getProviderLinks
}) => {
  const { currentPage, pageData, showAddForm, showAddButton } = formUILogic;
  if (currentPage === SHOW_ADD_BUTTON) {
    return (
      <Button
        onClick={showAddForm}
        variant="contained"
        size="medium"
        color="primary"
        startIcon={<AddBoxIcon />}
      >
        Add new tag
      </Button>
    );
  }

  return (
    <ProviderLinkBox
      pageData={pageData}
      currentPage={currentPage}
      showAddButton={showAddButton}
      tagTypes={tagTypes}
      platforms={platforms}
      searchEngines={searchEngines}
      providers={providers}
      templates={templates}
      getProviderLinks={getProviderLinks}
    />
  );
};

export default ProviderLinksFormContainer;
