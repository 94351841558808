import { useState, useRef, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { CsvUpload } from "../../../services/CsvUpload";
import moment from "moment";
import { useRelaxedInterval } from "../../../hooks/useRelaxedInterval";
import {
  useCsvContext,
  addPollingDataEntry,
  removePollingDataEntry,
  getPollingDataEntry
} from "../../../contexts/csv-context";

const CSV_CHECK_DELAY_TIME = 15000;

export const useCsvUploadLogic = (providerId) => {
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [pollingData, setPollingData] = useCsvContext();
  let dataForPolling = getPollingDataEntry(pollingData, providerId);
  dataForPolling = dataForPolling?.data || null;

  const [delay, setDelay] = useState(
    dataForPolling ? CSV_CHECK_DELAY_TIME : null
  ); // delay for polling

  //ref for hidden input button of type file
  const fileUploadButtonRef = useRef();

  const [uploadSuccess, setUploadSuccess] = useState({
    success: false,
    msg: "",
    data: null
  });

  //using ref value to open file upload dialog box
  const handleBrowseAction = (e) => {
    e.stopPropagation();
    fileUploadButtonRef.current.click();
  };
  const handleUploadChange = (e) => {
    updateUploadFile(fileUploadButtonRef.current.files[0]);
  };

  //File Drag and Drop event handler
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e?.dataTransfer?.files?.[0] && updateUploadFile(e.dataTransfer.files[0]);
  };
  //upload file temporarily to the state
  const updateUploadFile = (file) => {
    if (validateFile(file)) {
      setUploadFile(file);
    }
  };

  const validateFile = (file) => {
    let isValidFile = true;
    if (file === undefined || file == null) {
      isValidFile = false;
      return isValidFile;
    }
    let fileType = file.type;
    let validExtensions = [
      "text/csv",
      "application/vnd.ms-excel",
      "text/*",
      ""
    ];
    //checking if File is of csv type
    if (!validExtensions.includes(fileType)) {
      isValidFile = false;
      toastr.warning("Oops!", "File not of CSV Type");
      return isValidFile;
    }
    return isValidFile;
  };

  const getSuccessMsg = (data) => {
    let check_sum_totalSearches = data.check_sum_totalSearches || 0;
    let endDate = data.endDate ? moment(data.endDate).utc().format("l") : "-";
    let startDate = data.startDate
      ? moment(data.startDate).utc().format("l")
      : "-";
    let uploaded_sum_totalSearches = data.uploaded_sum_totalSearches || 0;
    const { total_uploaded, total_skipped } = data;
    let msg = `${total_uploaded ? total_uploaded : "0"} rows of data uploaded.`;
    if (total_skipped && total_skipped > 0) {
      msg = msg + `\n${total_skipped} rows were skipped.`;
    }

    return `Executed on: ${moment.utc().format("MMMM Do YYYY, h:mm:ss a")} .
    Processing ${startDate} to ${endDate} .
    ${msg}
    Check sum: ${check_sum_totalSearches} of searches in file VS ${uploaded_sum_totalSearches} of searches in DB`;
  };

  function handleSuccessUpload(data) {
    setUploadSuccess({
      success: true,
      msg: getSuccessMsg(data),
      data
    });
    setIsUploading(false);
    setDelay(null);
    removePollingDataEntry(setPollingData, { advertiser_id: providerId });
    toastr.success("Success", `CSV File updated successfully!`);
  }

  function handleNullPollingData() {
    setIsUploading(false);
    setDelay(null);
    removePollingDataEntry(setPollingData, { advertiser_id: providerId });
    toastr.error("Oops", "Unable to verify the csv status");
  }

  function handleFailedPollingData(msg) {
    setIsUploading(false);
    setDelay(null);
    removePollingDataEntry(setPollingData, { advertiser_id: providerId });
    toastr.error("Oops", msg);
  }

  async function checkPolling() {
    if (!dataForPolling || !dataForPolling?.created_id) {
      return;
    }

    const { csv, created_id } = dataForPolling;
    try {
      const pollingResult = await CsvUpload.getCSVUploadStatus(created_id, csv);
      if (pollingResult.success) {
        if (!pollingResult.data) {
          handleNullPollingData();
          return;
        }
        if (pollingResult.data.status === "Failed") {
          handleFailedPollingData(
            pollingResult.data.message || "Unable to upload the CSV DATA"
          );
        }
        if (
          pollingResult.data.status === "Uploaded Successfully" ||
          pollingResult.data.status === "Zero Records Uploaded" ||
          pollingResult.data.status === "Partially Uploaded"
        ) {
          handleSuccessUpload(pollingResult.data);
        }
      } else {
        throw new Error(pollingResult);
      }
    } catch (error) {
      setIsUploading(false);
      setDelay(null);
      removePollingDataEntry(setPollingData, { advertiser_id: providerId });
      console.trace(error.message);
      toastr.error("Oops", "Unable to verify the status of the csv upload");
    }
  }

  useRelaxedInterval(checkPolling, delay);

  const uploadCSVFile = ({ providerId }) => {
    if (!uploadFile) {
      return;
    }
    var formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("advertiser_id", providerId);
    setIsUploading(true);
    CsvUpload.uploadCsvFile({ data: formData })
      .then((response) => {
        if (response.success) {
          addPollingDataEntry(setPollingData, {
            advertiser_id: providerId,
            data: response.data
          });
          setDelay(CSV_CHECK_DELAY_TIME);
        } else {
          throw new Error(
            response?.error?.message || "Unable to upload the CSV DATA"
          );
        }
      })
      .catch((error) => {
        setIsUploading(false);
        setDelay(null);
        removePollingDataEntry(setPollingData, { advertiser_id: providerId });
        console.trace(error.message);
        toastr.error("Oops", error.message);
      });
  };

  function uploadAnotherFile() {
    setUploadSuccess({
      ...uploadSuccess,
      success: false
    });
    setUploadFile(null);
  }

  // Clearing up any uploaded file when provider changes
  useEffect(() => {
    uploadAnotherFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);
  let currentUploadStatus = false;
  if (isUploading || dataForPolling) {
    currentUploadStatus = true;
  }

  return {
    isUploading: currentUploadStatus,
    handleBrowseAction,
    handleUploadChange,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    uploadCSVFile,
    fileUploadButtonRef,
    uploadFile,
    uploadSuccess,
    uploadAnotherFile
  };
};
