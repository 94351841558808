/**
 * CUSTOM TOOLTIP OPTION FOR ADVETISER LINKS THAT ARE USED IN THE DROPDOWN
 * OF PUBLISHER TAG (TARGETING) FORM
 */
import React from "react";
import DropDownToolTip from "./DropDownTooltip";
import RelatedProviderLinkIcon from "../../../pages/ProviderLinks/RelatedProviderLinkIcon";
const PtLinksTooltipOption = ({
  option,
  getLabel,
  showValueInLabel,
  disabledMenuClassName,
  enabledMenuClassName,
  extraDependencies
}) => {
  const isDisabled = option?.disabled || false;
  const { openModal } = extraDependencies;
  return (
    <div
      className={`autoselect-value ${
        isDisabled ? disabledMenuClassName : enabledMenuClassName
      }`}
    >
      <DropDownToolTip title={option.label}>
        <div className="ptLinksTooltipOption">
          <span>{getLabel(option, showValueInLabel)}</span>
          <RelatedProviderLinkIcon
            openModal={() => openModal(option.value)}
            noOfRules={option.no_of_rules}
          />
        </div>
      </DropDownToolTip>
    </div>
  );
};

export default PtLinksTooltipOption;
