import { useState, useEffect, useCallback } from "react";
import { MappedFields } from "../../services/MappedFields";
/**
 * Custom Hook responsible for using the MappedFields Class Object
 * to fetch mapped fields.
 */

export const useFieldMappingLogic = ({ providerId }) => {
  const [loadingMappedFields, setLoadingMappedFields] = useState(true);

  const [mappedFields, setMappedFields] = useState(null);

  const fetchMappedFields = useCallback(() => {
    if (!providerId) return;
    setLoadingMappedFields(true);
    MappedFields.fetchMappedFields({ providerId })
      .then((response) => {
        if (response.success) {
          let mappedFields = response?.data?.mappingData || null;
          setMappedFields(mappedFields);
          setLoadingMappedFields(false);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        setMappedFields(null);
        setLoadingMappedFields(false);
      });
  }, [providerId]);

  useEffect(() => {
    fetchMappedFields();
  }, [fetchMappedFields]);

  return {
    loadingMappedFields,
    mappedFields
  };
};
