import Api from "../api";

class SkippedRows {
  fetchAggregatedCsvUploadStatus = async (params = {}) => {
    const response = await Api.get(
      "fetchall/revenueupload/status",
      null,
      params
    );
    return response.body;
  };
  fetchSkippedRows = async (params = {}) => {
    const response = await Api.get("skippedrows", null, params);
    return response.body;
  };

  fetchSkippedRowsForApi = async (params = {}, id = null) => {
    const response = await Api.get("advertiser/api/rowsStatus", null, params);
    return response.body;
  };

  fetchcsvFiles = async (params = {}) => {
    const response = await Api.get("csv/files", null, params);
    return response.body;
  };
}

export default new SkippedRows();
