import Api from "../api";

class User {
  fetchUserProfile = async (id) => {
    const { body } = await Api.get(`user/${id}`);
    return body;
  };

  // updatePassword = async (data) => {
  //   const { body } = await Api.put(`user/changePassword`, data);
  //   return body;
  // };
  updatePassword = async (data,userid) => {
    const { body } = await Api.put(`user/changePassword/${userid}`, data);
    return body;
  };

  updateProfile = async (id, data) => {
    const { body } = await Api.put(`user/${id}`, data);
    return body;
  };

  updateProfilePhoto = async (id, data) => {
    const { body } = await Api.filePut(`user/${id}`, data);
    return body;
  };
}

export default new User();
