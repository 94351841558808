import React from "react";
import PropTypes from "prop-types";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import TextField from "@material-ui/core/TextField";
import SimpleDateRangePicker from "../../../components/SimpleDateRangePicker/SimpleDateRangePicker";

const FilterSelections = ({
  dataLists,
  selectionAddActions,
  filterValues,
  handleSearch
}) => {
  const {
    handleCountryChange,
    handleQueryChange,
    handlePublisherChange,
    handleProviderChange,
    changeSelectedInterval,
    handleGroupByChange,
    handleTagIdChange,
    handleRuleIdChange,
    handleIpAddressChange,
    handleProvidersLinkChange,
    handleDateChange,
    handleBrowserChange,
    handlePlatformChange,
    handleOsListChange
  } = selectionAddActions;

  const {
    providers,
    publishers,
    countries,
    groupByList,
    intervals,
    tagIds,
    ruleIds,
    providersLinks,
    browsers,
    platforms,
    osList
  } = dataLists;

  const {
    selectedCountries,
    selectedPublishers,
    selectedProviders,
    selectedInterval,
    selectedTagIds,
    selectedGroupBy,
    fromDate,
    toDate,
    query,
    ipAddress,
    selectedRuleIds,
    selectedProvidersLinks,
    selectedBrowsers,
    selectedPlatforms,
    selectedOsList
  } = filterValues;

  return (
    <div className="logs__selections">
      <AutoCompleteSelect
        selected={selectedInterval}
        data={intervals}
        handleChange={changeSelectedInterval}
        label={"Interval"}
        showValueInLabel={false}
        enableMultiple={false}
      />
      <TextField
        variant="standard"
        margin="normal"
        label="Search for Query"
        onChange={handleQueryChange}
        onKeyPress={(e) => {
          e.key === "Enter" && handleSearch();
        }}
        value={query}
        fullWidth
      />
      <TextField
        variant="standard"
        margin="normal"
        label="Search for IP Address"
        onChange={handleIpAddressChange}
        onKeyPress={(e) => {
          e.key === "Enter" && handleSearch();
        }}
        value={ipAddress}
        fullWidth
      />{" "}
      <AutoCompleteSelect
        selected={selectedProvidersLinks}
        data={providersLinks}
        handleChange={handleProvidersLinkChange}
        label={"Adveriser Link ID"}
        showValueInLabel={true}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedRuleIds}
        data={ruleIds}
        handleChange={handleRuleIdChange}
        label={"Rule ID"}
        showValueInLabel={false}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedTagIds}
        data={tagIds}
        handleChange={handleTagIdChange}
        label={"Tag ID"}
        showValueInLabel={false}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedProviders}
        data={providers}
        handleChange={handleProviderChange}
        label={"Advertisers"}
        showValueInLabel={true}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedPublishers}
        data={publishers}
        handleChange={handlePublisherChange}
        label={"Publisher"}
        showValueInLabel={true}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedCountries}
        data={countries}
        handleChange={handleCountryChange}
        label={"Geo"}
        showValueInLabel={true}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedBrowsers}
        data={browsers}
        handleChange={handleBrowserChange}
        label={"Browsers"}
        showValueInLabel={false}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedPlatforms}
        data={platforms}
        handleChange={handlePlatformChange}
        label={"Platforms"}
        showValueInLabel={false}
        enableMultiple={true}
      />
      <AutoCompleteSelect
        selected={selectedOsList}
        data={osList}
        handleChange={handleOsListChange}
        label={"OS"}
        showValueInLabel={false}
        enableMultiple={true}
      />
      <SimpleDateRangePicker
        startDate={fromDate ? fromDate : null}
        endDate={toDate ? toDate : null}
        handleDateChange={handleDateChange}
      />
      <AutoCompleteSelect
        selected={selectedGroupBy}
        data={groupByList}
        handleChange={handleGroupByChange}
        label={"Group By"}
        showValueInLabel={false}
        enableMultiple={true}
      />
    </div>
  );
};

FilterSelections.propTypes = {
  dataLists: PropTypes.object.isRequired,
  selectionAddActions: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired
};

export default FilterSelections;
