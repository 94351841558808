import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProviders } from "../../reducers/provider/provider.selector";
import { Report } from "../../services/Report";
import { updateProviders } from "../../reducers/provider/provider.action";
import { useHistory } from "react-router-dom";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import ProviderIcon from "@material-ui/icons/CardTravelSharp";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import Table from "../../components/Table/Table";
import { Provider } from "../../services/Provider";
import FilterBox from "../../components/FilterBox/FilterBox";
import BulkActionBox from "../../components/BulkActionBox/BulkActionBox";
import { toastr } from "react-redux-toastr";
import { textFieldValid } from "../../helpers/validation";
import SimpleAccordion from "../../components/Accordion/Accordion";
import TabMenu from "../../components/TabMenu/TabMenu";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import ConfirmBoxWithForm from "../../components/ConfirmBox/ConfirmBoxWithForm";
import moment from "moment";
import { capitalizeFirstLetter } from "../../helpers/index";

import {
  fetchProvidersListStart,
  fetchProvidersListSuccess,
  fetchProvidersListFailure
} from "../../reducers/provider/provider.action";

const Index = () => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const history = useHistory();
  const [selected, setSelected] = React.useState([]);
  const providers = useSelector(getProviders);
  /**
   * state for opening and clossing different modals
   * according to their purpose
   */
  const [open, setOpen] = useState(false);
  const [bulkDeleteOpen, setBulkDeleteOpen] = useState(false);
  const [unArchiveOpen, setUnArchiveOpen] = useState(false);
  const [bulkRestoreOpen, setBulkRestoreOpen] = useState(false);
  const [deleteRevenue, setDeleteRevenue] = useState(false);

  //states for fitler box
  const [searchValue, setSearchValue] = useState("");

  const [active, setActive] = useState(false);
  const [inActive, setInActive] = useState(false);
  //state for pagination that will be used as params
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC
  const [total, setTotal] = useState(0);
  const [archived, setArchived] = useState(false);

  const [loading, setLoading] = useState(false);
  const prevPage = useRef(1); //!!IMP : SET IT SAME AS DEFAULT VALUE FOR PAGE STATE

  //selection index to choose between Archived and Normal Providers/Advertisers list List
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] =
    useState(0);
  const sections = [
    {
      name: "Advertisers"
    },
    {
      name: "Archived Advertisers"
    }
  ];
  let choiceOfWord =
    currentSelectedSectionIndex === 0 ? "archive" : "delete forever";

  const getRequestParams = useCallback(() => {
    let params = {};

    if (textFieldValid(searchValue)) {
      params["q"] = searchValue.trim();
    }

    if (archived) {
      params["archived"] = true;
    }

    if (active) {
      params["status"] = "active";
    }

    if (inActive) {
      params["status"] = "inactive";
    }
    params["page"] = page;
    params["size"] = size;
    params["order_by"] = orderBy;
    params["order_direction"] = orderDirection;

    return params;
  }, [
    active,
    inActive,
    orderBy,
    orderDirection,
    page,
    archived,
    searchValue,
    size
  ]);

  const fetchProviders = useCallback(
    (params = {}) => {
      setLoading(true);
      stableDispatch(fetchProvidersListStart());
      Provider.fetchProviders(params)
        .then((response) => {
          if (response.success) {
            let providers = response.data.providers.map((provider) => {
              provider.updatedAt = moment(provider.updatedAt)
                .utc()
                .format("lll");
              return provider;
            });
            stableDispatch(updateProviders(providers));
            setTotal(response.data.total);
            stableDispatch(fetchProvidersListSuccess());
            setLoading(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          stableDispatch(fetchProvidersListFailure(error.message));
          setLoading(false);
        });
    },
    [stableDispatch]
  );
  //fetching publishers and deselecting all the selection when params values changes
  useEffect(() => {
    fetchProviders(getRequestParams());
    prevPage.current === page && setSelected([]); //page changed don't make the selected rows empty
    if (page !== prevPage.current) {
      prevPage.current = page;
    }
  }, [
    active,
    inActive,
    size,
    orderBy,
    orderDirection,
    page,
    archived,
    getRequestParams,
    fetchProviders
  ]);

  /**
   * Closes any opening modal
   * and sets value to be changed through modal to null.
   */
  const handleModalClose = () => {
    setChangeID(null);
    setOpen(false);
    setUnArchiveOpen(false);
    setBulkDeleteOpen(false);
    setBulkRestoreOpen(false);
    setDeleteRevenue(false);
  };
  const [changeID, setChangeID] = useState(null);
  const headers = [
    { title: "ID", value: "id" },
    { title: "Name", value: "name" },
    { title: "Details", value: "details" },
    { title: "Status", value: "status" },
    { title: "Display in CSV UPLOAD", value: "display_in_upload_screen" }
  ];
  const headersForArchive = [
    { title: "ID", value: "id" },
    { title: "Name", value: "name" },
    { title: "Details", value: "details" },
    { title: "Archived At (UTC)", value: "updatedAt" }
  ];
  const handleEditAction = (id) => history.push(`/advertisers/edit/${id}`);
  const handleDeleteAction = (id) => {
    if (providerHasRules(id)) {
      toastr.info(
        "Info",
        "Cannot delete advertiser that are being used in targeting rules."
      );
      return;
    }
    setChangeID(id);
    setOpen(true);
  };
  const handleBulkRestoreAction = () => {
    if (selected.length > 0) {
      setBulkRestoreOpen(true);
    } else {
      toastr.warning("Oops", "No Advertisers selected");
    }
  };
  const deleteProvider = () => {
    Provider.deleteProvider(changeID)
      .then((response) => {
        if (response.success) {
          setChangeID(null);
          fetchProviders(getRequestParams());
          setOpen(false);
          toastr.success("Success", "Advertiser archived successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to archive at the moment!");
      });
  };

  /*archived and normal data selection change handler */
  const handleSelectionIndexChange = (index) => {
    //setting Page to 1
    setPage(1);
    setSelected([]);
    setSearchValue("");
    setActive(false);
    setInActive(false);
    setCurrentSelectedSectionIndex(index);
    if (index === 0) {
      setArchived(false);
    } else {
      setArchived(true);
    }
  };

  const handleBulkDeleteAction = () => {
    if (selected.length > 0) {
      setBulkDeleteOpen(true);
    } else {
      toastr.warning("Oops!", "No Advertiser selected");
    }
  };

  const bulkDelete = () => {
    let data;
    data = {
      ids: selected
    };
    Provider.bulkDeleteProviders(data)
      .then((response) => {
        if (response.success) {
          fetchProviders(getRequestParams());
          toastr.success("Success", "Advertisers archived");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to archive advertisers");
      });
    setBulkDeleteOpen(false);
    setSelected([]);
  };

  const bulkChangeStatus = (type) => {
    if (selected.length > 0) {
      let data, changeType;
      data = {
        ids: selected
      };
      if (type === "activate") {
        changeType = "active";
      }
      if (type === "de-activate") {
        changeType = "inactive";
      }
      Provider.bulkUpdateProviders(changeType, data)
        .then((response) => {
          if (response.success) {
            fetchProviders(getRequestParams());
            toastr.success("Success", "Status updated!");
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          toastr.error("Oops!! Not able to update advertiser's status.");
        });
      setSelected([]);
    } else {
      toastr.warning("Oops!", "Nothing Selected");
    }
  };

  const handleDeleteRevenue = () => {
    if (selected.length > 0) {
      setDeleteRevenue(true);
    } else {
      toastr.warning("Oops", "No Advertiser selected");
    }
  };
  const bulkRestore = () => {
    let data;
    data = {
      ids: selected
    };
    Provider.restoreProviders(data)
      .then((response) => {
        if (response.success) {
          fetchProviders(getRequestParams());
          toastr.success("Success", "Advertisers restored");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to restore advertisers");
      });
    setSelected([]);
  };

  const handleManageLinksAction = (id) => {
    history.push({
      pathname: "/advertisers/links",
      state: {
        provider: id
      }
    });
  };

  const completlyDeleteRevenue = async (data = {}) => {
    try {
      const postData = {
        type: "Advertiser",
        from: data.fromDate,
        to: data.toDate,
        ids: [...selected],
        publishers: selected.length <= 1 ? data.selectedPublishers : null
      };
      const deleted = await Report.removeReportsDataByPubAdv(postData, {});
      if (deleted.success) {
        handleModalClose();
        toastr.success(
          "Success",
          `Successfully deleted ${deleted.data.deleted} records`
        );
      } else {
        throw new Error("Something bad happend in API");
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", "Not able to remove at the moment");
    }
  };

  const icon = <ProviderIcon fontSize="inherit" color="primary" />;

  const actions = [
    {
      name: "edit",
      action: handleEditAction,
      icon: "editIcon"
    },
    {
      name: "delete",
      action: handleDeleteAction,
      icon: "deleteIcon"
    },
    {
      name: "managelinks",
      action: handleManageLinksAction,
      icon: "manageIconOne"
    }
  ];

  const unArchivePublisher = () => {
    let params = {
      archive: false
    };
    let dataToSend = {};
    Provider.updateProvider(changeID, dataToSend, params)
      .then((response) => {
        if (response.success) {
          fetchProviders(getRequestParams());
          toastr.success("Success", "Advertiser un-archived!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to un-archive the advertiser");
      });
  };

  const handleRestoreAction = (id) => {
    setChangeID(id);
    setUnArchiveOpen(true);
  };

  const handleDeleteForever = () => {
    let params = {
      permanent: true
    };
    Provider.deleteProvider(changeID, params)
      .then((response) => {
        if (response.success) {
          setChangeID(null);
          fetchProviders(getRequestParams());
          setOpen(false);
          toastr.success("Success", "Advertiser removed permanently!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to remove at the moment!");
      });
    setChangeID(null);
    setOpen(false);
  };

  //action list for archived table
  const actionsForArchive = [
    // {
    //   name: "deleteForever",
    //   action: handleDeleteAction,
    //   icon: "deleteForever"
    // },
    {
      name: "restore",
      action: handleRestoreAction,
      icon: "restore"
    }
  ];

  const deleteProviderModal = (
    <SimpleModal handleClose={handleModalClose}>
      <div className="delete-confirm-box">
        <Typography component="h3">
          Are you sure you want to {choiceOfWord}?
        </Typography>
        <div className="delete-confirm-box__buttons">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              //current Selection is 0 i.e normal data, archive
              if (currentSelectedSectionIndex === 0) deleteProvider();
              else handleDeleteForever();
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleModalClose()}
          >
            No
          </Button>
        </div>
      </div>
    </SimpleModal>
  );

  const bulkDeleteProviderModal = (
    <SimpleModal handleClose={handleModalClose}>
      <div className="delete-confirm-box">
        <Typography component="h3">
          Are you sure want to archive {selected.length} items?
        </Typography>
        <div className="delete-confirm-box__buttons">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => bulkDelete()}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleModalClose()}
          >
            No
          </Button>
        </div>
      </div>
    </SimpleModal>
  );

  const deleteRevenueModel = (
    <ConfirmBoxWithForm
      message={`Are you sure want to permanently delete revenue reports of selected items?`}
      confirmAction={completlyDeleteRevenue}
      denyAction={handleModalClose}
      type={"provider"}
      selected={selected}
    ></ConfirmBoxWithForm>
  );
  const unArchiveModal = (
    <ConfirmBox
      message={`Are you sure you want to un-archive this advertiser?`}
      denyAction={handleModalClose}
      confirmAction={() => {
        unArchivePublisher();
        handleModalClose();
      }}
    ></ConfirmBox>
  );

  const bulkRestoreProviderModal = (
    <ConfirmBox
      message={`Are you sure want to restore ${selected.length} items?`}
      confirmAction={() => {
        bulkRestore();
        handleModalClose();
      }}
      denyAction={handleModalClose}
    ></ConfirmBox>
  );

  const providerHasRules = (id) => {
    let providerIndex = providers.findIndex((provider) => provider.id === id);
    let noOfTargetings = parseInt(providers[providerIndex].no_of_rules);
    if (noOfTargetings > 0) {
      return true;
    }
    return false;
  };

  //update provider value when change made
  //in row.
  //Only for redux State
  const updateProvidersValue = (id, key, value) => {
    let tempProviders = [...providers];
    let changedIndex = tempProviders.findIndex(
      (provider) => provider.id === id
    );
    tempProviders[changedIndex][key] = value;
    dispatch(updateProviders(tempProviders));
  };

  const handleStatusToggle = (id, currentStatus, data) => {
    if (providerHasRules(id) && currentStatus === "active") {
      toastr.info(
        "Info",
        "Cannot change status of advertiser that are being used in targeting rules."
      );
      return;
    }
    let dataToSend = {};
    currentStatus === "active"
      ? (dataToSend.status = "inactive")
      : (dataToSend.status = "active");
    Provider.updateProvider(id, dataToSend)
      .then((response) => {
        if (response.success) {
          updateProvidersValue(id, "status", dataToSend.status);
          toastr.success("Success", "Status Updated");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to update");
      });
  };

  const handleDisplayStatus = (id, currentStatus, data) => {
    let dataToSend = {};
    currentStatus === true
      ? (dataToSend.display_in_upload_screen = false)
      : (dataToSend.display_in_upload_screen = true);
    Provider.updateProvider(id, dataToSend)
      .then((response) => {
        if (response.success) {
          updateProvidersValue(
            id,
            "display_in_upload_screen",
            dataToSend.display_in_upload_screen
          );
          toastr.success(
            "Success",
            currentStatus
              ? "Remove from CSV Upload Screen"
              : "Displayed in CSV Upload Screen"
          );
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to update");
      });
  };

  const handleCellValueUpdate = (data, key, value) => {
    let id = data.id;
    let dataToSend = {};
    dataToSend[key] = value;
    Provider.updateProvider(id, dataToSend)
      .then((response) => {
        if (response.success) {
          updateProvidersValue(id, key, value);
          toastr.success("Success", `${capitalizeFirstLetter(key)} updated.`);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
      });
  };

  return (
    <div className="publishers-container">
      <div className="publishers-container__header">
        <ContentHeader icon={icon} title="Advertiser List" />
        <div className="publishers-container__addBtn">
          <Button
            onClick={() => history.push("/advertisers/add")}
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddBoxIcon />}
          >
            Add
          </Button>
        </div>
      </div>
      <div className="publishers-container__filter-box">
        <SimpleAccordion header="Filters" key="provider-filter-box">
          <FilterBox
            active={active}
            inActive={inActive}
            setSearchValue={setSearchValue}
            setActive={setActive}
            setInActive={setInActive}
            archive={currentSelectedSectionIndex === 1}
            setSelected={setSelected}
          />
        </SimpleAccordion>

        <SimpleAccordion header="Bulk Actions" key="provider-bulk-action">
          <BulkActionBox
            handleBulkDeleteAction={handleBulkDeleteAction}
            handleStatusChange={bulkChangeStatus}
            archive={currentSelectedSectionIndex === 1}
            handleBulkRestore={handleBulkRestoreAction}
            handleDeleteRevenue={handleDeleteRevenue}
            type="provider"
          />
        </SimpleAccordion>
      </div>
      <div className="table-selection-tab">
        <TabMenu
          sections={sections}
          setCurrentSelectedSectionIndex={handleSelectionIndexChange}
          currentSelectedSectionIndex={currentSelectedSectionIndex}
        />
      </div>
      <div className="publishers-container__table">
        {providers && (
          <Table
            headers={
              currentSelectedSectionIndex === 0 ? headers : headersForArchive
            }
            rowValues={providers}
            actions={
              currentSelectedSectionIndex === 0 ? actions : actionsForArchive
            }
            selected={selected}
            setSelected={setSelected}
            tableType={
              currentSelectedSectionIndex === 0
                ? "provider"
                : "provider--archive"
            }
            handleStatusToggle={handleStatusToggle}
            handleDisplayStatus={handleDisplayStatus}
            handleCellValueUpdate={handleCellValueUpdate}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            total={total}
            isLoading={loading}
            rowsPerPageOptions={[10, 20, 50, 100, 150, 200]}
          />
        )}
        {open && deleteProviderModal}
        {bulkDeleteOpen && bulkDeleteProviderModal}
        {unArchiveOpen && unArchiveModal}
        {bulkRestoreOpen && bulkRestoreProviderModal}
        {deleteRevenue && deleteRevenueModel}
      </div>
    </div>
  );
};

export default Index;
