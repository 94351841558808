import {
  COUNTRY,
  BROWSER,
  TID,
  RULE_ID,
  PLATFORM,
  IP,
  OS_INFO,
  QUERY
} from "../../helpers/constant/filters";

import { useState, useEffect } from "react";

function getInitialShowState() {
  let showState = {};

  showState[COUNTRY] = true;
  showState[BROWSER] = true;
  showState[TID] = true;
  showState[RULE_ID] = true;
  showState[PLATFORM] = true;
  showState[IP] = true;
  showState[OS_INFO] = true;
  showState["provider_id"] = true;
  showState["pid"] = true;
  showState["request_at"] = true;
  showState["unique_ip"] = true;
  showState["total_searches"] = true;
  showState["link_id"] = true;
  showState["sno"] = true;
  showState[QUERY] = true;
  return showState;
}
export const useLogTableHeaderLogic = ({ selectedGroupBy, logs }) => {
  const [filteredTableHeaders, setFilteredTableHeaders] = useState([]);
  const [showState, setShowState] = useState(getInitialShowState());
  const [tableHeaders, setTableHeaders] = useState([]);

  const updateHeadersShowValue = (value, index) => {
    let newShowState = { ...showState };
    newShowState[value] = !newShowState[value];
    setShowState(newShowState);
  };

  useEffect(() => {
    function getTableHeaders(logs) {
      let headers = [{ title: "SNo", value: "sno", show: showState["sno"] }];
      let oneValueFromLogs = logs?.[0] || {};
      Object.keys(oneValueFromLogs).forEach((key) => {
        let header = groupByValueMappedToHeader(key);
        if (header) {
          headers.push(header);
        }
      });
      return headers;
    }
    function groupByValueMappedToHeader(value) {
      switch (value) {
        case COUNTRY:
          return { title: "Geo", value: COUNTRY, show: showState[COUNTRY] };
        case BROWSER:
          return { title: "Browser", value: BROWSER, show: showState[BROWSER] };
        case "provider_id":
          return {
            title: "Advertiser",
            value: "provider_id",
            show: showState["provider_id"]
          };
        case "pid":
          return { title: "Publisher", value: "pid", show: showState["pid"] };
        case TID:
          return { title: "Tag ID", value: TID, show: showState[TID] };
        case RULE_ID:
          return { title: "Rule ID", value: RULE_ID, show: showState[RULE_ID] };
        case QUERY:
          return { title: "Query", value: QUERY, show: showState[QUERY] };
        case "request_at":
          return {
            title: "Date (Utc Time)",
            value: "request_at",
            show: showState["request_at"]
          };
        case "unique_ip":
          return {
            title: "Unique IPs",
            value: "unique_ip",
            show: showState["unique_ip"]
          };
        case "total_searches":
          return {
            title: "Total Searches",
            value: "total_searches",
            show: showState["total_searches"]
          };
        case "link_id":
          return {
            title: "Advertiser Link",
            value: "link_id",
            show: showState["link_id"]
          };
        case PLATFORM:
          return {
            title: "Platform",
            value: PLATFORM,
            show: showState[PLATFORM]
          };
        case IP:
          return { title: "IP Address", value: IP, show: showState[IP] };
        case OS_INFO:
          return { title: "OS", value: OS_INFO, show: showState[OS_INFO] };
        default:
          return "";
      }
    }

    setTableHeaders(getTableHeaders(logs));
  }, [logs, showState]);

  useEffect(() => {
    setTableHeaders((tableHeaders) =>
      tableHeaders.map((header) => {
        header.show = showState[header.value];
        return header;
      })
    );
  }, [showState]);

  useEffect(() => {
    setFilteredTableHeaders(tableHeaders.filter((header) => header.show));
  }, [tableHeaders]);

  return {
    tableHeaders,
    filteredTableHeaders,
    updateHeadersShowValue
  };
};
