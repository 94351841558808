import ProviderActionTypes from "./provider.types";

const INITIAL_STATE = {
  error: null,
  isFetchingProvider: false,
  isFetchingProvidersList: false,
  disabledProviders: [],
  providers: [],
  providerLinks: [],
  deletedProviderLinks: [],
  providerLinksError: []
};

const providerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProviderActionTypes.FETCH_PROVIDER_START:
      return {
        ...state,
        isFetchingProvider: true
      };
    case ProviderActionTypes.FETCH_PROVIDER_SUCCESS:
      return {
        ...state,
        isFetchingProvider: false
      };
    case ProviderActionTypes.FETCH_PULISHER_FAILURE:
      return {
        ...state,
        isFetchingProvider: false,
        error: action.payload
      };
    case ProviderActionTypes.UPDATE_PROVIDERS:
      return {
        ...state,
        providers: action.payload
      };
    case ProviderActionTypes.ADD_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload
      };
    case ProviderActionTypes.FETCH_PROVIDERS_LIST_START:
      return {
        ...state,
        isFetchingProvidersList: true
      };
    case ProviderActionTypes.FETCH_PROVIDERS_LIST_SUCCESS:
      return {
        ...state,
        isFetchingProvidersList: false
      };
    case ProviderActionTypes.FETCH_PROVIDERS_LIST_FAILURE:
      return {
        ...state,
        isFetchingProvidersList: false,
        error: action.payload
      };
    case ProviderActionTypes.UPDATE_DISABLED_PROVIDERS_LIST:
      return {
        ...state,
        disabledProviders: action.payload
      };
    case ProviderActionTypes.UPDATE_DELETED_PROVIDER_LINKS:
      return {
        ...state,
        deletedProviderLinks: action.payload
      };
    case ProviderActionTypes.UPDATE_PROVIDER_LINKS:
      return {
        ...state,
        providerLinks: action.payload
      };
    case ProviderActionTypes.UPDATE_PROVIDER_LINKS_ERRORS:
      return {
        ...state,
        providerLinksError: action.payload
      };
    case ProviderActionTypes.CLEAR_PROVIDER_LINKS_AND_ERRORS:
      return {
        ...state,
        providerLinksError: [],
        providerLinks: []
      };
    default:
      return { ...state };
  }
};

export default providerReducer;
