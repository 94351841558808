import React from "react";
import { useLocation } from "react-router-dom";
import NoUserIdMsg from "./NoUserIdMsg";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import { useFetchDisplayColumns } from "./fetchDisplayColumns.logic";
import SelectionForm from "./SelectionForm";
import GroupIcon from "@material-ui/icons/Group";

const DisplayColumns = () => {
  const location = useLocation();
  const redirectedUserId = location?.state?.userId || null;
  const icon = <GroupIcon fontSize="inherit" color="primary" />;

  const { displayColumns, loading } = useFetchDisplayColumns({
    userId: redirectedUserId
  });

  if (!redirectedUserId) {
    return <NoUserIdMsg />;
  }

  if (loading.isLoading) {
    return (
      <div className="loading-div">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="flow-content">
      <ContentHeader icon={icon} title="Publishers Display Columns" />
      <div className="publisher-display-columns flow-content">
        <h2 classname="gray-text">User ID : {redirectedUserId}</h2>
        <SelectionForm
          displayColumns={displayColumns}
          userId={redirectedUserId}
        />
      </div>
    </div>
  );
};

export default DisplayColumns;
