/*
 *  Custom Hook to load Search Log Data
 */
import { useState, useEffect } from "react";
import moment from "moment";
import { Log } from "../../services/Logs";
import { textFieldValid } from "../../helpers/validation";

export const useLogData = ({
  filterValues,
  filtersClearToogle,
  selectionAddActions
}) => {
  const [logs, setLogs] = useState(null);
  const [monthToDateLogs, setMonthToDateLogs] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [total, setTotal] = useState(0);
  const [noOfFiltersSelected, setNoOfFiltersSelected] = useState(0);
  const [loadingLogs, setLoadingLogs] = useState({
    loading: true,
    error: false
  });

  /**
   * @side-effect : changes the number of filters selcted based on the params
   * @returns params for making the call
   */
  function getParams() {
    let params = {};
    const {
      selectedCountries,
      selectedPublishers,
      selectedProviders,
      selectedInterval,
      selectedTagIds,
      selectedGroupBy,
      fromDate,
      toDate,
      query,
      ipAddress,
      selectedRuleIds,
      selectedProvidersLinks,
      selectedBrowsers,
      selectedPlatforms,
      selectedOsList,
      orderBy,
      orderDirection
    } = filterValues;
    let filtersSelectedCount = 0;

    if (textFieldValid(query)) {
      params["q"] = query.trim();
      filtersSelectedCount++;
    }

    if (textFieldValid(ipAddress)) {
      params["ip_address"] = ipAddress.trim();
      filtersSelectedCount++;
    }

    if (moment(fromDate).isValid()) {
      params["from"] = moment(fromDate).format("YYYY-MM-DD");
      filtersSelectedCount++;
    }
    if (moment(toDate).isValid()) {
      params["to"] = moment(toDate).format("YYYY-MM-DD");
      filtersSelectedCount++;
    }

    if (selectedPublishers.length > 0) {
      let selection = [];
      for (let i in selectedPublishers) {
        selection.push(selectedPublishers[i]);
      }
      params["pid"] = selection;
      filtersSelectedCount++;
    }

    if (selectedBrowsers.length > 0) {
      let selection = [];
      for (let i in selectedBrowsers) {
        selection.push(selectedBrowsers[i]);
      }
      params["browser_info"] = selection;
      filtersSelectedCount++;
    }

    if (selectedPlatforms.length > 0) {
      let selection = [];
      for (let i in selectedPlatforms) {
        selection.push(selectedPlatforms[i]);
      }
      params["device_info"] = selection;
      filtersSelectedCount++;
    }

    if (selectedOsList.length > 0) {
      let selection = [];
      for (let i in selectedOsList) {
        selection.push(selectedOsList[i]);
      }
      params["os_info"] = selection;
      filtersSelectedCount++;
    }

    if (selectedProviders?.length > 0) {
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["provider_id"] = selection;
      filtersSelectedCount++;
    }

    if (selectedProvidersLinks?.length > 0) {
      let selection = [];
      for (let i in selectedProvidersLinks) {
        selection.push(selectedProvidersLinks[i]);
      }
      params["link_id"] = selection;
      filtersSelectedCount++;
    }

    if (selectedTagIds.length > 0) {
      let selection = [];
      for (let i in selectedTagIds) {
        selection.push(selectedTagIds[i]);
      }
      params["cid"] = selection;
      filtersSelectedCount++;
    }
    if (selectedRuleIds.length > 0) {
      let selection = [];
      for (let i in selectedRuleIds) {
        selection.push(selectedRuleIds[i]);
      }
      params["rule"] = selection;
      filtersSelectedCount++;
    }
    if (selectedGroupBy?.length > 0) {
      let selection = [];
      for (let i in selectedGroupBy) {
        selection.push(selectedGroupBy[i]);
      }
      params["group_by"] = selection;
      filtersSelectedCount++;
    }

    if (selectedInterval) {
      params["interval"] = selectedInterval;
      filtersSelectedCount++;
    }

    if (selectedCountries.length > 0) {
      let selection = [];
      for (let i in selectedCountries) {
        selection.push(selectedCountries[i]);
      }
      params["country"] = selection;
      filtersSelectedCount++;
    }
    params["page"] = page;
    params["size"] = size;
    if (
      selectedInterval &&
      (selectedInterval === "day" ||
        selectedInterval === "month" ||
        selectedInterval === "hour")
    ) {
      params["order_by"] = "request_at";
    }
    if (orderBy) {
      params["order_by"] = orderBy;
    }
    params["order_direction"] = orderDirection;

    setNoOfFiltersSelected(filtersSelectedCount);
    return params;
  }

  function formatLogRow(unFormattedLog, index) {
    let log = { ...unFormattedLog };
    if ("request_at" in log) {
      if (filterValues?.selectedInterval === "day") {
        log.request_at = moment(log.request_at).utc().format("MMM DD,YYYY");
      } else if (filterValues?.selectedInterval === "month") {
        log.request_at = moment(log.request_at).utc().format("MMM ,YYYY");
      } else if (filterValues?.selectedInterval === "hour") {
        log.request_at = moment(log.request_at)
          .utc()
          .format("MMMM Do YYYY, h:mm:ss a");
      } else {
        log.request_at = moment(log.request_at).utc().format("MMM DD,YYYY");
      }
    }
    if ("pid" in log) {
      //publisher
      log.pid = `${log?.publisher?.id || ""} - ${log?.publisher?.name || ""}`;
    }
    if ("provider" in log || "provider_id" in log) {
      //publisher or advertiser
      log.provider_id = `${log?.provider?.id || ""} - ${
        log?.provider?.name || ""
      }`;
    }
    if ("provider_link" in log) {
      log.link_id = `${log?.provider_link?.id || ""} - ${
        log?.provider_link?.link || ""
      }`;
    }
    if ("country" in log) {
      log.country = log?.country || "No geo data";
    }

    if ("unique_ip" in log) {
      let temp_ip = log?.unique_ip || "";
      delete log.unique_ip;
      log.unique_ip = temp_ip;
    }

    if ("total_searches" in log) {
      let temp_searches = log?.total_searches || "";
      delete log.total_searches;
      log.total_searches = temp_searches;
    }

    log.sno = index + 1 + size * (page - 1); // calculating the serial number of the row, size is from the state

    return log;
  }

  function getFormatedLogs(fetchedLogs) {
    let formatedLogs = [];
    for (let index = 0; index < fetchedLogs.length; index++) {
      let log = formatLogRow(fetchedLogs[index], index);
      formatedLogs.push(log);
    }
    return formatedLogs;
  }

  function fetchLogsData() {
    setLoadingLogs({
      loading: true,
      error: false
    });
    Log.fetchLogs(getParams())
      .then((response) => {
        if (response.success) {
          setTotal(response.data.total);
          let formatedLogs = getFormatedLogs(response.data.logs);
          setLogs(formatedLogs);
          setMonthToDateLogs(response.data?.monthToDate || null);
          // changing state inside timeout to prevent slow UI re-rendering
          // of logs table
          setTimeout(() => {
            setLoadingLogs({
              loading: false,
              error: false
            });
          }, 800);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        setLogs(null);
        setMonthToDateLogs(null);
        setLoadingLogs({
          loading: false,
          error: true
        });
      });
  }

  useEffect(() => {
    if (!size) {
      setSize(50);
    }
    if (!page) {
      setPage(1);
    }
  }, [size, page]);

  useEffect(() => {
    fetchLogsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    size,
    filterValues.orderBy,
    filterValues.orderDirection,
    filtersClearToogle
  ]);

  return {
    logs,
    loadingLogs: loadingLogs.loading,
    size,
    page,
    setPage,
    setSize,
    total,
    fetchLogsData,
    noOfFiltersSelected,
    monthToDateLogs
  };
};
