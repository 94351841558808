import Api from "../api";
class PublisherApiService {
  fetchPublisherApiInfo = async (id = null) => {
    const { body } = await Api.get(`publisher/api/details/${id}`);
    return body;
  };
  postPublisherApiInfo = async (data = {}, id = null) => {
    const { body } = await Api.post(`publisher/api/details/${id}`, data);
    return body;
  };
  fetchPublisherApiInfoByAccountId = async (id = null) => {
    const { body } = await Api.get(`publisher/api/details/account/${id}`);
    return body;
  };
}

export default new PublisherApiService();
