import Api from "../api";

class Report {
  fetchDashboardData = async (params = {}) => {
    const { body } = await Api.get(`dashboard`, null, params);
    return body;
  };

  downloadReport = async (params = {}) => {
    const { body, status } = await Api.get(
      `dashboard/download/csv`,
      "blob",
      params
    );
    return { data: body, status };
  };

  fetchMonthlyStats = async (params = {}) => {
    const { body } = await Api.get(`dashboard/monthlyStats`, null, params);
    return body;
  };

  removeReportData = async (accountId, params = {}) => {
    const { body } = await Api.destroy(
      `remove/reports/${accountId}`,
      {},
      params
    );
    return body;
  };

  removeReportsDataByPubAdv = async (data, params = {}) => {
    const { body } = await Api.destroy(
      `remove/eports/publisher_advertiser`,
      data,
      params
    );
    return body;
  };
  removeReportsDataFromSetting = async (
    data,
    params = {},
    publisherId = null
  ) => {
    const { body } = await Api.destroy(
      `remove/reports/publisher/${publisherId}`,
      data,
      params
    );
    return body;
  };
  fetchChannelsByAdId = async (params = {}) => {
    const { body } = await Api.get(
      `dashboard/advertisers/subids`,
      null,
      params
    );
    return body;
  };
}

export default new Report();
