import React from "react";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Button from "@material-ui/core/Button";
import { PublisherApiService } from "../../../../services/publisherApi";

const NotExistInfo = (props) => {
  const { publisher, setIsExistInfo } = props;

  const postDetails = async () => {
    const posted = await PublisherApiService.postPublisherApiInfo(
      {},
      publisher
    );
    if (posted) {
      setIsExistInfo(true);
    }
  };

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={(event) => postDetails()}
        size="medium"
        type="submit"
        startIcon={<AddBoxIcon />}
      >
        {" "}
        Set Token
      </Button>
    </div>
  );
};

export default NotExistInfo;
