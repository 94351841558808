import React from "react";
import PropTypes from "prop-types";
import UploadBox from "./UploadBox";
import NoMappedFieldMsg from "./NoMappedFieldMsg";
import { useRedirectLogic } from "../../../../hooks/useRedirectLogic";


const AdvertiserListBox = ({ advertiser }) => {
  const {redirectToAdvertiserPubAccounts} = useRedirectLogic();
  const { name, id, mapping } = advertiser;
  return (
    <div className="csv-upload__advertisers">
      <h3
        className={`${
          advertiser.status === "active"
            ? "csv-upload__active"
            : "csv-upload__inactive"
        }`}
      >{`${id}-${name}`} 
        <button style={{"margin":"2px","padding":"4px"}} onClick={()=>{
          redirectToAdvertiserPubAccounts(id)
        }} type="button">Linked Accounts</button>
      </h3>
      {mapping && <UploadBox providerId={id} />}
      {!mapping && <NoMappedFieldMsg providerId={id} />}
    </div>
  );
};

AdvertiserListBox.propTypes = {
  advertiser: PropTypes.object.isRequired
};

export default AdvertiserListBox;
