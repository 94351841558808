import React, { useState } from "react";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import { useProviderLinksFilters } from "./logic/providerLinksFilters.logic";
import { useFetchProviderLinks } from "./logic/fetchProviderLinks.logic";
import ProviderLinksFilter from "./layouts/ProviderLinksFilter";
import ProviderLinksTable from "./layouts/ProviderLinksTable";
import SimpleAccordion from "../../components/Accordion/Accordion";

import ProviderLinksFormContainer from "./ProviderLinksFormContainer";
import { useFormUILogic } from "./logic/formUI.logic";
import { useLocation } from "react-router-dom";

const ProviderLinksList = () => {
  const icon = <AllInclusiveIcon fontSize="inherit" color="primary" />;
  const location = useLocation();
  const providerId = location?.state?.provider || "";
  const [redirectedProviderId, setRedirectedProviderId] = useState(providerId);
  const filterValuesLogic = useProviderLinksFilters({
    redirectedProviderId,
    setRedirectedProviderId
  });
  const { filterValues, filtersClearToggle, dataLists, clearAllSelection } =
    filterValuesLogic;
  const { tagTypes, platforms, searchEngines, providers, templates } = dataLists;

  const fetchProviderLinksLogic = useFetchProviderLinks({
    filterValues,
    filtersClearToggle
  });
  const { getProviderLinks, loadingProviderLinks, updateProviderLinks } =
    fetchProviderLinksLogic;

  const formUILogic = useFormUILogic();
  const { showEditForm, showDuplicateForm } = formUILogic;

  return (
    <div className="provider-links-list__container">
      <div className="provider-links-list__header">
        <ContentHeader icon={icon} title="Advertiser Tags List" />
      </div>
      <div className="flow-content">
        <SimpleAccordion
          header="Filters"
          key="providerLinks-filters"
          defaultExpanded={true}
        >
          <ProviderLinksFilter
            filterValuesLogic={filterValuesLogic}
            handleSearch={getProviderLinks}
            loadingProviderLinks={loadingProviderLinks}
          />
        </SimpleAccordion>
        <div className="providerLinks__form__container">
          <ProviderLinksFormContainer
            tagTypes={tagTypes}
            platforms={platforms}
            searchEngines={searchEngines}
            providers={providers}
            templates={templates}
            formUILogic={formUILogic}
            getProviderLinks={clearAllSelection}
          />
        </div>
        <ProviderLinksTable
          fetchProviderLinksLogic={fetchProviderLinksLogic}
          updateProviderLinks={updateProviderLinks}
          showEditForm={showEditForm}
          showDuplicateForm={showDuplicateForm}
        />
      </div>
    </div>
  );
};

export default ProviderLinksList;
