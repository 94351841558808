import { useState, useEffect, useCallback } from "react";
import { Publisher } from "../../../services/Publisher";
import { toastr } from "react-redux-toastr";
/**
 * Custom Hook that fetches linked publisher accounts for a particular
 * targeting
 */

export const useFetchLinkedAccounts = ({ tid, pid, ruleID }) => {
  const [linkedAccounts, setLinkedAccounts] = useState(null);
  const [loadingLinkedAccounts, setIsLoadingLinkedAccounts] = useState(true);
  const [isMounted, setIsMounted] = useState(true); //state to track if the component is mounted

  const getLinkedAccounts = useCallback(async () => {
    try {
      setIsLoadingLinkedAccounts(true);
      const fetchedLinkedAccounts = await Publisher.fetchLinkedAccounts({
        tid,
        pid,
        ruleID
      });
      if (fetchedLinkedAccounts.success) {
        setLinkedAccounts(fetchedLinkedAccounts.data);
        setIsLoadingLinkedAccounts(false);
        toastr.success(
          "Success!",
          "Linked Accounts Info fetched successfully!"
        );
      } else {
        throw new Error(JSON.stringify(fetchedLinkedAccounts.error));
      }
    } catch (error) {
      console.trace(error?.message || "Oops! there was an error");
      setLinkedAccounts(null);
      setIsLoadingLinkedAccounts(false);
      toastr.error("Oops!", error.message);
    }
    Publisher.fetchLinkedAccounts({ tid, pid, ruleID });
  }, [pid, tid, ruleID]);

  useEffect(() => {
    if (isMounted) {
      getLinkedAccounts();
    }
    return () => {
      setIsMounted(false);
    };
  }, [isMounted, getLinkedAccounts]);

  return {
    linkedAccounts,
    loadingLinkedAccounts
  };
};
