import Api from "../api";

class CsvUpload {
  uploadCsvFile = async ({ data }) => {
    const { body } = await Api.filePost(`upload/singlecsvfile`, data);
    return body;
  };

  fetchAdvertiserWithMapping = async () => {
    const { body } = await Api.get("mapping/advertisers");
    return body;
  };

  getCSVUploadStatus = async (created_id, csv_name) => {
    const { body } = await Api.get(
      `upload/csvstatus?csv_status_id=${created_id}&csv_name=${csv_name}`
    );
    return body;
  };
}

export default new CsvUpload();
