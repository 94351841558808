import React from "react";
import Table from "../../../components/Table/Table";

const LogTable = ({
  logData,
  loadingLogs,
  tableHeaders,
  total,
  logDataHook,
  orderBy,
  orderDirection,
  setOrderBy,
  setOrderDirection
}) => {
  const { size, page, setPage, setSize } = logDataHook;

  return (
    <div>
      <Table
        headers={tableHeaders}
        rowValues={logData}
        isLoading={loadingLogs}
        noActions={true}
        noIds={true}
        noCheckboxes={true}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        total={total}
        rowsPerPageOptions={[50, 100, 200]}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        tableType="logs"
        handleRouteChange={(id) => {
          id && window.open(`/target/edit/${id}`, "_blank");
        }}
      />
    </div>
  );
};

export default LogTable;
