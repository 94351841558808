/**
 * Custom Hook to load Dashboard Data
 */
import { useState, useEffect } from "react";
import { Report } from "../../services/Report";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { CUSTOM } from "../../helpers/constant/filters";

export const useDashboardData = ({ filterValues, filtersClearToogle }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  const [loadingDashboardData, setLoadingDashboardData] = useState({
    loading: true,
    error: false
  });

  function getParams() {
    let params = {};
    const {
      selectedProviders,
      selectedCountries,
      selectedPublishers,
      fromDate,
      toDate,
      selectedInterval,
      selectedCompairServerSearches,
      selectedGroupBy,
      selectedRuleIds,
      selectedTagIds,
      selectedTagTypes,
      selectedPlatforms,
      selectedSearchEngines,
      selectedPublisherAccounts,
      selectedChannels,
      selectedPeriod,
      orderBy,
      orderDirection
    } = filterValues;

    if (selectedChannels?.length > 0) {
      let selection = [];
      for (let i in selectedChannels) {
        selection.push(selectedChannels[i]);
      }
      params["channel"] = selection;
    }
    if (selectedPublisherAccounts?.length > 0) {
      let selection = [];
      for (let i in selectedPublisherAccounts) {
        selection.push(selectedPublisherAccounts[i]);
      }
      params["pub_account_id"] = selection;
    }

    if (selectedProviders?.length > 0) {
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["provider_id"] = selection;
    }

    if (selectedRuleIds?.length > 0) {
      let selection = [];
      for (let i in selectedRuleIds) {
        selection.push(selectedRuleIds[i]);
      }
      params["rule_id"] = selection;
    }

    if (selectedTagIds?.length > 0) {
      let selection = [];
      for (let i in selectedTagIds) {
        selection.push(selectedTagIds[i]);
      }
      params["tag_id"] = selection;
    }

    if (selectedCountries?.length > 0) {
      let selection = [];
      for (let i in selectedCountries) {
        selection.push(selectedCountries[i]);
      }
      params["geo"] = selection;
    }

    if (selectedInterval) {
      params["interval"] = selectedInterval;
    }

    if (selectedCompairServerSearches) {
      params["total_searches_zero_and_higher_server_searches"] =
        selectedCompairServerSearches;
    }

    if (selectedGroupBy?.length > 0) {
      let selection = [];
      for (let i in selectedGroupBy) {
        selection.push(selectedGroupBy[i]);
      }
      params["group_by"] = selection;
    }

    if (selectedTagTypes?.length > 0) {
      let selection = [];
      for (let i in selectedTagTypes) {
        selection.push(selectedTagTypes[i]);
      }
      params["tag_type_id"] = selection;
    }

    if (selectedPlatforms?.length > 0) {
      let selection = [];
      for (let i in selectedPlatforms) {
        selection.push(selectedPlatforms[i]);
      }
      params["platform_id"] = selection;
    }

    if (selectedSearchEngines?.length > 0) {
      let selection = [];
      for (let i in selectedSearchEngines) {
        selection.push(selectedSearchEngines[i]);
      }
      params["search_engine_id"] = selection;
    }

    if (selectedPublishers.length > 0) {
      let selection = [];
      for (let i in selectedPublishers) {
        selection.push(selectedPublishers[i]);
      }
      params["publisher"] = selection;
    }

    if (selectedPeriod !== CUSTOM) {
      if (selectedPeriod) params[selectedPeriod] = true;
    } else {
      if (fromDate && moment(fromDate).isValid()) {
        params["start_date"] = moment(fromDate).format("YYYY-MM-DD");
      }
      if (toDate && moment(toDate).isValid()) {
        params["end_date"] = moment(toDate).format("YYYY-MM-DD");
      }
    }

    if (
      selectedInterval &&
      (selectedInterval === "day" ||
        selectedInterval === "month" ||
        selectedInterval === "hour")
    ) {
      params["order_by"] = "date";
      params["order_direction"] = orderDirection;
    }
    if (orderBy) {
      params["order_by"] = orderBy;
      params["order_direction"] = orderDirection;
    }

    params["page"] = page;
    params["size"] = size;

    return params;
  }

  // Format the data to be used in the table
  function formatListRow(unformattedRow, index) {
    let row = { ...unformattedRow };
    if ("date" in row) {
      if (filterValues?.selectedInterval === "day") {
        row.date = moment(row.date).utc().format("MMM DD,YYYY");
      } else if (filterValues?.selectedInterval === "month") {
        row.date = moment(row.date).utc().format("MMM ,YYYY");
      } else if (filterValues?.selectedInterval === "hour") {
        row.date = moment(row.date).utc().format("MMMM Do YYYY, h:mm:ss a");
      } else {
        row.date = moment(row.date).utc().format("MMM DD,YYYY");
      }
    }
    if ("publisher" in row) {
      delete row.publisher;
      row.publisher = `${row?.publisher_id?.id || ""} - ${
        row?.publisher_id?.name || ""
      }`;
    }
    if ("advertiser_id" in row) {
      delete row.advertiser_id;
      row.advertiser_id = `${row?.provider?.id || ""} - ${
        row?.provider?.name || ""
      }`;
    }
    if ("search_engine_id" in row) {
      row.search_engine_id = `${row?.search_engine?.id || ""} - ${
        row?.search_engine?.name || ""
      }`;
    }
    if ("tag_type_id" in row) {
      row.tag_type_id = `${row?.tag_type?.id || ""} - ${
        row?.tag_type?.name || ""
      }`;
    }
    if ("platform_id" in row) {
      row.platform_id = `${row?.platform?.id || ""} - ${
        row?.platform?.name || ""
      }`;
    }
    row.sno = index + 1 + size * (page - 1); // calculating the serial number of the row, size is from the state
    return row;
  }

  function checkIfAllNullValues(row) {
    let isNull = true;
    for (let key in row) {
      if (row[key] !== null) {
        isNull = false;
      }
    }
    return isNull;
  }

  function getFormattedListRows(list) {
    /**
       * Backend returns the data in the following format when it finds no value
       *      {
                  "net_revenue": null,
                  "total_searches": null,
                  "monetized_searches": null,
                  "clicks": null,
                  "ctr": null,
                  "rpm": null,
                  "rpmm": null,
                  "rpc": null
              }
              we need to return an empty array to prevent a row of null values in table UI
       */
    if (list.length === 1 && checkIfAllNullValues(list[0])) {
      return [];
    }
    let formatedList = [];
    list.forEach((row, index) => {
      formatedList.push(formatListRow(row, index));
    });
    return formatedList;
  }

  function fetchDashboardData() {
    setLoadingDashboardData({
      loading: true,
      error: false
    });
    Report.fetchDashboardData(getParams())
      .then((response) => {
        if (response.success) {
          let data = response.data;
          let listData = response.data?.list;
          if (listData.length > 0) {
            data.list = getFormattedListRows(listData);
          }
          setDashboardData(data);
          // changing state inside timeout to prevent slow UI re-rendering
          // of reporting table
          setTimeout(() => {
            setLoadingDashboardData({
              loading: false,
              error: false
            });
          }, 800);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        toastr.error("Oops", "Unable to load dashboard report data!");
        setLoadingDashboardData({
          loading: false,
          error: true
        });
        setDashboardData(null);
        console.trace(error.message);
      });
  }

  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    size,
    filterValues.orderBy,
    filterValues.orderDirection,
    filtersClearToogle
  ]);

  return {
    dashboardData,
    loadingDashboardData: loadingDashboardData.loading,
    size,
    page,
    setPage,
    setSize,
    fetchDashboardData
  };
};
