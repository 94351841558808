import React from "react";
import { SHOW_EDIT } from "../logic/formUI.logic";
import CheckIcon from "@material-ui/icons/Check";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  FormControl,
  TextField,
  FormHelperText,
  Button
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { useProviderLinkBox } from "../logic/providerlinkbox.logic";
import DuplicateWarningModal from "../../ProviderLinks/layouts/DuplicateWarningModal";
import InfoToolTip from "../../../components/InfoToolTip/InfoToolTip";
import ParamInfo from "../../ProviderLinks/layouts/ParamInfo";
import HelpIcon from "@material-ui/icons/Help";
import { TAG_TYPES } from "../../../helpers/constant/types";
export const ProviderLinkBox = ({
  pageData,
  currentPage,
  showAddButton,
  tagTypes,
  platforms,
  searchEngines,
  providers,
  getProviderLinks,
  templates
}) => {
  const {
    formValues,
    formErrors,
    handleChangeFunctions,
    submittingForm,
    handleSubmit,
    backEndDuplicates,
    duplicateModalOpen,
    getFormData,
    submitLinks,
    closeDuplicateModalHandler
  } = useProviderLinkBox({
    pageData,
    currentPage,
    getProviderLinks,
    showAddButton,
    platforms,
    searchEngines,
    tagTypes,
    templates
  });

  const {
    changeSelectedSearchEngine,
    changeSelectedTagType,
    changeSelectedPlatform,
    changeSelectedProvider,
    changeSelectedType,
    handleLinkChange,
    handleSourceTagChange,
    handleClarityIdChange,
    handleDescriptionChange,
    changeSelectedTemplate
  } = handleChangeFunctions;

  const TYPES = [
    {
      label: "REDIRECT",
      value: "redirect"
    },
    {
      label: "XML",
      value: "xml"
    },
    {
      label: "TEMPLATE",
      value: "template"
    }
  ];

  return (
    <div className="flex flow-content custom-fieldset provider-link-box">
      <div className="provider-links__helpers">
        <h4>Supported Dynamic Parameters</h4>
        <InfoToolTip title={<ParamInfo />} className="targeting-option__info">
          <div className="gray-icon ">
            <HelpIcon fontSize="small" />
          </div>
        </InfoToolTip>
      </div>
      <div className="provider-link-box__advertiser">
        <AutoCompleteSelect
          selected={formValues.providerId}
          data={providers}
          handleChange={changeSelectedProvider}
          label={"Advertiser"}
          enableMultiple={false}
          cannotSelectDisabled={false}
          withDotsForStatus={true}
          disabled={currentPage === SHOW_EDIT || submittingForm}
        />
        {formErrors?.providerId && (
          <FormHelperText className="red-text">
            Please select a advertiser
          </FormHelperText>
        )}
      </div>

      <div className="provider-link">
        <div className="provider-link__entry-items">
          <FormControl className="provider-link__link-id" fullWidth>
            <TextField
              label="ID"
              variant="outlined"
              name="link-id"
              type="number"
              value={formValues.linkID}
              disabled
            />
          </FormControl>
          <AutoCompleteSelect
            selected={formValues.type}
            data={TYPES}
            label={"Type"}
            handleChange={changeSelectedType}
            enableMultiple={false}
            cannotSelectDisabled={false}
            showValueInLabel={false}
            disabled={submittingForm}
          />
          {formErrors?.type && (
            <FormHelperText className="red-text">
              Please make sure to select the type
            </FormHelperText>
          )}
          {formValues?.type === TAG_TYPES.TEMPLATE && (
            <AutoCompleteSelect
              selected={formValues.templatePath}
              data={templates || []}
              label={"Template"}
              handleChange={changeSelectedTemplate}
              enableMultiple={false}
              cannotSelectDisabled={false}
              showValueInLabel={false}
              disabled={submittingForm}
            />
          )}
          <FormControl className="provider-link__link" fullWidth>
            <TextField
              label="Link"
              variant="outlined"
              name="link"
              value={formValues.link}
              onChange={handleLinkChange}
              disabled={submittingForm}
            />
            {formErrors?.link && (
              <FormHelperText className="red-text">
                Please make sure the link is valid, includes [Q] and uses only [
                ] Brackets.
              </FormHelperText>
            )}
          </FormControl>
          <div className="provider-link__dropdowns">
            <div>
              <AutoCompleteSelect
                selected={formValues.searchEngineId}
                data={searchEngines}
                handleChange={changeSelectedSearchEngine}
                label={"Search Engine"}
                enableMultiple={false}
                cannotSelectDisabled={false}
                disabled={submittingForm}
              />
              {formErrors?.searchEngineId && (
                <FormHelperText className="red-text">
                  Please make sure to select the search engine
                </FormHelperText>
              )}
            </div>
            <div>
              <AutoCompleteSelect
                selected={formValues.tagTypeId}
                data={tagTypes}
                handleChange={changeSelectedTagType}
                label={"Tag Types"}
                enableMultiple={false}
                cannotSelectDisabled={false}
                disabled={submittingForm}
              />
              {formErrors?.tagTypeId && (
                <FormHelperText className="red-text">
                  Please make sure to select the Tag Type
                </FormHelperText>
              )}
            </div>
            <div>
              <AutoCompleteSelect
                selected={formValues.platformId}
                data={platforms}
                handleChange={changeSelectedPlatform}
                label={"Platforms"}
                enableMultiple={false}
                cannotSelectDisabled={false}
                disabled={submittingForm}
              />
              {formErrors?.platformId && (
                <FormHelperText className="red-text">
                  Please make sure to select the Platform
                </FormHelperText>
              )}
            </div>
          </div>
          <MoreVertIcon className="vertical-divider" />
          <MoreHorizIcon className="horizontal-divider" />
        </div>

        <div className="provider-link__mapped-items">
          {formValues.type && formValues?.type !== TAG_TYPES.REDIRECT && (
            <FormControl className="provider-link__source-tag">
              <TextField
                label="Source Tag"
                variant="outlined"
                name="source_tag"
                value={formValues.sourceTag}
                onChange={handleSourceTagChange}
                disabled={submittingForm}
              />
            </FormControl>
          )}

          {formValues.type && formValues?.type !== TAG_TYPES.REDIRECT && (
            <FormControl className="provider-link__clarity-id">
              <TextField
                label="Clarity Id"
                variant="outlined"
                name="clarity_id"
                value={formValues.clarityId}
                onChange={handleClarityIdChange}
                disabled={submittingForm}
              />
            </FormControl>
          )}

          <FormControl className="provider-link__description">
            <TextField
              label="Description"
              variant="outlined"
              name="description"
              value={formValues.description}
              onChange={handleDescriptionChange}
              disabled={submittingForm}
            />
          </FormControl>
        </div>
      </div>
      <div className="provider-link-box__buttons">
        <Button
          color="primary"
          variant="contained"
          size="medium"
          startIcon={<CheckIcon />}
          type="submit"
          disabled={submittingForm}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button
          onClick={showAddButton}
          variant="contained"
          size="medium"
          color="secondary"
          startIcon={<ClearIcon />}
        >
          Cancel
        </Button>
      </div>
      {duplicateModalOpen && (
        <DuplicateWarningModal
          frontEndDuplicates={[]}
          backEndDuplicates={backEndDuplicates}
          denyAction={closeDuplicateModalHandler}
          confirmAction={() => {
            submitLinks(getFormData());
          }}
        />
      )}
    </div>
  );
};

export default ProviderLinkBox;
