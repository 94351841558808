export const textFieldValid = (fieldValue) => {
  if (fieldValue === null || fieldValue === undefined) return false;
  if (fieldValue.trim() === "") return false;
  return true;
};

export const validURL = (str) => {
  let regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
};

export const validEmail = (email) => {
  if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())) {
    return false;
  } else {
    return true;
  }
};

export const validate = (option, value) => {
  switch (option) {
    case "isRequired":
      return textFieldValid(value);
    case "validurl":
      return validURL(value);
    case "validEmail":
      return validEmail(value);
    default:
      return true;
  }
};

export const hasDuplicates = (array) => {
  return new Set(array).size !== array.length;
};
