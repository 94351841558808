import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import authReducer from "./authReducer";
import publishReducer from "./publisher/publisher.reducer";
import targetReducer from "./target/target.reducer";
import providerReducer from "./provider/provider.reducer";
import settingReducer from "./setting/setting.reducer";
import userReducer from "./user/user.reducer";
import publisherAccountReducer from "./publisherAccount/publisherAccount.reducer";
import apiDetailsReducer from "./apiDetails/apiDetails.reducer";
import skippedRowsReducer from "./skippedRows/skippedRows.reducer";

const reducers = {
  auth: authReducer,
  publisher: publishReducer,
  toastr: toastrReducer,
  target: targetReducer,
  provider: providerReducer,
  setting: settingReducer,
  user: userReducer,
  publisherAccount: publisherAccountReducer,
  apiDetails: apiDetailsReducer,
  skippedRows: skippedRowsReducer
};
export default combineReducers(reducers);
