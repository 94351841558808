import React from "react";

const ParamInfo = () => {
  return (
    <div className="dynamic-parameter-info">
      <p className="dynamic-parameter-info__text">
        <span>Search Query: </span> <span>[Q]</span>
      </p>
      <p className="dynamic-parameter-info__text">
        <span>Sub ID:</span> <span>[SID]</span>
      </p>
      <p className="dynamic-parameter-info__text">
        <span>Click ID:</span> <span>[CLICK_ID]</span>
      </p>
      <p className="dynamic-parameter-info__text">
        <span>No Of Result:</span> <span>[N]</span>
      </p>
    </div>
  );
};

export default ParamInfo;
