/*
 *  Custom Hook to download logs
 */

import moment from "moment";
import { Log } from "../../services/Logs";
import { textFieldValid } from "../../helpers/validation";
import { toastr } from "react-redux-toastr";

export const useLogDownload = ({ filterValues }) => {
  function getParams() {
    let params = {};
    const {
      selectedCountries,
      selectedPublishers,
      selectedProviders,
      selectedInterval,
      selectedTagIds,
      selectedGroupBy,
      fromDate,
      toDate,
      query,
      ipAddress,
      selectedRuleIds,
      selectedProvidersLinks,
      selectedBrowsers,
      selectedPlatforms,
      selectedOsList,
      orderBy,
      orderDirection
    } = filterValues;

    if (textFieldValid(query)) {
      params["q"] = query;
    }

    if (textFieldValid(ipAddress)) {
      params["ip_address"] = ipAddress;
    }

    if (moment(fromDate).isValid()) {
      params["from"] = moment(fromDate).format("YYYY-MM-DD");
    }
    if (moment(toDate).isValid()) {
      params["to"] = moment(toDate).format("YYYY-MM-DD");
    }

    if (selectedPublishers.length > 0) {
      let selection = [];
      for (let i in selectedPublishers) {
        selection.push(selectedPublishers[i]);
      }
      params["pid"] = selection;
    }

    if (selectedBrowsers.length > 0) {
      let selection = [];
      for (let i in selectedBrowsers) {
        selection.push(selectedBrowsers[i]);
      }
      params["browser_info"] = selection;
    }

    if (selectedPlatforms.length > 0) {
      let selection = [];
      for (let i in selectedPlatforms) {
        selection.push(selectedPlatforms[i]);
      }
      params["device_info"] = selection;
    }

    if (selectedOsList.length > 0) {
      let selection = [];
      for (let i in selectedOsList) {
        selection.push(selectedOsList[i]);
      }
      params["os_info"] = selection;
    }

    if (selectedTagIds.length > 0) {
      let selection = [];
      for (let i in selectedTagIds) {
        selection.push(selectedTagIds[i]);
      }
      params["cid"] = selection;
    }
    if (selectedRuleIds.length > 0) {
      let selection = [];
      for (let i in selectedRuleIds) {
        selection.push(selectedRuleIds[i]);
      }
      params["rule"] = selection;
    }

    if (selectedProvidersLinks?.length > 0) {
      let selection = [];
      for (let i in selectedProvidersLinks) {
        selection.push(selectedProvidersLinks[i]);
      }
      params["link_id"] = selection;
    }
    if (selectedProviders?.length > 0) {
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["provider_id"] = selection;
    }

    if (selectedGroupBy?.length > 0) {
      let selection = [];
      for (let i in selectedGroupBy) {
        selection.push(selectedGroupBy[i]);
      }
      params["group_by"] = selection;
    }

    if (selectedInterval) {
      params["interval"] = selectedInterval;
    }

    if (selectedCountries.length > 0) {
      let selection = [];
      for (let i in selectedCountries) {
        selection.push(selectedCountries[i]);
      }
      params["country"] = selection;
    }

    if (orderBy) {
      params["order_by"] = orderBy;
    }
    params["order_direction"] = orderDirection;

    return params;
  }

  const downloadLogs = () => {
    Log.downloadLogs(getParams())
      .then(({ data, status }) => {
        if (status === 200) {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${new Date()}.csv`);
          document.body.appendChild(link);
          link.click();
        } else {
          throw new Error(JSON.stringify(data?.message || "Cannot download"));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Cannot download at the moment!");
      });
  };

  return {
    downloadLogs
  };
};
