export const TargetActionTypes = {
  UPDATE_TARGETING_RULES: "UPDATE_TARGETING_RULES",
  UPDATE_TARGETING_FORM_ERRORS: "UPDATE_TARGETING_FORM_ERRORS",
  CLEAR_TARGETING_FORM: "CLEAR_TARGETING_FORM",
  UPDATE_TARGETING_FORM: "UPDATE_TARGETING_FORM",
  UPDATE_TARGETING_LISTS: "UPDATE_TARGETING_LISTS",
  UPDATE_DELETED_TARGETING_RULES: "UPDATE_DELETED_TARGETING_RULES"
};

export const updateTargetingRules = (targetingRules) => ({
  type: TargetActionTypes.UPDATE_TARGETING_RULES,
  payload: targetingRules
});

export const updateTargetingFormErrors = (targetingFormErrors) => ({
  type: TargetActionTypes.UPDATE_TARGETING_FORM_ERRORS,
  payload: targetingFormErrors
});

export const clearTargetingForm = () => ({
  type: TargetActionTypes.CLEAR_TARGETING_FORM
});

export const updateTargetingForm = (formValues) => ({
  type: TargetActionTypes.UPDATE_TARGETING_FORM,
  payload: formValues
});

export const updateTargetingLists = (targetings) => ({
  type: TargetActionTypes.UPDATE_TARGETING_LISTS,
  payload: targetings
});

export const updateDeletedTargetingRules = (deletedRules) => ({
  type: TargetActionTypes.UPDATE_DELETED_TARGETING_RULES,
  payload: deletedRules
});
