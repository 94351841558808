//Icons used in table actions column
import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuBookIcon from "@material-ui/icons/MenuBook"; //icon for managing publisher targeting
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import { FileCopyTwoTone } from "@material-ui/icons";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import TargetIcon from "@material-ui/icons/OfflineBoltSharp";
import CloseIcon from "@material-ui/icons/Close";
import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import InfoIcon from "@material-ui/icons/Info";

export const getActionIcons = (iconName, data = null) => {
  switch (iconName) {
    case "editIcon":
      return (
        <Tooltip title="Edit">
          <IconButton color="primary">
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    case "TargetinglinkIcon":
      return (
        <Tooltip title="View Targetings">
          <IconButton color="primary">
            <AllInclusiveIcon />
          </IconButton>
        </Tooltip>
      );
    case "redirectIcon":
      return (
        <Tooltip title="Redirect To Advertiser">
          <IconButton color="primary">
            <FlipToBackIcon />
          </IconButton>
        </Tooltip>
      );
    case "TargetinglinkIconDisabled":
      return (
        <Tooltip title="No Targetings">
          <IconButton>
            <AllInclusiveIcon />
          </IconButton>
        </Tooltip>
      );
    case "deleteIcon":
      return (
        <Tooltip title="Archive">
          <IconButton className="yellow-icon">
            <ArchiveIcon />
          </IconButton>
        </Tooltip>
      );
    case "delete":
      return (
        <Tooltip title="Delete">
          <IconButton className="red-icon">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      );
    case "deleteForever":
      return (
        <Tooltip title="Delete Forever">
          <IconButton color="secondary">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      );
    case "removeReport":
      return (
        <Tooltip title="Remove Report">
          <IconButton className="yellow-icon">
            <DeleteSweepIcon />
          </IconButton>
        </Tooltip>
      );
    case "duplicateIcon":
      return (
        <Tooltip title="Duplicate">
          <IconButton color="primary">
            <FileCopyTwoTone />
          </IconButton>
        </Tooltip>
      );
    case "manageIconOne":
      return (
        <Tooltip title="Manage Links">
          <IconButton color="primary">
            <AllInclusiveIcon />
          </IconButton>
        </Tooltip>
      );
    case "manageIconTwo":
      return (
        <Tooltip title="Manage">
          <IconButton color="inherit">
            <MenuBookIcon />
          </IconButton>
        </Tooltip>
      );
    case "copyToClipboardIcon":
      return (
        <Tooltip title="Copy Tag">
          <IconButton className="green-icon">
            <AssignmentTurnedInIcon />
          </IconButton>
        </Tooltip>
      );
    case "restore":
      return (
        <Tooltip title="Unarchive">
          <IconButton className="green-icon">
            <UnarchiveIcon />
          </IconButton>
        </Tooltip>
      );
    case "redirectToRevenue":
      return (
        <Tooltip title="Redirect To Revenue">
          <IconButton className="blue-icon">
            <AttachMoneyIcon />
          </IconButton>
        </Tooltip>
      );
    case "details":
      return (
        <Tooltip title="Details">
          <IconButton className="blue-icon">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      );
    case "display-column":
      return (
        <Tooltip title="Select Display Columns">
          <IconButton>
            <TextFieldsIcon />
          </IconButton>
        </Tooltip>
      );
    case "advertiserLinks-related":
      return (
        <Tooltip
          title={`${
            data.no_of_rules > 0
              ? "Display Related Rule and Tag"
              : "No Related Rule and Tag"
          }`}
        >
          <IconButton color={`${data.no_of_rules > 0 ? "primary" : "default"}`}>
            <TargetIcon />
          </IconButton>
        </Tooltip>
      );
    case "advertiserLinks-delete":
      return (
        <Tooltip title="Delete Link" className="provider-link__close">
          <IconButton color={true ? "secondary" : "default"}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      );
    default:
      return <div></div>;
  }
};
