import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Table from "../../components/Table/Table";
import { Publisher } from "../../services/Publisher";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import TargetIcon from "@material-ui/icons/OfflineBoltSharp";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { toastr } from "react-redux-toastr";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import TargetFilterBox from "./layouts/TargetFilterBox";
import TargetBulkAction from "./layouts/TargetBulkAction";
import SimpleAccordion from "../../components/Accordion/Accordion";
import { textFieldValid } from "../../helpers/validation";
import { useDispatch, useSelector } from "react-redux";
import TabMenu from "../../components/TabMenu/TabMenu";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import {
  updateTargetingForm,
  updateTargetingLists
} from "../../reducers/target/target.action";
import { getTargetings } from "../../reducers/target/target.selector";
import { capitalizeFirstLetter } from "../../helpers";
import { copyToClipboard } from "../../helpers/index";

const List = (props) => {
  const targetings = useSelector(getTargetings);
  const history = useHistory();
  const location = useLocation();
  //getting publisher id in case this page was redirected
  //from publisher listing page
  const getInitialPublisher = useCallback(() => {
    return location?.state?.publisher || "";
  }, [location]);
  const headers = [
    { title: "Tag ID", value: "id" },
    { title: "Publisher", value: "Publisher" },
    { title: "Tag", value: "link" },
    { title: "Type", value: "tag_type" },
    { title: "Description", value: "tag_description" },
    { title: "Status", value: "status" },
    { title: "Active", value: "is_active" },
    { title: "Last Updated (UTC)", value: "updatedAt" },
    { title: "Notes", value: "notes" }
    // { title: "CID", value: "client_id" }
  ];
  const headersForArchive = [
    { title: "Tag ID", value: "id" },
    { title: "Publisher", value: "Publisher" },
    { title: "Tag", value: "link" },
    { title: "Archived At (UTC) ", value: "updatedAt" }
    // { title: "CID", value: "client_id" }
  ];
  //selection index to choose between Archived and Normal Publishers List
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] =
    useState(0);
  const sections = [
    {
      name: "Publisher Tags"
    },
    {
      name: "Archived Publisher Tags"
    }
  ];
  let choiceOfWord =
    currentSelectedSectionIndex === 0 ? "archive" : "delete forever";
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  //state for modal
  const [open, setOpen] = useState(false);
  const [bulkDeleteOpen, setBulkDeleteOpen] = useState(false);
  const [unArchiveOpen, setUnArchiveOpen] = useState(false);
  const [bulkRestoreOpen, setBulkRestoreOpen] = useState(false);
  const [bulkPermanentDeleteOpen, setBulkPermanentDeleteOpen] = useState(false);

  //state for filter box
  const [searchValue, setSearchValue] = useState("");
  const [published, setPublished] = useState(false);
  const [draft, setDraft] = useState(false);
  const [publisher, setPublisher] = useState(() => getInitialPublisher());
  //state for pagination that will be used as params
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC
  const [total, setTotal] = useState(0);
  const [archived, setArchived] = useState(false);
  const [loading, setLoading] = useState(false);
  const prevPage = useRef(1); //!!IMP : SET IT SAME AS DEFAULT VALUE FOR PAGE STATE
  const getRequestParams = useCallback(() => {
    let params = {};

    if (textFieldValid(searchValue)) {
      params["q"] = searchValue.trim();
    }

    if (published) {
      params["status"] = "published";
    }
    if (archived) {
      params["archived"] = true;
    }

    if (draft) {
      params["status"] = "draft";
    }

    if (publisher !== "") {
      params["publisher"] = publisher;
    }

    params["page"] = page;
    params["size"] = size;
    params["order_by"] = orderBy;

    if (orderBy === "Publisher") {
      params["order_by"] = "publisher_id";
    }

    params["order_direction"] = orderDirection;

    return params;
  }, [
    draft,
    orderDirection,
    orderBy,
    page,
    size,
    published,
    publisher,
    searchValue,
    archived
  ]);
  const fetchTargeting = useCallback(
    (params = {}) => {
      setLoading(true);
      Publisher.getTargeting(params)
        .then((response) => {
          let formatedTargeting = [...response.data.targetings].map(
            (targeting) => {
              targeting.updatedAt = moment(targeting.updatedAt)
                .utc()
                .format("lll");
              targeting.status = capitalizeFirstLetter(targeting.status);
              return targeting;
            }
          );
          setTotal(response.data.total);
          stableDispatch(updateTargetingLists(formatedTargeting));
          setLoading(false);
        })
        .catch((error) => {
          console.trace(error.message);
          setLoading(false);
        });
    },
    [stableDispatch]
  );
  useEffect(() => {
    fetchTargeting(getRequestParams());
    prevPage.current === page && setSelected([]); //page changed don't make the selected rows empty
    if (page !== prevPage.current) {
      prevPage.current = page;
    }
  }, [
    draft,
    page,
    published,
    publisher,
    archived,
    size,
    orderBy,
    orderDirection,
    getRequestParams,
    fetchTargeting
  ]);

  const getPublisherIDOfTargeting = (id) => {
    let targetingIndex = targetings.findIndex(
      (targeting) => targeting.id === id
    );
    let publisher_id = targetings[targetingIndex].publisher_id;
    return publisher_id;
  };

  const handleModalClose = () => {
    setChangeID(null);
    setOpen(false);
    setBulkDeleteOpen(false);
    setUnArchiveOpen(false);
    setBulkRestoreOpen(false);
    setBulkPermanentDeleteOpen(false);
  };
  //publisher id to change through modal
  const [changeID, setChangeID] = useState(null);
  const [selected, setSelected] = React.useState([]);

  const handleDuplicateAction = (id) => {
    Publisher.fetchTargeting(id).then((response) => {
      if (response.success) {
        let targeting = response.data.targeting;
        const data = {
          publisher: targeting.publisher_id,
          domain: targeting.custom_domain,
          cusDomain: targeting.use_custom_domain,
          cusFallback: targeting.use_custom_fallback,
          is_active: targeting.is_active,
          subID: targeting.sub_id,
          clientID: null,
          // oID: targeting.o_id,
          status: targeting.status,
          useN: targeting.n,
          useSubId:
            targeting.use_sid === "true" || targeting.use_sid ? true : false,
          useClickId: targeting.click_id,
          fallback: targeting.default_fallback
            ? targeting.default_fallback
            : "",
          targeting: targeting.targeting_type,
          link: targeting.link,
          notes: targeting.notes ? targeting.notes : "",
          tagDescription: targeting.tag_description
            ? targeting.tag_description
            : ""
        };
        let newTargetingRules = [...targeting.targeting_rules];
        for (let i = 0; i < newTargetingRules.length; i++) {
          let rule = newTargetingRules[i];
          if (rule.id) {
            delete rule.id;
          }
          if (rule.targeting_id) {
            delete rule.targeting_id;
          }
        }
        data.targetingRules = [...newTargetingRules];
        dispatch(updateTargetingForm(data));
        setOpen(false);
        history.push("/target/add");
        toastr.success("Success", "Data duplicated!");
      }
    });
  };

  /*archived and normal data selection change handler */
  const handleSelectionIndexChange = (index) => {
    //setting Page to 1
    setPage(1);
    setSelected([]);
    setPublished(false);
    setDraft(false);
    setCurrentSelectedSectionIndex(index);
    if (index === 0) {
      //normal publisher
      setArchived(false);
    } else {
      setArchived(true);
    }
  };

  const handleEditAction = (id) => {
    // if (!checkDefaultDomainIsSet()) return;
    history.push(`/target/edit/${id}`);
  };
  const handleDeleteAction = (id) => {
    setChangeID(id);
    setOpen(true);
  };
  const deleteTargeting = () => {
    Publisher.deleteTargeting(changeID)
      .then((response) => {
        if (response.success) {
          setChangeID(null);
          fetchTargeting(getRequestParams());
          setOpen(false);
          toastr.success("Success", "Archived Successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops", "Failed to archive");
        setOpen(false);
      });
  };

  const unArchiveTargeting = () => {
    let params = {
      archive: false
    };
    let dataToSend = {};
    dataToSend.publisher_id = getPublisherIDOfTargeting(changeID);
    Publisher.updateTargeting(changeID, dataToSend, params)
      .then((response) => {
        if (response.success) {
          fetchTargeting(getRequestParams());
          toastr.success("Success", "Targeting un-archived!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to un-archive the targeting");
      });
  };

  const handleBulkRestoreAction = () => {
    if (selected.length > 0) {
      setBulkRestoreOpen(true);
    } else {
      toastr.warning("Oops", "No Targetings selected");
    }
  };

  const handleBulkPermanentDelete = () => {
    if (selected.length > 0) {
      setBulkPermanentDeleteOpen(true);
    } else {
      toastr.warning("Oops", "No Publisher selected");
    }
  };

  const handleRestoreAction = (id) => {
    setChangeID(id);
    setUnArchiveOpen(true);
  };

  const handleDeleteForever = () => {
    let params = {
      permanent: true
    };
    Publisher.deleteTargeting(changeID, params)
      .then((response) => {
        if (response.success) {
          setChangeID(null);
          fetchTargeting(getRequestParams());
          setOpen(false);
          toastr.success("Success", "Targeting removed permanently!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops", "Not able to remove at the moment");
        setOpen(false);
      });
    setChangeID(null);
    setOpen(false);
  };

  const handleCopyTagToClipboard = (id) => {
    let targeting = targetings.find((targeting) => targeting.id === id);
    copyToClipboard(targeting.link);
    toastr.success("Success", "Tag copied!");
  };

  //action list for archived table
  const actionsForArchive = [
    // {
    //   name: "deleteForever",
    //   action: handleDeleteAction,
    //   icon: "deleteForever"
    // },
    {
      name: "restore",
      action: handleRestoreAction,
      icon: "restore"
    }
  ];

  const actions = [
    {
      name: "edit",
      action: handleEditAction,
      icon: "editIcon"
    },
    {
      name: "delete",
      action: handleDeleteAction,
      icon: "deleteIcon"
    },
    {
      name: "duplicate",
      action: handleDuplicateAction,
      icon: "duplicateIcon"
    }
  ];
  const icon = <TargetIcon fontSize="inherit" color="primary" />;

  const handleBulkDeleteAction = () => {
    if (selected.length > 0) {
      setBulkDeleteOpen(true);
    } else {
      toastr.warning("Oops", "No Targeting selected");
    }
  };

  const bulkDelete = () => {
    let data;
    data = {
      ids: selected
    };
    Publisher.bulkDeleteTargetings(data)
      .then((response) => {
        if (response.success) {
          fetchTargeting(getRequestParams());
          toastr.success("Success", "Targetings archived");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to archive targetings");
      });
    setBulkDeleteOpen(false);
    setSelected([]);
  };

  const bulkPermanentDelete = () => {
    let data;
    data = {
      ids: selected
    };
    Publisher.bulkPermanentDeleteTargetings(data)
      .then((response) => {
        if (response.success) {
          fetchTargeting(getRequestParams());
          toastr.success("Success", "Targetingss permanently deleted!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to permanently delete targetings!");
      });
    setSelected([]);
  };

  const bulkRestore = () => {
    let data;
    data = {
      ids: selected
    };
    Publisher.restoreTargetings(data)
      .then((response) => {
        if (response.success) {
          fetchTargeting(getRequestParams());
          toastr.success("Success", "Targetings restored");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to restore targetings");
      });
    setSelected([]);
  };

  const deleteTargetingModal = (
    <SimpleModal handleClose={handleModalClose}>
      <div className="delete-confirm-box">
        <Typography component="h3">
          Are you sure you want to {choiceOfWord}?
        </Typography>
        <div className="delete-confirm-box__buttons">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              //current Selection is 0 i.e normal data, archive
              if (currentSelectedSectionIndex === 0) deleteTargeting();
              else handleDeleteForever();
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleModalClose()}
          >
            No
          </Button>
        </div>
      </div>
    </SimpleModal>
  );

  const handleStatusToggle = (id, currentStatus, data) => {
    let dataToSend = {};
    dataToSend.is_active = !currentStatus;
    dataToSend.publisher_id = data.publisher_id;
    Publisher.updateTargeting(id, dataToSend)
      .then((response) => {
        if (response.success) {
          /*change the targeting status from redux store targeting lists*/
          let tempTargetings = [...targetings];
          /*get the index of the targeting from the targetings arra */
          let changedIndex = tempTargetings.findIndex(
            (targeting) => targeting.id === id
          );
          /*change the status */
          tempTargetings[changedIndex].is_active = dataToSend.is_active;
          /*dispatch to store */
          dispatch(updateTargetingLists(tempTargetings));
          toastr.success("Success", "Status updated!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to update");
      });
  };

  const bulkChangeStatus = (type) => {
    if (selected.length > 0) {
      let data, changeType;
      data = {
        ids: selected
      };
      if (type === "activate") {
        changeType = true;
      }
      if (type === "de-activate") {
        changeType = false;
      }
      Publisher.bulkUpdateTargetings(changeType, data)
        .then((response) => {
          if (response.success) {
            fetchTargeting(getRequestParams());
            toastr.success("Success", "Status updated");
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          toastr.error("Oops!! Not able to update targetings status.");
        });
      setSelected([]);
    } else {
      toastr.warning("Oops", "Nothing Selected");
    }
  };

  const bulkChangePublishStatus = (type) => {
    if (selected.length > 0) {
      let data, changeType;
      data = {
        ids: selected
      };
      if (type === "draft") {
        changeType = "draft";
      }
      if (type === "published") {
        changeType = "published";
      }
      Publisher.bulkUpdatePublishStatusTargetings(changeType, data)
        .then((response) => {
          if (response.success) {
            fetchTargeting(getRequestParams());
            toastr.success("Success", "Status updated");
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          toastr.error("Oops!! Not able to update targetings status.");
        });
      setSelected([]);
    } else {
      toastr.warning("Oops", "Nothing Selected");
    }
  };

  const bulkDeleteTargetingModal = (
    <ConfirmBox
      message={`Are you sure want to archive ${selected.length} items?`}
      denyAction={handleModalClose}
      confirmAction={() => {
        bulkDelete();
        handleModalClose();
      }}
    ></ConfirmBox>
  );

  const bulkPermanentDeletePublisherModal = (
    <ConfirmBox
      message={`Are you sure want to permanently delete ${selected.length} items?`}
      confirmAction={() => {
        bulkPermanentDelete();
        handleModalClose();
      }}
      denyAction={handleModalClose}
    ></ConfirmBox>
  );

  const unArchiveModal = (
    <ConfirmBox
      message={`Are you sure you want to un-archive this targeting?`}
      denyAction={handleModalClose}
      confirmAction={() => {
        unArchiveTargeting();
        handleModalClose();
      }}
    ></ConfirmBox>
  );

  const bulkRestoreTargetingModal = (
    <ConfirmBox
      message={`Are you sure want to restore ${selected.length} items?`}
      confirmAction={() => {
        bulkRestore();
        handleModalClose();
      }}
      denyAction={handleModalClose}
    ></ConfirmBox>
  );

  const openTargetFormPage = () => {
    history.push("/target/add");
  };

  return (
    <div className="targetings-container">
      <div className="targetings-container__header">
        <ContentHeader icon={icon} title="Publisher Tags List" />
        <div className="targetings-container__addBtn">
          <Button
            onClick={openTargetFormPage}
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddBoxIcon />}
          >
            Add
          </Button>
        </div>
      </div>
      <div className="targetings-container__filter-box">
        <SimpleAccordion header="Filters" key="targeting-filterbox">
          <TargetFilterBox
            setSearchValue={setSearchValue}
            published={published}
            setPublished={setPublished}
            draft={draft}
            setDraft={setDraft}
            publisher={publisher}
            setPublisher={setPublisher}
            setSelected={setSelected}
            archive={currentSelectedSectionIndex === 1}
          />
        </SimpleAccordion>
        <SimpleAccordion header="Bulk Actions">
          <TargetBulkAction
            handleBulkDeleteAction={handleBulkDeleteAction}
            handleStatusChange={bulkChangeStatus}
            handlePublishStatusChange={bulkChangePublishStatus}
            archive={currentSelectedSectionIndex === 1}
            handleBulkRestore={handleBulkRestoreAction}
            handleBulkPermanentDelete={handleBulkPermanentDelete}
          />
        </SimpleAccordion>
      </div>
      <div className="table-selection-tab">
        <TabMenu
          sections={sections}
          setCurrentSelectedSectionIndex={handleSelectionIndexChange}
          currentSelectedSectionIndex={currentSelectedSectionIndex}
        />
      </div>
      <Table
        headers={
          currentSelectedSectionIndex === 0 ? headers : headersForArchive
        }
        rowValues={targetings}
        actions={
          currentSelectedSectionIndex === 0 ? actions : actionsForArchive
        }
        selected={selected}
        setSelected={setSelected}
        tableType={
          currentSelectedSectionIndex === 0
            ? "targetings"
            : "targetings--archive"
        }
        handleStatusToggle={handleStatusToggle}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        total={total}
        isLoading={loading}
        rowsPerPageOptions={[10, 50, 100, 150, 200]}
        handleEditAction={handleEditAction}
        hoverButtonAction={handleCopyTagToClipboard}
        hoverButtonMsg={"Copy Tag"}
      />
      {open && deleteTargetingModal}
      {bulkDeleteOpen && bulkDeleteTargetingModal}
      {unArchiveOpen && unArchiveModal}
      {bulkRestoreOpen && bulkRestoreTargetingModal}
      {bulkPermanentDeleteOpen && bulkPermanentDeletePublisherModal}
    </div>
  );
};

export default List;
