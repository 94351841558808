import React, { useState, useEffect, useCallback } from "react";
import { Button, FormControl, FormControlLabel } from "@material-ui/core";
import Rule from "./layouts/Rule";
import AddIcon from "@material-ui/icons/Add";
import { ruleArchiveMessage } from "./layouts/RuleArchiveMessage";
import InfoSharp from "@material-ui/icons/InfoSharp";
import { useSelector, useDispatch } from "react-redux";
import {
  updateTargetingRules,
  updateDeletedTargetingRules
} from "../../reducers/target/target.action";

import { getDeletedTargetingRules } from "../../reducers/target/target.selector";
import { getDisabledProviders } from "../../reducers/provider/provider.selector";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import { toastr } from "react-redux-toastr";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ErrorIcon from "@material-ui/icons/Error";
import { TARGETING_TYPES } from "../../helpers/constant/types";
import { useHandleFormValueChange } from "./logics/rule-block/handleFormValueChange.logic";
import { useGetProviders } from "./logics/rule-block/getProviders.logic";

const TargetRule = ({
  targeting_type,
  rulesBlockNumber,
  addMoreRulesBlock,
  logicError,
  oldRules,
  targetingID,
  editing,
  publisher,
  targetingRules,
  targetingRulesError,
  handleFormTouched
}) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const formValueHandlers = useHandleFormValueChange({
    targetingRules,
    handleFormTouched,
    oldRules
  }); //Rule Value Form Change
  const disabledProviders = useSelector(getDisabledProviders);
  const deletedTargetingRules = useSelector(getDeletedTargetingRules);
  const { providers } = useGetProviders();

  //state for modal
  const [open, setOpen] = useState(false);
  const handleModalClose = () => setOpen(false);
  //index of targeting Rule to Delete
  const [deleteIndex, setDeleteIndex] = useState(null);
  const handleDeleteAction = (index) => {
    if (!targetingRules[index]) {
      return;
    }
    if (targetingRules[index].id) {
      setDeleteIndex(index);
      setOpen(true);
    } else {
      let newTargetingRules = [...targetingRules];
      newTargetingRules.splice(index, 1);
      stableDispatch(updateTargetingRules(newTargetingRules));
      setDeleteIndex(null);
    }
  };

  const deleteRuleSet = () => {
    let newTargetingRules = [...targetingRules];
    let idToDelete = newTargetingRules[deleteIndex].id;
    let oldTargetingRules = JSON.parse(oldRules);
    let ruleToDelete = oldTargetingRules.find((rule) => rule.id === idToDelete);
    ruleToDelete.deleted = true;
    let newDeletedTargetingRules = [...deletedTargetingRules].concat(
      ruleToDelete
    );
    stableDispatch(updateDeletedTargetingRules(newDeletedTargetingRules));
    newTargetingRules.splice(deleteIndex, 1);
    stableDispatch(updateTargetingRules(newTargetingRules));
    setDeleteIndex(null);
  };

  useEffect(() => {
    if (targetingRules.length === 0) {
      let rules = [];
      rules.push({
        daily_cap: 0,
        daily_frequency: 100,
        provider_details: [{ traffic: 100 }]
      });
      stableDispatch(updateTargetingRules(rules));
    }
  }, [targetingRules, stableDispatch]);
  const rulesBlock = [];
  //pushing rules block as per array value
  for (let j = 0; j < rulesBlockNumber; j++) {
    rulesBlock.push(
      <Rule
        key={j}
        className="flex-item"
        originalIndex={j}
        targeting_type={targeting_type}
        targetingRuleError={targetingRulesError?.[j] || {}}
        targetingRule={targetingRules?.[j] || {}}
        providers={providers}
        handleDeleteAction={handleDeleteAction}
        disabledProviders={disabledProviders}
        targetingID={targetingID}
        editing={editing}
        publisher={publisher}
        formValueHandlers={formValueHandlers}
      />
    );
  }
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    let rules = [...targetingRules];
    if (rules[result.source.index]) {
      let [reorderedItem] = rules.splice(result.source.index, 1);
      rules.splice(result.destination.index, 0, reorderedItem);
      stableDispatch(updateTargetingRules(rules));
    } else {
      toastr.info("Cannot move empty rule block");
    }
  };

  return (
    <div className="target-rules-section">
      <div className="target-rules-section__labels">
        <div className="target-rules-section__labels__info">
          <FormControlLabel
            control={
              <InfoSharp
                className="info-icon"
                style={{ color: "blue" }}
                fontSize="large"
              />
            }
            label="Default Value of Daily Cap is 0."
          />
        </div>
        {targeting_type === TARGETING_TYPES.ROUND_ROBIN && logicError && (
          <div className="target-rules-section__labels__warning--weights">
            <FormControlLabel
              control={
                <ErrorIcon
                  className="info-icon"
                  color="secondary"
                  fontSize="large"
                />
              }
              label="Sum of all weights must not exceed 100 and each weight must be less than or equal to 100."
            />
          </div>
        )}

        {targeting_type === TARGETING_TYPES.WATERFALL && logicError && (
          <div className="target-rules-section__labels__warning--dailycap">
            <FormControlLabel
              control={
                <ErrorIcon
                  className="info-icon"
                  color="secondary"
                  fontSize="large"
                />
              }
              label="Atleast one daily cap must be of default value (0)."
            />
          </div>
        )}
      </div>

      <FormControl component="fieldset">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="targetRules">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {rulesBlock.map((block, idx) => {
                  return (
                    <Draggable
                      key={`block${idx}`}
                      draggableId={`id-${idx}`}
                      index={idx}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {block}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={() => addMoreRulesBlock()}
        startIcon={<AddIcon />}
        disabled={targetingRules.length !== rulesBlockNumber}
      >
        Add more ruleset
      </Button>
      {open && (
        <ConfirmBox
          message={ruleArchiveMessage}
          confirmAction={() => {
            deleteRuleSet();
            handleModalClose();
          }}
          denyAction={handleModalClose}
        />
      )}
    </div>
  );
};
export default TargetRule;
