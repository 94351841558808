import React from "react";
import PropTypes from "prop-types";

import { Tooltip, IconButton } from "@material-ui/core";
import TargetIcon from "@material-ui/icons/OfflineBoltSharp";

const RelatedProviderLinkIcon = ({ openModal, noOfRules }) => {
  return (
    <>
      <Tooltip
        title={`${
          noOfRules > 0
            ? "Display Related Rule and Tag"
            : "No Related Rule and Tag"
        }`}
      >
        <IconButton
          color={`${noOfRules > 0 ? "primary" : "default"}`}
          onClick={(event) => {
            event.stopPropagation();
            noOfRules > 0 && openModal();
          }}
        >
          <TargetIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

RelatedProviderLinkIcon.propTypes = {
  openModal: PropTypes.func.isRequired,
  noOfRules: PropTypes.number.isRequired
};

export default RelatedProviderLinkIcon;
