import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { Container } from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import { auth } from "../helpers";

import { logout } from "../services/Auth";
import DashboardNavs from "../components/DashboardNavs/DashboardNavs";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const currentUser = useSelector(auth.getCurrentUser);

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(auth.checkIfAuthenticated);

  const handleLogout = (event) => {
    // event.preventDefault();
    dispatch(
      logout(() => {
        toastr.success("", `Successfully logged out`);
      })
    );
  };

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  const hasAccess = auth.checkAccess(currentUser.role.role, rest.roles);

  return (
    <Route
      {...rest}
      render={(props) =>
        hasAccess ? (
          <>
            <DashboardNavs
              handleLogout={handleLogout}
              currentUser={currentUser}
            />
            <Container style={{ margin: "3rem auto" }}>
              <Component {...props} />
            </Container>
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
