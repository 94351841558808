import React from "react";
import PropTypes from "prop-types";
import FilterActionButtons from "./FilterActionButtons";
import FilterSelections from "./FilterSelections";

const FilterBox = ({ handleSearch, filterValuesLogic, loadingLogs }) => {
  const { selectionAddActions, filterValues, dataLists, clearAllSelection } =
    filterValuesLogic;

  return (
    <div className="flow-content logs-filter-box">
      <FilterSelections
        dataLists={dataLists}
        handleSearch={handleSearch}
        filterValues={filterValues}
        selectionAddActions={selectionAddActions}
      />
      <FilterActionButtons
        handleSearch={handleSearch}
        clearAllSelection={clearAllSelection}
        loadingLogs={loadingLogs}
      />
    </div>
  );
};

FilterBox.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  filterValuesLogic: PropTypes.object.isRequired,
  loadingLogs: PropTypes.bool.isRequired
};

export default FilterBox;
