import { useState, useEffect } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { Template } from "../../../services/Template";

export const useFetchTemplates = () => {
  const [templates, setTemplates] = useState([]);

  const [loadingTemplates, setLoadingTemplates] = useState({
    isLoading: false,
    error: false
  });

  const isMounted = useIsMounted();

  async function getTemplates() {
    try {
      setLoadingTemplates({ isLoading: true, error: false });
      const templates = await Template.fetchTemplates();
      if (templates.success && isMounted()) {
        let receivedTemplates = [];
        for (let p of templates.data.templates) {
          receivedTemplates.push({
            label: p.name,
            value: p.path
          });
        }
        setTemplates(receivedTemplates);
        setLoadingTemplates({ isLoading: false, error: false });
      } else {
        throw new Error(templates.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setTemplates([]);
        setLoadingTemplates({ isLoading: false, error: true });
      }
      console.trace(error.message || error);
    }
  }

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loadingTemplates,
    templates
  };
};
