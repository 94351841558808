import { useHistory } from "react-router-dom";
import { useModal } from "../../../hooks/useModal";
import { useState } from "react";
import { Provider } from "../../../services/Provider";
import { toastr } from "react-redux-toastr";
import { getActiveRules } from "../../ProviderLinks/helpers";
export const useProviderLinkTableLogic = ({
  updateProviderLinks,
  getProviderLinks,
  showEditForm,
  showDuplicateForm
}) => {
  const [relatedModalOpen, openRelatedModal, closeRelatedModal] = useModal();
  const [relatedModalData, setRelatedModalData] = useState(null);

  const [warningModalOpen, openWarningModal, closeWarningModal] = useModal();
  const [warningModalData, setWarningModalData] = useState(null);

  const [
    confirmDeleteModalOpen,
    openConfirmDeleteModal,
    closeConfirmDeleteModal
  ] = useModal();
  const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(null);

  const history = useHistory();
  const headers = [
    { title: "ID", value: "id" },
    { title: "Link", value: "link" },
    { title: "Advertiser", value: "provider_id" },
    { title: "Type", value: "type" },
    { title: "Search Engine", value: "search_engine_id" },
    { title: "Tag Type", value: "tag_type_id" },
    { title: "Platform", value: "platform_id" },
    { title: "Description", value: "description" },
    // { title: "Status", value: "status" },
    { title: "Is Linked", value: "isLinked" },
    { title: "Is Live", value: "isLive" },
    { title: "Status", value: "disabled" }
  ];

  function openFormPage(id, data) {
    const provider_id = data?.provider.id || "";
    if (provider_id) {
      history.push({
        pathname: "/advertisers/links/add",
        state: {
          provider: provider_id
        }
      });
    }
  }

  function closeRelatedModalHandler() {
    setRelatedModalData(null);
    closeRelatedModal();
  }

  function closeWarningModalHandler() {
    setWarningModalData(null);
    closeWarningModal();
  }

  function onRelatedButtonClick(id, data) {
    if (data.no_of_rules > 0) {
      setRelatedModalData({
        linkID: data.id,
        targetObj: data.targetObj
      });
    }
    openRelatedModal();
  }

  async function deleteProviderLink() {
    try {
      const response = await Provider.deleteProviderLink(
        confirmDeleteModalData
      );
      if (response.success) {
        closeConfirmDeleteModalHandler();
        toastr.success("Success", "Advertiser Link Deleted");
        getProviderLinks();
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      closeConfirmDeleteModalHandler();
      console.trace(error.message);
      toastr.info("Oops!", error.message);
    }
  }

  function closeConfirmDeleteModalHandler() {
    setConfirmDeleteModalData(null);
    closeConfirmDeleteModal();
  }

  function onDeleteButtonClick(id, data) {
    if (parseInt(data?.no_of_rules) > 0) {
      toastr.info("Oops", "Link is currently used in some targeting rule!");
      return;
    }
    setConfirmDeleteModalData(id);
    openConfirmDeleteModal();
  }

  function onDeleteConfirmed() {
    closeConfirmDeleteModal();
    deleteProviderLink();
  }

  const handleStatusToggle = async (id, currentStatus, data) => {
    if (data?.no_of_active_rules && parseInt(data?.no_of_active_rules) > 0) {
      setWarningModalData(getActiveRules(data?.targetObj || []));
      openWarningModal();
      return;
    }
    try {
      const {
        link,
        search_engine_type,
        p_sid,
        searchq_val,
        n_val,
        sub_id_val,
        description,
        click_id_val
      } = data;
      const dataToSend = [
        {
          id,
          provider_id: data.provider.id,
          link,
          search_engine_type,
          p_sid,
          searchq_val,
          n_val,
          sub_id_val,
          description,
          click_id_val,
          disabled: !currentStatus,
          platform_id: data.platform.id,
          search_engine_id: data.search_engine.id,
          tag_type_id: data.tag_type.id
        }
      ];

      const savedData = await Provider.saveProviderLinks(id, dataToSend);
      if (savedData.success) {
        updateProviderLinks(id, "disabled", dataToSend[0].disabled);
        toastr.success("Success!", "Status updated successfully");
      } else {
        throw new Error(JSON.stringify(savedData.error));
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", "Not able to change status at the moment!");
    }
  };

  function openEditForm(id, data) {
    showEditForm(data);
  }

  function openDuplicateForm(id, data) {
    showDuplicateForm(data);
  }

  let actions = [
    { name: "managelinks", action: openFormPage, icon: "manageIconOne" },
    {
      name: "advertiserLinks-related",
      action: onRelatedButtonClick,
      icon: "advertiserLinks-related"
    },
    {
      name: "advertiserLinks-delete",
      action: onDeleteButtonClick,
      icon: "advertiserLinks-delete"
    },
    {
      name: "editIcon",
      action: openEditForm,
      icon: "editIcon"
    },
    {
      name: "duplicateIcon",
      action: openDuplicateForm,
      icon: "duplicateIcon"
    }
  ];

  return {
    headers,
    actions,
    relatedModalOpen,
    relatedModalData,
    closeRelatedModalHandler,
    handleStatusToggle,
    warningModalOpen,
    warningModalData,
    closeWarningModalHandler,
    confirmDeleteModalOpen,
    closeConfirmDeleteModalHandler,
    onDeleteConfirmed
  };
};
