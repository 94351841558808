//Manage form for providers and publishers.
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import { textFieldValid } from "../../helpers/validation";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CheckIcon from "@material-ui/icons/Check";
import { Prompt } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import { useFormTouched } from "../../hooks/useFormTouched";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";

const Form = ({ editing, onFormSubmit, initialValues, handleCancel, type }) => {
  const [name, setName] = useState(initialValues.name);
  const [details, setDetails] = useState(initialValues.details);
  const [pid, setPID] = useState(initialValues.id);
  const [status, setStatus] = useState(initialValues.status);
  const [showPrompt, setShowPrompt] = useState(true);
  const [open, setOpen] = useState(false);
  const [csv_source_identifier, setCsvCol] = useState("");
  const [link_source_identifier, setLinkCol] = useState("");
  //const [api_source_identifier, setApiCol] = useState("");
  const [display_in_upload_screen, setDisplayUploadScreen] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({
    name: false,
    details: false,
    csv_source_identifier: false,
    // api_source_identifier: false,
    link_source_identifier: false
  });

  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction: () => {}
  });

  useEffect(() => {
    setName(initialValues.name);
    setDetails(initialValues.details);
    setPID(initialValues.id);
    setStatus(initialValues.status);
    if (type === "provider") {
      setDisplayUploadScreen(initialValues.display_in_upload_screen);
      setCsvCol(initialValues?.csv_source_identifier);
      // setApiCol(initialValues?.api_source_identifier);
      setLinkCol(initialValues?.link_source_identifier);
    }
  }, [initialValues, type]);

  const validateForm = () => {
    let isValid = true;
    if (!textFieldValid(name)) {
      setFieldErrors({ ...fieldErrors, name: true });
      isValid = false;
      return;
    }
    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    clearFormTouched();
    setShowPrompt(false);
    //changing status to null in case of empty string
    let detailsValue = details;
    if (!textFieldValid(details)) {
      detailsValue = null;
    }
    const data = {
      name: name.trim(),
      details: detailsValue,
      status
    };
    if (type === "provider") {
      data["display_in_upload_screen"] = display_in_upload_screen;
      data["csv_source_identifier"] =
        csv_source_identifier?.length > 0 ? csv_source_identifier : null;
      // data["api_source_identifier"] =
      //   api_source_identifier?.length > 0 ? api_source_identifier : null;
      data["link_source_identifier"] =
        link_source_identifier?.length > 0 ? link_source_identifier : null;
    }
    if (editing) {
      onFormSubmit(pid, data);
    } else {
      onFormSubmit(data);
    }
  };

  let submitButton = (
    <Button
      color="primary"
      onClick={(e) => handleSubmit(e)}
      variant="contained"
      size="medium"
      startIcon={<CheckIcon />}
      type="submit"
    >
      {" "}
      Save
    </Button>
  );

  const disableStatus = () => {
    setStatus("inactive");
  };

  const handleEditCheckboxChange = (e) => {
    if (type === "provider" && status === "active") {
      if (parseInt(initialValues.no_of_rules) > 0) {
        toastr.info(
          "Info",
          "Cannot change status of advertiser that are being used in targeting rules."
        );
        return;
      }
    }

    if (type === "publisher" && status === "active") {
      if (parseInt(initialValues.no_of_targetings) > 0) {
        setOpen(true);
        return;
      }
    }
    e.target.checked ? setStatus("active") : setStatus("inactive");
    handleFormTouched();
  };

  const handleCheckBoxChange = (e) => {
    e.target.checked ? setStatus("active") : setStatus("inactive");
    handleFormTouched();
  };

  const handleUploadCSVChange = (e) => {
    setDisplayUploadScreen(e.target.checked);
    handleFormTouched();
  };
  const statusModal = (
    <ConfirmBox
      message={`This publisher has associated targetings.Are you sure?`}
      confirmAction={() => {
        handleFormTouched();
        disableStatus();
        setOpen(false);
      }}
      denyAction={() => setOpen(false)}
    ></ConfirmBox>
  );

  return (
    <>
      <form className="form">
        {editing && (
          <div className="form__pid">
            <h3 className="dark-grey-text">PID</h3>
            <TextField
              variant="filled"
              margin="normal"
              name="pid"
              value={pid}
              error={fieldErrors.pid}
              onChange={(e) => {
                handleFormTouched();
                setPID(e.target.value);
              }}
              disabled
            />
          </div>
        )}
        <div className="form__status">
          <h3 className="dark-grey-text">Status</h3>
          <FormControlLabel
            control={
              <Checkbox
                onChange={
                  editing ? handleEditCheckboxChange : handleCheckBoxChange
                }
                name="status"
                color="primary"
                checked={status === "active" ? true : false}
              />
            }
            label="Active"
          />
        </div>
        {type === "provider" && (
          <div className="form__upload_csv">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleUploadCSVChange}
                  name="display_in_csv_upload"
                  color="primary"
                  checked={display_in_upload_screen}
                />
              }
              label="Display In Csv Upload"
            />
          </div>
        )}
        <div className="form__name">
          <FormControl fullWidth>
            <TextField
              variant="standard"
              margin="normal"
              label="Name"
              name="name"
              value={name}
              error={fieldErrors.name}
              onChange={(e) => {
                handleFormTouched();
                setName(e.target.value);
                setFieldErrors({ ...fieldErrors, name: false });
              }}
              autoFocus
              placeholder="Enter Name"
            />
            {fieldErrors.name && (
              <FormHelperText>Name is Required</FormHelperText>
            )}
          </FormControl>
          {type === "provider" && (
            <div className="flex-box align-items-bottom">
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  margin="normal"
                  label="CSV Source Identifier"
                  name="csv_source_identifier"
                  value={csv_source_identifier}
                  error={fieldErrors.csv_source_identifier}
                  onChange={(e) => {
                    handleFormTouched();
                    setCsvCol(e.target.value);
                    setFieldErrors({
                      ...fieldErrors,
                      csv_source_identifier: false
                    });
                  }}
                  placeholder="Enter Source Identifier"
                />
                {fieldErrors.csv_source_identifier && (
                  <FormHelperText color="secondary">
                    Please enter CSV column identifier
                  </FormHelperText>
                )}
              </FormControl>
              <Tooltip title={"Column Name (CSV)"}>
                <div className="gray-icon ">
                  <HelpIcon fontSize="small" />
                </div>
              </Tooltip>
            </div>
          )}

          {/* {type === "provider" && (
            <div className="flex-box align-items-bottom">
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  margin="normal"
                  label="API Source Identifier"
                  name="api_source_identifier"
                  value={api_source_identifier}
                  error={fieldErrors.api_source_identifier}
                  onChange={(e) => {
                    handleFormTouched();
                    setApiCol(e.target.value);
                    setFieldErrors({
                      ...fieldErrors,
                      api_source_identifier: false
                    });
                  }}
                  placeholder="Enter Source Identifier"
                />
                {fieldErrors.api_source_identifier && (
                  <FormHelperText color="secondary">
                    Please enter API column identifier
                  </FormHelperText>
                )}
              </FormControl>
              <Tooltip title={"Column Name (API)"}>
                <div className="gray-icon ">
                  <HelpIcon fontSize="small" />
                </div>
              </Tooltip>
            </div>
          )} */}

          {type === "provider" && (
            <div className="flex-box align-items-bottom">
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  margin="normal"
                  label="Link Source Identifier"
                  name="link_source_identifier"
                  value={link_source_identifier}
                  error={fieldErrors.link_source_identifier}
                  onChange={(e) => {
                    handleFormTouched();
                    setLinkCol(e.target.value);
                    setFieldErrors({
                      ...fieldErrors,
                      link_source_identifier: false
                    });
                  }}
                  placeholder="Enter Source Identifier"
                />
                {fieldErrors.link_source_identifier && (
                  <FormHelperText color="secondary">
                    Please enter link source identifier
                  </FormHelperText>
                )}
              </FormControl>
              <Tooltip title={"Name of the parameter in the link"}>
                <div className="gray-icon ">
                  <HelpIcon fontSize="small" />
                </div>
              </Tooltip>
            </div>
          )}
        </div>

        <div className="form__details">
          <FormControl fullWidth>
            <TextField
              label="Details"
              name="details"
              multiline
              variant="outlined"
              value={details}
              error={fieldErrors.details}
              onChange={(e) => {
                handleFormTouched();
                setDetails(e.target.value);
                setFieldErrors({ ...fieldErrors, details: false });
              }}
              rows={4}
              rowsMax={10}
              fullWidth
            />
            {fieldErrors.details && (
              <FormHelperText color="secondary">
                Please enter details.
              </FormHelperText>
            )}
          </FormControl>
        </div>

        <div className="form__controls">
          {submitButton}
          <Button
            onClick={handleCancel}
            variant="contained"
            size="medium"
            color="secondary"
            startIcon={<ClearIcon />}
          >
            Cancel
          </Button>
        </div>
        {open && statusModal}
      </form>
      <Prompt
        when={showPrompt && formTouched.current}
        message="Are you sure you want to leave?"
      />
    </>
  );
};

export default Form;
