import React from "react";
import { useDisplayNameLogic } from "../display-name.logic.js";
import { useAdvertiserSelectLogic } from "../advertiser-select.logic";
import { useFieldsFormLogic } from "./fields-form.logic";
import MappingFieldForm from "./Layout/MappingFieldForm";
import AdvertiserSelectInfoMsg from "./Layout/AdvertiserSelectInfoMsg";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";
import { Prompt } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useParams } from "react-router-dom";

const FieldMapping = () => {
  const { loadingDisplayNames, displayNames } = useDisplayNameLogic();
  const { id } = useParams();
  // const providerId = location.state?.providerId || "";
  const providerId = id || "";

  const {
    // providers,
    provider,
    // handleProviderChange,
    // loadingProviders,
    openProviderChangeMsg,
    changeProvider,
    handleModalClose
  } = useAdvertiserSelectLogic(providerId);

  const {
    values,
    handleChange,
    formTouched,
    handleSubmit,
    handleCancel,
    loadingMappedFields,
    updateConfirmationModalOpen,
    onSubmitButtonClick,
    isSubmittingData,
    closeUpdateConfirmationModal
  } = useFieldsFormLogic({
    provider,
    displayNames
  });

  // const icon = <GridOnIcon fontSize="inherit" color="primary" />;

  const getForm = () => {
    return provider ? (
      <MappingFieldForm
        values={values}
        handleChange={handleChange}
        handleSubmit={onSubmitButtonClick}
        handleCancel={handleCancel}
        displayNames={displayNames}
        loadingMappedFields={loadingMappedFields}
        isSubmittingData={isSubmittingData}
      />
    ) : (
      <AdvertiserSelectInfoMsg />
    );
  };

  return (
    <div className="field-mapping-container flow-content">
      {/* <ContentHeader icon={icon} title="Map CSV Fields" />
      <AdvertiserSelector
        provider={provider}
        setProvider={handleProviderChange}
        loadingProviders={loadingProviders}
        providers={providers}
        formTouched={formTouched}
      /> */}
      {loadingDisplayNames ? (
        <div className="loading-div">
          <LoadingSpinner />
        </div>
      ) : (
        getForm()
      )}
      {openProviderChangeMsg && (
        <ConfirmBox
          message="Changes made will not be saved. Are you sure you want to select another advertiser?"
          confirmAction={changeProvider}
          denyAction={handleModalClose}
        />
      )}
      {updateConfirmationModalOpen && (
        <ConfirmBox
          message="Are you sure you want to change the mapping?"
          confirmAction={handleSubmit}
          denyAction={closeUpdateConfirmationModal}
        />
      )}
      <Prompt when={formTouched} message="Are you sure you want to leave?" />
    </div>
  );
};

export default FieldMapping;
