import React from "react";
import { useParams } from "react-router-dom";
import { useReportDataRemove } from "./logic/reportDataRemove.logic";
import SimpleDateRangePicker from "../../components/SimpleDateRangePicker/SimpleDateRangePicker";
import PublisherAccountRemoveButtons from "./layouts/PublisherAccountRemoveButtons";
import { useFetchPublisherAccountById } from "./logic/fetchPublisherAccountById.logic";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
const PublisherAccountRemove = () => {
  const { id } = useParams();

  const {
    fromDate,
    toDate,
    handleDateChange,
    loadingRemovalResult,
    handleRemoveAction,
    isValid,
    handleCancelAction,
    removeModal,
    confirmRemoveAction,
    closeRemoveModal
  } = useReportDataRemove(id);

  const { publisherAccount, loadingPublisherAccount } =
    useFetchPublisherAccountById(id);

  if (!id) {
    return <div>Sorry, no id for publisher account found</div>;
  }

  if (loadingPublisherAccount.error || !publisherAccount) {
    return (
      <div>
        Sorry, facing problem loading publisher account data. Please reload the
        page and try again.
      </div>
    );
  }

  const advertiser_name = publisherAccount?.provider?.name || "";
  const publisher_name = publisherAccount?.publisher?.name || "";
  const { sin, source_identifier, rule_id } = publisherAccount;

  const icon = <AddToPhotosIcon fontSize="inherit" color="primary" />;

  return (
    <div className="remove-report flow-content">
      <ContentHeader icon={icon} title="Remove Report Data" />
      <fieldset className="custom-fieldset flow-content">
        <legend>
          Please select the dates to remove report data for publisher account
        </legend>

        <div className="remove-report__info">
          <span> Publisher Ad Account ID: {id}</span>
          <span> Publisher Name: {publisher_name}</span>
          <span> Advertiser Name: {advertiser_name}</span>
          <span> Link Source Identifier: {source_identifier}</span>
          <span> Rule ID: {rule_id}</span>
          <span> Sub ID: {sin}</span>
        </div>

        <div className="remove-report__date-picker">
          <SimpleDateRangePicker
            startDate={fromDate ? fromDate : null}
            endDate={toDate ? toDate : null}
            handleDateChange={handleDateChange}
          />
        </div>

        <PublisherAccountRemoveButtons
          loading={loadingRemovalResult.loading}
          cancelAction={handleCancelAction}
          submitAction={handleRemoveAction}
        />

        {!isValid && (
          <div className="red-text">
            <p>Please select a valid date range</p>
          </div>
        )}
      </fieldset>

      {loadingRemovalResult.success && (
        <div className="remove-report__message">
          <h3>Deleted {loadingRemovalResult.deletedCount} rows of data.</h3>
        </div>
      )}

      {removeModal && (
        <ConfirmBox
          title="Remove Report Data"
          message={`Are you sure you want to remove report data for publisher account ${id}?`}
          confirmAction={confirmRemoveAction}
          denyAction={closeRemoveModal}
        />
      )}
    </div>
  );
};

export default PublisherAccountRemove;
