import React, { useEffect, useCallback } from "react";
import { FormControl, FormControlLabel } from "@material-ui/core";
import TypeRule from "./layouts/TypeRule";
import InfoSharp from "@material-ui/icons/InfoSharp";
import { useSelector, useDispatch } from "react-redux";
import {
  updateTargetingRules
} from "../../reducers/target/target.action";

import { getDisabledProviders } from "../../reducers/provider/provider.selector";
import { useHandleFormValueChange } from "./logics/rule-block/handleFormValueChange.logic";
import { useGetProviders } from "./logics/rule-block/getProviders.logic";

const TargetTypeRule = ({
  targeting_type,
  oldRules,
  targetingID,
  editing,
  publisher,
  targetingRules,
  targetingRulesError,
  handleFormTouched,
  tag_type
}) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const formValueHandlers = useHandleFormValueChange({
    targetingRules,
    handleFormTouched,
    oldRules
  }); //Rule Value Form Change
  const disabledProviders = useSelector(getDisabledProviders);
  const { providers } = useGetProviders();

  useEffect(() => {
    if (targetingRules.length === 0) {
      let rules = [];
      rules.push({
        daily_cap: 0,
        daily_frequency: 100,
        provider_details: [{ traffic: 100 }]
      });
      stableDispatch(updateTargetingRules(rules));
    }
  }, [targetingRules, stableDispatch]);

  return (
    <div className="target-rules-section">
      <div className="target-rules-section__labels">
        <div className="target-rules-section__labels__info">
          <FormControlLabel
            control={
              <InfoSharp
                className="info-icon"
                style={{ color: "blue" }}
                fontSize="large"
              />
            }
            label="Default Value of Daily Cap is 0."
          />
        </div>
      </div>

      <FormControl component="fieldset">
      <TypeRule
        key={0}
        className="flex-item"
        originalIndex={0}
        targeting_type={targeting_type}
        targetingRuleError={targetingRulesError?.[0] || {}}
        targetingRule={targetingRules?.[0] || {}}
        providers={providers}
        disabledProviders={disabledProviders}
        targetingID={targetingID}
        editing={editing}
        publisher={publisher}
        formValueHandlers={formValueHandlers}
        tag_type={tag_type}
      />
      </FormControl>
    </div>
  );
};
export default TargetTypeRule;
