import React from "react";
import Table from "../../../components/Table/Table";
import { useProviderLinkTableLogic } from "../logic/providerLinksTable.logic";
import RelatedProviderLinkModal from "../../ProviderLinks/RelatedProviderLinkModal";
import RelatedProviderLinkWarningModal from "../../ProviderLinks/RelatedProviderLinkWarningModal";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";

const ProviderLinksTable = ({
  fetchProviderLinksLogic,
  updateProviderLinks,
  showEditForm,
  showDuplicateForm
}) => {
  const {
    providerLinks,
    loadingProviderLinks,
    page,
    setPage,
    size,
    setSize,
    total,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    getProviderLinks
  } = fetchProviderLinksLogic;
  const {
    headers,
    actions,
    relatedModalOpen,
    relatedModalData,
    closeRelatedModalHandler,
    handleStatusToggle,
    warningModalOpen,
    warningModalData,
    closeWarningModalHandler,
    confirmDeleteModalOpen,
    closeConfirmDeleteModalHandler,
    onDeleteConfirmed
  } = useProviderLinkTableLogic({
    updateProviderLinks,
    getProviderLinks,
    showEditForm,
    showDuplicateForm
  });

  return (
    <div>
      {providerLinks && (
        <Table
          headers={headers}
          rowValues={providerLinks || []}
          actions={actions}
          isLoading={loadingProviderLinks.isLoading}
          noCheckboxes={true}
          noIds={true}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
          total={total}
          rowsPerPageOptions={[10, 20, 30, 50, 100, 200]}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          handleStatusToggle={handleStatusToggle}
          tableType="providerLinks"
        />
      )}
      {relatedModalData && (
        <RelatedProviderLinkModal
          modalOpen={relatedModalOpen}
          closeModal={closeRelatedModalHandler}
          targetObj={relatedModalData.targetObj}
          linkID={relatedModalData.linkID}
        />
      )}
      {warningModalData && (
        <RelatedProviderLinkWarningModal
          relatedActiveRules={warningModalData || []}
          modalOpen={warningModalOpen}
          closeModal={closeWarningModalHandler}
        />
      )}
      {confirmDeleteModalOpen && (
        <ConfirmBox
          message="Are you sure you want to delete?"
          confirmAction={onDeleteConfirmed}
          denyAction={closeConfirmDeleteModalHandler}
        />
      )}
    </div>
  );
};

export default ProviderLinksTable;
