import Api from "../api";
class ProviderApiService {
  addProviderCredentials = async (data = [], provider_id = null) => {
    const { body } = await Api.post(
      `providers/api/credentials/${provider_id}`,
      data
    );
    return body;
  };
  applyCroneJob = async (data = {}) => {
    const { body } = await Api.post(`providers/api/cronejob`, data);
    return body;
  };
  getProviderCredentials = async (provider_id = null) => {
    const { body } = await Api.get(`providers/api/credentials/${provider_id}`);
    return body;
  };

  fetchProvidersAoiDetails = async (
    params = { order_by: "id", order_direction: "ASC" }
  ) => {
    const { body } = await Api.get(`providers/api/apiinfo`, null, params);
    return body;
  };

  fetchProvidersAoiDetailsById = async (id = null) => {
    const { body } = await Api.get(`providers/api/apiinfo/${id}`);
    return body;
  };

  deleteProviderCredentials = async (provider_id = null) => {
    const { body } = await Api.destroy(
      `advertiser/api/credential/${provider_id}`
    );
    return body;
  };
}
export default new ProviderApiService();
