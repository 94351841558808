import React from "react";
import { RevColHeadersContextProvider } from "../../contexts/revenue-context";
import RevenueReport from "./RevenueReport";

const RevenueReportContainer = () => (
  <RevColHeadersContextProvider>
    <RevenueReport />
  </RevColHeadersContextProvider>
);
export default RevenueReportContainer;
