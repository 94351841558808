import Api from "../api";

class ShareRevenue {
  editUpdateShareRevenue = async (data = {}) => {
    const { body } = await Api.post(`shareRevenue`, data);
    return body;
  };
  getShareRevenue = async () => {
    const { body } = await Api.get(`shareRevenue`);
    return body;
  };
}

export default new ShareRevenue();
