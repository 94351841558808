import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Publisher } from "../../../services/Publisher";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";

const TargetFilterBox = ({
  setSearchValue,
  published,
  setPublished,
  draft,
  publisher,
  setPublisher,
  setDraft,
  archive = false,
  setSelected
}) => {
  const [publishers, setPublishers] = useState(null);
  const [query, setQuery] = useState("");

  const handleSearchButton = () => {
    setSearchValue(query);
    setSelected([]);
  };

  const fetchPublishers = () => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          for (let p of data) {
            publishers.push({ label: p.name, value: p.id });
          }
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  };
  useEffect(() => {
    fetchPublishers();
  }, []);

  const handleChange = (type) => {
    if (type === "published") {
      if (!published) setDraft(false);
      setPublished(!published);
    } else {
      if (!draft) setPublished(false);
      setDraft(!draft);
    }
  };

  const handlePublisherChange = (value) => {
    setPublisher(value);
  };

  const handleClearAll = () => {
    setQuery("");
    setSearchValue("");
    setPublished(false);
    setDraft(false);
    setPublisher("");
  };

  return (
    <div className="filter-box__container">
      <div className="filter-box">
        <div className="filter-box__controls">
          <div className="filter-box__searchbar">
            {/* <h4>Search Text</h4> */}
            <div className="filter-box__searchField">
              <TextField
                variant="standard"
                margin="normal"
                label="Type to search ..."
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && handleSearchButton();
                }}
                value={query}
                fullWidth
              />
            </div>
          </div>
          <div className="filter-box__options">
            <div className="filter-box__options-select">
              <AutoCompleteSelect
                selected={publisher ? publisher : ""}
                data={publishers || []}
                handleChange={(value) => handlePublisherChange(value)}
                label={"Publisher"}
                showValueInLabel={true}
                enableMultiple={false}
                withDotsForStatus={true}
                cannotSelectDisabled={false}
              />
            </div>
            {!archive && (
              <>
                <h4>Filter By Status</h4>
                <div className="filter-box__options-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={published}
                        onChange={() => handleChange("published")}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="cusDomain"
                      />
                    }
                    label="Published"
                  />
                </div>
                <div className="filter-box__options-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={draft}
                        onChange={() => handleChange("draft")}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="cusDomain"
                      />
                    }
                    label="Draft"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="filter-box__button">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSearchButton}
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleClearAll}
            startIcon={<ClearAllIcon />}
          >
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TargetFilterBox;
