import Api from "../api";

class PublisherAccount {
  fetchPublisherAccounts = async (params = {}) => {
    const response = await Api.get("pubaccount", null, params);
    return response.body;
  };
  fetchPublisherById = async (id, params = {}) => {
    const response = await Api.get(`pubaccount/${id}`, null, params);
    return response.body;
  };
  updateAccounts = async (id, data, params = {}) => {
    const { body } = await Api.put(`pubaccount/${id}`, data, params);
    return body;
  };

  verifyAccounts = async (data) => {
    const { body } = await Api.post("pubaccount/verify", data);
    return body;
  };
}

export default new PublisherAccount();
