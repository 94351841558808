import React, { useCallback } from "react";
import HistoryIcon from "@material-ui/icons/History";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import ListItemText from "@material-ui/core/ListItemText";
import { useSelector } from "react-redux";
import moment from "moment";
import { getProviders } from "../../../reducers/provider/provider.selector";

const History = ({ targetingHistory }) => {
  //return nothing if null
  const providers = useSelector(getProviders);

  const getProviderName = useCallback(
    (id) => {
      if (providers.length !== 0) {
        let provider = providers.find((p) => p.value === id);
        return provider.label;
      }
      return "";
    },
    [providers]
  );

  if (!targetingHistory)
    return (
      <div className="targeting-history--none">
        <h3>
          Sorry, this targeting does not have any previously saved history
        </h3>
      </div>
    );

  return (
    <div className="targeting-history">
      <div className="targeting-history__content">
        <div className="targeting-history__header">
          <ContentHeader
            icon={<HistoryIcon fontSize="inherit" color="primary" />}
            title="Targetings Saved History"
          />
        </div>
        <div className="targeting-history__dateTime">
          <span>
            {targetingHistory.publishedAt &&
              `Last Published (UTC) on:${moment(targetingHistory.publishedAt)
                .utc()
                .format("lll")}`}
          </span>
          <span>
            {targetingHistory.updatedAt &&
              `Last Updated (UTC) on:${moment(targetingHistory.updatedAt)
                .utc()
                .format("lll")}`}
          </span>
        </div>
        <div className="targeting-history__linkinfo">
          <h3>Tag Related Information</h3>
          <ListItemText primary="Tag" secondary={targetingHistory.link} />
          <hr />{" "}
          <ListItemText
            primary="Active"
            secondary={targetingHistory.is_active ? "True" : "False"}
          />
          <hr />{" "}
          <div className="targeting-history__info-variables">
            <ListItemText
              primary="Sub ID"
              secondary={
                targetingHistory.sub_id ? targetingHistory.sub_id : "-"
              }
            />
            <ListItemText
              primary="Tag ID"
              secondary={
                targetingHistory.client_id ? targetingHistory.client_id : "-"
              }
            />
            <ListItemText
              primary="Publisher ID"
              secondary={
                targetingHistory.publisher_id
                  ? targetingHistory.publisher_id
                  : "-"
              }
            />
            {/* <ListItemText
              primary="OID"
              secondary={targetingHistory.oid ? targetingHistory.oid : "-"}
            /> */}
          </div>
          <hr />
          <ListItemText
            primary="Domain Info"
            secondary={targetingHistory.custom_domain}
          />
          <hr />
          <div className="targeting-history__info-variables">
            <ListItemText
              primary="Use N"
              secondary={targetingHistory.n ? "True" : "False"}
            />
            <ListItemText
              primary="Use Click ID"
              secondary={targetingHistory.click_id ? "True" : "False"}
            />
            <ListItemText
              primary="Custom Domain"
              secondary={targetingHistory.use_custom_domain ? "True" : "False"}
            />
            <ListItemText
              primary="Custom Fallback"
              secondary={
                targetingHistory.use_custom_fallback ? "True" : "False"
              }
            />
            <ListItemText
              primary="Status"
              secondary={targetingHistory.status}
              className="capitalize"
            />
          </div>
          <hr />
          <ListItemText
            primary="Notes"
            secondary={targetingHistory.notes ? targetingHistory.notes : "-"}
          />
          <hr />
          <ListItemText
            primary="Default Fallback"
            secondary={targetingHistory.default_fallback}
          />
          <hr />
          <div className="targeting-history__info-variables">
            <ListItemText
              primary="Publisher Name"
              secondary={targetingHistory?.publisher_info?.name}
            />
            <ListItemText
              primary="Publisher's Status"
              secondary={
                targetingHistory?.publisher_info?.status ? "True" : "False"
              }
            />
            <ListItemText
              primary="Publisher Details"
              secondary={
                targetingHistory?.publisher_info?.details
                  ? targetingHistory?.publisher_info?.details
                  : "-"
              }
            />
          </div>
          <hr />
        </div>
        <div className="targeting_history__rules">
          <h3>Targeting's Rules</h3>
          {targetingHistory?.targeting_rules &&
            targetingHistory?.targeting_rules.map((rule) => {
              return (
                <div className="history-rule-box" key={`rule${rule.id}`}>
                  <h4>Priority: {rule.priority} </h4>
                  <div className="history-rule-box__contents">
                    <ListItemText
                      primary="Daily Cap"
                      secondary={rule.daily_cap}
                    />
                    <ListItemText
                      primary="Weight"
                      secondary={
                        rule.daily_frequency ? rule.daily_frequency : "-"
                      }
                    />
                    <div className="history-rule-box__provider-details">
                      {rule.provider_details &&
                        rule.provider_details.map((detail, index) => (
                          <div
                            key={`${detail.provider_id}${detail.traffic}${index}`}
                            className="history-rule-box__provider-details__detail"
                          >
                            <ListItemText
                              primary="A-B Testing"
                              secondary={detail.traffic}
                            />
                            <ListItemText
                              primary={`${
                                index === 0
                                  ? "Advertiser 1 ID"
                                  : "Advertiser 2 ID"
                              }`}
                              secondary={getProviderName(detail.provider_id)}
                            />
                            <ListItemText
                              primary={`${
                                index === 0
                                  ? "Advertiser 1 Link"
                                  : "Advertiser 2 Link"
                              }`}
                              secondary={detail.provider_link}
                            />
                          </div>
                        ))}
                    </div>
                    <ListItemText
                      primary="Comment"
                      secondary={rule.comment ? rule.comment : "-"}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default History;
