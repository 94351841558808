import { useEffect, useState } from "react";
import { User } from "../../../services/ReportingUser";

import { useIsMounted } from "../../../hooks/useIsMounted";
import { useFormTouched } from "../../../hooks/useFormTouched";
import {
  INITIAL_SEARCHES,
  FOLLOWON_SEARCHES,
  RPC,
  RPMM,
  RPM,
  AD_COVERAGE,
  CLICKS,
  DATE,
  COMPANY_NAME,
  CHANNEL,
  SEARCH_ENGINE,
  TAG_TYPE,
  GEO,
  SERVER_TOTAL_SEARCHES,
  TOTAL_SEARCHES,
  MONETIZED_SEARCHES,
  PUBLISHER_REVENUE,
  LINK_PLATFORM,
  CTR
} from "../../../helpers/constant/filters";
import { removeValueFromArray } from "../../../helpers";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
export const columnsList = [
  COMPANY_NAME,
  CHANNEL,
  SEARCH_ENGINE,
  TAG_TYPE,
  LINK_PLATFORM,
  GEO,
  SERVER_TOTAL_SEARCHES,
  TOTAL_SEARCHES,
  MONETIZED_SEARCHES,
  CTR,
  CLICKS,
  AD_COVERAGE,
  PUBLISHER_REVENUE,
  RPM,
  RPMM,
  RPC,
  FOLLOWON_SEARCHES,
  INITIAL_SEARCHES
];

export const getLabel = (field) => {
  switch (field) {
    case DATE:
      return "Date";
    case COMPANY_NAME:
      return "Company Name";
    case CHANNEL:
      return "Sub ID (Channel)";
    case SEARCH_ENGINE:
      return "Search Engine";
    case TAG_TYPE:
      return "Tag Type";
    case LINK_PLATFORM:
      return "Platform";
    case GEO:
      return "GEO";
    case SERVER_TOTAL_SEARCHES:
      return "Server Total Searches";
    case TOTAL_SEARCHES:
      return "Total Searches";
    case MONETIZED_SEARCHES:
      return "Monetized Searches";
    case CLICKS:
      return "Clicks";
    case AD_COVERAGE:
      return "Ad Coverage";
    case PUBLISHER_REVENUE:
      return "Publisher Revenue";
    case RPM:
      return "RPM";
    case RPMM:
      return "Monetized RPM";
    case RPC:
      return "RPC";
    case FOLLOWON_SEARCHES:
      return "Follow-on Searches";
    case INITIAL_SEARCHES:
      return "Initial Searches";
    case CTR:
      return "CTR";
    default:
      return field;
  }
};

export const useDisplayColumnsList = (displayColumns, userId) => {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isValid, setIsValid] = useState(true); //form selection is valid
  const history = useHistory();
  const isMounted = useIsMounted();
  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction: () => {}
  });
  useEffect(() => {
    if (displayColumns.length > 0) {
      setSelectedColumns(displayColumns);
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [displayColumns]);

  const removeFromSelectedColumn = (column) => {
    handleFormTouched();
    const currentColumns = [...selectedColumns];
    return setSelectedColumns(removeValueFromArray(currentColumns, column));
  };

  const addToSelectedColumn = (column) => {
    handleFormTouched();
    const currentColumns = [...selectedColumns];
    return setSelectedColumns([...currentColumns, column]);
  };

  const saveDisplayColumns = async () => {
    setIsSaving(true);
    const dataToSend = {
      data: selectedColumns
    };
    try {
      const savedDisplayColumns = await User.postPublisherDisplayColumns(
        userId,
        dataToSend
      );
      if (savedDisplayColumns.success) {
        clearFormTouched();
        if (isMounted()) {
          setIsSaving(false);
          setIsEditing(true);
        }
        toastr.success("Success", "Display columns saved successfully");
      } else {
        throw new Error(
          savedDisplayColumns.error.message || "Unable to save display columns"
        );
      }
    } catch (error) {
      if (isMounted()) {
        setIsSaving(false);
      }
      console.trace(error.message);
      toastr.warning(
        "Oops!",
        error.message || "Unable to save display columns"
      );
    }
  };

  const validateDisplayColumns = () => {
    if (selectedColumns.length === 0) {
      toastr.warning("Oops!", "Please select requird values");
      return false;
    }
    if (
      !selectedColumns.includes(PUBLISHER_REVENUE) &&
      !selectedColumns.includes(MONETIZED_SEARCHES) &&
      !selectedColumns.includes(TOTAL_SEARCHES)
    ) {
      toastr.warning("Oops!", "Please select required values");
      return false;
    }
    return true;
  };

  const updateDisplayColumns = async () => {
    setIsSaving(true);
    const dataToSend = {
      data: selectedColumns
    };
    try {
      const savedDisplayColumns = await User.updatePublisherDisplayColumns(
        userId,
        dataToSend
      );
      if (savedDisplayColumns.success) {
        clearFormTouched();
        if (isMounted()) {
          setIsSaving(false);
        }
        toastr.success("Success", "Display columns updated successfully");
      } else {
        throw new Error(
          savedDisplayColumns.error.message ||
            "Unable to update display columns"
        );
      }
    } catch (error) {
      if (isMounted()) {
        setIsSaving(false);
      }
      console.trace(error.message);
      toastr.warning(
        "Oops!",
        error.message || "Unable to update display columns"
      );
    }
  };

  const submitSelectedColumns = () => {
    setIsValid(true);
    if (!validateDisplayColumns()) {
      setIsValid(false);
      return;
    }
    if (!isEditing) {
      saveDisplayColumns();
    } else {
      updateDisplayColumns();
    }
  };

  const handleCancel = () => {
    history.push("/usermanagement");
  };

  return {
    selectedColumns,
    columnsList,
    removeFromSelectedColumn,
    addToSelectedColumn,
    submitSelectedColumns,
    isSaving,
    formTouched,
    getLabel,
    handleCancel,
    isValid
  };
};
