import { useState, useCallback, useEffect } from "react";
import { useTextInput } from "../../../hooks/useTextInput";
import { useMultipleSelect } from "../../../hooks/useMultipleSelect";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";
import { Platform } from "../../../services/Platform";
import { SearchEngine } from "../../../services/SearchEngine";
import { TagType } from "../../../services/TagType";
import { Provider } from "../../../services/Provider";
import { Publisher } from "../../../services/Publisher";
import { Template } from "../../../services/Template";
import { findObject } from "../../../helpers";


export const useProviderLinksFilters = ({
  redirectedProviderId,
  setRedirectedProviderId
}) => {
  const [query, handleQueryChange, setQuery] = useTextInput({
    initialValue: ""
  });

  // value: all (both live and not live), pending (for not live) and live (for live)
  const [isLive, setIsLive] = useState({
    value: "all"
  });

  // value: linked (for linked), not_linked (for not linked) and all (for both linked and not linked)
  const [isLinked, setIsLinked] = useState({
    value: "all"
  });

  function changeToLive() {
    setIsLive({
      value: "live"
    });
  }

  function changeToNotLive() {
    setIsLive({
      value: "not_live"
    });
  }

  function clearIsLive() {
    setIsLive({
      value: "all"
    });
  }

  function changeToLinked() {
    setIsLinked({
      value: "linked"
    });
  }

  function changeToNotLinked() {
    setIsLinked({
      value: "not_linked"
    });
  }

  function clearIsLinked() {
    setIsLinked({
      value: "all"
    });
  }



  const {
    setData: setTypes,
    selectedData: selectedTypes,
    data: types,
    handleSelectedDataChange: handleTypeChange,
    removeSelectedDataFromList: removeTypeFromList,
    removeAll: removeAllTypes
  } = useMultipleSelect();

  const {
    setData: setTagTypes,
    selectedData: selectedTagTypes,
    data: tagTypes,
    handleSelectedDataChange: handleTagTypeChange,
    removeSelectedDataFromList: removeTagTypeFromList,
    removeAll: removeAllTagTypes
  } = useMultipleSelect();

  const {
    setData: setPlatforms,
    selectedData: selectedPlatforms,
    data: platforms,
    handleSelectedDataChange: handlePlatformChange,
    removeSelectedDataFromList: removePlatformFromList,
    removeAll: removeAllPlatforms
  } = useMultipleSelect();

  const {
    setData: setSearchEngines,
    selectedData: selectedSearchEngines,
    data: searchEngines,
    handleSelectedDataChange: handleSearchEngineChange,
    removeSelectedDataFromList: removeSearchEngineFromList,
    removeAll: removeAllSearchEngines
  } = useMultipleSelect();

  const {
    setData: setProviders,
    selectedData: selectedProviders,
    data: providers,
    handleSelectedDataChange: handleProviderChange,
    removeSelectedDataFromList: removeProviderFromList,
    removeAll: removeAllProviders
  } = useMultipleSelect();

  const {
    setData: setPublishers,
    selectedData: selectedPublishers,
    data: publishers,
    handleSelectedDataChange: handlePublisherChange,
    removeSelectedDataFromList: removePublisherFromList,
    removeAll: removeAllPublishers
  } = useMultipleSelect();


  const {
    setData: setTemplates,
    selectedData: selectedTemplate,
    data: templates,
    handleSelectedDataChange: handleTemplateChange,
    removeSelectedDataFromList: removeTemplatesFromList,
    removeAll: removeAllTemplates
  } = useMultipleSelect();

  const [filtersClearToggle, setFiltersClearToggle] = useState(false);

  function clearAllSelection() {
    setQuery("");
    removeAllTagTypes();
    removeAllPlatforms();
    removeAllSearchEngines();
    removeAllProviders();
    removeAllPublishers();
    removeAllTemplates();
    clearIsLive();
    clearIsLinked();
    removeAllTypes();
    setFiltersClearToggle(!filtersClearToggle);
  }

  let filterValues = {
    query,
    selectedTagTypes,
    selectedPlatforms,
    selectedSearchEngines,
    selectedProviders,
    selectedPublishers,
    selectedTemplate,
    selectedTypes,
    isLive,
    isLinked
  };

  let selectionAddActions = {
    handleQueryChange,
    handleTagTypeChange,
    handlePlatformChange,
    handleSearchEngineChange,
    handlePublisherChange,
    handleProviderChange,
    handleTemplateChange,
    handleTypeChange,
    changeToLive,
    changeToNotLive,
    clearIsLive,
    changeToLinked,
    changeToNotLinked,
    clearIsLinked
  };

  let dataLists = {
    tagTypes,
    platforms,
    searchEngines,
    providers,
    publishers,
    templates,
    types
  };

  const fetchPublishers = useCallback(() => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          for (let p of data) {
            publishers.push({ label: p.name, value: p.id });
          }
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProviders = useCallback(() => {
    Provider.fetchProviders({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.providers;
          const providers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            providers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setProviders(providers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPlatforms = useCallback(() => {
    Platform.fetchPlatforms({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.platforms;
          const platforms = [];
          for (let p of data) {
            platforms.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setPlatforms(platforms);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setPlatforms]);

  const fetchSearchEngines = useCallback(() => {
    SearchEngine.fetchSearchEngines({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.search_engines;
          const searchEngines = [];
          for (let p of data) {
            searchEngines.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setSearchEngines(searchEngines);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setSearchEngines]);

  const fetchTagTypes = useCallback(() => {
    TagType.fetchTagTypes({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.tag_types;
          const tagTypes = [];
          for (let p of data) {
            tagTypes.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setTagTypes(tagTypes);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setTagTypes]);

  const fetchTemplates = useCallback(() => {
    Template.fetchTemplates()
      .then((response) => {
        if (response.success) {
          const data = response.data.templates;
          const templates = [];
          for (let p of data) {
            templates.push({
              label: p.name,
              value: p.path
            });
          }
          setTemplates(templates);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setTemplates]);

  const fetchTypes = useCallback(() => {

      const TYPES = [
        {
          label: "REDIRECT",
          value: "redirect"
        },
        {
          label: "XML",
          value: "xml"
        },
        {
          label: "TEMPLATE",
          value: "template"
        }
      ];
    setTypes(TYPES)
  },[setTypes])

  useEffect(() => {
    fetchPlatforms();
    fetchSearchEngines();
    fetchTagTypes();
    fetchProviders();
    fetchPublishers();
    fetchTemplates();
    fetchTypes();
  }, [
    fetchTagTypes,
    fetchSearchEngines,
    fetchPlatforms,
    fetchProviders,
    fetchPublishers,
    fetchTemplates,
    fetchTypes
  ]);

  useEffect(() => {
    if (providers && providers.length > 0) {
      if (redirectedProviderId && findObject(providers, redirectedProviderId)) {
        handleProviderChange([redirectedProviderId]);
        setRedirectedProviderId(null);
        setFiltersClearToggle(!filtersClearToggle);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers]);

  return {
    clearAllSelection,
    filterValues,
    selectionAddActions,
    filtersClearToggle,
    dataLists,
    removeTagTypeFromList,
    removePlatformFromList,
    removeSearchEngineFromList,
    removeProviderFromList,
    removePublisherFromList,
    removeTemplatesFromList,
    removeTypeFromList
  };
};
