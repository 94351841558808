import Api from "../api";

class TagType {
  addTagType = async (data) => {
    const { body } = await Api.post("tagType", data);
    return body;
  };

  fetchTagType = async (id) => {
    const { body } = await Api.get(`tagType/${id}`);
    return body;
  };

  updateTagType = async (id, data, params = {}) => {
    const { body } = await Api.put(`tagType/${id}`, data, params);
    return body;
  };

  fetchTagTypes = async (
    params = { order_by: "id", order_direction: "ASC" }
  ) => {
    const { body } = await Api.get(`tagTypes`, null, params);
    return body;
  };

  deleteTagType = async (id, params = {}) => {
    const { body } = await Api.destroy(`tagType/${id}`, {}, params);
    return body;
  };
}

export default new TagType();
