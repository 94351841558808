import React from "react";
import StatCard from "../../../../components/StatCard/StatCard";
import PropTypes from "prop-types";
import {
  MONETIZED_SEARCHES,
  TOTAL_SEARCHES,
  PUBLISHER_REVENUE
} from "../../../../helpers/constant/filters";

/**
 *  Component to render simple Stats
 */

const Stats = ({ reportStats, fields, displayColumns }) => {
  let { pub_revenue, monetized_searches, total_searches } =
    reportStats || "No Info";
  return (
    <div className="flex-box  full-width-children dashboard-stats">
      {displayColumns.includes(MONETIZED_SEARCHES) && (
        <StatCard title={fields?.monetized_searches || "Monetized Searches"}>
          <span>{monetized_searches || "No Info"}</span>
        </StatCard>
      )}
      {displayColumns.includes(PUBLISHER_REVENUE) && (
        <StatCard title={fields?.[PUBLISHER_REVENUE] || "Net Revenue"}>
          <span>
            {pub_revenue ? (+pub_revenue).toFixed(2) : "No Net Revenue"}
          </span>
        </StatCard>
      )}
      {displayColumns.includes(TOTAL_SEARCHES) && (
        <StatCard title={fields?.total_searches || "Total Searches"}>
          <span>{total_searches || "No Search Info"}</span>
        </StatCard>
      )}
    </div>
  );
};

export default Stats;

Stats.propTypes = {
  reportStats: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired
};
