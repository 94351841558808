import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

const SelectMenu = ({
  error,
  name,
  selected,
  handleChange,
  label,
  data,
  placeholder = "None",
  showValueInLabel = true,
  withDotsForStatus = false
}) => {
  let enabledMenuClassName, disabledMenuClassName;

  enabledMenuClassName = withDotsForStatus
    ? "select-value--dot--enabled"
    : "select-value--enabled";
  disabledMenuClassName = withDotsForStatus
    ? "select-value--dot--disabled"
    : "select-value--disabled";

  return (
    <FormControl variant="outlined" className="form-control">
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={selected}
        onChange={handleChange}
        label={label}
        name={name}
        error={error}
        defaultValue=""
      >
        <MenuItem value="" className={enabledMenuClassName}>
          <em>{placeholder}</em>
        </MenuItem>
        {data &&
          data.map((d) => (
            <MenuItem
              key={d.value}
              value={d.value}
              className={`${
                d.disabled ? disabledMenuClassName : enabledMenuClassName
              }`}
            >
              {showValueInLabel ? `${d.value} - ${d.label}` : `${d.label}`}
            </MenuItem>
          ))}
      </Select>
      {error && (
        <FormHelperText className="red-text">
          Please select a value.
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectMenu;
