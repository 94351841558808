import { useState, useEffect, useRef } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { textFieldValid } from "../../../helpers/validation";
import { Provider } from "../../../services/Provider";
import axios from "axios";

export const useFetchProviderLinks = ({ filterValues, filtersClearToggle }) => {
  const [providerLinks, setProviderLinks] = useState([]);
  const sourceRef = useRef(null);
  const [loadingProviderLinks, setLoadingProviderLinks] = useState({
    isLoading: false,
    error: false
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [total, setTotal] = useState(0);

  function getParams() {
    let params = {};
    const {
      query,
      selectedTagTypes,
      selectedPlatforms,
      selectedProviders,
      selectedSearchEngines,
      selectedPublishers,
      selectedTypes,
      isLive,
      isLinked
    } = filterValues;
    if (textFieldValid(query)) {
      params["link"] = query.trim();
    }
    if (orderBy && orderBy !== "isLinked" && orderBy !== "isLive") {
      params["order_by"] = orderBy;
    }
    if (selectedTagTypes?.length > 0) {
      let selection = [];
      for (let i in selectedTagTypes) {
        selection.push(selectedTagTypes[i]);
      }
      params["tag_type_id"] = selection;
    }

    if (selectedPublishers?.length > 0) {
      let selection = [];
      for (let i in selectedPublishers) {
        selection.push(selectedPublishers[i]);
      }
      params["publisher_id"] = selection;
    }

    if (selectedTypes?.length > 0) {
      let selection = [];
      for (let i in selectedTypes) {
        selection.push(selectedTypes[i]);
      }
      params["type"] = selection;
    }

    if (selectedPlatforms?.length > 0) {
      let selection = [];
      for (let i in selectedPlatforms) {
        selection.push(selectedPlatforms[i]);
      }
      params["platform_id"] = selection;
    }

    if (selectedSearchEngines?.length > 0) {
      let selection = [];
      for (let i in selectedSearchEngines) {
        selection.push(selectedSearchEngines[i]);
      }
      params["search_engine_id"] = selection;
    }

    if (selectedProviders?.length > 0) {
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["provider_id"] = selection;
    }

    if (isLive !== "all") {
      params["isLive"] = isLive.value;
    }

    if (isLinked !== "all") {
      params["isLinked"] = isLinked.value;
    }

    params["order_direction"] = orderDirection;

    params["page"] = page;
    params["size"] = size;
    return params;
  }

  const isMounted = useIsMounted();

  function formatProviderLinks(providerLinks) {
    return providerLinks.map((providerLink) => {
      return {
        ...providerLink,
        search_engine_id: `${providerLink?.search_engine?.id || ""} - ${
          providerLink?.search_engine?.name || ""
        }`,
        platform_id: `${providerLink?.platform?.id || ""} - ${
          providerLink?.platform?.name || ""
        }`,
        tag_type_id: `${providerLink?.tag_type?.id || ""} - ${
          providerLink?.tag_type?.name || ""
        }`,
        provider_id: `${providerLink?.provider?.id || ""} - ${
          providerLink?.provider?.name || ""
        }`
      };
    });
  }

  async function getProviderLinks() {
    try {
      setLoadingProviderLinks({
        ...loadingProviderLinks,
        isLoading: true,
        error: false
      });
      sourceRef.current = axios.CancelToken.source();
      const providerLinks = await Provider.fetchProviderLinksList(
        getParams(),
        sourceRef.current.token
      );

      if (providerLinks.success && isMounted()) {
        const fetchedProviderLinks =
          providerLinks.data.providerLinks.providerLinks || [];
        setProviderLinks(formatProviderLinks(fetchedProviderLinks));
        setTotal(providerLinks.data.providerLinks.total);
        setLoadingProviderLinks({
          ...loadingProviderLinks,
          isLoading: false,
          error: false
        });
      } else {
        throw new Error(providerLinks?.error?.message);
      }
    } catch (error) {
      if (isMounted()) {
        setProviderLinks([]);
        setLoadingProviderLinks({
          ...loadingProviderLinks,
          isLoading: false,
          error: true
        });
      }
      console.trace(error?.message || error);
    }
  }

  function updateProviderLinks(id, key, value) {
    let tempProviderLinks = [...providerLinks];
    let changedIndex = tempProviderLinks.findIndex(
      (provider) => provider.id === id
    );
    tempProviderLinks[changedIndex][key] = value;
    setProviderLinks(tempProviderLinks);
  }

  useEffect(() => {
    if (loadingProviderLinks.isLoading) {
      sourceRef.current.cancel();
      sourceRef.current = null;
    }
    getProviderLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, orderBy, orderDirection, filtersClearToggle]);

  return {
    providerLinks,
    loadingProviderLinks,
    page,
    size,
    orderBy,
    orderDirection,
    total,
    getProviderLinks,
    setPage,
    setSize,
    setOrderBy,
    setOrderDirection,
    updateProviderLinks
  };
};
