import React, { useState } from "react";
import SimpleDateRangePicker from "../../../components/SimpleDateRangePicker/SimpleDateRangePicker";
import { toastr } from "react-redux-toastr";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ProviderApiService } from "../../../services/providerApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from "moment";

const RunApi = ({ selected, fetchApiDetails }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleToDate = (date) => {
    if (!date) {
      setToDate(null);
      return;
    }
    if (!fromDate) {
      setToDate(date);
    } else {
      if (moment(date).isAfter(fromDate) || moment(date).isSame(fromDate)) {
        setToDate(date);
      } else setToDate(fromDate);
    }
  };

  const handleFromDate = (date) => {
    if (!date) {
      setFromDate(null);
      return;
    }
    if (!toDate) {
      setFromDate(date);
    } else {
      if (moment(date).isBefore(toDate) || moment(date).isSame(toDate)) {
        setFromDate(date);
      } else {
        setToDate(date);
        setFromDate(date);
      }
    }
  };

  const handleDateChange = (fromDate, toDate) => {
    setError(false);
    setErrorMessage("");
    handleFromDate(fromDate);
    handleToDate(toDate);
  };

  const validateForm = () => {
    if (!fromDate || !toDate) {
      setError(true);
      setErrorMessage("Please select date range");
      return false;
    }
    if (selected.length <= 0) {
      if (!fromDate || !toDate) {
        setError(true);
        setErrorMessage("Please select date range");
      } else {
        setError(true);
        setErrorMessage("You have not selected any Rows");
      }
      return false;
    }

    return true;
  };

  const handleRunApi = async (event) => {
    setDisabled(true);
    event.preventDefault();
    try {
      if (validateForm()) {
        setError(false);
        setErrorMessage("");
        const response = await ProviderApiService.applyCroneJob({
          selected,
          fromDate,
          toDate
        });
        if (!response.success) {
          throw new Error("Something went wrong");
        } else {
          fetchApiDetails();
          toastr.success("Success", "Run Api Successfully");
        }
      }
    } catch (error) {
      console.log("====" + error + "====");
      toastr.error("Error", error.message);
    } finally {
      setDisabled(false);
    }
  };
  const handleLoading = disabled ? (
    <CircularProgress size={24} color="white" />
  ) : (
    <ExitToAppIcon />
  );
  return (
    <div className="provider-run-api" style={{ margin: "20px 0px" }}>
      <div
        className="provider-run-api__"
        style={{ display: "flex", "column-gap": "7px" }}
      >
        <SimpleDateRangePicker
          startDate={fromDate ? fromDate : null}
          endDate={toDate ? toDate : null}
          handleDateChange={handleDateChange}
        />

        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={disabled}
          startIcon={handleLoading}
          onClick={handleRunApi}
        >
          Run API
        </Button>
      </div>
      {error && (
        <FormHelperText color="secondary" style={{ color: "red" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
};

export default RunApi;
