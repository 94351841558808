import React from "react";
import InfoToolTip from "../../../../components/InfoToolTip/InfoToolTip";
import AssessmentIcon from "@material-ui/icons/Assessment";
const SearchStatsBtn = ({
  targeting_id,
  redirectToSearchLogsPage,
  loadingSearchStats,
  searchCount
}) => {
  if (!targeting_id || loadingSearchStats.error) {
    return <></>;
  }

  if (loadingSearchStats.loading) {
    return "...";
  }

  const InfoBox = (
    <div className="default-padding">
      <pre>{searchCount} searches in last 24 hours</pre>
    </div>
  );

  return (
    <InfoToolTip
      title={InfoBox}
      className="targeting-option__info"
      style={{ cursor: "pointer" }}
      onClick={() => redirectToSearchLogsPage(targeting_id)}
    >
      <div className={searchCount !== 0 ? "blue-icon" : "gray-icon"}>
        <AssessmentIcon />
      </div>
    </InfoToolTip>
  );
};

export default SearchStatsBtn;
