import { useState } from "react";

/** Custom hook to switch between table and form.
 *
 *  Made to be used in the PlatformForm and PlatformTable components,Search Engine Form and Search Engine Table components
 *  as well as Tag Type Form and Tag Type Table components.
 *
 */
export const useFormAndTableSwitch = () => {
  const [currentPage, setCurrentPage] = useState({
    page: "table",
    pageData: {
      id: null
    }
  });

  const openAddForm = () => {
    setCurrentPage({
      page: "form",
      pageData: {
        id: null
      }
    });
  };

  const openEditForm = (id) => {
    setCurrentPage({
      page: "form",
      pageData: {
        id: id
      }
    });
  };

  const openTable = () => {
    setCurrentPage({
      page: "table",
      pageData: {
        id: null
      }
    });
  };

  return {
    currentPage,
    openAddForm,
    openEditForm,
    openTable
  };
};
