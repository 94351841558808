import PublisherActionTypes from "./publisher.types";

export const addError = () => ({
  type: PublisherActionTypes.ADD_ERROR_MESSAGE,
  payload: "Sorry, Facing some errors."
});

export const fetchPublisherStart = () => ({
  type: PublisherActionTypes.FETCH_PUBLISHER_START
});

export const fetchPublisherSuccess = () => ({
  type: PublisherActionTypes.FETCH_PUBLISHER_SUCCESS
});

export const fetchPublisherFailure = (error) => ({
  type: PublisherActionTypes.FETCH_PUBLISHER_FAILURE,
  payload: error
});

export const fetchPublishersListStart = () => ({
  type: PublisherActionTypes.FETCH_PUBLISHERS_LIST_START
});

export const fetchPublishersListSuccess = () => ({
  type: PublisherActionTypes.FETCH_PUBLISHERS_LIST_SUCCESS
});

export const fetchPublishersListFailure = (error) => ({
  type: PublisherActionTypes.FETCH_PUBLISHERS_LIST_FAILURE
});

export const updatePublishersList = (publishers) => ({
  type: PublisherActionTypes.UPDATE_PUBLISHERS_LIST,
  payload: publishers
});
