import React from "react";
import { useSelector } from "react-redux";
import { getSettingsObject } from "../../../reducers/setting/setting.selector";
import WarningBox from "./WarningBox";
import InfoBox from "./InfoBox";
import SettingsIcon from "@material-ui/icons/Settings";

const Infos = () => {
  const settingsObject = useSelector(getSettingsObject);
  const settingsIcon = <SettingsIcon fontSize="inherit" color="primary" />;

  return (
    <div className="infos">
      <div className="infos__default-settings">
        {Object.keys(settingsObject).includes("default_domain") &&
        Object.keys(settingsObject).includes("system_fallback") ? (
          <>
            <InfoBox header="Default Values" icon={settingsIcon}>
              <p>Default Domain : {settingsObject.default_domain}</p>
              <p>S2S Domain URL : {settingsObject.redirection_domain}</p>
              <p>System Fallback URL : {settingsObject.system_fallback}</p>
            </InfoBox>
          </>
        ) : (
          <WarningBox />
        )}
      </div>
    </div>
  );
};

export default Infos;
