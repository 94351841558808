/**
 * Component that maps form values from redux and returns it for use in rule section
 */
export const useGetFormValues = ({ targetingRule, targetingRuleError }) => {
  let dailyCap = targetingRule?.daily_cap;
  let comment = targetingRule?.comment || "";
  let dailyFrequency = "";
  if (targetingRule) {
    if (
      targetingRule?.daily_frequency !== null ||
      targetingRule?.daily_frequency !== undefined
    ) {
      dailyFrequency = targetingRule?.daily_frequency;
    }
  }

  let disabled = targetingRule?.disabled || false;
  let trafficOne = targetingRule?.provider_details?.[0]?.traffic || "";
  let trafficTwo = targetingRule?.provider_details?.[1]?.traffic || 0;

  let providerOne = targetingRule?.provider_details?.[0]?.provider_id || "";
  let providerTwo = targetingRule?.provider_details?.[1]?.provider_id || "";
  let providerOneLink =
    targetingRule?.provider_details?.[0]?.provider_link || "";
  let providerTwoLink =
    targetingRule?.provider_details?.[1]?.provider_link || "";
  let targeting_id = targetingRule?.id || "";

  //defining form error values
  let dailyCapError = targetingRuleError?.dailyCap || false;
  let trafficOneError = targetingRuleError?.trafficOne || false;
  let trafficTwoError = targetingRuleError?.trafficTwo || false;
  let providerOneError = targetingRuleError?.providerOne || false;
  let providerTwoError = targetingRuleError?.providerTwo || false;
  let providerOneLinkError = targetingRuleError?.providerOneLink || false;
  let providerTwoLinkError = targetingRuleError?.providerTwoLink || false;
  let dailyFrequencyError = targetingRuleError?.dailyFrequency || false;

  const formValues = {
    dailyCap,
    comment,
    dailyFrequency,
    disabled,
    trafficOne,
    trafficTwo,
    providerOne,
    providerTwo,
    providerOneLink,
    providerTwoLink,
    targeting_id
  };

  const formErrors = {
    dailyCapError,
    trafficOneError,
    trafficTwoError,
    providerOneError,
    providerTwoError,
    providerOneLinkError,
    providerTwoLinkError,
    dailyFrequencyError
  };

  return {
    formValues,
    formErrors
  };
};
