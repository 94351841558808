import Api from "../api";

class Logs {
  fetchLogs = async (params = {}) => {
    const { body } = await Api.get(`logInfo`, null, params);
    return body;
  };

  downloadLogs = async (params = {}) => {
    const { body, status } = await Api.get(
      `logInfo/download/csv`,
      "blob",
      params
    );
    return { data: body, status };
  };
}

export default new Logs();
