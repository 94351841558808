import React, { useState, useEffect, useRef, useCallback } from "react";
import Table from "../../components/Table/Table";
import { Publisher } from "../../services/Publisher";
import { Report } from "../../services/Report";
import {
  fetchPublishersListStart,
  fetchPublishersListSuccess,
  fetchPublishersListFailure,
  updatePublishersList
} from "../../reducers/publisher/publisher.action";
import { useSelector, useDispatch } from "react-redux";
import { getPublishers } from "../../reducers/publisher/publisher.selector";
import { useHistory } from "react-router-dom";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import FilterBox from "../../components/FilterBox/FilterBox";
import BulkActionBox from "../../components/BulkActionBox/BulkActionBox";
import { toastr } from "react-redux-toastr";
import { textFieldValid } from "../../helpers/validation";
import SimpleAccordion from "../../components/Accordion/Accordion";
import WarningIcon from "@material-ui/icons/Warning";
import TabMenu from "../../components/TabMenu/TabMenu";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import ConfirmBoxWithForm from "../../components/ConfirmBox/ConfirmBoxWithForm";
import moment from "moment";
import { capitalizeFirstLetter } from "../../helpers/index";

const Publishers = () => {
  const publishers = useSelector(getPublishers);
  //table headers, same values/names that we get from the backend api.
  const headers = [
    { title: "ID", value: "id" },
    { title: "Name", value: "name" },
    { title: "Details", value: "details" },
    { title: "Status", value: "status" }
  ];
  const headersForArchive = [
    { title: "ID", value: "id" },
    { title: "Name", value: "name" },
    { title: "Details", value: "details" },
    { title: "Archived At (UTC)", value: "updatedAt" }
  ];
  //selection index to choose between Archived and Normal Publishers List
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] =
    useState(0);
  const sections = [
    {
      name: "Publishers"
    },
    {
      name: "Archived Publishers"
    }
  ];
  let choiceOfWord =
    currentSelectedSectionIndex === 0 ? "archive" : "delete forever";
  //publisher id to change through modal action
  const [changeID, setChangeID] = useState(null);

  //table rows selected
  const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const history = useHistory();

  //state for modal for deleting, bulk delete , status toggle, and restore
  const [open, setOpen] = useState(false);
  const [bulkDeleteOpen, setBulkDeleteOpen] = useState(false);
  const [statusToggleOpen, setStatusToggleOpen] = useState(false);
  const [unArchiveOpen, setUnArchiveOpen] = useState(false);
  const [bulkRestoreOpen, setBulkRestoreOpen] = useState(false);
  const [bulkPermanentDeleteOpen, setBulkPermanentDeleteOpen] = useState(false);
  const [deleteRevenue, setDeleteRevenue] = useState(false);

  //state for filter box that will be used as params
  const [searchValue, setSearchValue] = useState("");
  const [active, setActive] = useState(false);
  const [inActive, setInActive] = useState(false);
  //state for pagination that will be used as params
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC
  const [total, setTotal] = useState(0);
  const [archived, setArchived] = useState(false);
  const [loading, setLoading] = useState(false);
  const prevPage = useRef(1); //!!IMP : SET IT SAME AS DEFAULT VALUE FOR PAGE STATE

  /*archived and normal data selection change handler */
  const handleSelectionIndexChange = (index) => {
    //setting Page to 1
    setPage(1);
    setSelected([]);
    setSearchValue("");
    setActive(false);
    setInActive(false);
    setCurrentSelectedSectionIndex(index);
    if (index === 0) {
      setArchived(false);
    } else {
      setArchived(true);
    }
  };

  const getRequestParams = useCallback(() => {
    let params = {};

    if (textFieldValid(searchValue)) {
      params["q"] = searchValue.trim();
    }
    if (archived) {
      params["archived"] = true;
    }

    if (active) {
      params["status"] = "active";
    }

    if (inActive) {
      params["status"] = "inactive";
    }

    params["page"] = page;
    params["size"] = size;
    params["order_by"] = orderBy;
    params["order_direction"] = orderDirection;

    return params;
  }, [
    archived,
    active,
    inActive,
    page,
    size,
    orderBy,
    orderDirection,
    searchValue
  ]);

  const fetchPublishers = useCallback(
    (params = {}) => {
      setLoading(true);
      stableDispatch(fetchPublishersListStart());
      Publisher.fetchPublishers(params)
        .then((response) => {
          if (response.success) {
            let publishers = response.data.publishers.map((publisher) => {
              publisher.updatedAt = moment(publisher.updatedAt)
                .utc()
                .format("lll");
              return publisher;
            });
            stableDispatch(updatePublishersList(publishers));
            setTotal(response.data.total);
            stableDispatch(fetchPublishersListSuccess());
            setLoading(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          stableDispatch(fetchPublishersListFailure(error.message));
          setLoading(false);
        });
    },
    [stableDispatch]
  );
  //fetching publishers with params when filter box's active and inactive state changes
  useEffect(() => {
    fetchPublishers(getRequestParams());
    prevPage.current === page && setSelected([]); //page changed don't make the selected rows empty
    if (page !== prevPage.current) {
      prevPage.current = page;
    }
  }, [
    active,
    inActive,
    size,
    orderBy,
    page,
    orderDirection,
    archived,
    getRequestParams,
    fetchPublishers
  ]);

  const handleModalClose = () => {
    setChangeID(null);
    setOpen(false);
    setBulkDeleteOpen(false);
    setStatusToggleOpen(false);
    setUnArchiveOpen(false);
    setBulkRestoreOpen(false);
    setBulkPermanentDeleteOpen(false);
    setDeleteRevenue(false);
  };

  const deletePublisher = () => {
    Publisher.deletePublisher(changeID)
      .then((response) => {
        if (response.success) {
          fetchPublishers(getRequestParams());
          toastr.success("Success", "Publisher archived successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to archive at the moment");
      });
    setChangeID(null);
    setOpen(false);
  };

  //functions to handle different actions in the list
  const handleEditAction = (id) => history.push(`/publishers/edit/${id}`);
  const handleDeleteAction = (id) => {
    setChangeID(id);
    setOpen(true);
  };

  const handleBulkRestoreAction = () => {
    if (selected.length > 0) {
      setBulkRestoreOpen(true);
    } else {
      toastr.warning("Oops", "No Publisher selected");
    }
  };

  const handleBulkDeleteAction = () => {
    if (selected.length > 0) {
      setBulkDeleteOpen(true);
    } else {
      toastr.warning("Oops", "No Publisher selected");
    }
  };

  const handleBulkPermanentDelete = () => {
    if (selected.length > 0) {
      setBulkPermanentDeleteOpen(true);
    } else {
      toastr.warning("Oops", "No Publisher selected");
    }
  };

  const handleDeleteRevenue = () => {
    if (selected.length > 0) {
      setDeleteRevenue(true);
    } else {
      toastr.warning("Oops", "No Publisher selected");
    }
  };

  const bulkDelete = () => {
    let data;
    data = {
      ids: selected
    };
    Publisher.bulkDeletePublishers(data)
      .then((response) => {
        if (response.success) {
          fetchPublishers(getRequestParams());
          toastr.success("Success", "Publishers archived");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to archive publishers");
      });
    setBulkDeleteOpen(false);
    setSelected([]);
  };

  const bulkPermanentDelete = () => {
    let data;
    data = {
      ids: selected
    };
    Publisher.bulkPermanentDeletePublishers(data)
      .then((response) => {
        if (response.success) {
          fetchPublishers(getRequestParams());
          toastr.success("Success", "Publishers permanently deleted!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to permanently delete publishers!");
      });
    setSelected([]);
  };

  const bulkChangeStatus = (type) => {
    if (selected.length > 0) {
      let data, changeType;
      data = {
        ids: selected
      };
      if (type === "activate") {
        changeType = "active";
      }
      if (type === "de-activate") {
        changeType = "inactive";
      }
      Publisher.bulkUpdatePublishers(changeType, data)
        .then((response) => {
          if (response.success) {
            fetchPublishers(getRequestParams());
            toastr.success("Success", "Status updated");
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          toastr.error("Oops!! Not able to update publishers status.");
        });
      setSelected([]);
    } else {
      toastr.warning("Oops", "Nothing Selected");
    }
  };

  const bulkRestore = () => {
    let data;
    data = {
      ids: selected
    };
    Publisher.restorePublishers(data)
      .then((response) => {
        if (response.success) {
          fetchPublishers(getRequestParams());
          toastr.success("Success", "Publishers restored");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!! Not able to restore publishers");
      });
    setSelected([]);
  };

  const icon = <AssignmentIcon fontSize="inherit" color="primary" />;

  const handleRedirectTargetings = (id) => {
    history.push({
      pathname: "/target",
      state: {
        publisher: id
      }
    });
  };

  const unArchivePublisher = () => {
    let params = {
      archive: false
    };
    let dataToSend = {};
    Publisher.updatePublisher(changeID, dataToSend, params)
      .then((response) => {
        if (response.success) {
          fetchPublishers(getRequestParams());
          toastr.success("Success", "Publisher un-archived!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to un-archive the publisher");
      });
  };

  const handleRestoreAction = (id) => {
    setChangeID(id);
    setUnArchiveOpen(true);
  };

  const handleDeleteForever = () => {
    let params = {
      permanent: true
    };
    Publisher.deletePublisher(changeID, params)
      .then((response) => {
        if (response.success) {
          fetchPublishers(getRequestParams());
          toastr.success("Success", "Publisher removed permanently!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to remove at the moment");
      });
    setChangeID(null);
    setOpen(false);
  };

  const handleDetailsAction = (id) => {
    window.open(`/publishers/details/${id}`, "_blank");
  };

  //action list with icon and corresponding action creator
  const actions = [
    {
      name: "edit",
      action: handleEditAction,
      icon: "editIcon"
    },
    {
      name: "delete",
      action: handleDeleteAction,
      icon: "deleteIcon"
    },
    {
      name: "openTargetings",
      action: handleRedirectTargetings,
      icon: "TargetinglinkIcon"
    },
    {
      name: "disabledOpenTargetings",
      action: () => {
        toastr.info("Sorry!", "No associated targetings");
      },
      icon: "TargetinglinkIconDisabled"
    },
    {
      name: "details",
      action: handleDetailsAction,
      icon: "details"
    }
  ];

  //action list for archived table
  const actionsForArchive = [
    // {
    //   name: "deleteForever",
    //   action: handleDeleteAction,
    //   icon: "deleteForever"
    // },
    {
      name: "restore",
      action: handleRestoreAction,
      icon: "restore"
    }
  ];

  const publisherHasTargetings = (id) => {
    let publisherIndex = publishers.findIndex(
      (publisher) => publisher.id === id
    );
    let noOfTargetings = parseInt(publishers[publisherIndex].no_of_targetings);
    if (noOfTargetings > 0) {
      return true;
    }
    return false;
  };

  const deletePublisherModal = (
    <SimpleModal handleClose={handleModalClose}>
      <div className="delete-confirm-box">
        {open && changeID && publisherHasTargetings(changeID) ? (
          <div className="delete-confirm-box__warning">
            <WarningIcon style={{ fontSize: 30 }} className="blink" />
            <Typography component="h3">
              This publisher has associated targetings. Would you like to go
              ahead and {choiceOfWord}?
            </Typography>
          </div>
        ) : (
          <Typography component="h3">
            Are you sure you want to {choiceOfWord}?
          </Typography>
        )}
        <div className="delete-confirm-box__buttons">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              //current Selection is 0 i.e normal data, archive
              if (currentSelectedSectionIndex === 0) deletePublisher();
              else handleDeleteForever();
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleModalClose()}
          >
            No
          </Button>
        </div>
      </div>
    </SimpleModal>
  );

  const completlyDeleteRevenue = async (data = {}) => {
    try {
      const postData = {
        type: "Publisher",
        from: data.fromDate,
        to: data.toDate,
        ids: [...selected],
        advertisers: selected.length <= 1 ? data.selectedProviders : null
      };
      const deleted = await Report.removeReportsDataByPubAdv(postData, {});
      if (deleted.success) {
        handleModalClose();
        toastr.success(
          "Success",
          `Successfully deleted ${deleted.data.deleted} records`
        );
      } else {
        throw new Error("Something bad happend in API");
      }
    } catch (error) {
      console.trace(error.message);
      toastr.error("Oops!", "Not able to remove at the moment");
    }
  };

  const bulkDeletePublisherModal = (
    <ConfirmBox
      message={`Are you sure want to archive ${selected.length} items?`}
      confirmAction={() => {
        bulkDelete();
        handleModalClose();
      }}
      denyAction={handleModalClose}
    ></ConfirmBox>
  );

  const bulkPermanentDeletePublisherModal = (
    <ConfirmBox
      message={`Are you sure want to permanently delete ${selected.length} items?`}
      confirmAction={() => {
        bulkPermanentDelete();
        handleModalClose();
      }}
      denyAction={handleModalClose}
    ></ConfirmBox>
  );

  const deleteRevenueModel = (
    <ConfirmBoxWithForm
      message={`Are you sure want to permanently delete revenue reports of selected items?`}
      confirmAction={completlyDeleteRevenue}
      denyAction={handleModalClose}
      type={"publisher"}
      selected={selected}
    ></ConfirmBoxWithForm>
  );

  const bulkRestorePublisherModal = (
    <ConfirmBox
      message={`Are you sure want to restore ${selected.length} items?`}
      confirmAction={() => {
        bulkRestore();
        handleModalClose();
      }}
      denyAction={handleModalClose}
    ></ConfirmBox>
  );

  const statusToggleModal = (
    <ConfirmBox
      message={`Publisher has associated targetings. Would you like to de-activate?`}
      confirmAction={() => {
        handleModalClose();
        changePublisherStatus(changeID, "active");
      }}
      denyAction={handleModalClose}
    ></ConfirmBox>
  );

  const unArchiveModal = (
    <ConfirmBox
      message={`Are you sure you want to un-archive this publisher?`}
      denyAction={handleModalClose}
      confirmAction={() => {
        unArchivePublisher();
        handleModalClose();
      }}
    ></ConfirmBox>
  );

  //update publishers value when change made in table rows
  //on redux state, for UI purpose
  const updatePublishersValue = (id, key, value) => {
    let tempPublishers = [...publishers];
    let changedIndex = tempPublishers.findIndex(
      (publisher) => publisher.id === id
    );
    tempPublishers[changedIndex][key] = value;
    dispatch(updatePublishersList(tempPublishers));
  };

  const changePublisherStatus = (id, currentStatus) => {
    let dataToSend = {};
    currentStatus === "active"
      ? (dataToSend.status = "inactive")
      : (dataToSend.status = "active");
    Publisher.updatePublisher(id, dataToSend)
      .then((response) => {
        if (response.success) {
          updatePublishersValue(id, "status", dataToSend.status);
          toastr.success("Success", "Status Updated");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to update");
      });
  };

  const handleStatusToggle = (id, currentStatus) => {
    if (publisherHasTargetings(id) && currentStatus === "active") {
      setChangeID(id);
      setStatusToggleOpen(true);
    } else {
      changePublisherStatus(id, currentStatus);
    }
  };

  const handleCellValueUpdate = (data, key, value) => {
    let id = data.id;
    let dataToSend = {};
    dataToSend[key] = value;
    Publisher.updatePublisher(id, dataToSend)
      .then((response) => {
        if (response.success) {
          updatePublishersValue(id, key, value);
          toastr.success("Success", `${capitalizeFirstLetter(key)} updated.`);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
      });
  };

  return (
    <div className="publishers-container">
      <div className="publishers-container__header">
        <ContentHeader icon={icon} title="Publisher List" />
      </div>
      <div className="publishers-container__addBtn">
        <Button
          onClick={() => history.push("/publishers/add")}
          variant="contained"
          size="medium"
          color="primary"
          startIcon={<AddBoxIcon />}
        >
          Add
        </Button>
      </div>
      <div className="publishers-container__filter-box">
        <SimpleAccordion header="Filters" key="publisher-filter-box">
          <FilterBox
            active={active}
            inActive={inActive}
            setSearchValue={setSearchValue}
            setActive={setActive}
            setInActive={setInActive}
            archive={currentSelectedSectionIndex === 1}
            setSelected={setSelected}
          />
        </SimpleAccordion>
        <SimpleAccordion header="Bulk Actions" key="publisher-bulk-action">
          <BulkActionBox
            handleBulkDeleteAction={handleBulkDeleteAction}
            handleStatusChange={bulkChangeStatus}
            handleBulkRestore={handleBulkRestoreAction}
            handleBulkPermanentDelete={handleBulkPermanentDelete}
            handleDeleteRevenue={handleDeleteRevenue}
            archive={currentSelectedSectionIndex === 1}
            type="publisher"
          />
        </SimpleAccordion>
      </div>
      <div className="table-selection-tab">
        <TabMenu
          sections={sections}
          setCurrentSelectedSectionIndex={handleSelectionIndexChange}
          currentSelectedSectionIndex={currentSelectedSectionIndex}
        />
      </div>
      <div className="publishers-container__table">
        {publishers && (
          <Table
            headers={
              currentSelectedSectionIndex === 0 ? headers : headersForArchive
            }
            rowValues={publishers}
            actions={
              currentSelectedSectionIndex === 0 ? actions : actionsForArchive
            }
            selected={selected}
            setSelected={setSelected}
            tableType={
              currentSelectedSectionIndex === 0
                ? "publisher"
                : "publisher--archive"
            }
            handleStatusToggle={handleStatusToggle}
            handleCellValueUpdate={handleCellValueUpdate}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            total={total}
            isLoading={loading}
            rowsPerPageOptions={[10, 20, 50, 100, 150, 200]}
          />
        )}
        {open && deletePublisherModal}
        {bulkDeleteOpen && bulkDeletePublisherModal}
        {statusToggleOpen && statusToggleModal}
        {unArchiveOpen && unArchiveModal}
        {bulkRestoreOpen && bulkRestorePublisherModal}
        {bulkPermanentDeleteOpen && bulkPermanentDeletePublisherModal}
        {deleteRevenue && deleteRevenueModel}
      </div>
    </div>
  );
};

export default Publishers;
