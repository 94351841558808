import React, { useState, useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import { ProviderApiService } from "../../../services/providerApi";
import Form from "./Form";

const FormRender = (props) => {
  const { provider_id, type } = props;
  const history = useHistory();
  const [formValues, setFormValues] = useState([
    {
      api_key: "",
      api_url: "",
      is_active: false,
      advertiser_id: provider_id,
      errors: {
        api_key: null,
        api_url: null
      }
    }
  ]);

  const prevIsValid = () => {
    const someEmpty = formValues.some(
      (item) => item.api_key === "" || item.api_url === ""
    );
    if (someEmpty) {
      formValues.forEach((item, index) => {
        const allPrev = [...formValues];

        if (formValues[index].api_key === "") {
          allPrev[index].errors.api_key = "API key is required";
        }
        if (formValues[index].api_url === "") {
          allPrev[index].errors.api_url = "API url is required";
        }
        setFormValues(allPrev);
      });
    }
    return !someEmpty;
  };

  const addAnotherApiDetails = (event) => {
    event.preventDefault();
    const newInputState = {
      api_key: "",
      api_url: "",
      is_active: true,
      errors: {
        api_key: null,
        api_url: null
      }
    };

    if (prevIsValid()) {
      setFormValues((prev) => [...prev, newInputState]);
    }
  };

  const removeFormFields = (index, e) => {
    e.preventDefault();
    setFormValues((prev) => prev.filter((item) => item !== prev[index]));
  };

  const submitData = async (event) => {
    try {
      event.preventDefault();
      if (prevIsValid()) {
        const formData = formValues.map(function (em) {
          return {
            api_key: em.api_key.trim(),
            api_url: em.api_url.length > 0 ? em.api_url.trim() : null,
            is_active: em.is_active
          };
        });
        const response = await ProviderApiService.addProviderCredentials(
          formData,
          provider_id
        );
        if (response.success) {
          toastr.success("Success", "API credentials added successfully!");
          history.push(`/advertisers`);
        } else {
          throw new Error(response?.error?.message);
        }
      }
    } catch (error) {
      toastr.error("Opps", error.message || error);
    }
  };

  const getProviderCredentials = useCallback(async () => {
    try {
      const response = await ProviderApiService.getProviderCredentials(
        provider_id
      );
      if (
        response &&
        response.success &&
        response.data &&
        response?.data?.api_credentials
      ) {
        const data = response.data.api_credentials.map((em) => {
          return {
            ...em,
            api_url: em.api_url || "",
            api_key: em.api_key || "",
            errors: {
              api_key: null,
              api_url: null
            }
          };
        });
        setFormValues(data);
      }
    } catch (error) {
      toastr.error("Opps", error.message || error);
    }
  }, [provider_id]);

  let submitButton = (
    <Button
      color="primary"
      variant="contained"
      size="medium"
      onClick={(event) => submitData(event)}
      startIcon={<CheckIcon />}
      type="submit"
    >
      {" "}
      Save
    </Button>
  );

  const addMoreButton = (
    <Button
      color="primary"
      variant="contained"
      onClick={(event) => addAnotherApiDetails(event)}
      size="medium"
      type="submit"
      startIcon={<AddBoxIcon />}
    >
      {" "}
      Add Another
    </Button>
  );

  const handleChange = (index, event) => {
    event.preventDefault();
    event.persist();
    setFormValues((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {
          ...item,
          [event?.target?.name]:
            event.target.name === "is_active"
              ? event.target.checked
              : event.target.value,
          errors: {
            ...item.errors,
            [event.target.name]:
              event.target.value.length > 0
                ? null
                : [event.target.name] + " Is required"
          }
        };
      });
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    history.push("/advertisers");
  };

  useEffect(() => {
    if (provider_id) {
      getProviderCredentials();
    }
  }, [getProviderCredentials, provider_id]);

  return (
    <div>
      <div className="form-Container">
        {formValues.map((element, index) => (
          <>
            <div
              className="custom-fieldset"
              style={{ padding: "18px" }}
              key={index}
            >
              {formValues.length > 1 && (
                <ClearIcon
                  onClick={(e) => removeFormFields(index, e)}
                  style={{ float: "right" }}
                  variant="contained"
                  color="secondary"
                />
              )}
              <Form
                key={index}
                provider_id={provider_id}
                value={element}
                index={index}
                handleChange={handleChange}
              />
            </div>
            <br />
          </>
        ))}
        <br />
        {addMoreButton}
      </div>
      {type === "providerEdit" && (
        <div className="form__controls">
          {submitButton}
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            startIcon={<ClearIcon />}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};
export default FormRender;
