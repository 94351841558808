import React from "react";

import { useGetTagStats } from "../logics/getTagStats.logic";

const TagStats = ({ tagId }) => {
  const {
    totalMonthSearches,
    loadingTotalMonthSearches,
    totalTodaySearches,
    loadingTotalTodaySearches,
    totalYesterdaySearches,
    loadingTotalYesterdaySearches
  } = useGetTagStats(tagId);

  if (
    !tagId ||
    loadingTotalMonthSearches.error ||
    loadingTotalYesterdaySearches.error ||
    loadingTotalTodaySearches.error
  )
    return <></>;

  if (
    loadingTotalMonthSearches.loading ||
    loadingTotalYesterdaySearches.loading ||
    loadingTotalTodaySearches.loading
  ) {
    return (
      <div className="tag-stats">
        <pre>...Loading Stats</pre>
      </div>
    );
  }

  return (
    <div className="tag-stats">
      <pre>MTD Searches: {totalMonthSearches}</pre>
      <pre>Current Searches: {totalTodaySearches}</pre>
      <pre>Yesterday Searches: {totalYesterdaySearches}</pre>
    </div>
  );
};

export default TagStats;
