import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import AssessmentIcon from "@material-ui/icons/Assessment";

import { useLogsFilterLogic } from "./logs-filter-logic";
import { useLogData } from "./logs-fetch-logic";
import { useLogTableHeaderLogic } from "./logs-table-header-logic";
import LogTable from "./Layouts/LogTable";
import SimpleAccordion from "../../components/Accordion/Accordion";
import FilterBox from "./Layouts/FilterBox";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import { useLogDownload } from "./logs-download-logic";
import LogTiles from "./Layouts/LogTiles";
import ColumnFilters from "../../components/ColumnsFilter/ColumnsFilter";

const Logs = () => {
  const icon = <AssessmentIcon fontSize="inherit" color="primary" />;
  const filterValuesLogic = useLogsFilterLogic();
  const {
    filterValues,
    clearAllSelection,
    filtersClearToogle,
    selectionAddActions
  } = filterValuesLogic;

  const logDataHook = useLogData({
    filterValues,
    filtersClearToogle,
    selectionAddActions
  });

  const { downloadLogs } = useLogDownload({ filterValues });

  const {
    fetchLogsData,
    logs,
    monthToDateLogs,
    total,
    loadingLogs,
    noOfFiltersSelected
  } = logDataHook;

  const { tableHeaders, filteredTableHeaders, updateHeadersShowValue } =
    useLogTableHeaderLogic({
      selectedGroupBy: filterValues.selectedGroupBy,
      logs
    });

  function clearAllFilters() {
    clearAllSelection();
  }

  return (
    <div className="logs-container">
      <ContentHeader icon={icon} title="Search Logs" />
      <SimpleAccordion
        header="Filters"
        key="logs-filters"
        defaultExpanded={true}
      >
        <FilterBox
          handleSearch={fetchLogsData}
          filterValuesLogic={filterValuesLogic}
          clearAllSelection={clearAllFilters}
          loadingLogs={loadingLogs}
        />
      </SimpleAccordion>
      {!loadingLogs && noOfFiltersSelected > 0 && logs && logs?.length > 0 && (
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          onClick={downloadLogs}
          startIcon={<GetAppIcon />}
          className="download-btn"
        >
          Download
        </Button>
      )}

      {noOfFiltersSelected > 0 && (
        <SimpleAccordion header="Show Table Columns" defaultExpanded={true}>
          <ColumnFilters
            columns={tableHeaders}
            handleCheckboxChange={updateHeadersShowValue}
            dynamicTableFilters={true}
          />
        </SimpleAccordion>
      )}

      {noOfFiltersSelected === 0 ? (
        <LogTiles
          monthToDateLogs={monthToDateLogs ? monthToDateLogs : []}
          logs={logs ? logs : []}
          loadingLogs={loadingLogs}
        />
      ) : (
        <LogTable
          logData={logs ? logs : []}
          loadingLogs={loadingLogs}
          tableHeaders={filteredTableHeaders}
          total={total ? total : 0}
          logDataHook={logDataHook}
          orderBy={filterValues.orderBy}
          orderDirection={filterValues.orderDirection}
          setOrderBy={selectionAddActions.setOrderBy}
          setOrderDirection={selectionAddActions.setOrderDirection}
        />
      )}
    </div>
  );
};

export default Logs;
