import React from "react";
import { sortRelatedRules, getUniqueRelatedRules } from "../helpers";
import { useRedirectLogic } from "../../../hooks/useRedirectLogic";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const RelatedTagBox = ({ relatedTags, linkID }) => {
  const tagsInUse = getUniqueRelatedRules(sortRelatedRules(relatedTags));
  const { redirectToReportPage, redirectToSearchLogsPage } = useRedirectLogic();
  return (
    <div className="related-tag-box flow-content ">
      <h2 className="related-tag-box__heading">Advertiser Link ID: {linkID}</h2>
      {tagsInUse.map((tag) => {
        return (
          <div
            className={`tag-box flow-content--small white-border-box ${
              tag.disabled && "tag-box--disabled"
            }`}
            key={`${tag.tag_id}+${tag.rule_id}}`}
            onClick={() => window.open(`/target/edit/${tag.tag_id}`, "_blank")}
          >
            <div className="tag-box__publisher">{`Publisher : ${
              tag?.publisher_id || ""
            } - ${tag?.publisher_name || ""}`}</div>
            <div className="tag-box__tag">Tag ID : {tag.tag_id}</div>
            <div className="tag-box__rules">Rule ID : {tag.rule_id}</div>
            <div className="tag-box__rules">
              <span className="tag-box__rule">
                Status:
                {tag.disabled ? " Inactive" : " Active"}
              </span>
              <Tooltip title="Revenue Report">
                <IconButton
                  className={tag.rule_id ? "green-icon" : "gray-icon"}
                  onClick={() => redirectToReportPage(tag.rule_id, "day")}
                >
                  <AttachMoneyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Search Logs">
                <IconButton
                  className={tag.rule_id ? "blue-icon" : "gray-icon"}
                  onClick={() => redirectToSearchLogsPage(tag.rule_id, "day")}
                >
                  <AssessmentIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RelatedTagBox;
