import {
  COUNTRIES,
  GROUP_BY,
  INTERVALS,
  BROWSERS,
  PLATFORMS,
  OS_LIST,
  COUNTRY,
  ADVERTISER,
  PUBLISHER,
  RULE_ID,
  TID,
  BROWSER,
  ADVERTISER_LINK,
  PLATFORM,
  IP,
  OS_INFO,
  QUERY,
  SELECT_ALL
} from "../../helpers/constant/filters";
import { useMultipleSelect } from "../../hooks/useMultipleSelect";
import { useSelect } from "../../hooks/useSelect";
import { Publisher } from "../../services/Publisher";
import { Provider } from "../../services/Provider";
import { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { useTextInput } from "../../hooks/useTextInput";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  ADMIN_LOG_RULE_ID,
  ADMIN_LOG_FROM_DATE,
  ADMIN_LOG_INTERVAL
} from "../../helpers/constant/localStorage";
import { MONTH_TO_DATE } from "../../helpers/constant/filters";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../helpers/constant/misc";
import { findObject } from "../../helpers";

const all_selected_by = [
  COUNTRY,
  ADVERTISER,
  PUBLISHER,
  RULE_ID,
  TID,
  BROWSER,
  ADVERTISER_LINK,
  PLATFORM,
  IP,
  OS_INFO,
  QUERY
];

export const useLogsFilterLogic = () => {
  const [orderBy, setOrderBy] = useState("");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [query, handleQueryChange, setQuery] = useTextInput({
    initialValue: ""
  });

  const [ipAddress, handleIpAddressChange, setIpAddress] = useTextInput({
    initialValue: ""
  });

  const [localStorageRuleId, setLocalStorageRuleId] = useLocalStorage(
    ADMIN_LOG_RULE_ID,
    ""
  );

  const [localStorageFromDate, setLocalStorageFromDate] = useLocalStorage(
    ADMIN_LOG_FROM_DATE,
    ""
  );

  const [localStorageInterval, setLocalStorageInterval] = useLocalStorage(
    ADMIN_LOG_INTERVAL,
    ""
  );

  const {
    setData: setPublishers,
    selectedData: selectedPublishers,
    data: publishers,
    handleSelectedDataChange: handlePublisherChange,
    removeSelectedDataFromList: removePublisherFromList,
    removeAll: removeAllPublishers
  } = useMultipleSelect();

  const {
    setData: setBrowsers,
    selectedData: selectedBrowsers,
    data: browsers,
    handleSelectedDataChange: handleBrowserChange,
    removeSelectedDataFromList: removeBrowserFromList,
    removeAll: removeAllBrowsers
  } = useMultipleSelect();

  const {
    setData: setPlatforms,
    selectedData: selectedPlatforms,
    data: platforms,
    handleSelectedDataChange: handlePlatformChange,
    removeSelectedDataFromList: removePlatformFromList,
    removeAll: removeAllPlatforms
  } = useMultipleSelect();

  const {
    setData: setOsList,
    selectedData: selectedOsList,
    data: osList,
    handleSelectedDataChange: handleOsListChange,
    removeSelectedDataFromList: removeOsFromList,
    removeAll: removeAllOs
  } = useMultipleSelect();

  const {
    setData: setTagIds,
    selectedData: selectedTagIds,
    data: tagIds,
    handleSelectedDataChange: handleTagIdChange,
    removeSelectedDataFromList: removeTagIdFromList,
    removeAll: removeAllTagIds
  } = useMultipleSelect();

  const {
    setData: setProvidersLinks,
    selectedData: selectedProvidersLinks,
    data: providersLinks,
    handleSelectedDataChange: handleProvidersLinkChange,
    removeSelectedDataFromList: removeProvidersLinkFromList,
    removeAll: removeAllProvidersLinks
  } = useMultipleSelect();

  const {
    setData: setRuleIds,
    selectedData: selectedRuleIds,
    data: ruleIds,
    handleSelectedDataChange: handleRuleIdChange,
    removeSelectedDataFromList: removeRuleIdFromList,
    removeAll: removeAllRuleIds
  } = useMultipleSelect();

  const {
    setData: setCountriesData,
    selectedData: selectedCountries,
    data: countries,
    handleSelectedDataChange: handleCountryChange,
    removeSelectedDataFromList: removeCountryFromList,
    removeAll: removeAllCountries
  } = useMultipleSelect();

  const {
    setData: setProviders,
    selectedData: selectedProviders,
    data: providers,
    handleSelectedDataChange: handleProviderChange,
    removeSelectedDataFromList: removeProviderFromList,
    removeAll: removeAllProviders
  } = useMultipleSelect();

  const {
    setData: setIntervalData,
    selectedData: selectedInterval,
    data: intervals,
    changeSelectedData: changeSelectedInterval,
    clearSelection: clearSelectedInterval
  } = useSelect();

  const {
    setData: setGroupByData,
    selectedData: selectedGroupBy,
    data: groupByList,
    handleSelectedDataChange: handleGroupByChange,
    removeSelectedDataFromList: removeGroupByFromList,
    removeAll: removeAllGroupBy
  } = useMultipleSelect();

  const [filtersClearToogle, setFiltersClearToggle] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const fetchProvidersLinks = useCallback(() => {
    Provider.fetchAllProviderLinks().then((response) => {
      if (response.success) {
        const data = response.data.providerLinks;
        const providersLinks = [];
        for (let p of data) {
          providersLinks.push({
            label: p.link,
            value: p.id,
            disabled: p.disabled
          });
        }
        setProvidersLinks(providersLinks);
      } else {
        throw new Error(JSON.stringify(response.error));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProviders = useCallback(() => {
    Provider.fetchProviders({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.providers;
          const providers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            providers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setProviders(providers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTargeting = useCallback(() => {
    Publisher.getTargeting({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.targetings;
          const targetings = [];
          for (let t of data) {
            let isInactive = !t.is_active;
            targetings.push({
              label: t.id,
              value: t.id,
              status: t.status,
              disabled: isInactive
            });
          }
          setTagIds(targetings);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTargetingRules = useCallback(() => {
    Publisher.fetchTargetingRules()
      .then((response) => {
        if (response.success) {
          const data = response.data.targetingRules;
          const targetingRules = [];
          for (let t of data) {
            targetingRules.push({
              label: t.id,
              value: t.id,
              disabled: t.disabled
            });
          }
          setRuleIds(targetingRules);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPublishers = useCallback(() => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          for (let p of data) {
            publishers.push({ label: p.name, value: p.id });
          }
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToDate = (date) => {
    //date to be set is null , set it and return
    if (!date) {
      setToDate(null);
      return;
    }
    if (!fromDate) {
      setToDate(date);
    } else {
      if (moment(date).isAfter(fromDate) || moment(date).isSame(fromDate)) {
        setToDate(date);
      } else setToDate(fromDate);
    }
  };

  const handleFromDate = (date) => {
    //date to be set is null , set it and return
    if (!date) {
      setFromDate(null);
      return;
    }
    if (!toDate) {
      setFromDate(date);
    } else {
      if (moment(date).isBefore(toDate) || moment(date).isSame(toDate)) {
        setFromDate(date);
      } else {
        setToDate(date);
        setFromDate(date);
      }
    }
  };

  const handleDateChange = (fromDate, toDate) => {
    handleFromDate(fromDate);
    handleToDate(toDate);
  };

  /**
   * making api calls and setting all the list values
   * */
  useEffect(() => {
    setCountriesData(COUNTRIES);
    fetchProvidersLinks();
    fetchPublishers();
    fetchProviders();
    fetchTargeting();
    fetchTargetingRules();
    setGroupByData(GROUP_BY);
    setIntervalData(INTERVALS);
    setBrowsers(BROWSERS);
    setPlatforms(PLATFORMS);
    setOsList(OS_LIST);
  }, [
    fetchProviders,
    fetchPublishers,
    setCountriesData,
    setGroupByData,
    setIntervalData,
    fetchTargeting,
    fetchTargetingRules,
    fetchProvidersLinks,
    setBrowsers,
    setPlatforms,
    setOsList
  ]);

  useEffect(() => {
    if (ruleIds && ruleIds.length > 0) {
      if (
        findObject(ruleIds, localStorageRuleId) &&
        localStorageRuleId &&
        localStorageFromDate
      ) {
        handleRuleIdChange([localStorageRuleId]);
        if (localStorageFromDate === MONTH_TO_DATE) {
          setFromDate(moment().startOf("month"));
        }
        if (localStorageInterval && localStorageInterval === "day") {
          changeSelectedInterval(localStorageInterval);
        }
        setLocalStorageInterval("");
        setFiltersClearToggle(!filtersClearToogle);
        setLocalStorageRuleId("");
        setLocalStorageFromDate("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleIds]);

  /** selecting all the options in the group by when select all is selected */
  useEffect(() => {
    if (selectedGroupBy.includes(SELECT_ALL)) {
      handleGroupByChange(all_selected_by);
    }
  }, [selectedGroupBy, handleGroupByChange]);

  function clearAllSelection() {
    removeAllCountries();
    removeAllProviders();
    removeAllPublishers();
    removeAllTagIds();
    setQuery("");
    setIpAddress("");
    setFromDate(null);
    setToDate(null);
    setFiltersClearToggle(!filtersClearToogle);
    clearSelectedInterval();
    removeAllGroupBy();
    removeAllRuleIds();
    removeAllProvidersLinks();
    removeAllBrowsers();
    removeAllPlatforms();
    removeAllOs();
  }

  let filterValues = {
    selectedCountries,
    selectedPublishers,
    selectedProviders,
    selectedInterval,
    selectedTagIds,
    selectedGroupBy,
    fromDate,
    toDate,
    query,
    ipAddress,
    selectedRuleIds,
    selectedProvidersLinks,
    selectedBrowsers,
    selectedPlatforms,
    selectedOsList,
    orderBy,
    orderDirection
  };

  let dataLists = {
    providers,
    publishers,
    countries,
    groupByList,
    intervals,
    tagIds,
    ruleIds,
    providersLinks,
    browsers,
    platforms,
    osList
  };

  let selectionAddActions = {
    handleCountryChange,
    handleFromDate,
    handleToDate,
    handleQueryChange,
    handlePublisherChange,
    handleProviderChange,
    changeSelectedInterval,
    handleGroupByChange,
    handleTagIdChange,
    handleRuleIdChange,
    handleIpAddressChange,
    handleProvidersLinkChange,
    handleDateChange,
    handleBrowserChange,
    handlePlatformChange,
    handleOsListChange,
    setOrderDirection,
    setOrderBy
  };

  return {
    filterValues,
    dataLists,
    selectionAddActions,
    filtersClearToogle,
    removeCountryFromList,
    clearAllSelection,
    removeGroupByFromList,
    removeProviderFromList,
    removePublisherFromList,
    removeTagIdFromList,
    removeRuleIdFromList,
    removeProvidersLinkFromList,
    removeOsFromList,
    removeBrowserFromList,
    removePlatformFromList
  };
};
