import React from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const Form = ({ value, index, handleChange }) => {
  return (
    <>
      <form className="formData">
        <div className="flex-box align-items-bottom">
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="is_active"
                  onChange={(e) => handleChange(index, e)}
                  checked={value.is_active === true ? true : false}
                />
              }
              label={value.is_active === true ? "Active" : "Inactive"}
            />
            <TextField
              variant="standard"
              margin="normal"
              label="API Base URL"
              value={value.api_url || ""}
              onChange={(e) => handleChange(index, e)}
              error={value.errors.api_url ? true : false}
              name="api_url"
              placeholder="Enter Source API URL"
            />
            {value.errors.api_url && (
              <FormHelperText>{value.errors.api_url}</FormHelperText>
            )}
            <TextField
              variant="standard"
              margin="normal"
              label="Token"
              value={value.api_key || ""}
              onChange={(e) => handleChange(index, e)}
              error={value.errors.api_key ? true : false}
              name="api_key"
              placeholder="Enter Api Token"
            />
            {value.errors.api_key && (
              <FormHelperText>{value.errors.api_key}</FormHelperText>
            )}
          </FormControl>
        </div>
      </form>
    </>
  );
};

export default Form;
