import { useState } from "react";

/**
 *  Logical Hook to change between different states of the form or
 * to change between button and the table
 *  */

export const SHOW_ADD_BUTTON = "show add button";
export const SHOW_EDIT = "show_edit_form";
export const SHOW_NEW_CREATION_FORM = "show_new_creation_form";
const initial_page_data = {
  id: "",
  link: "",
  description: "",
  platform: {
    id: ""
  },
  search_engine: {
    id: ""
  },
  tag_type: {
    id: ""
  },
  provider: {
    id: ""
  },
  tag_type_id: "",
  type: "",
  template_path: ""
};

export const useFormUILogic = () => {
  const [currentPage, setCurrentPage] = useState(SHOW_ADD_BUTTON);
  const [pageData, setPageData] = useState(initial_page_data);

  const showAddForm = () => {
    setPageData(initial_page_data);
    setCurrentPage(SHOW_NEW_CREATION_FORM);
  };

  const showEditForm = (pageData) => {
    setPageData(pageData);
    setCurrentPage(SHOW_EDIT);
    window.scrollTo(0, 0);
  };

  const showDuplicateForm = (pageData) => {
    setPageData(pageData);
    setCurrentPage(SHOW_NEW_CREATION_FORM);
    window.scrollTo(0, 0);
  };

  const showAddButton = () => {
    setPageData(initial_page_data);
    setCurrentPage(SHOW_ADD_BUTTON);
  };

  return {
    currentPage,
    pageData,
    showAddForm,
    showEditForm,
    showDuplicateForm,
    showAddButton
  };
};
