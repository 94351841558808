import React from "react";
import PropTypes from "prop-types";

const NumberInfoTiles = ({ number, title }) => {
  return (
    <div className="number-info-tiles">
      <h4>{number}</h4>
      <h4>{title}</h4>
    </div>
  );
};

NumberInfoTiles.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};

export default NumberInfoTiles;
