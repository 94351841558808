import React from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";

const PublisherAccountFilterSelections = ({
  selectionAddActions,
  filterValues,
  handleSearch,
  dataLists
}) => {
  const {
    handleQueryChange,
    handleProviderChange,
    handleSearchPublisherChange,
    handleTagIdChange,
    handleRuleIdChange
  } = selectionAddActions;

  const {
    query,
    selectedProviders,
    selectedPublishers,
    selectedTagIds,
    selectedRuleIds
  } = filterValues;

  const { providers, publishers, tagIds, ruleIds } = dataLists;

  return (
    <>
      <div className="providerLinks-filter__selections">
        <TextField
          variant="standard"
          margin="normal"
          label="Search By Link Source Identifier"
          onKeyPress={(e) => {
            e.key === "Enter" && handleSearch();
          }}
          value={query}
          onChange={handleQueryChange}
          fullWidth
        />
        <AutoCompleteSelect
          selected={selectedProviders}
          data={providers}
          handleChange={handleProviderChange}
          label={"Advertisers"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={true}
        />
        <AutoCompleteSelect
          selected={selectedPublishers}
          data={publishers}
          handleChange={handleSearchPublisherChange}
          label={"Publishers"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={true}
        />
        <AutoCompleteSelect
          selected={selectedTagIds}
          data={tagIds}
          handleChange={handleTagIdChange}
          label={"Tag ID"}
          showValueInLabel={false}
          enableMultiple={true}
          withDotsForStatus={true}
        />
        <AutoCompleteSelect
          selected={selectedRuleIds}
          data={ruleIds}
          handleChange={handleRuleIdChange}
          label={"Rule Id"}
          showValueInLabel={false}
          enableMultiple={true}
          withDotsForStatus={true}
        />
        <div
          style={{
            display: "flex",
            gap: "2rem"
          }}
        ></div>
      </div>
    </>
  );
};

PublisherAccountFilterSelections.propTypes = {
  selectionAddActions: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired
};

export default PublisherAccountFilterSelections;
