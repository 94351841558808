import { useState, useCallback, useEffect } from "react";
import { Log } from "../../../services/Logs";
import { useIsMounted } from "../../../hooks/useIsMounted";
import moment from "moment";
export const useGetTagStats = (tagId) => {
  const [loadingTotalMonthSearches, setLoadingTotalMonthSearches] = useState({
    loading: false,
    error: false
  });
  const [totalMonthSearchesStats, setTotalMonthSearchesStats] = useState({});

  const [loadingTotalTodaySearches, setLoadingTotalTodaySearches] = useState({
    loading: false,
    error: false
  });

  const [totalTodaySearchesStats, setTotalTodaySearchesStats] = useState({});

  const [loadingTotalYesterdaySearches, setLoadingTotalYesterdaySearches] =
    useState({
      loading: false,
      error: false
    });

  const [totalYesterdaySearchesStats, setTotalYesterdaySearchesStats] =
    useState({});

  const isMounted = useIsMounted();

  const fetchSearches = useCallback(
    async (tagId, fromDate, toDate, setLoading, setStats) => {
      try {
        setLoading({ loading: true, error: false });
        const fetchedLogs = await Log.fetchLogs({
          cid: tagId,
          from: fromDate,
          to: toDate
        });
        if (fetchedLogs.success && isMounted()) {
          setStats(fetchedLogs.data);
          setLoading({ loading: false, error: false });
        }
      } catch (error) {
        console.trace(
          error?.message ||
            "Oops! there was an error loading searchs stats for the tag"
        );
        if (isMounted()) {
          setStats({});
          setLoading({ loading: false, error: true });
        }
      }
    },
    [isMounted]
  );
  const fetchTotalMonthCurrentStats = useCallback(
    (tagId) => {
      fetchSearches(
        tagId,
        moment().startOf("month").format("YYYY-MM-DD"),
        new Date(Date.now()),
        setLoadingTotalMonthSearches,
        setTotalMonthSearchesStats
      );
    },
    [fetchSearches]
  );

  const fetchTotalTodayCurrentStats = useCallback(
    (tagId) => {
      fetchSearches(
        tagId,
        moment().startOf("day").format("YYYY-MM-DD"),
        new Date(Date.now()),
        setLoadingTotalTodaySearches,
        setTotalTodaySearchesStats
      );
    },
    [fetchSearches]
  );

  /** Fetching Search Stats for Yesterday **/
  const fetchTotalYesterdayCurrentStats = useCallback(
    (tagId) => {
      fetchSearches(
        tagId,
        moment().subtract(1, "day").startOf("day").format("YYYY-MM-DD"),
        moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD"),
        setLoadingTotalYesterdaySearches,
        setTotalYesterdaySearchesStats
      );
    },
    [fetchSearches]
  );

  useEffect(() => {
    if (tagId && isMounted()) {
      fetchTotalMonthCurrentStats(tagId);
      fetchTotalTodayCurrentStats(tagId);
      fetchTotalYesterdayCurrentStats(tagId);
    }
  }, [
    tagId,
    fetchTotalMonthCurrentStats,
    fetchTotalTodayCurrentStats,
    fetchTotalYesterdayCurrentStats,
    isMounted
  ]);

  const totalMonthSearches =
    totalMonthSearchesStats?.logs?.[0]?.total_searches || 0;

  const totalTodaySearches =
    totalTodaySearchesStats?.logs?.[0]?.total_searches || 0;

  const totalYesterdaySearches =
    totalYesterdaySearchesStats?.logs?.[0]?.total_searches || 0;

  return {
    totalMonthSearches,
    loadingTotalMonthSearches,
    totalTodaySearches,
    loadingTotalTodaySearches,
    totalYesterdaySearches,
    loadingTotalYesterdaySearches
  };
};
