import React from "react";
import PropTypes from "prop-types";
import NumberInfoTiles from "../../../components/NumberInfoTiles/NumberInfoTiles";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const LogTiles = ({ logs, loadingLogs, monthToDateLogs }) => {
  const lastTwoDaysStat = logs?.[0] || {};
  const monthToDateStat = monthToDateLogs?.[0] || {};

  if (loadingLogs) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="log-tiles__container">
      <div className="log-tiles__row">
        <h3 className="log-tiles__row__heading">Last 48 hours</h3>
        <div className="log-tiles__row__info">
          <NumberInfoTiles
            number={+lastTwoDaysStat?.unique_ip || 0}
            title={"Unique IPs"}
          />
          <NumberInfoTiles
            number={+lastTwoDaysStat?.total_searches || 0}
            title={"Total Searches"}
          />
        </div>
      </div>
      <div className="log-tiles__row">
        <h3 className="log-tiles__row__heading">Month To Date</h3>
        <div className="log-tiles__row__info">
          <NumberInfoTiles
            number={+monthToDateStat?.unique_ip || 0}
            title={"Unique IPs"}
          />
          <NumberInfoTiles
            number={+monthToDateStat?.total_searches || 0}
            title={"Total Searches"}
          />
        </div>
      </div>
    </div>
  );
};

LogTiles.propTypes = {
  logs: PropTypes.array.isRequired,
  monthToDateLogs: PropTypes.array.isRequired,
  loadingLogs: PropTypes.bool.isRequired
};

export default LogTiles;
