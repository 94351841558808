import Api from "../api";

class Template {
  fetchTemplates = async () => {
    const { body } = await Api.get(`templates`);
    return body;
  };
}

export default new Template();
