import React from "react";
import PropTypes from "prop-types";
import { useModal } from "../../../hooks/useModal";
import LinkedAccountModal from "./LinkedAccountModal";

// Linked Account Button that Opens the Model with a list of linked accounts when clicked
const LinkedAccountButton = ({ pid, tid, ruleID }) => {
  const [isAccountModalOpen, openAccountModal, closeAccountModal] = useModal();

  return (
    <>
      <button
        className="linked-accounts-open-btn"
        onClick={(event) => {
          event.preventDefault();
          openAccountModal();
        }}
      >
        Publisher Ad Accounts
      </button>
      {isAccountModalOpen && (
        <LinkedAccountModal
          closeModal={closeAccountModal}
          pid={pid}
          tid={tid}
          ruleID={ruleID}
        />
      )}
    </>
  );
};

LinkedAccountButton.propTypes = {
  pid: PropTypes.number.isRequired,
  tid: PropTypes.number.isRequired,
  ruleID: PropTypes.number.isRequired
};

export default LinkedAccountButton;
