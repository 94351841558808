import React from "react";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import InfoToolTip from "../../../../components/InfoToolTip/InfoToolTip";
const LifeStats = ({ hasLife, loadingLifeStats, targeting_id }) => {
  if (!targeting_id || loadingLifeStats.error) {
    return <></>;
  }

  if (loadingLifeStats.loading) {
    return "...";
  }

  const InfoBox = (
    <div className="default-padding">
      <pre>{hasLife ? "Active in last hour" : "Not active in last hour"}</pre>
    </div>
  );

  return (
    <InfoToolTip
      title={InfoBox}
      className="targeting-option__info"
      style={{ cursor: "pointer" }}
    >
      <div className={hasLife ? "green-icon" : "red-icon"}>
        <LiveTvIcon />
      </div>
    </InfoToolTip>
  );
};

export default LifeStats;
