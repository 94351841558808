import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
const LinkedAccountList = ({ linkedAccounts }) => {
  const history = useHistory();
  function openAccountManagementPage(publisherAccountId) {
    history.push(`/publisheraccountmanagement/edit/${publisherAccountId}`);
  }
  return (
    <div>
      {
        <div className="linked-account-box flow-content ">
          <h2>Publisher Ad Accounts</h2>
          {linkedAccounts.map((linkedAccount) => (
            <div
              className="linked-account-box__id-box  flow-content--small white-border-box"
              key={linkedAccount.id}
              onClick={() => openAccountManagementPage(linkedAccount.id)}
            >
              <div className="linked-account-box__id-box__header">
                Publisher ad Account ID
              </div>
              <div className="linked-account-box__id-box __id">
                {linkedAccount.id}
              </div>
            </div>
          ))}
        </div>
      }
    </div>
  );
};

LinkedAccountList.propTypes = {
  linkedAccounts: PropTypes.array.isRequired
};

export default LinkedAccountList;
