import React from "react";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import { useDisplayNameLogic } from "../display-name.logic.js";
import { useDisplayNamesFormLogic } from "./displayNames-form.logic";
import { Prompt } from "react-router-dom";
import DisplayNamesForm from "./Layout/DisplayNamesForm";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const DisplayNamesMapping = () => {
  const { loadingDisplayNames, displayNames } = useDisplayNameLogic();
  const { values, handleChange, formTouched, handleSubmit, handleCancel } =
    useDisplayNamesFormLogic({ displayNames });

  const icon = <BlurLinearIcon fontSize="inherit" color="primary" />;

  return (
    <div className="field-mapping-container flow-content">
      <ContentHeader icon={icon} title="Map Display Names" />
      {loadingDisplayNames ? (
        <div className="loading-div">
          <LoadingSpinner />
        </div>
      ) : (
        <DisplayNamesForm
          values={values}
          handleChange={handleChange}
          formTouched={formTouched}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      )}
      <Prompt when={formTouched} message="Are you sure you want to leave?" />
    </div>
  );
};

export default DisplayNamesMapping;
