import React from "react";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import ReportFallback from "./ReportFallback/ReportFallback";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import SimpleAccordion from "../../components/Accordion/Accordion";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import FilterBox from "./FilterBox/FilterBox";
import ReportsBody from "./ReportsBody";

import { useReportFiltersLogic } from "./report-filters-logic";
import { useDashboardData } from "./useDashboardData";
import { useReportDownload } from "./report-download-logic";
import { useDisplayNameLogic } from "../mapping/display-name.logic";
import { useReportTableFilterLogic } from "./report-table-filter-logic";

const RevenueReport = () => {
  const filterValuesLogic = useReportFiltersLogic();
  const {
    filterValues,
    clearAllSelection,
    filtersClearToogle,
    selectionAddActions
  } = filterValuesLogic;
  const dashboardDataHook = useDashboardData({
    filterValues,
    filtersClearToogle
  });
  const { downloadReport } = useReportDownload({ filterValues });
  const { fetchDashboardData, loadingDashboardData } = dashboardDataHook;

  const { loadingDisplayNames, displayNames } = useDisplayNameLogic();
  const list = dashboardDataHook?.dashboardData?.list || [];

  const tableLogicHook = useReportTableFilterLogic({
    displayNames,
    list
  });

  function clearAllFilters() {
    clearAllSelection();
    fetchDashboardData();
  }

  return (
    <div className="flow-content">
      <ContentHeader
        icon={<MonetizationOnIcon fontSize="inherit" color="primary" />}
        title="Revenue Reports"
      />
      <ErrorBoundary FallbackComponent={ReportFallback}>
        {/* <h2 className="dashboard-subheading">Report Filters</h2> */}
        <SimpleAccordion
          header="Report Filters"
          key="report-filter-selection"
          defaultExpanded={true}
        >
          <FilterBox
            handleSearch={fetchDashboardData}
            filterValuesLogic={filterValuesLogic}
            clearAllSelection={clearAllFilters}
            loadingDashboardData={loadingDashboardData}
          />
        </SimpleAccordion>
        <ReportsBody
          dashboardDataHook={dashboardDataHook}
          displayNames={displayNames}
          loadingDisplayNames={loadingDisplayNames}
          tableLogicHook={tableLogicHook}
          downloadReport={downloadReport}
          orderBy={filterValues.orderBy}
          orderDirection={filterValues.orderDirection}
          setOrderBy={selectionAddActions.setOrderBy}
          setOrderDirection={selectionAddActions.setOrderDirection}
        />
      </ErrorBoundary>
    </div>
  );
};

export default RevenueReport;
