import React from "react";
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { TARGETING_TYPES } from "../../../helpers/constant/types";
import LinkedAccountButton from "./LinkedAccountButton";
import { useGetFormValues } from "../logics/rule-block/getFormValues.logic";
import { useGetProviderLinks } from "../logics/rule-block/getProviderLinks.logic";
import { useRedirectLogic } from "../../../hooks/useRedirectLogic";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import ProviderLinkAccountSelector from "./ProviderLinkAccountSelector";
import { useGetRuleStatsLogic } from "../logics/rule-block/getRuleStats.logic";
import SearchStatsBtn from "./StatButtons/SearchStatsBtn";
import LifeStats from "./StatButtons/LifeStats";
import GrossRevenueStatsBtn from "./StatButtons/GrossRevenueStatsBtn";
import SimpleModal from "../../../components/SimpleModal/SimpleModal";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";
const Rule = ({
  originalIndex,
  targeting_type,
  targetingRuleError,
  targetingRule,
  providers,
  handleDeleteAction,
  disabledProviders,
  targetingID,
  editing,
  publisher,
  formValueHandlers
}) => {
  const { formValues, formErrors } = useGetFormValues({
    targetingRule,
    targetingRuleError
  });

  const {
    dailyCap,
    comment,
    dailyFrequency,
    disabled,
    trafficOne,
    trafficTwo,
    providerOne,
    providerTwo,
    providerOneLink,
    providerTwoLink,
    targeting_id
  } = formValues;

  const {
    dailyCapError,
    trafficOneError,
    trafficTwoError,
    providerOneError,
    providerTwoError,
    providerOneLinkError,
    providerTwoLinkError,
    dailyFrequencyError
  } = formErrors;

  const { providerLinksOne, providerLinksTwo } = useGetProviderLinks({
    providerOne,
    providerTwo
  });

  const {
    handleChange,
    handleProviderChange,
    handleTrafficOneChange,
    handleTrafficTwoChange,
    handleDisabledChange,
    handleProviderLinkChanges,
    statusChangeOpen,
    statusInformOpen,
    updateStatusChange,
    closeAllModals
  } = formValueHandlers;

  const { redirectToReportPage, redirectToSearchLogsPage } = useRedirectLogic();

  const {
    loadingSearchStats,
    searchCount,
    loadingLifeStats,
    hasLife,
    loadingGrossRevenueStats,
    grossRevenue,
    grossRevenueDate
  } = useGetRuleStatsLogic(targeting_id);

  return (
    <form noValidate autoComplete="off">
      <div className="rule__section">
        <div className="rule__section__id">
          <Tooltip title="Drag and drop to change priority">
            <ImportExportIcon color="primary" className="drag-drop-icon" />
          </Tooltip>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              value={targeting_id}
              disabled={true}
              label="ID"
            />
          </FormControl>
        </div>
        <div className="rule__section__daily-cap">
          <FormControl fullWidth>
            <TextField
              label="Daily Cap"
              name="daily_cap"
              value={dailyCap === undefined ? "" : dailyCap}
              onChange={(e) =>
                handleChange(e.target.name, e.target.value, originalIndex)
              }
              error={dailyCapError}
              type="number"
            />
            {dailyCapError && (
              <FormHelperText className="red-text">
                Numeric value starting from 0 required!
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="rule__section__weight">
          <FormControl fullWidth>
            <TextField
              label="Weight(%)"
              name="daily_frequency"
              type="number"
              value={
                targeting_type === TARGETING_TYPES.WATERFALL
                  ? ""
                  : dailyFrequency
              }
              onChange={(e) =>
                handleChange(e.target.name, e.target.value, originalIndex)
              }
              disabled={targeting_type === TARGETING_TYPES.WATERFALL}
              error={dailyFrequencyError}
            />
          </FormControl>
        </div>

        <div className="rule__section__testing__block rule__section__testing__block--ab-test">
          <FormControl fullWidth>
            <TextField
              label="A-B Testing"
              name="trafficOne"
              value={trafficOne}
              onChange={(e) =>
                handleTrafficOneChange(e.target.value, originalIndex)
              }
              error={trafficOneError}
              type="number"
            />
            {trafficOneError && (
              <FormHelperText className="red-text">
                Integer between 0-100 required
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="A-B Testing"
              name="trafficTwo"
              /*adding "" to deal with react known issue 9402(github) 
                that reads 001 the same as 1 and thus does not render the
                updated value as desired
                https://github.com/facebook/react/issues/9402
                */
              value={trafficTwo + ""}
              onChange={(e) =>
                handleTrafficTwoChange(e.target.value, originalIndex)
              }
              error={trafficTwoError}
              type="number"
            />
            {trafficTwoError && (
              <FormHelperText className="red-text">
                Integer between 0-100 required
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="rule__section__testing__block rule__section__testing__block--adv">
          <AutoCompleteSelect
            selected={providerOne ? providerOne : ""}
            data={providers}
            handleChange={(value) =>
              handleProviderChange(
                {
                  target: {
                    name: "providerOne",
                    value
                  }
                },
                originalIndex,
                disabledProviders
              )
            }
            label={"Advertiser 1"}
            showValueInLabel={true}
            enableMultiple={false}
            withDotsForStatus={true}
            error={providerOneError}
            cannotSelectDisabled={true}
          />
          <AutoCompleteSelect
            selected={providerTwo ? providerTwo : ""}
            data={providers}
            handleChange={(value) =>
              handleProviderChange(
                {
                  target: {
                    name: "providerTwo",
                    value
                  }
                },
                originalIndex,
                disabledProviders
              )
            }
            label={"Advertiser 2"}
            showValueInLabel={true}
            enableMultiple={false}
            withDotsForStatus={true}
            error={providerTwoError}
            cannotSelectDisabled={true}
          />
        </div>
        <div className="rule__section__testing__block rule__section__testing__block--link">
          <ProviderLinkAccountSelector
            selected={providerOneLink}
            data={providerLinksOne}
            handleProviderLinkChanges={handleProviderLinkChanges}
            error={providerOneLinkError}
            originalIndex={originalIndex}
            name="providerOneLink"
            label="Advertiser 1 Link"
            providerLinksOne={providerLinksOne}
            providerLinksTwo={providerLinksTwo}
          />
          <ProviderLinkAccountSelector
            selected={providerTwoLink}
            data={providerLinksTwo}
            handleProviderLinkChanges={handleProviderLinkChanges}
            error={providerTwoLinkError}
            originalIndex={originalIndex}
            name="providerTwoLink"
            label="Advertiser 2 Link"
            providerLinksOne={providerLinksOne}
            providerLinksTwo={providerLinksTwo}
          />
        </div>
        <div className="rule__section__comments">
          <TextField
            fullWidth
            label="Comment"
            multiline
            rows={4}
            variant="outlined"
            name="comment"
            value={comment}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, originalIndex)
            }
          />
        </div>
        <div className="rule__section__action-section">
          <FormControlLabel
            control={
              <Tooltip title={`${!disabled ? "Disable Rule" : "Enable Rule"}`}>
                <Switch
                  checked={!disabled}
                  onChange={() =>
                    handleDisabledChange(originalIndex, disabled, targeting_id)
                  }
                  name="ruleChecked"
                />
              </Tooltip>
            }
          />
          <Tooltip title="Archive">
            <IconButton
              className={targeting_id ? "red-icon" : "gray-icon"}
              onClick={() => {
                targeting_id && handleDeleteAction(originalIndex);
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
          <GrossRevenueStatsBtn
            loadingGrossRevenueStats={loadingGrossRevenueStats}
            grossRevenue={grossRevenue}
            grossRevenueDate={grossRevenueDate}
            targeting_id={targeting_id}
            redirectToReportPage={redirectToReportPage}
          />
          <SearchStatsBtn
            targeting_id={targeting_id}
            redirectToSearchLogsPage={redirectToSearchLogsPage}
            loadingSearchStats={loadingSearchStats}
            searchCount={searchCount}
          />
          <LifeStats
            targeting_id={targeting_id}
            hasLife={hasLife}
            loadingLifeStats={loadingLifeStats}
          />
        </div>
        {targeting_id && publisher && targetingID && (
          <span className="rule__section__linked-accountBtn">
            <LinkedAccountButton
              pid={+publisher}
              tid={+targetingID}
              ruleID={+targeting_id}
            />
          </span>
        )}

        <span className="rule__section__closeBtn">
          <Tooltip title="Delete Rule">
            <IconButton
              className={!targeting_id ? "red-icon" : "gray-icon"}
              onClick={() => {
                !targeting_id && handleDeleteAction(originalIndex);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </span>
      </div>
      {statusChangeOpen && (
        <ConfirmBox
          message="Disabling the rule will deactivate the publisher account related to this rule box, publisher account will be valid till yesterday.. Do you wish to proceed?"
          confirmAction={updateStatusChange}
          denyAction={closeAllModals}
        />
      )}
      {statusInformOpen && (
        <SimpleModal handleClose={closeAllModals}>
          <div className="rule-disable-inform">
            <p>
              Sorry, cannot enable the rule. This is done to maintain the
              integrity of the publisher ad accounts which are disabled. Please
              create a new rule if you wish to use the same values instead.
            </p>
          </div>
        </SimpleModal>
      )}
    </form>
  );
};

export default Rule;
