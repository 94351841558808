import Api from "../api";

class Provider {
  addProvider = async (data) => {
    const { body } = await Api.post("provider", data);
    return body;
  };

  fetchProvider = async (id) => {
    const { body } = await Api.get(`provider/${id}`);
    return body;
  };

  fetchProviderLinks = async (
    id,
    params = { order_by: "id", order_direction: "ASC" }
  ) => {
    const { body } = await Api.get(`provider/${id}/links`, null, params);
    return body;
  };

  fetchAllProviderLinks = async () => {
    const { body } = await Api.get("providers/links");
    return body;
  };

  saveProviderLinks = async (id, data) => {
    const { body } = await Api.post(`provider/${id}/links`, data);
    return body;
  };

  deleteProviderLink = async (linkId) => {
    const { body } = await Api.destroy(`provider/link/${linkId}`);
    return body;
  };

  updateProvider = async (id, data, params = {}) => {
    const { body } = await Api.put(`provider/${id}`, data, params);
    return body;
  };

  bulkDeleteProviders = async (data) => {
    const { body } = await Api.destroy(`providers`, data);
    return body;
  };

  bulkUpdateProviders = async (type, data) => {
    const { body } = await Api.put(`providers?status=${type}`, data);
    return body;
  };

  restoreProviders = async (data) => {
    const { body } = await Api.put(`providers?deleted=false`, data);
    return body;
  };

  fetchProviders = async (
    params = { order_by: "id", order_direction: "ASC" }
  ) => {
    const { body } = await Api.get(`providers`, null, params);
    return body;
  };

  fetchProviderLinksList = async (
    params = { order_by: "id", order_direction: "ASC" },
    cancelToken = null
  ) => {
    const { body } = await Api.get(
      "provider/all/links",
      null,
      params,
      cancelToken
    );
    return body;
  };

  deleteProvider = async (id, params = {}) => {
    const { body } = await Api.destroy(`provider/${id}`, {}, params);
    return body;
  };

  getIdenticalLinks = async (id, data, includeSelf = false, link_id = null) => {
    const { body } = await Api.post(
      `provider/${id}/identicallinks?link_id=${link_id ? link_id : ""}${
        includeSelf ? "&include_self=true" : ""
      }`,
      data
    );
    return body;
  };
}

export default new Provider();
