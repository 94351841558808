import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { setCurrentUser } from "../services/Auth";
import store from "../store";

import Home from "../pages/Home";
import Login from "../pages/auth/Login";
import Publishers from "../pages/Publishers/index";
import ManagePublishers from "../pages/Publishers/Manage";
import PublisherLinks from "../pages/Publishers/Links/index";
import Providers from "../pages/Provider";
import PrivateRoute from "./PrivateRoute";
import Target from "../pages/target/List";
import TargetAdd from "../pages/target";
import Logs from "../pages/logs/Logs";
import Profile from "../pages/profile/Profile";
import Settings from "../pages/settings/Settings";
import SystemLogs from "../pages/SystemLogs/SystemLogs";
import ProviderLinks from "../pages/ProviderLinks";
import FieldMapping from "../pages/mapping/CsvFieldMapping/FieldMapping";
import DisplayNamesMapping from "../pages/mapping/DisplayNames/DisplayNamesMapping";
import CsvUpload from "../pages/mapping/csvUpload/CsvUpload";
import Users from "../pages/usermanagemet/index";
import AddUser from "../pages/usermanagemet/addUser/addUser";
import EditUser from "../pages/usermanagemet/editUser/editUser";
import PublisherDisplayColumns from "../pages/usermanagemet/displayColumns/displayColumns";
import LinkedAccounts from "../pages/linkedAccounts/LinkedAccounts";
import RevenueReport from "../pages/RevenueReport/RevenueReportContainer";
import ReportingLogs from "../pages/reportingLogs";
import ReportsDetails from "../pages/reportingLogs/logsDetails";
import PublisherAccountList from "../pages/publisherAccounts/PublisherAccountList";
import ProviderLinksList from "../pages/ProviderLinksList/ProviderLinksList";
import PublisherAccountRemove from "../pages/publisherAccounts/PublisherAccountRemove";
import SkippedRowsList from "../pages/skippedReports/SkippedRowsList";
import ROLES from "./Role";
import ProviderApiInfo from "../pages/ProviderApiInfo/index";
import ProviderApiDetails from "../pages/ProviderApiDetails";
import DetailsInfo from "../pages/ProviderApiDetails/layouts/DetailsInfo";

import PublisherApiInfo from "../pages/Publishers/ApiInfo";
import RevenueReportStatus from "../pages/skippedReports/uploadStatus";

const cookieToken = Cookies.get("nitro-token");

if (localStorage["nitro-token"] && cookieToken) {
  const parsedToken = JSON.parse(localStorage["nitro-token"]);
  const { token } = parsedToken;
  const { timestamp } = parsedToken;
  const today = new Date();

  const previousDate = new Date(timestamp);
  previousDate.setDate(previousDate.getDate() + 7);

  const fullDateToday = today.getTime();
  const fullPreviousDate = previousDate.getTime();

  const decoded = jwtDecode(token);
  if (fullDateToday <= fullPreviousDate && decoded?.role?.role) {
    store.dispatch(setCurrentUser(decoded));
  } else {
    localStorage.removeItem("nitro-token");
    Cookies.remove("nitro-token");
  }
}

const Routes = () => {
  return (
    <>
      <div>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="bottom-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={false}
          closeOnToastrClick
        />
      </div>
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path="/" component={Home} roles={ROLES.all} />
          <PrivateRoute
            exact
            path="/publisher/apidetails"
            component={PublisherApiInfo}
            roles={ROLES.publisher}
          />
          <PrivateRoute
            exact
            path="/publishers"
            component={Publishers}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publishers/add"
            component={ManagePublishers}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publishers/edit/:id"
            component={ManagePublishers}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publishers/links/edit"
            component={PublisherLinks}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publishers/details/:id"
            component={PublisherApiInfo}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers"
            component={Providers}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers/add"
            component={ProviderApiInfo}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers/edit/:id"
            component={ProviderApiInfo}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/apidetails"
            component={ProviderApiDetails}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/apidetails/:id"
            component={DetailsInfo}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers/links/add"
            component={ProviderLinks}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers/links"
            component={ProviderLinksList}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/logs"
            component={Logs}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/settings"
            component={Settings}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/systemlogs"
            component={SystemLogs}
            roles={ROLES.admin}
          />
          <Route exact path="/login" component={Login} roles={ROLES.all} />
          <PrivateRoute
            exact
            path="/target"
            component={Target}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/target/add"
            component={TargetAdd}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/target/edit/:id"
            component={TargetAdd}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/profile"
            component={Profile}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/mappedFields"
            component={FieldMapping}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/displaynames"
            component={DisplayNamesMapping}
            roles={ROLES.admin}
          />{" "}
          <PrivateRoute
            exact
            path="/csvupload"
            component={CsvUpload}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/usermanagement"
            component={Users}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/usermanagement/adduser"
            component={AddUser}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/usermanagement/edit/:id"
            component={EditUser}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publisherDisplayColumns"
            component={PublisherDisplayColumns}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publisheraccountmanagement/edit/:id"
            component={LinkedAccounts}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/revenuereport"
            component={RevenueReport}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/pubaccount"
            component={PublisherAccountList}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/reportinglogs"
            component={ReportingLogs}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/reportinglogs/:id/:action"
            component={ReportsDetails}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/revenueReportStatus"
            component={SkippedRowsList}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/csvUploadStatus"
            component={RevenueReportStatus}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/remove/reports/:id"
            component={PublisherAccountRemove}
            roles={ROLES.admin}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps)(Routes);
