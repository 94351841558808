import { useState, useEffect } from "react";
import { validate } from "../../../helpers/validation";
import { useHistory } from "react-router-dom";
import { useFieldMappingLogic } from "../field-mapping.logic";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { getKeyByValue, changeEmptyStringToNull } from "../../../helpers";
import { MappedFields } from "../../../services/MappedFields";
import { toastr } from "react-redux-toastr";
import {
  MAPPING_COLUMN_NAME,
  csv_mapping_order
} from "../../../helpers/constant/mapping";
/**
 *
 * @param {provider} Loads the value of data fields if saved any from the backend
 */
export const useFieldsFormLogic = ({ provider, displayNames }) => {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const [formTouched, setFormTouched] = useState(false);
  const [updateConfirmationModalOpen, setUpdateConfirmationModalOpen] =
    useState(false);

  const [values, setValues] = useState({});
  const { loadingMappedFields, mappedFields } = useFieldMappingLogic({
    providerId: provider
  });

  const [shouldIUpdate, setShouldIUpdate] = useState(() => {
    return { val: mappedFields ? true : false, id: mappedFields?.id };
  });

  const [isSubmittingData, setIsSubmittingData] = useState(false);

  const handleChange = (e) => {
    setFormTouched(true);
    setValues({
      ...values,
      [e.target.name]: {
        ...values[e.target.name],
        value: e.target.value,
        error: false
      }
    });
  };

  const handleCancel = () => {
    history.push("/");
  };

  //validating the entered value based on the validation options
  const validateOptions = (isValid, tempFormValues, valueKeys) => {
    valueKeys.forEach((key) => {
      values[key].validOptions.forEach((option) => {
        if (!validate(option, values[key].value)) {
          tempFormValues = {
            ...tempFormValues,
            [key]: {
              ...values[key],
              error: true
            }
          };
          isValid = false;
        }
      });
    });
    return { isValid, tempFormValues };
  };
  // get count of the entered values along with the key of the entered value
  const getCountOfEnteredValues = (valueKeys) => {
    let countMap = {};
    for (let i = 0; i < valueKeys.length; i++) {
      let value = values[valueKeys[i]].value;
      if (!value) {
        continue;
      }
      value = value.toLowerCase().trim();

      if (countMap[value]) {
        countMap[value].count += 1;
        countMap[value].keys.push(valueKeys[i]);
      } else {
        countMap[value] = {};
        countMap[value].count = 1;
        countMap[value].keys = [valueKeys[i]];
      }
    }
    return countMap;
  };

  /** Validating for duplicate values entered, ignoring for when the duplicate is for the keys total_searches and monetized_searches */
  const validateDuplicates = (isValid, tempFormValues, valueKeys) => {
    let valueCounts = getCountOfEnteredValues(valueKeys);
    let keys = Object.keys(valueCounts);

    for (let i = 0; i < keys.length; i++) {
      if (
        valueCounts[keys[i]].count === 2 &&
        valueCounts[keys[i]].keys.length === 2 &&
        valueCounts[keys[i]].keys.includes("total_searches") &&
        valueCounts[keys[i]].keys.includes("monetized_searches")
      ) {
        continue;
      }
      if (valueCounts[keys[i]].count > 1) {
        let duplicatedFormKeys = valueCounts[keys[i]].keys;
        for (let j = 0; j < duplicatedFormKeys.length; j++) {
          tempFormValues = {
            ...tempFormValues,
            [duplicatedFormKeys[j]]: {
              ...values[duplicatedFormKeys[j]],
              error: true
            }
          };
          isValid = false;
        }
      }
    }

    return { isValid, tempFormValues };
  };

  // validating search values duplicate for same case.
  // business logic is to ignore duplication for different case (lowercase or uppercase) but check for duplication for same case
  const validateDuplicateSearches = (isValid, tempFormValues) => {
    let totalSearches = values["total_searches"];
    let monetizedSearches = values["monetized_searches"];

    if (
      totalSearches.value &&
      monetizedSearches.value &&
      totalSearches.value === monetizedSearches.value
    ) {
      tempFormValues = {
        ...tempFormValues,
        total_searches: {
          ...totalSearches,
          error: true
        },
        monetized_searches: {
          ...monetizedSearches,
          error: true
        }
      };
      isValid = false;
    }

    return { isValid, tempFormValues };
  };

  const validateForm = () => {
    let isValid = true;
    let tempFormValues = { ...values };
    let valueKeys = Object.keys(values);

    let validateOptionsCheck = validateOptions(
      isValid,
      tempFormValues,
      valueKeys
    );
    isValid = validateOptionsCheck.isValid;
    tempFormValues = validateOptionsCheck.tempFormValues;

    let validateDuplicateSearchesCheck = validateDuplicateSearches(
      isValid,
      tempFormValues
    );
    isValid = validateDuplicateSearchesCheck.isValid;
    tempFormValues = validateDuplicateSearchesCheck.tempFormValues;

    let validateDuplicatesCheck = validateDuplicates(
      isValid,
      tempFormValues,
      valueKeys
    );
    isValid = validateDuplicatesCheck.isValid;
    tempFormValues = validateDuplicatesCheck.tempFormValues;

    setValues(tempFormValues);
    return isValid;
  };

  function getFormData() {
    let formData = {};
    Object.keys(values).forEach((key) => {
      /** If usere entered blank spaces or empty string coverting
      /*  them to null and excluding them from the object as the 
      /* value is being used as key in the object to be sent to API.*/
      let keyForSubmission = changeEmptyStringToNull(values[key].value);
      if (keyForSubmission) {
        formData[keyForSubmission.trim()] = key;
      }
    });
    return formData;
  }

  const handleSubmit = () => {
    if (!validateForm()) {
      toastr.error("Error", "Please enter valid and unique values");
      setUpdateConfirmationModalOpen(false);
      return;
    }
    const formData = {
      fields: getFormData(),
      advertiser_id: provider,
      uploaded_by: currentUser.id,
      updated_by: currentUser.id
    };
    if (shouldIUpdate.val) {
      updateMappedFields(formData);
    } else {
      saveMappedFields(formData);
    }
  };

  function saveMappedFields(data) {
    setIsSubmittingData(true);
    MappedFields.storeMappedFields({ data })
      .then((response) => {
        if (response.success) {
          //probably have to update display names here
          setFormTouched(false);
          setIsSubmittingData(false);
          history.push("/csvupload");
          toastr.success("Success", "Mapped CSV Fields Saved!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
        setIsSubmittingData(false);
      });
  }

  function askForUpdateConfirmation() {
    setUpdateConfirmationModalOpen(true);
  }

  function closeUpdateConfirmationModal() {
    setUpdateConfirmationModalOpen(false);
  }

  function onSubmitButtonClick(e) {
    e.preventDefault();
    if (shouldIUpdate.val) {
      askForUpdateConfirmation();
    } else {
      handleSubmit();
    }
  }

  function updateMappedFields(data) {
    setIsSubmittingData(true);
    MappedFields.updateMappedFields({ data, id: shouldIUpdate.id })
      .then((response) => {
        if (response.success) {
          //probably have to update display names here
          closeUpdateConfirmationModal();
          setFormTouched(false);
          toastr.success("Success", "Mapped Fields updated!");
          setIsSubmittingData(false);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
        setIsSubmittingData(false);
      });
  }

  // When provider changes, changing the state  of form to untouched state.
  useEffect(() => {
    if (provider) {
      setFormTouched(false);
    }
  }, [provider]);

  /**
   *  Function that return a required validation options
   *  in array for different mapping columns
   */
  function getValidationOptions(column) {
    let validationOptions = [];
    switch (column) {
      case MAPPING_COLUMN_NAME.date:
      case MAPPING_COLUMN_NAME.subId:
      case MAPPING_COLUMN_NAME.geo:
      case MAPPING_COLUMN_NAME.total_searches:
      case MAPPING_COLUMN_NAME.gross_revenue:
        validationOptions = ["isRequired"];
        break;
      default:
        validationOptions = [];
    }

    return validationOptions;
  }

  /**
   *  Updating the form itself and the values of the form
   *  when displaynames are updated or mappedField Values are updated!
   *  If, mappedFields exist, it means the form should update and hence
   *  we also update the state 'shouldIUpdate'
   */
  useEffect(() => {
    setShouldIUpdate({
      val: mappedFields ? true : false,
      id: mappedFields?.id
    });
    if (displayNames?.fields) {
      let newValues = {};
      let mappedFieldsValues = mappedFields?.fields || null;
      Object.keys(displayNames.fields).forEach((key) => {
        if (csv_mapping_order.includes(key)) {
          newValues[key] = {
            value: getKeyByValue(mappedFieldsValues, key) || "",
            error: false,
            label: displayNames.fields[key],
            type: "text",
            errorMsg: "Please enter equivalent field",
            validOptions: getValidationOptions(key)
          };
        }
      });
      setValues(newValues);
    }
  }, [displayNames, mappedFields]);

  /**
   * setting up page exit listener
   */
  useEffect(() => {
    function handlePageExit(e) {
      e.preventDefault();
      e.returnValue =
        "Changes made to mapped Fields will be not saved! Are you sure?";
    }
    formTouched && window.addEventListener("beforeunload", handlePageExit);
    !formTouched && window.removeEventListener("beforeunload", handlePageExit);
    return () => {
      window.removeEventListener("beforeunload", handlePageExit);
    };
  }, [formTouched]);

  return {
    values,
    handleChange,
    formTouched,
    handleSubmit,
    handleCancel,
    loadingMappedFields,
    updateConfirmationModalOpen,
    askForUpdateConfirmation,
    closeUpdateConfirmationModal,
    shouldIUpdate,
    onSubmitButtonClick,
    isSubmittingData
  };
};
