import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingSpinner = () => (
  <div className="spinner-container">
    <CircularProgress />
  </div>
);

export default LoadingSpinner;
