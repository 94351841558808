export const getLabel = (field) => {
  switch (field) {
    case "date":
      return "Date";
    case "tag_number":
      return "tag_number";
    case "rule_id":
      return "Rule ID";
    case "advertiser_id":
      return "Advertiser";
    case "publisher":
      return "Publisher";
    case "subId":
      return "Sub ID (Channel)";
    case "geo":
      return "Geo";
    case "total_searches":
      return "Total Searches";
    case "monetized_searches":
      return "Monetized Searches";
    case "clicks":
      return "Clicks";
    case "ctr":
      return "CTR";
    case "gross_revenue":
      return "Gross Revenue";
    case "net_revenue":
      return "Net Revenue";
    case "rpm":
      return "RPM";
    case "rpmm":
      return "Monetized RPM";
    case "rpc":
      return "RPC";
    default:
      return field;
  }
};
