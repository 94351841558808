import { useState, useEffect } from "react";
import { validate } from "../../../helpers/validation";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { DisplayNames } from "../../../services/DisplayNames";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { MAPPING_COLUMN_NAME } from "../../../helpers/constant/mapping";

const DATABASE_COLUMNS = [
  MAPPING_COLUMN_NAME.date,
  MAPPING_COLUMN_NAME.subId,
  MAPPING_COLUMN_NAME.publisher,
  MAPPING_COLUMN_NAME.tag_description,
  MAPPING_COLUMN_NAME.advertiser_id,
  MAPPING_COLUMN_NAME.publisher,
  MAPPING_COLUMN_NAME.rule_id,
  MAPPING_COLUMN_NAME.tag_number,
  MAPPING_COLUMN_NAME.gross_revenue,
  MAPPING_COLUMN_NAME.geo,
  MAPPING_COLUMN_NAME.total_searches,
  MAPPING_COLUMN_NAME.clicks,
  MAPPING_COLUMN_NAME.monetized_searches,
  MAPPING_COLUMN_NAME.net_revenue,
  MAPPING_COLUMN_NAME.ctr,
  MAPPING_COLUMN_NAME.rpm,
  MAPPING_COLUMN_NAME.rpmm,
  MAPPING_COLUMN_NAME.rpc,
  MAPPING_COLUMN_NAME.followon_searches,
  MAPPING_COLUMN_NAME.initial_searches
];

export const useDisplayNamesFormLogic = ({ displayNames }) => {
  const history = useHistory();
  const { currentUser } = useCurrentUser();

  const [formTouched, setFormTouched] = useState(false);
  const [shouldIUpdate, setShouldIUpdate] = useState(() => {
    return { val: displayNames ? true : false, id: displayNames?.id };
  });

  const [values, setValues] = useState(() => {
    let formValues = {};
    DATABASE_COLUMNS.forEach((field) => {
      formValues[field] = {
        value: displayNames?.[field] || "",
        error: false,
        label: field,
        type: "text",
        errorMsg: "Please enter Display Name",
        validOptions: ["isRequired"]
      };
    });
    return formValues;
  });

  const handleChange = (e) => {
    setFormTouched(true);
    setValues({
      ...values,
      [e.target.name]: {
        ...values[e.target.name],
        value: e.target.value,
        error: false
      }
    });
  };

  const handleCancel = () => {
    history.push("/");
  };

  const validateForm = () => {
    let isValid = true;
    let tempFormValues = { ...values };
    Object.keys(values).forEach((key) => {
      values[key].validOptions.forEach((option) => {
        if (!validate(option, values[key].value)) {
          tempFormValues = {
            ...tempFormValues,
            [key]: {
              ...values[key],
              error: true
            }
          };
          isValid = false;
        }
      });
    });
    setValues(tempFormValues);
    return isValid;
  };

  const getFormData = () => {
    let formData = {};
    Object.keys(values).forEach((key) => {
      formData[key] = values[key].value.trim();
    });
    return formData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const formData = { fields: getFormData(), updatedBy: currentUser.id };
    if (shouldIUpdate.val) {
      updateDisplayNames(formData);
    } else {
      saveDisplayNames(formData);
    }
  };

  function saveDisplayNames(data) {
    DisplayNames.storeDisplayNames({ data })
      .then((response) => {
        if (response.success) {
          //probably have to update display names here
          setFormTouched(false);
          toastr.success("Success", "Display Names saved!");
          history.push("/mappedFields");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
      });
  }

  function updateDisplayNames(data) {
    DisplayNames.updateDisplayNames({ data, id: shouldIUpdate.id })
      .then((response) => {
        if (response.success) {
          //probably have to update display names here
          setFormTouched(false);
          toastr.success("Success", "Display Names updated!");
          // history.push("/mappedFields");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
      });
  }

  /**
   * setting up page exit listener
   */
  useEffect(() => {
    function handlePageExit(e) {
      e.preventDefault();
      e.returnValue =
        "Changes made to Display Names will be not saved! Are you sure?";
    }
    formTouched && window.addEventListener("beforeunload", handlePageExit);
    !formTouched && window.removeEventListener("beforeunload", handlePageExit);
    return () => {
      window.removeEventListener("beforeunload", handlePageExit);
    };
  }, [formTouched]);

  /**
   * Use Effect to change the value of the form
   */
  useEffect(() => {
    if (displayNames?.fields) {
      let newValues = {};
      Object.keys(displayNames.fields).forEach((key) => {
        newValues[key] = {
          value: displayNames.fields[key] || "",
          error: false,
          label: key,
          type: "text",
          errorMsg: "Please enter equivalent Display Name",
          validOptions: ["isRequired"]
        };
      });
      setValues(newValues);
      setShouldIUpdate({ val: true, id: displayNames.id });
    }
  }, [displayNames]);

  return {
    values,
    handleChange,
    formTouched,
    handleSubmit,
    handleCancel
  };
};
