import React from "react";
import PropTypes from "prop-types";
import { useFetchLinkedAccounts } from "../logics/fetchLinkedAccount";
import SimpleModal from "../../../components/SimpleModal/SimpleModal";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import LinkedAccountList from "./LinkedAccountList";
const LinkedAccountModal = ({ pid, tid, ruleID, closeModal }) => {
  const { linkedAccounts, loadingLinkedAccounts } = useFetchLinkedAccounts({
    pid,
    tid,
    ruleID
  });
  console.log({ linkedAccounts });
  return (
    <SimpleModal handleClose={closeModal}>
      {loadingLinkedAccounts && (
        <div className="linked-account-modal">
          <h3>Loading Publisher Ad Accounts...</h3>
          <LoadingSpinner />
        </div>
      )}
      {!loadingLinkedAccounts && linkedAccounts && (
        <LinkedAccountList
          linkedAccounts={linkedAccounts ? linkedAccounts : []}
        />
      )}
      {!loadingLinkedAccounts && !linkedAccounts && (
        <div className="linked-account-modal">
          <h3>Oops! No Publisher Ad Accounts Found</h3>
        </div>
      )}
    </SimpleModal>
  );
};

LinkedAccountModal.propTypes = {
  pid: PropTypes.number.isRequired,
  tid: PropTypes.number.isRequired,
  ruleID: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default LinkedAccountModal;
