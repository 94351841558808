/**
 * Component to fetch Provider Links
 */

import { useState, useEffect, useCallback } from "react";
import { Provider } from "../../../../services/Provider";
import { toastr } from "react-redux-toastr";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../../helpers/constant/misc";

export const useGetProviderLinks = ({ providerOne, providerTwo, tag_type }) => {
  //provider links for provider one and two
  const [providerLinksOne, setProviderLinksOne] = useState([]);
  const [providerLinksTwo, setProviderLinksTwo] = useState([]);

  const fetchProviderLinks = useCallback(
    (provider, selection) => {
      let params = {
        order_by: "id",
        order_direction: "ASC",
        size: DEFAULT_FETCH_PARAM_SIZE,
        type: tag_type
      };
      Provider.fetchProviderLinks(provider, params)
        .then((response) => {
          if (response.success) {
            const data = response.data.provider.provider_links;
            const providerLinks = [];
            for (let p of data) {
              providerLinks.push({
                label: p.link.length < 51 ? p.link : p.link.substring(0, 50).concat(" ..."),
                value: p.id,
                disabled: p.disabled,
                no_of_rules: p.no_of_rules,
                no_of_active_rules: p.no_of_active_rules,
                targetObj: p?.targetObj || []
              });
            }
            if (selection === 1) {
              setProviderLinksOne(providerLinks);
            } else {
              setProviderLinksTwo(providerLinks);
            }
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          setProviderLinksOne([]);
          setProviderLinksTwo([]);
          toastr.error(
            "Oops!",
            `Provider ID-${provider}'s links could not be loaded!`
          );
          console.trace(error.message);
        });
    },
    [tag_type]
  );

  /**loading provider links when different publisher is selected */
  useEffect(() => {
    if (providerOne) {
      fetchProviderLinks(providerOne, 1);
    } else {
      setProviderLinksOne([]);
    }
  }, [providerOne, fetchProviderLinks]);

  /**loading provider links when different publisher is selected */
  useEffect(() => {
    if (providerTwo) {
      fetchProviderLinks(providerTwo, 2);
    } else {
      setProviderLinksTwo([]);
    }
  }, [providerTwo, fetchProviderLinks]);

  return {
    providerLinksOne,
    providerLinksTwo
  };
};
