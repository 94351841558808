import moment from "moment";
/**
 *  Custom Hook to download admin reports
 */
import { Report } from "../../services/Report";
import { toastr } from "react-redux-toastr";
import { CUSTOM } from "../../helpers/constant/filters";
import { useRevColHeadersContext } from "../../contexts/revenue-context";

export const useReportDownload = ({ filterValues }) => {
  const [colHeaders] = useRevColHeadersContext();
  let tableHeaders = colHeaders.tableHeaders;
  function getParams() {
    let params = {};
    const {
      selectedProviders,
      selectedCountries,
      selectedPublishers,
      fromDate,
      toDate,
      selectedInterval,
      selectedCompairServerSearches,
      selectedGroupBy,
      selectedRuleIds,
      selectedTagIds,
      selectedTagTypes,
      selectedPlatforms,
      selectedSearchEngines,
      selectedChannels,
      selectedPublisherAccounts,
      selectedPeriod,
      orderBy,
      orderDirection
    } = filterValues;
    if (selectedChannels?.length > 0) {
      let selection = [];
      for (let i in selectedChannels) {
        selection.push(selectedChannels[i]);
      }
      params["channel"] = selection;
    }
    if (selectedPublisherAccounts?.length > 0) {
      let selection = [];
      for (let i in selectedPublisherAccounts) {
        selection.push(selectedPublisherAccounts[i]);
      }
      params["pub_account_id"] = selection;
    }

    if (selectedProviders?.length > 0) {
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["provider_id"] = selection;
    }

    if (selectedRuleIds?.length > 0) {
      let selection = [];
      for (let i in selectedRuleIds) {
        selection.push(selectedRuleIds[i]);
      }
      params["rule_id"] = selection;
    }

    if (selectedTagIds?.length > 0) {
      let selection = [];
      for (let i in selectedTagIds) {
        selection.push(selectedTagIds[i]);
      }
      params["tag_id"] = selection;
    }

    if (selectedCountries?.length > 0) {
      let selection = [];
      for (let i in selectedCountries) {
        selection.push(selectedCountries[i]);
      }
      params["geo"] = selection;
    }

    if (selectedInterval) {
      params["interval"] = selectedInterval;
    }

    if (selectedCompairServerSearches) {
      params["total_searches_zero_and_higher_server_searches"] =
        selectedCompairServerSearches;
    }

    if (selectedGroupBy?.length > 0) {
      let selection = [];
      for (let i in selectedGroupBy) {
        selection.push(selectedGroupBy[i]);
      }
      params["group_by"] = selection;
    }

    if (selectedPublishers.length > 0) {
      let selection = [];
      for (let i in selectedPublishers) {
        selection.push(selectedPublishers[i]);
      }
      params["publisher"] = selection;
    }

    if (selectedTagTypes?.length > 0) {
      let selection = [];
      for (let i in selectedTagTypes) {
        selection.push(selectedTagTypes[i]);
      }
      params["tag_type_id"] = selection;
    }

    if (selectedPlatforms?.length > 0) {
      let selection = [];
      for (let i in selectedPlatforms) {
        selection.push(selectedPlatforms[i]);
      }
      params["platform_id"] = selection;
    }

    if (selectedSearchEngines?.length > 0) {
      let selection = [];
      for (let i in selectedSearchEngines) {
        selection.push(selectedSearchEngines[i]);
      }
      params["search_engine_id"] = selection;
    }

    if (selectedPeriod !== CUSTOM) {
      if (selectedPeriod) params[selectedPeriod] = true;
    } else {
      if (fromDate && moment(fromDate).isValid()) {
        params["start_date"] = moment(fromDate).format("YYYY-MM-DD");
      }
      if (toDate && moment(toDate).isValid()) {
        params["end_date"] = moment(toDate).format("YYYY-MM-DD");
      }
    }

    if (orderBy) {
      params["order_by"] = orderBy;
      params["order_direction"] = orderDirection;
    }

    let filteredTableHeaders = tableHeaders
      .filter((header) => header.show && header.value !== "sno")
      .map((header) => {
        return header.value;
      });

    params["tableHeaders"] = filteredTableHeaders;

    return params;
  }

  const downloadReport = () => {
    Report.downloadReport(getParams())
      .then(({ data, status }) => {
        if (status === 200) {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${new Date()}.csv`);
          document.body.appendChild(link);
          link.click();
        } else {
          throw new Error(JSON.stringify(data?.message || "Cannot download"));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Cannot download at the moment!");
      });
  };

  return {
    downloadReport
  };
};
