import React from "react";
import PropTypes from "prop-types";

const DuplicateAdvertiserLinksMsg = ({ duplicates }) => {
  if (duplicates.length === 0) {
    return null;
  }
  return (
    <div className="target-duplicate-advertiser-links-msg flow-content">
      <h2>Duplicated Advertiser Links</h2>
      <p>
        Please select another advertiser links in the current form or
        disable/archive the rules in another publisher tags
      </p>
      <hr />
      {duplicates.map((duplicate, index) => {
        return (
          <>
            <div
              onClick={() =>
                window.open(`/target/edit/${duplicate.tid}`, "_blank")
              }
              key={`${duplicate?.sin}-${duplicate?.id}-${duplicate?.tid}-${duplicate?.rule_id}-${index}`}
            >
              <p>Tag ID: {`${duplicate?.tid}`}</p>
              <p>Rule ID : {`${duplicate?.rule_id}`}</p>
              <p>Sub ID: {`${duplicate?.sin}`}</p>
            </div>
          </>
        );
      })}
    </div>
  );
};

DuplicateAdvertiserLinksMsg.propTypes = {
  duplicates: PropTypes.array.isRequired
};
export default DuplicateAdvertiserLinksMsg;
