const PublisherActionTypes = {
  FETCH_PUBLISHER_START: "FETCH_PUBLISHER_START",
  FETCH_PUBLISHER_SUCCESS: "FETCH_PUBLISHER_SUCCESS",
  FETCH_PULISHER_FAILURE: "FETCH_PUBLISHER_FAILURE",
  ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
  FETCH_PUBLISHERS_LIST_START: "FETCH_PUBLISHERS_LIST_START",
  FETCH_PUBLISHERS_LIST_FAILURE: "FETCH_PUBLISHERS_LIST_FAILURE",
  FETCH_PUBLISHERS_LIST_SUCCESS: "FETCH_PUBLISHERS_LIST_SUCCESS",
  UPDATE_PUBLISHERS_LIST: "UPDATE_PUBLISHERS_LIST"
};

export default PublisherActionTypes;
