import Api from "../api";

class ReportingLogs {
  fetchReports = async (params={}) => {
    const response = await Api.get('skippedrows',null,params)
    return response.body;
  };
  fetchReportsById = async(id,params={})=>{
    const response = await Api.get(`skippedrows/${id}`,null,params)
    return response.body;
};
}

export default new ReportingLogs();
