import React from "react";
import {
  FormControl,
  FormControlLabel,
  TextField,
  Tooltip,
  IconButton,
  FormHelperText
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Switch from "@material-ui/core/Switch";
import CloseIcon from "@material-ui/icons/Close";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import RelatedProviderLinkIcon from "../RelatedProviderLinkIcon";
import RelatedProviderLinkModal from "../RelatedProviderLinkModal";
import { useModal } from "../../../hooks/useModal";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";

const LinkBox = ({
  linkValues,
  handleLinkFormValueChange,
  linkIndex,
  handleDeleteAction,
  linkErrors,
  platforms,
  searchEngines,
  tagTypes
}) => {
  const [modalOpen, openModal, closeModal] = useModal();

  //form values

  const link = linkValues?.link || "";
  const linkID = linkValues?.id || "";
  const noOfRules = linkValues?.no_of_rules || 0;
  const disabled = linkValues?.disabled || false;
  const targetObj = linkValues?.targetObj || [];
  const description = linkValues?.description || "";
  const searchEngineId = linkValues?.search_engine_id || "";
  const platformId = linkValues?.platform_id || "";
  const tagTypeId = linkValues?.tag_type_id || "";

  return (
    <div className="provider-link">
      <div className="provider-link__entry-items">
        <FormControl className="provider-link__link-id" fullWidth>
          <TextField
            label="ID"
            variant="outlined"
            name="link-id"
            type="number"
            value={linkID}
            disabled
          />
        </FormControl>
        <FormControl className="provider-link__link" fullWidth>
          <TextField
            label="Link"
            variant="outlined"
            name="link"
            value={link}
            onChange={(e) => handleLinkFormValueChange(e, linkIndex)}
          />
          {linkErrors?.link && (
            <FormHelperText className="red-text">
              Please make sure the link is valid, includes [Q] and uses only [ ]
              Brackets.
            </FormHelperText>
          )}
        </FormControl>
        <div className="provider-link__dropdowns">
          <div>
            <AutoCompleteSelect
              selected={searchEngineId}
              data={searchEngines || []}
              handleChange={(value) =>
                handleLinkFormValueChange(
                  {
                    target: {
                      name: "search_engine",
                      value
                    }
                  },
                  linkIndex
                )
              }
              label={"Search Engine"}
              enableMultiple={false}
              cannotSelectDisabled={false}
            />
            {linkErrors?.search_engine_id && (
              <FormHelperText className="red-text">
                Please make sure to select the search engine
              </FormHelperText>
            )}
          </div>
          <div>
            <AutoCompleteSelect
              selected={tagTypeId}
              data={tagTypes || []}
              handleChange={(value) =>
                handleLinkFormValueChange(
                  {
                    target: {
                      name: "tag_type",
                      value
                    }
                  },
                  linkIndex
                )
              }
              label={"Tag Types"}
              enableMultiple={false}
              cannotSelectDisabled={false}
            />
            {linkErrors?.tag_type_id && (
              <FormHelperText className="red-text">
                Please make sure to select the Tag Type
              </FormHelperText>
            )}
          </div>
          <div>
            <AutoCompleteSelect
              selected={platformId}
              data={platforms || []}
              handleChange={(value) =>
                handleLinkFormValueChange(
                  {
                    target: {
                      name: "platform",
                      value
                    }
                  },
                  linkIndex
                )
              }
              label={"Platforms"}
              enableMultiple={false}
              cannotSelectDisabled={false}
            />
            {linkErrors?.platform_id && (
              <FormHelperText className="red-text">
                Please make sure to select the Platform
              </FormHelperText>
            )}
          </div>
        </div>

        <MoreVertIcon className="vertical-divider" />
        <MoreHorizIcon className="horizontal-divider" />
      </div>
      <div className="provider-link__mapped-items">
        <FormControl className="provider-link__description">
          <TextField
            label="Description"
            variant="outlined"
            name="description"
            value={description}
            onChange={(e) => handleLinkFormValueChange(e, linkIndex)}
          />
        </FormControl>
      </div>
      <div className="provider-link__actions">
        <RelatedProviderLinkIcon openModal={openModal} noOfRules={noOfRules} />
        <FormControlLabel
          control={
            <Tooltip title={`${true ? "Disable Link" : "Enable Link"}`}>
              <Switch
                checked={!disabled}
                onChange={(e) => handleLinkFormValueChange(e, linkIndex)}
                name="disabled"
              />
            </Tooltip>
          }
        />
        <Tooltip title="Delete Link" className="provider-link__close">
          <IconButton
            color={true ? "secondary" : "default"}
            onClick={() => {
              handleDeleteAction(linkIndex);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <RelatedProviderLinkModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        targetObj={targetObj}
        linkID={linkID}
      />
    </div>
  );
};

export default LinkBox;
