import React from "react";
import SimpleModal from "../SimpleModal/SimpleModal";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const ConfirmBox = ({
  message,
  confirmAction,
  denyAction,
  hideCloseButton = false
}) => {
  return (
    <SimpleModal handleClose={denyAction}>
      <div className="delete-confirm-box">
        <Typography component="h3">{message}</Typography>
        <div className="delete-confirm-box__buttons">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => confirmAction()}
          >
            Yes
          </Button>
          {!hideCloseButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => denyAction()}
            >
              No
            </Button>
          )}
        </div>
      </div>
    </SimpleModal>
  );
};

export default ConfirmBox;
