import React, { useEffect, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import EditableCell from "./layouts/EditableCell";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import NoData from "./layouts/NoData";
import WithSpinner from "../WithSpinner/WithSpinner";
import { getActionIcons } from "../../helpers/icon";
import { Tooltip } from "@material-ui/core";
import HoverActionCell from "./layouts/HoverActionCell";
import moment from "moment";

const useStyles = makeStyles({
  capitalize: {
    textTransform: "capitalize"
  }
});
const ListingTable = React.memo(
  ({
    headers,
    rowValues,
    actions,
    selected = [],
    setSelected,
    tableType = "",
    handleStatusToggle,
    handleCellValueUpdate,
    page,
    setPage,
    size = 5,
    setSize,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    total,
    noActions = false,
    noIds = false,
    noCheckboxes = false,
    rowsPerPageOptions = [5, 10, 15, 20],
    handleEditAction = null,
    paginateRequired = true,
    handleRouteChange,
    hoverButtonMsg,
    hoverButtonAction,
    ...props
  }) => {
    const classes = useStyles();
    const stableSetPage = useCallback(setPage, [setPage]);
    //fix for when table page exceeds data value
    useEffect(() => {
      if (rowValues.length === 0 && page !== 1) {
        stableSetPage(1);
      }
      if (!page) {
        stableSetPage(1);
      }
    }, [rowValues, page, stableSetPage]);

    const onChangePage = (event, nextPage) => {
      setPage(nextPage + 1);
    };
    const onChangeRowsPerPage = (event) => {
      setPage(1);
      setSize(event.target.value);
    };
    const handleRowClick = (id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    };
    const handleSelectAllClick = (event) => {
      let currentSelected = [...selected];
      if (event.target.checked) {
        rowValues.forEach((r) => {
          let selectedIndex = currentSelected.indexOf(r.id);
          if (selectedIndex === -1) {
            currentSelected.push(r.id);
          }
        });
      } else {
        rowValues.forEach((r) => {
          let selectedIndex = currentSelected.indexOf(r.id);
          if (selectedIndex !== -1) {
            currentSelected.splice(selectedIndex, 1);
          }
        });
      }
      setSelected(currentSelected);
    };

    const isSelected = (id) => {
      const selectedIndex = selected.indexOf(id);
      if (selectedIndex === -1) {
        return false; //not selected yet
      }
      return true; //selected
    };

    const changeStatus = (status) => {
      if (status === "Failed") {
        return "Failed API";
      }
      if (status === "Processing") {
        return "Processing";
      }
      if (status === "Uploaded") {
        return "Uploaded";
      }
      if (status === "Constant") {
        return "Initialized";
      } else {
        return status;
      }
    };

    //function to handle editable cells or cells that can be toggled
    const getCellValue = (header, data) => {
      if (header === "Publisher") {
        return (
          <TableCell key={`ab+${header}`}>{data.publisher_info.name}</TableCell>
        );
      }
      /**
       * If cell value is not null or undefined
       */
      if (data[header] !== undefined && data[header] !== null) {
        /**
         * For Publisher and provider, name and detail cell value,
         * return a editable cell
         */
        if (
          (header === "name" || header === "details") &&
          (tableType === "publisher" || tableType === "provider")
        ) {
          return (
            <EditableCell
              key={`${data.id}+${header}`}
              header={header}
              data={data}
              handleCellValueUpdate={handleCellValueUpdate}
            />
          );
        }
        /**
         * For Publisher and provider , status cell needs to be of toggle type
         * that updates the status
         */
        if (
          header === "status" &&
          (tableType === "publisher" || tableType === "provider")
        ) {
          return (
            <TableCell key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={data.status === "active"}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleStatusToggle(data.id, data.status, data);
                    }}
                    //prevent event bubbling on double click
                    onDoubleClick={(e) => e.stopPropagation()}
                    name="Status"
                  />
                }
              />
            </TableCell>
          );
        }

        if (header === "display_in_upload_screen" && tableType === "provider") {
          return (
            <TableCell key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={data.display_in_upload_screen}
                    onChange={(e) => {
                      e.stopPropagation();
                      props.handleDisplayStatus(
                        data.id,
                        data.display_in_upload_screen,
                        data
                      );
                    }}
                    //prevent event bubbling on double click
                    onDoubleClick={(e) => e.stopPropagation()}
                    name="Display in CSV UPLOAD"
                  />
                }
              />
            </TableCell>
          );
        }

        if (tableType === "providerLinks")
          if (header === "disabled" && tableType === "providerLinks") {
            /** For provider links, link status comes in "disabled" header,
             * if disabled is false, the switch is checked else not checked
             */
            return (
              <TableCell key={`ab+${header}`}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!data.disabled}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleStatusToggle(data.id, data.disabled, data);
                      }}
                      //prevent event bubbling on double click
                      onDoubleClick={(e) => e.stopPropagation()}
                      name="Status"
                    />
                  }
                />
              </TableCell>
            );
          }

        if (header === "blocked" && tableType === "user") {
          return (
            <TableCell key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!data.blocked}
                    onChange={() =>
                      handleStatusToggle(data.id, data.blocked, data)
                    }
                    name="Status"
                  />
                }
              />
            </TableCell>
          );
        }

        if (header === "link" && tableType === "providerLinks") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              <p title={data?.link}>{data?.link.length < 51 ? data?.link : data?.link.substring(0, 50).concat(" ...")}</p>
            </TableCell>
          );
        }

        if (header === "provider" && tableType === "user") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.provider?.name}
            </TableCell>
          );
        }

        if (header === "provider" && tableType === "skippedrows") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.provider?.id + "-" + data?.provider.name}
            </TableCell>
          );
        }

        if (header === "createdAt" && tableType === "skippedrows") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {moment.utc(data?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </TableCell>
          );
        }

        if (header === "publisher" && tableType === "user") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.publisher?.name}
            </TableCell>
          );
        }

        if (header === "publisher_id" && tableType === "publisheraccount") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.publisher?.id + "-" + data?.publisher?.name}
            </TableCell>
          );
        }

        if (header === "provider_id" && tableType === "publisheraccount") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.provider?.id + "-" + data?.provider?.name}
            </TableCell>
          );
        }

        if (header === "advertiser_id" && tableType === "apiDetails") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.provider?.id + "-" + data?.provider?.name}
            </TableCell>
          );
        }

        if (header === "last_updated_db" && tableType === "apiDetails") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {moment
                .utc(data?.last_updated_db)
                .format("MMMM Do YYYY, h:mm:ss a")}
            </TableCell>
          );
        }

        if (header === "apply_from_data" && tableType === "apiDetails") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {moment(data?.apply_from_data).format("YYYY-MM-DD")}
            </TableCell>
          );
        }
        if (header === "apply_to_date" && tableType === "apiDetails") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {moment(data?.apply_to_date).format("YYYY-MM-DD")}
            </TableCell>
          );
        }

        if (header === "called_api_date" && tableType === "apiDetails") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {moment
                .utc(data?.called_api_date)
                .format("MMMM Do YYYY, h:mm:ss a")}
            </TableCell>
          );
        }

        if (header === "uploaded_status" && tableType === "apiDetails") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {changeStatus(data?.uploaded_status)}
            </TableCell>
          );
        }

        if (header === "status" && tableType === "publisheraccount") {
          return (
            <TableCell key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={data.status}
                    // onChange={() =>
                    //   handleStatusToggle(data.id, data.status, data)
                    // }
                    name="Status"
                  />
                }
              />
            </TableCell>
          );
        }

        if (header === "account" && tableType === "user") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.account?.name}
            </TableCell>
          );
        }
        /**
         * For Targetings , "Active" cell needs to be of toggle type
         * that updates the current status of the targeting
         */
        if (header === "is_active" && tableType === "targetings") {
          return (
            <TableCell key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={data.is_active}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleStatusToggle(data.id, data.is_active, data);
                    }}
                    //prevent event bubbling on double click
                    onDoubleClick={(e) => e.stopPropagation()}
                    name="Is Active"
                  />
                }
              />
            </TableCell>
          );
        }
        /**
         * Log Table, Rule Cell value that needs to redirect to Targeting Page
         * using cid i.e the id of targeting
         */
        if (tableType === "logs" && header === "cid") {
          return (
            <TableCell
              key={`ab+${header}`}
              className="cursor-pointer link-text"
              onClick={() => handleRouteChange(data.cid)}
            >
              <Tooltip title="Open Targeting">
                <span>{data[header].toString()}</span>
              </Tooltip>
            </TableCell>
          );
        }
        /**
         * Targeting Table, Tag Cell Value
         */
        if (tableType === "targetings" && header === "link") {
          return (
            <HoverActionCell
              header={header}
              data={data}
              key={`hoverable+${header}`}
              hoverButtonAction={() => hoverButtonAction(data.id)}
              hoverButtonMsg={hoverButtonMsg}
            />
          );
        }

        /**
         * Normal value that is not falsy and does not match the above conditions
         */
        return (
          <TableCell key={`ab+${header}`}>{data[header].toString()}</TableCell>
        );
      } else {
        /**
         * Undefined cell value for name and details that needs to be editable
         */
        if (
          (header === "name" || header === "details") &&
          (tableType === "publisher" || tableType === "provider")
        ) {
          return (
            <EditableCell
              key={`${data.id}+${header}`}
              header={header}
              data={data}
              handleCellValueUpdate={handleCellValueUpdate}
            />
          );
        }
      }

      return <TableCell key={`ab+${header}`}></TableCell>;
    };

    const currentRowsSelected = () => {
      let selectedCount = 0;
      rowValues.forEach((r) => {
        let selectedIndex = selected.indexOf(r.id);
        if (selectedIndex !== -1) {
          selectedCount = selectedCount + 1;
        }
      });
      return selectedCount;
    };

    const handleOrderByChange = (newOrderBy) => {
      //return in case of Serial Number Header being clicked
      if (newOrderBy === "sno") return;
      newOrderBy !== orderBy && setOrderBy(newOrderBy);
      let newOrderDirection = orderDirection === "DESC" ? "ASC" : "DESC";
      setOrderDirection(newOrderDirection);
    };

    const handleValueRowDoubleClick = (e, id) => {
      if (tableType === "targetings") {
        handleEditAction(id);
      }
    };

    const getActionColumnPublisher = (value, dataId, noOfTargetings = "0") => {
      let icon;
      if (value.icon === "TargetinglinkIcon") {
        icon = parseInt(noOfTargetings) > 0 ? getActionIcons(value.icon) : null;
      } else if (value.icon === "TargetinglinkIconDisabled") {
        icon =
          parseInt(noOfTargetings) === 0 ? getActionIcons(value.icon) : null;
      } else {
        icon = getActionIcons(value.icon);
      }
      return (
        icon && (
          <span
            className="action-icon"
            key={value.name}
            onClick={() => value.action(dataId)}
          >
            {icon}
          </span>
        )
      );
    };

    const getActionColumn = (value, dataId, data = {}) => (
      <span
        className="action-icon"
        key={value.name}
        onClick={(e) => {
          e.stopPropagation();
          value.action(dataId, data);
        }}
        //prevent event bubbling on double click
        onDoubleClick={(e) => e.stopPropagation()}
      >
        {tableType === "providerLinks"
          ? getActionIcons(value.icon, data)
          : getActionIcons(value.icon)}
      </span>
    );

    if (rowValues.length === 0) return <NoData />;
    return (
      <Container className="table__container">
        <TableContainer component={Paper} elevation={3}>
          <Table className="list-table">
            <TableHead>
              <TableRow
                className={`tableRow ${
                  rowValues.length === currentRowsSelected() &&
                  rowValues.length !== 0
                    ? "rowSelected"
                    : ""
                }`}
              >
                {!noCheckboxes && (
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="status"
                          color="primary"
                          onChange={handleSelectAllClick}
                          checked={
                            rowValues.length === currentRowsSelected() &&
                            rowValues.length !== 0
                          }
                        />
                      }
                    />
                  </TableCell>
                )}

                {headers.map((header, index) => {
                  return (
                    <TableCell
                      className={classes.capitalize}
                      key={`${header.title}+${index}`}
                    >
                      <div
                        className="table_header"
                        onClick={() => handleOrderByChange(header.value)}
                      >
                        {orderBy === header.value ? (
                          orderDirection === "DESC" ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )
                        ) : (
                          ""
                        )}
                        {header.title}
                      </div>
                    </TableCell>
                  );
                })}
                {!noActions && (
                  <TableCell key="tbHeaderNormal">Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowValues.map((data, index) => {
                return (
                  <TableRow
                    key={` ${index} + 123`}
                    className={`tableRow ${
                      isSelected(data.id) ? "rowSelected" : ""
                    }`}
                    onDoubleClick={(e) => handleValueRowDoubleClick(e, data.id)}
                  >
                    {!noCheckboxes && (
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="status"
                              color="primary"
                              checked={isSelected(data.id)}
                              onClick={() => handleRowClick(data.id)}
                            />
                          }
                        />
                      </TableCell>
                    )}

                    {headers.map((header) => getCellValue(header.value, data))}
                    {!noActions && (
                      <TableCell style={{ display: "flex" }}>
                        {actions.map((value) =>
                          tableType === "publisher"
                            ? getActionColumnPublisher(
                                value,
                                data.id,
                                data?.no_of_targetings
                              )
                            : getActionColumn(value, data.id, data)
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {paginateRequired && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={total}
            rowsPerPage={size}
            page={page - 1}
            component={Paper}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        )}
      </Container>
    );
  }
);

export default WithSpinner(ListingTable);
