/**
 * Custom Hook Component to handle Form Value Changes for Rules
 */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateTargetingRules } from "../../../../reducers/target/target.action";
import { useModal } from "../../../../hooks/useModal";

export const useHandleFormValueChange = ({
  targetingRules,
  handleFormTouched,
  oldRules
}) => {
  let newTargetingRules = [...targetingRules];
  const dispatch = useDispatch();

  const [statusChangeOpen, openStatusChange, closeStatusChange] =
    useModal(false);
  const [statusChangeValue, setStatusChangeValue] = useState({
    originalIndex: "",
    disabled: ""
  });

  const [statusInformOpen, openStatusInform, closeStatusInform] =
    useModal(false);

  // Handler For Daily Cap , Daily Frequency and Comment
  const handleValuesChange = (
    name,
    value,
    currentTargetingRules,
    originalIndex
  ) => {
    currentTargetingRules[name] = value;
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateTargetingRules(newTargetingRules));
  };

  const handleProviderOneChange = ({
    event,
    providerDetailsArrayExist,
    currentTargetingRules,
    isValueEmpty,
    originalIndex
  }) => {
    if (providerDetailsArrayExist) {
      currentTargetingRules.provider_details[0] = {
        ...currentTargetingRules.provider_details[0],
        provider_id: event.target.value,
        provider_link: ""
      };
      let areThereTwoProviderDetails =
        currentTargetingRules.provider_details.length === 2;
      if (areThereTwoProviderDetails && isValueEmpty) {
        currentTargetingRules.provider_details.shift();
      }
    } else {
      //if provider_details don't exist create new array and push
      currentTargetingRules.provider_details = [];
      currentTargetingRules.provider_details.push({
        provider_id: event.target.value
      });
    }
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateTargetingRules(newTargetingRules));
  };

  const handleProviderTwoChange = ({
    event,
    providerDetailsArrayExist,
    currentTargetingRules,
    isValueEmpty,
    originalIndex
  }) => {
    if (providerDetailsArrayExist) {
      let areThereTwoProviderDetails =
        currentTargetingRules.provider_details.length === 2;
      if (areThereTwoProviderDetails) {
        currentTargetingRules.provider_details[1] = {
          ...currentTargetingRules.provider_details[1],
          provider_id: event.target.value,
          provider_link: ""
        };
        if (isValueEmpty) {
          currentTargetingRules.provider_details.pop();
          currentTargetingRules.provider_details[0] = {
            ...currentTargetingRules.provider_details[0],
            traffic: 100
          };
        }
      } else {
        currentTargetingRules.provider_details.push({
          provider_id: event.target.value
        });
      }
    } else {
      //if first provider detail does not exist
      //don't do anything
      return;
    }
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateTargetingRules(newTargetingRules));
  };

  const handleProviderChange = (event, originalIndex, disabledProviders) => {
    let isDisabledProviderSelected = disabledProviders.includes(
      event.target.value
    );
    if (isDisabledProviderSelected) {
      return;
    }
    let newTargetingRules = [...targetingRules];
    newTargetingRules[originalIndex] =
      { ...newTargetingRules[originalIndex] } || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    let isItProviderOne = event.target.name === "providerOne";
    let isItProviderTwo = event.target.name === "providerTwo";
    let isValueEmpty = event.target.value === "";
    let providerDetailsArrayExist = currentTargetingRules.provider_details
      ? true
      : false;
    if (isItProviderOne) {
      handleProviderOneChange({
        event,
        providerDetailsArrayExist,
        currentTargetingRules,
        isValueEmpty,
        originalIndex
      });
    }

    if (isItProviderTwo) {
      handleProviderTwoChange({
        event,
        providerDetailsArrayExist,
        currentTargetingRules,
        isValueEmpty,
        originalIndex
      });
    }
    handleFormTouched();
  };

  const handleTrafficOneChange = (value, originalIndex) => {
    if (parseInt(value) > 100 || parseInt(value) < 0) return;
    let newTargetingRules = [...targetingRules];
    newTargetingRules[originalIndex] =
      { ...newTargetingRules[originalIndex] } || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    let providerDetailsArrayExist = currentTargetingRules.provider_details
      ? true
      : false;

    let isValueNot100OrEmpty = parseInt(value) !== 100 || value !== "";
    if (providerDetailsArrayExist) {
      let areThereTwoProviderDetails =
        currentTargetingRules.provider_details.length === 2;

      currentTargetingRules.provider_details[0] = {
        ...currentTargetingRules.provider_details[0],
        traffic: value
      };
      if (areThereTwoProviderDetails) {
        currentTargetingRules.provider_details[1] = {
          ...currentTargetingRules.provider_details[1],
          traffic: Math.abs(100 - value)
        };
      } else {
        currentTargetingRules.provider_details.push({
          traffic: Math.abs(100 - value)
        });
      }
    }
    //if provider_details don't exist
    else {
      currentTargetingRules.provider_details = [];
      currentTargetingRules.provider_details.push({
        traffic: value
      });
      if (isValueNot100OrEmpty) {
        currentTargetingRules.provider_details.push({
          traffic: Math.abs(value - 100)
        });
      }
    }
    //dispatching the changes to the redux store
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateTargetingRules(newTargetingRules));
    handleFormTouched();
  };

  const handleTrafficTwoChange = (value, originalIndex) => {
    if (parseInt(value) > 100 || parseInt(value) < 0) return;
    let newTargetingRules = [...targetingRules];
    newTargetingRules[originalIndex] =
      { ...newTargetingRules[originalIndex] } || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    let providerDetailsArrayExist = currentTargetingRules.provider_details
      ? true
      : false;
    if (providerDetailsArrayExist) {
      currentTargetingRules.provider_details[1] = {
        ...currentTargetingRules.provider_details[1],
        traffic: parseInt(value, 10)
      };
      currentTargetingRules.provider_details[0] = {
        ...currentTargetingRules.provider_details[0],
        traffic: Math.abs(100 - value)
      };
    } else {
      //if first provider detail does not exist
      //don't do anything
      return;
    }
    //dispatching the changes to the redux store
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateTargetingRules(newTargetingRules));
    handleFormTouched();
  };

  //Original Index -> refers to the index of the rule block
  const handleChange = (name, value, originalIndex) => {
    newTargetingRules[originalIndex] = newTargetingRules[originalIndex] || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    //handling value change for daily_cap, daily_frequency, comment
    if (
      name === "daily_cap" ||
      name === "daily_frequency" ||
      name === "comment"
    ) {
      handleValuesChange(name, value, currentTargetingRules, originalIndex);
    }
    handleFormTouched();
  };

  const changeStatus = (originalIndex, disabled) => {
    let newTargetingRules = [...targetingRules];
    newTargetingRules[originalIndex] =
      { ...newTargetingRules[originalIndex] } || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    currentTargetingRules.disabled = !disabled;
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateTargetingRules(newTargetingRules));
    handleFormTouched();
  };

  const updateStatusChange = () => {
    changeStatus(statusChangeValue.originalIndex, statusChangeValue.disabled);
    closeAllModals();
  };

  const closeAllModals = () => {
    setStatusChangeValue({
      originalIndex: "",
      disabled: ""
    });
    closeStatusInform();
    closeStatusChange();
  };

  const handleEnableRule = (originalIndex, disabled, rule_id) => {
    let allowChange = true;
    // if already saved rule, check for the disabled rule status. It it is disabled, show status inform model otherwise change the status
    if (rule_id) {
      let oldTargetingRules = JSON.parse(oldRules);
      let ruleToDelete = oldTargetingRules.find((rule) => rule.id === rule_id);
      if (ruleToDelete.disabled) {
        openStatusInform();
        allowChange = false;
      }
    }

    allowChange && changeStatus(originalIndex, disabled);
  };

  const handleDisableRule = (originalIndex, disabled, rule_id) => {
    setStatusChangeValue({
      originalIndex,
      disabled
    });
    openStatusChange();
  };
  // handler for disable/enable button for targeting rule
  const handleDisabledChange = (originalIndex, disabled, rule_id) => {
    if (disabled) {
      handleEnableRule(originalIndex, disabled, rule_id);
    } else {
      handleDisableRule(originalIndex, disabled, rule_id);
    }
  };

  const isProviderLinkDisabled = (id, providerLinks) => {
    let providerLink = providerLinks.find(
      (providerLink) => providerLink.value === id
    );
    return providerLink.disabled;
  };

  const handleProviderLinkOneChange = ({
    originalIndex,
    currentTargetingRules,
    providerDetailsArrayExist,
    value
  }) => {
    if (providerDetailsArrayExist) {
      currentTargetingRules.provider_details[0] = {
        ...currentTargetingRules.provider_details[0],
        provider_link: value
      };
    } else {
      //if provider_details don't exist create new array and push
      currentTargetingRules.provider_details = [];
      currentTargetingRules.provider_details.push({
        provider_link: value
      });
    }
    //dispatching the changes to the redux store
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateTargetingRules(newTargetingRules));
  };

  const handleProviderLinkTwoChange = ({
    originalIndex,
    currentTargetingRules,
    providerDetailsArrayExist,
    value
  }) => {
    if (providerDetailsArrayExist) {
      let areThereTwoProviderDetails =
        currentTargetingRules.provider_details.length === 2;
      if (areThereTwoProviderDetails) {
        currentTargetingRules.provider_details[1] = {
          ...currentTargetingRules.provider_details[1],
          provider_link: value
        };
      } else {
        currentTargetingRules.provider_details.push({
          provider_link: value
        });
      }
    } else {
      //if first provider detail does not exist
      //don't do anything
      return;
    }
    //dispatching the changes to the redux store
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateTargetingRules(newTargetingRules));
  };

  //handing value change for provider One Link and Provider Two Link
  const handleProviderLinkChanges = (
    name,
    value,
    originalIndex,
    providerLinksOne,
    providerLinksTwo
  ) => {
    //checking if disabled link is selected
    let checkPLinks =
      name === "providerOneLink" ? providerLinksOne : providerLinksTwo;
    if (value) {
      if (isProviderLinkDisabled(value, checkPLinks)) {
        return;
      }
    }

    let newTargetingRules = targetingRules;
    newTargetingRules[originalIndex] = newTargetingRules[originalIndex] || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    let isProviderOneLinkChanged = name === "providerOneLink";
    let isProviderTwoLinkChanged = name === "providerTwoLink";
    let isValueEmpty = value === "";
    let providerDetailsArrayExist = currentTargetingRules.provider_details
      ? true
      : false;
    if (isProviderOneLinkChanged) {
      handleProviderLinkOneChange({
        originalIndex,
        currentTargetingRules,
        providerDetailsArrayExist,
        value
      });
    }

    if (isProviderTwoLinkChanged) {
      handleProviderLinkTwoChange({
        originalIndex,
        currentTargetingRules,
        providerDetailsArrayExist,
        isValueEmpty,
        value
      });
    }
    handleFormTouched();
  };
  return {
    handleChange,
    handleProviderChange,
    handleTrafficOneChange,
    handleTrafficTwoChange,
    handleDisabledChange,
    handleProviderLinkChanges,
    statusChangeOpen,
    statusInformOpen,
    updateStatusChange,
    closeAllModals
  };
};
