import { useState, useEffect } from "react";
import {
  useRevColHeadersContext,
  updateRevColHeaders
} from "../../../../contexts/revenue-context";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { REVENUE_TABLE_HEADERS_PUBLISHER } from "../../../../helpers/constant/localStorage";
import {
  CHANNEL,
  AD_COVERAGE,
  PUBLISHER_REVENUE,
  DATE,
  GEO,
  TOTAL_SEARCHES,
  MONETIZED_SEARCHES,
  CLICKS,
  CTR,
  NET_REVENUE,
  RPM,
  RPC,
  RPMM,
  FOLLOWON_SEARCHES,
  INITIAL_SEARCHES,
  SEARCH_ENGINE,
  LINK_PLATFORM,
  TAG_TYPE
} from "../../../../helpers/constant/filters";
import { table_mapping_order_publisher } from "../../../../helpers/constant/mapping";

function getInitialShowState() {
  let showState = {};
  showState[DATE] = true;
  showState[CHANNEL] = true;
  showState[SEARCH_ENGINE] = true;
  showState[LINK_PLATFORM] = true;
  showState[TAG_TYPE] = true;
  showState[GEO] = true;
  showState[TOTAL_SEARCHES] = true;
  showState[MONETIZED_SEARCHES] = true;
  showState[CLICKS] = true;
  showState[CTR] = true;
  showState[AD_COVERAGE] = true;
  showState[PUBLISHER_REVENUE] = true;
  showState[NET_REVENUE] = true;
  showState[RPM] = true;
  showState[RPMM] = true;
  showState[FOLLOWON_SEARCHES] = true;
  showState[INITIAL_SEARCHES] = true;
  showState["search_counts"] = true;
  showState[RPC] = true;

  showState["sno"] = true;

  return showState;
}

export const useReportTableFilterLogic = ({ displayNames, list }) => {
  const revHeadersCtxValues = useRevColHeadersContext(); // Use Revenue Headers Context
  const revHeadersCtxDispatch = revHeadersCtxValues[1];
  const [tableHeaders, setTableHeaders] = useState([]);
  const [filteredTableHeaders, setFilteredTableHeaders] = useState([]);
  // const [showState, setShowState] = useState(getInitialShowState());
  const [showState, setShowState] = useLocalStorage(
    REVENUE_TABLE_HEADERS_PUBLISHER,
    getInitialShowState()
  );

  const updateHeadersShowValue = (value, index) => {
    let newShowState = { ...showState };
    newShowState[value] = !newShowState[value];
    setShowState(newShowState);
  };

  /** updating table headers when display names and data changes */
  useEffect(() => {
    /**
     *  Function that returns an object of column headers from displayNames
     *  to be used in Table
     */
    function getColumnHeaders(displayNames) {
      let columnHeaders = {};
      Object.keys(displayNames).forEach((columnValue) => {
        columnHeaders[columnValue] = {
          show: showState[columnValue],
          title: displayNames[columnValue],
          value: columnValue
        };
      });
      return columnHeaders;
    }

    // Remaining Table Column not mapped to display names
    function groupByValueMappedToHeader(value) {
      switch (value) {
        case CHANNEL:
          return { title: "Channel", value: CHANNEL, show: showState[CHANNEL] };
        case AD_COVERAGE:
          return {
            title: "Ad Coverage (%)",
            value: AD_COVERAGE,
            show: showState[AD_COVERAGE]
          };
        case PUBLISHER_REVENUE:
          return {
            title: "Net Revenue",
            value: PUBLISHER_REVENUE,
            show: showState[PUBLISHER_REVENUE]
          };
        case "search_counts":
          return {
            title: "Server total searches",
            value: "search_counts",
            show: showState["search_counts"]
          };
        case SEARCH_ENGINE:
          return {
            title: "Search Engine",
            value: SEARCH_ENGINE,
            show: showState[SEARCH_ENGINE]
          };
        case LINK_PLATFORM:
          return {
            title: "Link Platform",
            value: LINK_PLATFORM,
            show: showState[LINK_PLATFORM]
          };
        case TAG_TYPE:
          return {
            title: "Tag Type",
            value: TAG_TYPE,
            show: showState[TAG_TYPE]
          };
        default:
          return "";
      }
    }

    //function to get headers based on columns state whose show value are set to true
    function getTableHeaders(tableColumns, list) {
      let headers = [{ title: "SNo", value: "sno", show: showState["sno"] }];
      let oneValueFromList = list?.[0] || {};
      let keysFromValue = Object.keys(oneValueFromList);
      table_mapping_order_publisher.forEach((value) => {
        if (keysFromValue.includes(value)) {
          let header = {};
          if (value in tableColumns) {
            header = tableColumns[value];
          } else {
            header = groupByValueMappedToHeader(value);
          }
          if (header) {
            headers.push(header);
          }
        }
      });
      return headers;
    }

    if (displayNames && list && list?.length > 0) {
      let tableColumns = getColumnHeaders(displayNames.fields);
      let tableHeaders = getTableHeaders(tableColumns, list);
      setTableHeaders(tableHeaders);
    }
  }, [displayNames, list, showState]);

  useEffect(() => {
    setTableHeaders((tableHeaders) =>
      tableHeaders.map((header) => {
        header.show = showState[header.value];
        return header;
      })
    );
  }, [showState]);

  useEffect(() => {
    setFilteredTableHeaders(tableHeaders.filter((header) => header.show));
    updateRevColHeaders(revHeadersCtxDispatch, tableHeaders);
    // eslint-disable-next-line
  }, [tableHeaders]);

  return {
    tableHeaders,
    filteredTableHeaders,
    updateHeadersShowValue
  };
};
