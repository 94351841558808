import React from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { FileCopyTwoTone } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

const LinkInfo = () => {
  return (
    <div className="linkInfo">
      <div className="linkInfo__top">
        <FormControl>
          <TextField
            variant="standard"
            margin="normal"
            label="Source ID (SID)"
            name="sid"
            placeholder="Source ID (PID)"
          />
        </FormControl>
        <FormControl>
          <TextField
            variant="standard"
            margin="normal"
            label="Client ID (CID)"
            name="cid"
            placeholder="Client ID (CID)"
          />
        </FormControl>
        <div className="linkInfo__top-cusDomain">
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                name="cusDomain"
              />
            }
            label="Use Custom Domain"
          />
          <FormControl fullWidth>
            <TextField
              variant="standard"
              margin="normal"
              label="Domain"
              name="domain"
              placeholder="Domain"
            />
          </FormControl>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              name="activeLink"
            />
          }
          label="Active Link"
        />
      </div>
      <div className="linkInfo__bottom">
        <FormControl fullWidth>
          <TextField
            variant="standard"
            margin="normal"
            label="Link"
            name="link"
            placeholder="Link"
            disabled
            value={"https://nitro.com/?cid=321&sid=122"}
          />
        </FormControl>
        <Tooltip title="Copy Link">
          <IconButton aria-label="history" className="copy-icon">
            <FileCopyTwoTone fontSize="large" />
          </IconButton>
        </Tooltip>
      </div>
      <span className="linkInfo__closeBtn">
        <IconButton color="secondary">
          <CloseIcon />
        </IconButton>
      </span>
    </div>
  );
};

export default LinkInfo;
