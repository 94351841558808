/**
 * Helper Functions for the Target Form Page
 */
import { TARGETING_TYPES } from "../../../helpers/constant/types";
/*pushing targeting id to all targeting rules array when updating */
export const formatTargetDataForSave = (
  targetingRules,
  targeting_id,
  targetingType
) => {
  //push targeting_id to all the array objects
  let rulesWithTargetId = [];
  for (let j = 0; j < targetingRules.length; j++) {
    let object = { ...targetingRules[j] };
    object["targeting_id"] = targeting_id;
    object["priority"] = j + 1;
    if (typeof object["comment"] === "string") {
      if (object["comment"].trim() === "") {
        object["comment"] = null;
      }
    }
    if (!object["daily_cap"]) {
      object["daily_cap"] = 0;
    }
    if (targetingType === TARGETING_TYPES.WATERFALL) {
      object["daily_frequency"] = null;
    }
    rulesWithTargetId.push(object);
  }
  return rulesWithTargetId;
};

export const sumOfInputedWeights = (targetingRules) => {
  let sum = 0;
  let noOfZeroWeights = 0;
  for (let j = 0; j < targetingRules.length; j++) {
    let isTheRuleDisabled = targetingRules?.[j]?.disabled || false; // adding false because newly added rules does not have this field
    switch (isTheRuleDisabled) {
      case false:
        // check the sum and zero weights for enabled rules
        if (targetingRules?.[j]?.["daily_frequency"]) {
          let value = parseFloat(targetingRules[j]["daily_frequency"]);
          if (value !== 0 && !isNaN(value)) {
            sum = sum + value;
          } else {
            noOfZeroWeights++;
          }
        } else {
          noOfZeroWeights++;
        }
        break;
      default:
        //do nothing for the default case
        break;
    }
  }
  return { sum, noOfZeroWeights };
};

/**
 * Check the sum of all the weights(frequency) of targeting rules
 * @return true if sum is less than equal to 100, return false if not valid
 */
export const areTheWeightsValid = (targetingRules) => {
  let { sum, noOfZeroWeights } = sumOfInputedWeights(targetingRules);
  if (sum === 100 && noOfZeroWeights !== 0) {
    return false;
  }
  if (sum <= 100) {
    return true;
  }
  return false;
};

/**
 * Checks if targeting type of waterfall has atleast one limitless daily cap
 * @returns true if there is atleast one limitless daily_cap value for
 * targeting type of waterfall
 */
export const isThereLimitlessDailyCap = (targetingRules) => {
  let valueIndex = targetingRules.findIndex(
    (rule) => parseInt(rule.daily_cap) === 0
  );
  return valueIndex === -1 ? false : true;
};

/**
 * Input: Targeting Rules,
 * Output: Number of Active Targeting Rules i.e the rule is not disabled
 */
export const getNumberOfActiveRules = (targetingRules) => {
  let noOfActiveRules = 0;
  for (let j = 0; j < targetingRules.length; j++) {
    let isTheRuleDisabled = targetingRules?.[j]?.disabled || false; // adding false because newly added rules does not have this field
    if (!isTheRuleDisabled) {
      noOfActiveRules = noOfActiveRules + 1;
    }
  }
  return noOfActiveRules;
};

export const ROUND_ROBIN_HELP_TEXT = `Each rule is executed once, then cycle starts all over again.`;
export const WATERFALL_HELP_TEXT = `Each rule is executed until it reached its daily cap, only
  then next rule shall be executed.`;


export const REDIRECT_HELP_TEXT = `The links publisher gets will redirect to another page`;
export const XML_HELP_TEXT = `The links publisher gets will return xml feed`;
export const TEMPLATE_HELP_TEXT = `The links publisher gets will return template`;