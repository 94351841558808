import React from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const ProviderLinksFilterSelections = ({
  selectionAddActions,
  filterValues,
  handleSearch,
  dataLists
}) => {
  const {
    handleQueryChange,
    handleTagTypeChange,
    handlePlatformChange,
    handleSearchEngineChange,
    handleProviderChange,
    handlePublisherChange,
    // changeToLive,
    // changeToNotLive,
    // clearIsLive,
    changeToLinked,
    changeToNotLinked,
    clearIsLinked,
    handleTypeChange,
  } = selectionAddActions;

  const {
    query,
    selectedTagTypes,
    selectedPlatforms,
    selectedSearchEngines,
    selectedProviders,
    selectedPublishers,
    // isLive,
    isLinked,
    selectedTypes
  } = filterValues;

  const { tagTypes, platforms, searchEngines, providers, publishers,types } =
    dataLists;


  return (
    <>
      <div className="providerLinks-filter__selections">
        <TextField
          variant="standard"
          margin="normal"
          label="Search for Link"
          onKeyPress={(e) => {
            e.key === "Enter" && handleSearch();
          }}
          value={query}
          onChange={handleQueryChange}
          fullWidth
        />
        <AutoCompleteSelect
          selected={selectedProviders}
          data={providers}
          handleChange={handleProviderChange}
          label={"Advertisers"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={true}
        />
        <AutoCompleteSelect
          selected={selectedPublishers}
          data={publishers}
          handleChange={handlePublisherChange}
          label={"Publishers"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={true}
        />
        <AutoCompleteSelect
          selected={selectedPlatforms}
          data={platforms}
          handleChange={handlePlatformChange}
          label={"Platform"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={false}
        />
        <AutoCompleteSelect
          selected={selectedSearchEngines}
          data={searchEngines}
          handleChange={handleSearchEngineChange}
          label={"Search Engine"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={false}
        />
        <AutoCompleteSelect
          selected={selectedTagTypes}
          data={tagTypes}
          handleChange={handleTagTypeChange}
          label={"Tag Type"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={false}
        />
        <AutoCompleteSelect
            selected={selectedTypes}
            data={types}
            handleChange={handleTypeChange}
            label={"Type"}
            showValueInLabel={false}
            enableMultiple={true}
            withDotsForStatus={false}
          />
        <div
          style={{
            display: "flex",
            gap: "2rem"
          }}
        >
          <div>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLinked.value === "linked"}
                    onChange={
                      isLinked.value === "linked"
                        ? clearIsLinked
                        : changeToLinked
                    }
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                label="Linked"
              />
            </div>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLinked.value === "not_linked"}
                    onChange={
                      isLinked.value === "not_linked"
                        ? clearIsLinked
                        : changeToNotLinked
                    }
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                label="Not Linked"
              />
            </div>
          </div>
          <div>
            {/* <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLive.value === "live"}
                    onChange={
                      isLive.value === "live" ? clearIsLive : changeToLive
                    }
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                label="Live"
              />
            </div>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLive.value === "not_live"}
                    onChange={
                      isLive.value === "not_live"
                        ? clearIsLive
                        : changeToNotLive
                    }
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                label="Pending"
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

ProviderLinksFilterSelections.propTypes = {
  selectionAddActions: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired
};

export default ProviderLinksFilterSelections;
