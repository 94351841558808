import { useEffect, useCallback, useState } from "react";
import moment from "moment";

import {
  INTERVALS_REPORT_ADMIN,
  GROUP_BY_REPORT_ADMIN,
  COUNTRIES,
  PERIODS
} from "../../helpers/constant/filters";
import {
  ADMIN_REPORT_RULE_ID,
  ADMIN_REPORT_ADVERTISER_ID,
  ADMIN_REPORT_FROM_DATE,
  ADMIN_REPORT_INTERVAL
} from "../../helpers/constant/localStorage";

import { useMultipleSelect } from "../../hooks/useMultipleSelect";
import { useSelect } from "../../hooks/useSelect";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { findObject } from "../../helpers";
import { MONTH_TO_DATE } from "../../helpers/constant/filters";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../helpers/constant/misc";

import { Provider } from "../../services/Provider";
import { Publisher } from "../../services/Publisher";
import { Platform } from "../../services/Platform";
import { TagType } from "../../services/TagType";
import { SearchEngine } from "../../services/SearchEngine";
import { PublisherAccount } from "../../services/publisherAccounts";
import { Report } from "../../services/Report";

export const useReportFiltersLogic = () => {
  const [localStorageRuleId, setLocalStorageRuleId] = useLocalStorage(
    ADMIN_REPORT_RULE_ID,
    ""
  );

  const [localStorageAdvertiserId, setLocalStorageAdvertiserId] =
    useLocalStorage(ADMIN_REPORT_ADVERTISER_ID, "");

  const [localStorageFromDate, setLocalStorageFromDate] = useLocalStorage(
    ADMIN_REPORT_FROM_DATE,
    ""
  );

  const [localStorageInterval, setLocalStorageInterval] = useLocalStorage(
    ADMIN_REPORT_INTERVAL,
    ""
  );

  const {
    setData: setProviders,
    selectedData: selectedProviders,
    data: providers,
    handleSelectedDataChange: handleProviderChange,
    removeSelectedDataFromList: removeProviderFromList,
    removeAll: removeAllProviders
  } = useMultipleSelect();

  const {
    setData: setCountriesData,
    selectedData: selectedCountries,
    data: countries,
    handleSelectedDataChange: handleCountryChange,
    removeSelectedDataFromList: removeCountryFromList,
    removeAll: removeAllCountries
  } = useMultipleSelect();

  const {
    setData: setPublishers,
    selectedData: selectedPublishers,
    data: publishers,
    handleSelectedDataChange: handlePublisherChange,
    removeSelectedDataFromList: removePublisherFromList,
    removeAll: removeAllPublishers
  } = useMultipleSelect();

  const {
    setData: setIntervalData,
    selectedData: selectedInterval,
    data: intervals,
    changeSelectedData: changeSelectedInterval,
    clearSelection: clearSelectedInterval
  } = useSelect();

  const {
    selectedData: selectedCompairServerSearches,
    data: compairServerSearches,
    changeSelectedData: changeSelectedCompairServerSearches,
    clearSelection: clearSelectedCompairServerSearches
  } = useSelect();

  const {
    setData: setPeriodData,
    selectedData: selectedPeriod,
    data: periods,
    changeSelectedData: changeSelectedPeriod,
    clearSelection: clearSelectedPeriod
  } = useSelect();

  const {
    setData: setGroupByData,
    selectedData: selectedGroupBy,
    data: groupByList,
    handleSelectedDataChange: handleGroupByChange,
    removeSelectedDataFromList: removeGroupByFromList,
    removeAll: removeAllGroupBy
  } = useMultipleSelect();

  const {
    setData: setRuleIds,
    selectedData: selectedRuleIds,
    data: ruleIds,
    handleSelectedDataChange: handleRuleIdChange,
    removeSelectedDataFromList: removeRuleIdFromList,
    removeAll: removeAllRuleIds
  } = useMultipleSelect();

  const {
    setData: setTagIds,
    selectedData: selectedTagIds,
    data: tagIds,
    handleSelectedDataChange: handleTagIdChange,
    removeSelectedDataFromList: removeTagIdFromList,
    removeAll: removeAllTagIds
  } = useMultipleSelect();

  const {
    setData: setPlatforms,
    selectedData: selectedPlatforms,
    data: platforms,
    handleSelectedDataChange: handlePlatformChange,
    removeSelectedDataFromList: removePlatformFromList,
    removeAll: removeAllPlatforms
  } = useMultipleSelect();

  const {
    setData: setSearchEngines,
    selectedData: selectedSearchEngines,
    data: searchEngines,
    handleSelectedDataChange: handleSearchEngineChange,
    removeSelectedDataFromList: removeSearchEngineFromList,
    removeAll: removeAllSearchEngines
  } = useMultipleSelect();

  const {
    setData: setTagTypes,
    selectedData: selectedTagTypes,
    data: tagTypes,
    handleSelectedDataChange: handleTagTypeChange,
    removeSelectedDataFromList: removeTagTypeFromList,
    removeAll: removeAllTagTypes
  } = useMultipleSelect();

  const {
    setData: setPublisherAccounts,
    selectedData: selectedPublisherAccounts,
    data: publisherAccounts,
    handleSelectedDataChange: handlePublisherAccountChange,
    removeSelectedDataFromList: removePublisherAccountFromList,
    removeAll: removeAllPublisherAccounts
  } = useMultipleSelect();

  const {
    setData: setChannels,
    selectedData: selectedChannels,
    data: channels,
    handleSelectedDataChange: handleChannelsChange,
    removeSelectedDataFromList: removeChannelsFromList,
    removeAll: removeAllChannels
  } = useMultipleSelect();

  const [filtersClearToogle, setFiltersClearToggle] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [orderBy, setOrderBy] = useState("");
  const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC

  const fetchChannelsByAdv = useCallback(() => {
    let params = {};
    if (selectedProviders?.length > 0) {
      removeChannelsFromList();
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["advertiser_id"] = selection;
    }
    Report.fetchChannelsByAdId({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE,
      ...params
    }).then((response) => {
      if (response.success) {
        const data = response?.data || [];
        const channels = [];
        for (let d of data) {
          channels.push({
            label: d?.channel,
            value: d?.channel
          });
        }
        setChannels(channels);
      }
    });
    // eslint-disable-next-line
  }, [setChannels, selectedProviders]);

  useEffect(() => {
    fetchChannelsByAdv();
  }, [fetchChannelsByAdv]);

  const fetchPublisherAccounts = useCallback(() => {
    PublisherAccount.fetchPublisherAccounts({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response?.data?.data || [];
          const publisherAccounts = [];
          for (let p of data) {
            publisherAccounts.push({
              label: p.id,
              value: p.id,
              status: p.status
            });
          }
          setPublisherAccounts(publisherAccounts);
        } else {
          throw new Error(
            response.error
              ? JSON.stringify(response.error)
              : new Error("Error fetching publisher accounts")
          );
        }
      })
      .catch((error) => {
        console.trace(error);
      });
  }, [setPublisherAccounts]);

  const fetchProviders = useCallback(() => {
    Provider.fetchProviders({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.providers;
          const providers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            providers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setProviders(providers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setProviders]);

  const fetchPublishers = useCallback(() => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            publishers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setPublishers]);

  const fetchTargeting = useCallback(() => {
    Publisher.getTargeting({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.targetings;
          const targetings = [];
          for (let t of data) {
            let isInactive = !t.is_active;
            targetings.push({
              label: t.id,
              value: t.id,
              status: t.status,
              disabled: isInactive
            });
          }
          setTagIds(targetings);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setTagIds]);

  const fetchTargetingRules = useCallback(() => {
    Publisher.fetchTargetingRules()
      .then((response) => {
        if (response.success) {
          const data = response.data.targetingRules;
          const targetingRules = [];
          for (let t of data) {
            targetingRules.push({
              label: t.id,
              value: t.id,
              disabled: t.disabled
            });
          }
          setRuleIds(targetingRules);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setRuleIds]);

  const fetchPlatforms = useCallback(() => {
    Platform.fetchPlatforms({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.platforms;
          const platforms = [];
          for (let p of data) {
            platforms.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setPlatforms(platforms);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setPlatforms]);

  const fetchSearchEngines = useCallback(() => {
    SearchEngine.fetchSearchEngines({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.search_engines;
          const searchEngines = [];
          for (let p of data) {
            searchEngines.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setSearchEngines(searchEngines);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setSearchEngines]);

  const fetchTagTypes = useCallback(() => {
    TagType.fetchTagTypes({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.tag_types;
          const tagTypes = [];
          for (let p of data) {
            tagTypes.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setTagTypes(tagTypes);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setTagTypes]);

  /**
   * making api calls and setting all the list values
   * */
  useEffect(() => {
    // fetchChannelsByAdv();
    fetchTargeting();
    fetchTargetingRules();
    fetchProviders();
    fetchPublishers();
    fetchPlatforms();
    fetchSearchEngines();
    fetchTagTypes();
    fetchPublisherAccounts();
    setCountriesData(COUNTRIES);
    setIntervalData(INTERVALS_REPORT_ADMIN);
    setGroupByData(GROUP_BY_REPORT_ADMIN);
    setPeriodData(PERIODS);
  }, [
    //fetchChannelsByAdv,
    setGroupByData,
    fetchProviders,
    setCountriesData,
    fetchPublishers,
    setIntervalData,
    fetchTargetingRules,
    fetchTargeting,
    fetchPlatforms,
    fetchSearchEngines,
    fetchPublisherAccounts,
    fetchTagTypes,
    setPeriodData
  ]);

  function clearAllSelection() {
    removeAllProviders();
    removeAllPublishers();
    removeAllCountries();
    setFromDate(null);
    setToDate(null);
    setFiltersClearToggle(!filtersClearToogle);
    removeAllGroupBy();
    removeAllRuleIds();
    removeAllTagIds();
    removeAllTagTypes();
    removeAllPlatforms();
    removeAllSearchEngines();
    removeAllPublisherAccounts();
    removeAllChannels();
    clearSelectedInterval();
    clearSelectedPeriod();
    clearSelectedCompairServerSearches();
  }

  useEffect(() => {
    if (ruleIds && ruleIds.length > 0) {
      if (
        findObject(ruleIds, localStorageRuleId) &&
        localStorageRuleId &&
        localStorageFromDate
      ) {
        handleRuleIdChange([localStorageRuleId]);
        if (localStorageFromDate === MONTH_TO_DATE) {
          changeSelectedPeriod(MONTH_TO_DATE);
        }
        setFiltersClearToggle((filtersClearToogle) => !filtersClearToogle);
        if (localStorageInterval && localStorageInterval === "day") {
          changeSelectedInterval(localStorageInterval);
        }
        setLocalStorageInterval("");
        setLocalStorageRuleId("");
        setLocalStorageFromDate("");
      }
    }
  }, [
    ruleIds,
    changeSelectedInterval,
    handleRuleIdChange,
    setFiltersClearToggle,
    changeSelectedPeriod,
    setLocalStorageInterval,
    setLocalStorageRuleId,
    setLocalStorageFromDate,
    localStorageRuleId,
    localStorageFromDate,
    localStorageInterval
  ]);

  useEffect(() => {
    // providers is alias for advertisers
    if (providers && providers.length > 0) {
      if (
        findObject(providers, localStorageAdvertiserId) &&
        localStorageAdvertiserId &&
        localStorageFromDate
      ) {
        handleProviderChange([localStorageAdvertiserId]);
        if (localStorageFromDate === MONTH_TO_DATE) {
          changeSelectedPeriod(MONTH_TO_DATE);
        }
        setFiltersClearToggle((filtersClearToogle) => !filtersClearToogle);
        if (localStorageInterval && localStorageInterval === "day") {
          changeSelectedInterval(localStorageInterval);
        }
        setLocalStorageInterval("");
        setLocalStorageAdvertiserId("");
        setLocalStorageFromDate("");
      }
    }
  }, [
    localStorageAdvertiserId,
    localStorageFromDate,
    localStorageInterval,
    changeSelectedInterval,
    handleProviderChange,
    setFiltersClearToggle,
    changeSelectedPeriod,
    setLocalStorageInterval,
    setLocalStorageAdvertiserId,
    setLocalStorageFromDate,
    providers
  ]);

  const handleToDate = (date) => {
    //date to be set is null , set it and return
    if (!date) {
      setToDate(null);
      return;
    }
    if (!fromDate) {
      setToDate(date);
    } else {
      if (moment(date).isAfter(fromDate) || moment(date).isSame(fromDate)) {
        setToDate(date);
      } else setToDate(fromDate);
    }
  };

  const handleFromDate = (date) => {
    //date to be set is null , set it and return
    if (!date) {
      setFromDate(null);
      return;
    }
    if (!toDate) {
      setFromDate(date);
    } else {
      if (moment(date).isBefore(toDate) || moment(date).isSame(toDate)) {
        setFromDate(date);
      } else {
        setToDate(date);
        setFromDate(date);
      }
    }
  };

  const handleDateChange = (fromDate, toDate) => {
    handleFromDate(fromDate);
    handleToDate(toDate);
  };

  let filterValues = {
    selectedChannels,
    selectedProviders,
    selectedCountries,
    selectedPublishers,
    selectedCompairServerSearches,
    fromDate,
    toDate,
    selectedInterval,
    selectedGroupBy,
    selectedRuleIds,
    selectedTagIds,
    selectedTagTypes,
    selectedPlatforms,
    selectedSearchEngines,
    selectedPublisherAccounts,
    selectedPeriod,
    orderBy,
    orderDirection
  };

  let dataLists = {
    channels,
    publishers,
    providers,
    countries,
    intervals,
    compairServerSearches,
    groupByList,
    tagIds,
    ruleIds,
    tagTypes,
    platforms,
    searchEngines,
    publisherAccounts,
    periods
  };

  let selectionAddActions = {
    handleChannelsChange,
    handleProviderChange,
    handleCountryChange,
    handlePublisherChange,
    changeSelectedCompairServerSearches,
    handleFromDate,
    handleToDate,
    changeSelectedInterval,
    handleGroupByChange,
    handleRuleIdChange,
    handleTagIdChange,
    handleDateChange,
    handleTagTypeChange,
    handlePlatformChange,
    handleSearchEngineChange,
    handlePublisherAccountChange,
    changeSelectedPeriod,
    setOrderBy,
    setOrderDirection
  };

  return {
    filterValues,
    clearAllSelection,
    dataLists,
    selectionAddActions,
    filtersClearToogle,
    removeProviderFromList,
    removeCountryFromList,
    removeAllCountries,
    removePublisherFromList,
    removeGroupByFromList,
    removeTagIdFromList,
    removeRuleIdFromList,
    removeTagTypeFromList,
    removePlatformFromList,
    removeSearchEngineFromList,
    removePublisherAccountFromList,
    removeChannelsFromList
  };
};
