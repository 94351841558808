import React from "react";
import TagStats from "./TagStats";
import ZapButtons from "./ZapButtons";

const FormAddtionalHeaders = ({ targetingID }) => {
  return (
    <>
      <ZapButtons targetingID={targetingID} />
      <TagStats tagId={targetingID} />
    </>
  );
};

export default FormAddtionalHeaders;
